<template>
<vuescroll id="home" :ops="ops" class="main-scroll">
   <div v-if="showBroadcasts" @noBroadcasts="onShowBroadcasts" class="main-right-heading">
      <p><small>live</small><span></span> now</p>
    </div>
   <div v-if="showBroadcasts" class="live-slider">
    <HomeCarousel></HomeCarousel>
  </div>
   <div class="video">
     <div class="video-heading">
      <h3>Famous <small>Servers</small></h3>
      <!--a href="#">See More <span><i class="fas fa-arrow-right"></i></span></a-->
    </div>
     <div class="video-blog">
          <HomeFamousServersParent></HomeFamousServersParent>
     </div>
  </div>
    <div class="video">
        <div class="video-heading">
            <h3>Latest <small>Servers</small></h3>
            <!--a href="#">See More <span><i class="fas fa-arrow-right"></i></span></a-->
        </div>
        <div  class="video-blog">
            <HomeLatestServersParent></HomeLatestServersParent>
        </div>
    </div>
   <div class="category">
     <div class="category-heading">
        <div class="video-heading">
            <h3>Categories <small>we think you’ll like</small></h3>
<!--            <b-button @click.prevent="$router.push({name: 'Browse'})" >See More <span><i class="fas fa-arrow-right"></i></span></b-button>-->
       </div>
       <HomeCategoriesParent></HomeCategoriesParent>
   </div>


  </div>

</vuescroll>
</template>

<script>
  import HomeCarousel from "../components/HomeCore/HomeCarousel";
  //import HomeCategoryYouLike from "../components/HomeCore/HomeCategoryYouLike";
  import HomeFamousServersParent from "../components/HomeFamousServersParent";
  import HomeCategoriesParent from "../components/HomeCategoriesParent";
  import HomeLatestServersParent from "../components/HomeLatestServersParent";
  import vuescroll from 'vuescroll';
  import axios from "@/axios";

  export default {
    name: 'Home',
    components: {
      //HomeCategoryYouLike,
      HomeCarousel,
      HomeFamousServersParent,
      HomeLatestServersParent,
      HomeCategoriesParent,
      vuescroll
    },
    data(){
      return{
        showBroadcasts: false,
        ops: {
          vuescroll: {
            mode: 'native',
            sizeStrategy: 'percent',
            detectResize: true,
            /** Enable locking to the main axis if user moves only slightly on one of them at start */
            locking: false,
          },
          scrollPanel: {
            initialScrollY: '0%',
            scrollingX: false,
            scrollingY: true,
            speed: 200,
            //easing: undefined,
            //verticalNativeBarPos: 'left'
          },
          rail: {},
          bar: {},
        },
        categories: null
      }
    },
    created() {
      this.$store.dispatch('set_openCloseSidebar', 'close'); // Temp
    },
    methods: {
      onShowBroadcasts(value) {
        this.onShowBroadcasts = value;
      }
    }
  }
</script>
<style>
  .main-scroll {
      overflow: auto;
      height: 100%;
      max-height: 100%;
  }


  .main-right-heading p {
    font-size: 22px;
    font-weight: 500;
    color: #fff;
  }

  .main-right-heading p span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #ff0000;
    border-radius: 50%;
    margin: 0px 5px;
    position: relative;
    top: -2px;
  }

  .main-right-heading small {
    font-size: 22px;
    font-weight: 500;
    color: #ff0000;
  }

  .main-right-heading {
    padding: 40px 60px;
    background: #171C22;
    /*padding-left: 60px;*/
  }
  .live-slider {
    position: relative;
  }
  .live-slider {
    background-color: #171C22;
    padding-bottom: 40px;
  }
  .video-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .video-heading h3 {
    font-size: 23px;
  }

  .video-heading h3 small {
    font-size: 19px;
    opacity: .6;
    font-weight: 400;
  }

  .video-heading a {
    display: inline-block;
    background-color: #6843FF;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 34px;
    color: #fff;
    transform: all .2s ease;
    -webkit-transform: all .2s ease;
    -moz-transform: all .2s ease;
  }

  .video-heading a:hover {
    opacity: .7;
  }

  .video-heading a span {
    display: inline-block;
    margin-left: 5px;
    color: #fff;
  }

  .video {
    background-color: #1E2124;
  }
  .video {
    padding: 40px 20px;
  }
  .category {
    padding: 40px 20px;
  }


  .category-heading a,
  .category-heading button {
    background-color: #26282A;
    border-color: transparent;
    color: #FFFFFF;
  }

  .category-heading a:hover,
  .category-heading button:hover {
    border-color: transparent;
    color: #FFFFFF;
  }

  .category-part {
    padding: 1.5em !important;
  }

</style>
