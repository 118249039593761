<template>
  <section style="height: 100%;max-height: 100%;">

    <div v-if="selectedServer" id="selected-server-title" class="server-title columns">

      <b-dropdown v-if="selectedServer.owner === userDataAfterLogIn._id" :triggers="['click']" aria-role="list" >
        <template #trigger="{ active }">
          <b-button
              id="server-settings-trigger"
              class="server-settings-trigger"
              :label="selectedServer.name"
              :icon-right="active ? 'angle-up' : 'angle-down'"
              expanded
          />
        </template>

        <!--          <template #trigger="{ active }">-->
        <!--            <div class="columns">-->
        <!--              <span class="column is-5  is-offset-1"-->
        <!--                  v-if="selectedServer.owner && selectedServer.owner === userDataAfterLogIn._id">-->
        <!--                {{ selectedServer.name }}-->
        <!--              </span>-->
        <!--              <fa-icon :icon="active ? 'angle-up' : 'angle-down'"> </fa-icon>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--          <div class="dropdown-menu-item-invite-people">-->
        <!--            <b-dropdown-item aria-role="listitem">+ Invite People</b-dropdown-item>-->
        <!--            <img src="../../assets/img/add-user-icon.png">-->
        <!--          </div>-->
        <!--          <hr class="menu-horizontal-line">-->
        <!--          <div id="menu-items-middle">-->
        <!--            <b-dropdown-item aria-role="listitem">Notification Settings</b-dropdown-item>-->
        <!--            <b-dropdown-item aria-role="listitem">Privacy Settings</b-dropdown-item>-->
        <!--            <b-dropdown-item aria-role="listitem">Manage Roles</b-dropdown-item>-->
        <!--          </div>-->
        <!--          <hr class="menu-horizontal-line">-->
        <b-dropdown-item dropdown-background-color="#fff"
                         @click="showServerSettingsModal = !showServerSettingsModal"
                         aria-role="listitem">
          Channel Settings
        </b-dropdown-item>
      </b-dropdown>
      <span v-else class="server-options-trigger">{{ selectedServer.name }}</span>
    </div>
    <ServerSettings
        v-if="showServerSettingsModal"
        v-on:serverSettingsFormClose="showServerSettingsModal = false"
        :server="selectedServer"
    />
    <div id="selected-server-rooms-menu" v-if="selectedServer">
      <div class="vuescroll-rooms">
        <a class="toggle-vuescroll-rooms" type="is-light"
           @click.prevent="isRoomsOpen = !isRoomsOpen">
          <b-icon icon-pack="fa" :icon="isRoomsOpen ?  'caret-up' : 'caret-down'"></b-icon>
        </a>
        <a class="title-vuescroll-rooms" type="is-light"
           @click.prevent="isRoomsOpen = !isRoomsOpen">Rooms</a>
        <a class="add-new-room"
           v-if="selectedServer && selectedServer.owner && selectedServer.owner === userDataAfterLogIn._id"
           type="is-light" @click.prevent="showCreateRoom = !showCreateRoom">
          <b-icon icon-pack="fa" icon="plus"></b-icon>
        </a>
        <!--a v-if="loadingRooms" style="border: 1px solid transparent" class="toggle-modal is-pulled-right" loading>Loading</a-->
      </div>
      <b-button v-if="loadingRooms"
                style="background-color: transparent; border-color: transparent; color: #7c7c7c;"
                expanded
                :loading="loadingRooms"
      >Loading Rooms...
      </b-button>
      <b-menu-item
          v-if="(!loadingRooms && selectedServerRooms && !selectedServerRooms.length)"
          class="server-room"
      ><span class="server-name"># No Rooms Yet</span>
      </b-menu-item>

      <vuescroll v-show="isRoomsOpen" :ops="ops" v-if="selectedServerRooms && selectedServerRooms.length > 0">
        <b-menu-item
            class="server-room"
            v-for="(room , i) in selectedServerRooms"
            :class="{ 'selected' : selectedRoom && room && selectedRoom._id === room._id,
                        'hidden' : !room,
                        'broadcast' : room && room.name.slice(0, 5) === 'Broad',
                         'joined' : userRooms &&  userRooms.indexOf(room._id) !== -1
                      }"
            :key="'room-'+i"
            @click="onRoom(room)"
        >
          <template slot="label">
            <span v-if="room" class="server-name"># {{ room.name }}</span>
            <b-button
                :disabled="true"
                class="room-access"
                v-if="userRooms"
            >
              <fa-icon v-if="userRooms.indexOf(room._id) !== -1" :size="$faDefaultIconSize" style="color: #4a4a4a;"
                       icon="check-circle"></fa-icon>
              <fa-icon v-if="userRooms.indexOf(room._id) === -1 && room.price > 0" :size="$faDefaultIconSize"
                       style="color: #4a4a4a;"
                       icon="lock"></fa-icon>
              <fa-icon v-if="userRooms.indexOf(room._id) === -1 && room.price === 0" :size="$faDefaultIconSize"
                       style="color: #4a4a4a;"
                       icon="arrow-circle-right"></fa-icon>
            </b-button>
            <b-button
                :disabled="true"
                class="room-access"
                v-if="!userRooms"
            >
              <fa-icon v-if="room.price > 0" :size="$faDefaultIconSize" style="color: #4a4a4a;" icon="lock"></fa-icon>
              <fa-icon v-if="room.price === 0" :size="$faDefaultIconSize" style="color: #4a4a4a;"
                       icon="arrow-circle-right"></fa-icon>
            </b-button>
          </template>
        </b-menu-item>
      </vuescroll>
    </div>

    <div id="selected-server-users-menu" v-if="selectedServerUsers">
      <div class="vuescroll-rooms">
        <a class="toggle-vuescroll-rooms" type="is-light"
           @click.prevent="isUsersOpen = !isUsersOpen"
        >
          <b-icon icon-pack="fa" :icon="isUsersOpen ?  'caret-up' : 'caret-down'"></b-icon>
        </a>
        <a class="title-vuescroll-rooms" type="is-light"
           @click.prevent="isRoomsOpen = !isRoomsOpen">Users</a>

      </div>
      <vuescroll v-show="isUsersOpen" :ops="ops">
        <div class="server-user"
             v-for="selectedServerMember in selectedServerUsers"
             :key="selectedServerMember._id">
          <b-menu-item
              @click="openPrivateRoom(selectedServerMember)"
          >
            <template slot="label">
              <span class="server-user-image"
                    :style="{ 'background-image': `url(${setAvatar(selectedServerMember.user.image)})` }">&nbsp;</span>
              <span class="server-user-name">{{ userLabel(selectedServerMember) }}</span>
              <!--b-dropdown aria-role="list" class="mini-profile is-pulled-right is-top-right" append-to-body>
                <b-icon class="mini-profile-trigger" style="color: #4a4a4a;" icon="account" slot="trigger"></b-icon>
                <b-dropdown-item aria-role="listitem" >Image Here</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" >Profile</b-dropdown-item>
              </b-dropdown-->
            </template>
          </b-menu-item>
          <b-button
              :id="'su-'+selectedServerMember._id"
              class="mini-profile-trigger"
              @click="openProfileModal(selectedServerMember, $event)"
              style="border: 0 solid transparent !important; box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);">
            <!--b-icon style="color: #4a4a4a;" icon="account"></b-icon-->
            <fa-icon v-if="profileModalUser && profileModalUser._id === selectedServerMember._id"
                     icon="times-circle" class="navbar-button-icon" :size="$faDefaultIconSize" style="color: #4a4a4a;"/>
            <fa-icon v-else icon="info-circle" class="navbar-button-icon" :size="$faDefaultIconSize"
                     style="color: #4a4a4a;"/>

          </b-button>
        </div>
      </vuescroll>
    </div>
    <MiniProfile id="mini-profile-modal"
                 ref="miniProfile" v-if="profileModalUser"
                 :user="profileModalUser"
                 v-on:openPrivate="openPrivateRoom"></MiniProfile>

    <CreateRoom v-if="showCreateRoom && selectedServer" :server="selectedServer"
                v-on:createRoomFormClose="showCreateRoom = false"></CreateRoom>
    <JoinRoom v-if="roomToJoin" :room="roomToJoin"></JoinRoom>
  </section>
</template>

<script>
//import CreateRoom from '../RoomsCore/CreateRoom.vue';
import JoinRoom from '../RoomsCore/JoinRoom.vue';
import MiniProfile from '../Traits/MiniProfileDiv.vue';
import axios from "@/axios";
import vuescroll from "vuescroll";
import EventBus from '@/eventBus';
import ServerSettings from '../ServersCore/ServerSettings';

export default {
  name: "RoomsAndUsers",
  components: {
    //CreateRoom,
    JoinRoom,
    vuescroll,
    MiniProfile,
    'CreateRoom': () => import('../RoomsCore/CreateRoom.vue'),
    ServerSettings
  },
  props: ['loadingRooms'],
  data() {
    return {
      showServerSettingsModal: false,
      isOpen: 0,
      modalLeft: null,
      autoSelection: null,
      modalBottom: null,
      isRoomsOpen: true,
      isUsersOpen: true,
      rooms: [],
      // loadingRooms: false,
      loadingUsers: false,
      isActive: true,
      showCreateRoom: false,
      //userServers: null,
      serverUsers: null,
      showJoinRoom: false,
      roomToJoin: null,
      profileModalUser: null,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {},
      },
    }
  },
  watch: {
    rooms: () => {
      return this.$store.getters.get_selectedServerRooms;
    },
    // loadingRooms: (loadingRooms) => {
    //   console.log('loadingRooms', loadingRooms)
    // }
  },
  computed: {
    selectedRoom() {
      return this.$store.getters.get_selectedRoom;
    },
    selectedServerRooms() {
      return this.$store.getters.get_selectedServerRooms;
    },
    selectedServer() {
      return this.$store.getters.get_selectedServer;
    },
    selectedServerUsers() {
      return this.$store.getters.get_selectedServerUsers;
    },
    token() {
      return this.$store.getters.get_token;
    },
    defaultOwnerImage() {
      return require('@/assets/img/user-profile-default.png');
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    userRooms() {
      return this.$store.getters.get_userRooms;
    },
    userServers() {
      return this.$store.getters.get_userServers;
    },
  },
  created() {
    // console.log('rooms user created', this.$route.params);
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'set_selectedServerRooms' && mutation.payload) {
        console.log('set_selectedServerRooms1');
        if (this.$route.params && this.$route.params.room) {
          const roomToChoose = mutation.payload.filter(room => room.slug === this.$route.params.room);
          console.log('set_selectedServerRooms', roomToChoose, this.$route.params.room);
          if (roomToChoose.length) {
            this.onRoom(roomToChoose[0])
          }
        }
      }
    });
  },
  mounted() {
    console.log(this.selectedServer)
  },
  methods: {
    async onRoom(selectedRoom) {
      // let autoSelect = this.$store.getters.get_autoSelect;
      // console.log('onRoom ruse', selectedRoom);
      this.$store.commit('set_autoSelect', null);
      this.$store.commit('set_selectedRoom', null);
      this.$store.commit('set_selectedRoomMessages', []);
      this.$store.commit('set_selectedRoomGroups', []);
      this.$store.commit('set_previousMessagesIndex', null);
      this.$store.commit('set_nextMessagesIndex', null);
      if (this.userRooms && this.userRooms.length && this.userRooms.indexOf(selectedRoom._id) !== -1) {
        // this.respondToRouteChanges = false;
        // this.$router.replace({params: { room: selectedRoom.slug }})
        //     .finally(() => {
        //       // this.respondToRouteChanges = true;
        //     });
        this.$store.commit('set_selectedRoom', selectedRoom);
        // this.$router.replace(`/servers/${this.selectedServer.slug}/${selectedRoom.slug}`);
        // this.$router.replace(`/servers/${this.selectedServer.slug}/${selectedRoom.slug}`);
        // this.$router.push({ path: `/servers/${this.selectedServer.slug}/${selectedRoom.slug}` });
      } else {
        // In case we are not already joined room
        this.roomToJoin = selectedRoom;
      }

    },
    openProfileModal(user, event) {
      if (this.profileModalUser && this.profileModalUser._id === user._id) {
        this.profileModalUser = null;
      } else {
        this.profileModalUser = null;
        let parent = event.target.closest(".mini-profile-trigger");
        if (parent) {
          let viewportOffset = parent.getBoundingClientRect();
          let modalLeft = viewportOffset.left;
          let modalTop = viewportOffset.top;
          document.documentElement.style.setProperty('--modalLeft', `${modalLeft}px`);
          document.documentElement.style.setProperty('--modalTop', `${modalTop}px`);
          //console.log('openProfileModal :  ', user);
        }
        setTimeout(() => {
          this.profileModalUser = user;
        }, 100);

      }
    },
    closeProfileModal(user, event) {
      console.log('closeProfileModal : ', event);
    },
    async openPrivateRoom(member) {
      this.profileModalUser = null;
      try {
        if (Object.keys(this.$store.getters.get_privateChatWithMember).length === 0 || member.user._id !== this.$store.getters.get_privateChatWithMember.user._id) {
          this.$store.commit('set_selectedPrivateRoomGroups', []);
          this.$store.commit('set_selectedPrivateRoomMessages', []);
          // console.log('clicked regular member is :  ', member);
          this.$store.commit('set_privateChatWithMember', member)
          const privateRoom = await this.$store.dispatch('createPrivateRoom', member);
          // console.log('ACTION RETURN SELECTED PRIVATE ROOM IF EXISTING OR NOT ', privateRoom);
          // Set an if here
          EventBus.$emit('sidebarClass', 'normal');
          this.$store.commit('set_privateSelectedRoom', privateRoom);
          this.$store.commit('set_openPrivateChat'); // Check
          EventBus.$emit('sidebarClass', 'normal');
          EventBus.$emit('selectedTab', 'chat');
          this.$store.commit('set_showLoadMoreMessagesPrivate', true);
          await this.$store.dispatch('messagesWithPaginationPrivate',
              {
                roomId: privateRoom._id,
                flag: 1,
              });
        }
      } catch (e) {
        console.log('ERROR with openPrivateRoom  ', e);
      }
    },
    userLabel(serverUser) {
      if (serverUser.user && serverUser.user.username) {
        return serverUser.user.username;
      } else {
        return 'User';
      }
    },
    userImage(serverUser) {
      let name;
      if (serverUser.user && serverUser.user.image) {
        return '';
      } else if (serverUser.user && serverUser.user.username) {
        return serverUser.user.username.slice(0, 1);
      } else {
        return '';
      }
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },
    userImageStyle(serverUser) {
      let style = `background-image: url(${this.defaultOwnerImage});`;
      if (serverUser.user && serverUser.user.image && serverUser.user.image.slice(0, 4) === 'http') {
        //console.log(typeof serverUser.user.image);
        style = `background-image: url(${serverUser.user.image});`;
      }
      return style;
    },
    selectUser(serverUser) {
      this.openPrivateRoom
    },
    async setSelectedServerRooms1s() {
      let result = await this.getSelectedServerRooms();
      if (result) {
        this.selectedServerRooms = result;
      }
    },
    async getSelectedServerRooms() {
      this.loading = true;

      try {
        let responseRooms = await axios.get('/rooms');
        this.loading = false;
        return responseRooms.data;
      } catch (e) {
        console.log('GET ROOMS ERROR  ', e);
        this.loading = false;
        return e;
      }
    },
    async deleteServer(id) {
      try {
        let response = await axios.delete('/servers/' + id);
        console.log('server delete', response);
        let user = {
          token: this.token
        };
        let result = await this.$store.dispatch('login', user);
        //this.$router.replace('/');

      } catch (e) {
        console.log('GET ROOMS ERROR  ', e);
      }

    },
    async setHashtagsForMention() {
      const hashtags = [];
      const {data} = await axios.get('/hashtag');
      data.forEach(hashtag => {
        const temp = {};
        temp['id'] = hashtag._id;
        temp['name'] = hashtag.name;
        hashtags.push(temp);
      })

      return hashtags;
    },
    serverSettings() {
    },
  },
}
</script>

<style>

#selected-server-title {
  text-align: left;
  height: 5%;
  max-height: 5%;
  /*overflow: auto;*/
  display: table;
  width: 100%;
  margin-top: 5px;
  padding-left: 20px;
}

#selected-server-title span {
  display: table-cell;
  vertical-align: middle;
}

#selected-server-title span:hover {
  /*cursor: pointer;*/
  /*color: rgb(103, 76, 244);*/
}

#selected-server-title .dropdown,
#selected-server-title .dropdown-trigger {
  width: 100%;
}

#selected-server-rooms-menu,
#selected-server-users-menu {
  height: 47.5%;
  max-height: 47.5%;
  overflow: hidden;
}

#selected-server-rooms-menu .menu-label,
#selected-server-users-menu .menu-label {
  text-align: center;
  margin: 0;
  padding: 1em;
  font-size: 1.1em;
  letter-spacing: 0;
  text-transform: capitalize;
  color: #fff;
}

#selected-server-rooms-menu .menu,
#selected-server-users-menu .menu {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}


#selected-server-rooms-menu ul.menu-list,
#selected-server-users-menu ul.menu-list {
  padding: 0;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#selected-server-rooms-menu ul.menu-list li {

}

#selected-server-rooms-menu ul.menu-list li a {

}

#selected-server-rooms-menu .menu-list li.selected,
#selected-server-users-menu .menu-list li.selected {
  background-color: #303539;
  color: #fff;
}

#selected-server-rooms-menu .menu-list li.selected a:hover,
#selected-server-users-menu .menu-list li.selected a:hover {
  background-color: transparent;
  color: #fff;
}

#selected-server-rooms-menu .menu-list li.selected a,
#selected-server-users-menu .menu-list li.selected a {
  color: #fff;
}

#selected-server-rooms-menu .menu-list a.is-active,
#selected-server-users-menu .menu-list a.is-active {
  background-color: transparent;
  color: #808080;
}

#selected-server-rooms-menu .menu-list a.is-active.is-expanded,
#selected-server-users-menu .menu-list a.is-active.is-expanded {
  background-color: transparent;
  color: #808080;
}


#selected-server-rooms-menu ul.menu-list li a:hover,
#selected-server-users-menu ul.menu-list li a:hover {
  /**background-color: #2e2e2e;*/

  background-color: transparent;
  color: #a588ff;
  text-decoration: none;
}

#selected-server-rooms-menu .menu-list li ul,
#selected-server-users-menu .menu-list li ul {
  border-left: 0px solid #dbdbdb;
  margin: 0em;
  padding-left: 0;
}

#selected-server-rooms-menu .menu-list a.is-active.is-expanded:first-child,
#selected-server-users-menu .menu-list a.is-active.is-expanded:first-child {
  color: #808080;
}

button.toggle-modal {
  background-color: transparent !important;
  color: inherit !important;
  height: 100%;
  max-height: 100%;
  padding: 0;
  margin-top: -3px;
}

.toggle-modal .icon {

}

.server-title {
  text-align: center;
  margin-bottom: 1em;
}

#selected-server-users-menu .server-user a {
  display: table;
  width: 100%;
}

#selected-server-users-menu .__vuescroll {
  height: 100%;
  max-height: 100%;
}

#selected-server-users-menu .__panel {
  height: 100%;
  max-height: 100%;
}

#selected-server-users-menu .__view {
  height: 100%;
  max-height: 100%;
}

.server-user {
  padding: 9px 26px 9px 26px;
  list-style: none;
  position: relative;
}

.server-user.hidden {
  display: none;
}

.server-user a {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.server-room {
  padding: 9px 26px 9px 26px;
  display: table;
  width: 100%;
  position: relative;
}

.server-room.hidden,
.server-room.broadcast {
  display: none;
}

.server-room.selected {
  background-color: #303538;
}


.server-room a {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.server-room span {
  color: #7c7c7c;
}

/**
.server-room.joined span {
  color: #aeaeae;
}
*/


.server-name {
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 0;
}

.server-user-name {
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 9px;
}

.server-user-image {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  margin: 0 9px 0 0;
  /*padding: 8px 8px 8px 9px;*/
  padding: 0;
  border-radius: 14px;
  background-color: #5f5bfc;
  background-position: center;
  background-size: cover;
  display: table-cell;
}

#rooms-scroll-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#selected-server-users-menu .__vuescroll {
  height: calc(100% - 40px) !important;
  max-height: calc(100% - 40px) !important;
}

#selected-server-rooms-menu .__vuescroll {
  height: calc(100% - 40px) !important;
  max-height: calc(100% - 40px) !important;
}

.vuescroll-rooms,
.vuescroll-users {
  padding: 9px 30px 9px 30px;
  width: 100%;
  max-width: 100%;
  display: table;
  position: relative;
}


.toggle-vuescroll-rooms {
  display: table-cell;
  color: #888888;
  vertical-align: middle;
  position: absolute;
  left: 6px;
  top: 10px;
}

.title-vuescroll-rooms {
  padding: 0;
  /**width: 65%;
  max-width: 65%;*/
  display: table-cell;
  color: #888888;
  vertical-align: middle;
}


.add-new-room {
  width: 20%;
  max-width: 20%;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  color: #888888;
}

.title-vuescroll-rooms:hover,
.toggle-vuescroll-rooms:hover,
.add-new-room:hover {
  color: #7957d5;
  text-decoration: none;
}


.toggle-vuescroll {
  width: 100%;
  max-width: 100%;
  display: table;
  color: #888888;
  height: 40px;
}


.toggle-vuescroll:hover {
  color: #7957d5;
  text-decoration: none;
}

.toggle-vuescroll span {
  display: table-cell;
  vertical-align: middle;
  padding-left: 1em;
}

.toggle-vuescroll span.icon {
  padding-left: 0.5em;
}

.mini-profile {

}

button.mini-profile-trigger {
  position: absolute;
  right: 2px;
  background-color: transparent;
  border: 0 solid transparent !important;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  top: 7px;
}

button.mini-profile-trigger:hover {
  background-color: transparent;
  border: 0 solid transparent !important;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
}

button.room-access {
  position: absolute;
  right: 2px;
  background-color: transparent;
  border: 0 solid transparent !important;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  top: 0px;
  cursor: default;
}

button.room-access[disabled] {
  background-color: transparent;
  border: 0 solid transparent !important;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  cursor: default;
}

button.room-access:hover {
  background-color: transparent;
  border: 0 solid transparent !important;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
}


/**
#mini-profile-modal .modal-content {
  background-color: #1e2124 !important;
  padding: 0;
  border-radius: 0 !important;
  overflow: hidden;
  position: absolute;
  height: 335px;
  left: calc(var(--modalLeft) + 20px );
  top: calc(var(--modalTop) - 310px );
}
*/

#mini-profile-modal {
  background-color: #1e2124 !important;
  padding: 0;
  border-radius: 0 !important;
  overflow: hidden;
  position: absolute;
  height: 335px;
  width: 255px;
  left: calc(var(--modalLeft) - 40px);
  top: calc(var(--modalTop) - 365px);
  z-index: 99;
}


#mini-profile-modal .modal-background {
  background-color: rgba(10, 10, 10, 0) !important;
  /**display: none;
  left: calc(var(--modalLeft) + 50px );*/
}

#server-settings-trigger {
  background-color: transparent;
  border-color: transparent;
  color: #FFFFFF;
  position: relative;
  display: block;
}



#server-settings-trigger:hover span {
  color: #FFFFFF;
}

#server-settings-trigger .icon {
  position: absolute;
  right: 25px;
  color: #7957d5;
  top: 50%;
  transform: translateY(-50%);
}

#server-settings-trigger:hover .icon {
  color: #7957d5;
}

.server-options-trigger {
  background-color: transparent;
  border-color: transparent;
  color: #FFFFFF;
  position: relative;
  display: block;
  padding: calc(0.5em - 1px) 1em;
}

.dropdown.dropdown-menu-animation .dropdown-menu {
  width: 100%;
}

.dropdown.dropdown-menu-animation .dropdown-menu .dropdown-content {
  background: #2E3135;
  text-align: left;
}
.dropdown.dropdown-menu-animation .dropdown-menu .dropdown-item {
  color: #A0ABB3;
  line-height: 14px;
}
.dropdown.dropdown-menu-animation .dropdown-menu .dropdown-item:hover,
.dropdown-menu-item-invite-people:hover{
  color: #8064F6;
  background-color: transparent!important;
}

hr.menu-horizontal-line {
  background: #D8D8D8;
  opacity: 0.06;
}

.dropdown-menu-item-invite-people {
  height: 16px;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.dropdown-menu-item-invite-people img {
  width: 17px;
  height: 17px;
  margin: 10px 21px 0 0;
  cursor: pointer;
}

#menu-items-middle {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
