<template>
    <section>
        <b-modal id="login" v-model="showSignIn" :width="540" scroll="keep" :on-cancel="handleClose"
        >
          <h4 class="brand">PROFIT</h4>
          <h2 class="modal-title medium">{{titles[activeStep]}}</h2>
            <b-steps
                    v-model="activeStep"
                    :animated="isAnimated"
                    :rounded="isRounded"
                    :has-navigation="hasNavigation"
                    :icon-prev="prevIcon"
                    :icon-next="nextIcon"
                    :label-position="labelPosition"
                    :mobile-mode="mobileMode">
                <b-step-item step="0" label="Login" :clickable="isStepsClickable">
                    <ValidationObserver tag="div" class="form-observer" ref="loginForm" v-slot="{ invalid }">
                        <form >
                            <ValidationProvider tag="div" class="validation-field"  name="username" rules="required" v-slot="{ errors, valid }">
                                <b-field label="Username / Email"
                                         class="form-field"
                                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                >
                                    <b-input class="login-input" vid="username" v-model="username" :readonly="loading"></b-input>
                                </b-field>
                                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{errors[0]}}&nbsp;</p>
                            </ValidationProvider>

                            <ValidationProvider  tag="div" class="validation-field" name="password" rules="required" v-slot="{ errors, valid }">
                                <b-field label="Password"
                                         class="form-field"
                                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                >
                                    <b-input type="password"
                                             v-model="password"
                                             class="login-input"
                                             vid="password"
                                             :readonly="loading"
                                             @keyup.native.enter="doLogin"
                                             password-reveal>
                                    </b-input>
                                </b-field>
                                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{errors[0]}}&nbsp;</p>
                            </ValidationProvider>

                            <footer id="login-footer" class="footer">
<!--                                <p class="form-note">Forgot your password?</p>-->
                                <p class="form-note"><a href="#" @click.prevent="askReset($event)">I've Forgot Password</a></p>
                                <p class="form-note" style="margin-top: 2em;" ><span >Don't have an account? </span><a  style="margin-left: 3px;" href="#" @click.prevent="openSignup()">Register</a></p>
                                <b-button
                                        class="submit login-submit"
                                        type="submit"
                                        ref="loginButton"
                                        :loading="loading"
                                        :disabled="invalid"
                                        @click="doLogin"
                                >Login</b-button>
                            </footer>
                        </form>
                    </ValidationObserver>
                </b-step-item>

                <b-step-item step="1" label="Reset Password" :clickable="isStepsClickable" :type="{'is-success': isProfileSuccess}">
                    <section>
<!--                      <p class=""><a href="#" @click.prevent="activeStep = 0">Back</a></p>-->
<!--                      <h3 style="margin:1em auto;" >Password Reset</h3>-->
                        <ValidationObserver tag="div" class="form-observer" ref="form" v-slot="{ invalid }">
                            <form @submit.prevent="handleReset">
                                <ValidationProvider  tag="div" class="validation-field" name="Your Email" rules="required|email" v-slot="{ errors, valid }">
                                    <b-field label="Your Email"
                                             :message="errors"
                                             :class="{ 'is-danger': errors[0], 'is-success': valid }"
                                             :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                             class="form-field"
                                    >
                                        <b-input type="text"
                                                 autocomplete="off"
                                                 class="login-input"
                                                 v-model="emailForReset"
                                                 vid="emailForReset"
                                                 :readonly="loading"
                                        >
                                        </b-input>
                                    </b-field>
                                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                                </ValidationProvider>
                                <footer id="password-reset-footer" class="footer">
                                    <b-button
                                            class="submit"
                                            type="submit"
                                            style="width: 100%;margin: 21px 0;"
                                            :loading="loading"
                                            :disabled="invalid"
                                            @click="handleReset"
                                    >Reset Password</b-button>
                                  <p class="bottom-link"><a style="color: white" href="#" @click.prevent="activeStep = 0">Back to login</a></p>
                                </footer>
                            </form>
                        </ValidationObserver>
                    </section>
                </b-step-item>
                <b-step-item step="2"  label="" :clickable="isStepsClickable">
<!--                  <h1 style="text-align: center;font-size: 120%;">Reset password Email was sent!</h1>-->
                  <div class="xcentered" style="margin:16px 0 24px;"><img :src="emailIcon"  /></div>
<!--                  <sweetalert-icon v-if="false" icon="warning"></sweetalert-icon>-->
                  <footer id="password-reset-conf-footer" class="footer">
                    <p class="product-19" style="margin: 0 0 57px;" >Please open the email and follow <br> the password reset link.</p>
                    <p class="product-15" style="color: #51555A">Have not received email?</p>
                    <p class="product-15">Please check the spam folder.</p>
                    <b-button
                        class="submit"
                        type="submit"
                        @click="handleClose()"
                    >Close</b-button>
                  </footer>
                </b-step-item>
                <b-step-item step="3"  label="Confirm Account" :clickable="isStepsClickable">
                  <h1 style="text-align: center;font-size: 120%;">Email confirmation is <span>required.</span></h1>
                  <sweetalert-icon icon="warning"></sweetalert-icon>
                  <footer id="account-confirm-footer" class="footer">
                    <p class="signup-note">Please open the welcome email and follow the activation link.</p>
                    <p class="signup-note" style="margin-top: 0.25em;"><span style="color: #7957d5;">Have not received email?</span> Please check the spam folder.</p>
                    <b-button
                        class="submit"
                        type="submit"
                        @click="handleResend()"
                    >Resend Email</b-button>
                  </footer>
                </b-step-item>
            </b-steps>
        </b-modal>
    </section>
</template>

<script>
    import axios from "../axios";
    import {
        ValidationProvider,
        ValidationObserver,
        extend
    } from 'vee-validate/dist/vee-validate.full';

    import SweetalertIcon from 'vue-sweetalert-icons';

    export default {
        name: "Login",
        components: {
            ValidationObserver,
            ValidationProvider,
            SweetalertIcon
        },
        data(){
            const today = new Date();
            return{
                tokens: null,
                authData: null,
                accessToken: null,
                refreshToken: null,
                validToken: null,
                username:'',
                email:'',
                password: '',
                password2: '',
                firstName: '',
                lastName: '',
                emailForReset: '',
                resetEmailSent : false,
                date: new Date(),
                minDate: new Date(1950,1,1),
                maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                activeStep: 0,
                locale: undefined,  // browser locale
                loading: false,
                showSignIn: true,
                isProfileSuccess: false,
                isAnimated: true,
                isRounded: true,
                isStepsClickable: false,
                hasNavigation: false,
                customNavigation: false,
                prevIcon: 'chevron-left',
                nextIcon: 'chevron-right',
                labelPosition: 'bottom',
                mobileMode: 'minimalist',
                titles: ['Login', 'Reset Password', '', 'Confirm Account' ]
            }
        },
        computed:{
            open_login1(){
                return this.$store.getters.get_openCloseLogIn;
            },
            get_user(){
                return this.$store.getters.get_user;
            },
            get_token() {
                return this.$store.getters.get_token;
            },
            get_refreshToken() {
                return this.$store.getters.get_refreshToken;
            },
            get_userDataAfterLogIn() {
                return this.$store.getters.get_userDataAfterLogIn;
            },
            axios(){
                return this.$store.getters.get_axios;
            },
            emailIcon() {
              return require('@/assets/img/Email.svg');
            },
        },
        mounted() {

        },
        methods:{
            async doLogin() {
                this.loading = true;
                try {
                    this.authData = {
                        usernameOrEmail: this.username,
                        password: this.password,
                    };
                    let result = await this.$store.dispatch('login', this.authData);
                    this.loading = false;
                    if (result.status === 401) {
                        this.activeStep = 3;
                    } else if (result.status > 401 || (result.statusCode && result.statusCode > 401 ) ) {
                      this.$refs.loginForm.setErrors({ password: ['Username or Password is Wrong'] });
                    } else if(result.status === 201 && result.data && result.data.tokens) {
                      //this.$localStorage.set('accessToken', result.data.tokens.accessToken);
                      //this.$localStorage.set('user', JSON.stringify(result.data.user)); // tempo
                      this.$store.commit('set_openCloseLogIn');
                      //this.$store.dispatch('connectToSocket', result.data.tokens.accessToken);
                    } else {
                      this.$store.commit('set_openCloseLogIn');
                    }
                } catch (e) {
                    console.log('error:', e);
                }
            },
            handleEnter() {
              document.querySelector('#login').addEventListener('keypress', (e) => {
                if (e.key === 'Enter') {
                  console.log('enter:', this.$refs.loginButton); // Check
                }
              });
            },
            handleResend() {

            },
            dateToLocalISO(date) {
                const off    = date.getTimezoneOffset()
                const absoff = Math.abs(off)
                return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
                    (off > 0 ? '-' : '+') +
                    (absoff / 60).toFixed(0).padStart(2,'0') + ':' +
                    (absoff % 60).toString().padStart(2,'0'))
            },
            async getUser(user) {
                try {
                    return await this.axios.get('/users/' + user._id);
                }
                catch (error) {
                    console.log(error);
                    return error;
                }
            },
            askReset(e) {
                e.preventDefault();
                this.activeStep = 1;
            },
            openSignup() {
              this.$store.commit('set_openCloseLogIn');
              this.$store.commit('set_openSignUp');

            },
            handleReset(e) {
              e.preventDefault();
              this.requestReset();
            },
            requestReset() {
              this.loading = true;
                this.authData = {
                  email: this.emailForReset,
                };
                this.axios.post('/auth/reset-password/' + this.emailForReset)
                    .then(response => {
                      this.loading = false;
                      if (response.status && response.status >= 200 && response.status < 300) {
                        this.activeStep = 2;
                      } else if  (response.status && response.status >= 400 && response.status < 500) {
                        this.$refs.form.setErrors({ emailForReset: ['No account with this email exists!'] });
                      }
                    })
                    .catch(function (error) {
                      this.loading = false;
                      console.log(error);
               });
            },
            handleClose() {
                this.$store.commit('set_openCloseLogIn');
            },
        },
    }
</script>

<style>

    /*#login {*/
    /*    width: 414px;*/
    /*    height: 726px;*/
    /*    padding: 51px;*/
    /*    border-radius: 17px;*/
    /*    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);*/
    /*    background-color: #1e2124;*/
    /*}*/

    /*h2.modal-title {*/
    /*    margin: 0.25em 0 0.1em;*/
    /*    font-family: Circular, sans-serif;*/
    /*    font-size: 41px;*/
    /*    font-weight: 500;*/
    /*    font-stretch: normal;*/
    /*    font-style: normal;*/
    /*    line-height: normal;*/
    /*    letter-spacing: -1.16px;*/
    /*    color: rgba(255, 255, 255, 0.84);*/
    /*    overflow: hidden;*/
    /*    white-space: normal;*/
    /*}*/

    /*#login h4 {*/
    /*    margin: 0;*/
    /*    font-family: Circular, sans-serif;*/
    /*    font-size: 11px;*/
    /*    font-weight: 500;*/
    /*    font-stretch: normal;*/
    /*    font-style: normal;*/
    /*    line-height: normal;*/
    /*    letter-spacing: 3.07px;*/
    /*    color: #8356ff;*/
    /*}*/

    /*#login.modal .modal-content {*/
    /*    background-color: #1e2124 !important;*/
    /*    max-width: 420px !important;*/
    /*    padding: 1.5em 1em 1em;*/
    /*    border-radius: 17px!important;*/
    /*    !*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*!*/
    /*}*/

    #login-footer {
        margin-top: 1em;
        background-color: transparent;
        padding: 0;
        width: 100%;
        text-align: center;
    }

    #password-reset-footer {
        margin-top: 1em;
        background-color: transparent;
        padding: 0;
        width: 100%;
        text-align: center;
    }


    #password-reset-conf-footer  {
      /*margin-top: 1em;*/
      background-color: transparent;
      padding: 0;
      width: 100%;
      text-align: center;
    }

    /*.login-field {*/
    /*    margin: 0.5em auto !important;*/
    /*    border-bottom: 1px solid #2d3034 !important;*/
    /*}*/

    /*.help {*/
    /*    min-height: 1em;*/
    /*    margin: 0;*/
    /*    display: block !important;*/
    /*}*/

    /*.login-note {*/
    /*    font-weight: 400;*/
    /*    font-size: 12px;*/
    /*    text-align: left;*/
    /*    letter-spacing: 0.5px;*/
    /*}*/


    /*.login-field label {*/
    /*    color: #51555a !important;*/
    /*    font-family: Circular, sans-serif;*/
    /*    font-size: 17px;*/
    /*    font-weight: normal;*/
    /*    font-stretch: normal;*/
    /*    font-style: normal;*/
    /*    line-height: normal;*/
    /*    letter-spacing: -0.11px;*/
    /*    margin-bottom: 0.1em !important;*/
    /*}*/

    /*.login-input input {*/
    /*    background-color: transparent;*/
    /*    color: #ffffff;*/
    /*    border: 0px solid transparent !important;*/
    /*    !**border-bottom-width: 1px;*!*/
    /*    !**border-bottom: 1px solid #2d3034 !important;**!*/
    /*}*/

    /*.login-input input:focus,*/
    /*.login-input input.is-danger,*/
    /*.login-input input.is-success,*/
    /*.datepicker input,*/
    /*.datepicker input:focus,*/
    /*.datepicker input.is-danger,*/
    /*.datepicker input.is-success,*/
    /*.datepicker .dropdown-trigger .input[readonly]:focus,*/
    /*.datepicker input.is-danger:focus,*/
    /*.datepicker input.is-success:focus,*/
    /*.datepicker .dropdown .input[readonly]:focus,*/
    /*.login-input input.is-danger:focus,*/
    /*.login-input input.is-success:focus {*/
    /*    border: 0px solid transparent;*/
    /*    -webkit-box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0);*/
    /*    box-shadow: 0 0 0 0.125em rgba(241, 70, 104, 0);*/
    /*}*/

    /*.b-steps .steps {*/
    /*    display: none;*/
    /*}*/

    /*button.submit {*/
    /*    padding: 25px 89px 27px 98px;*/
    /*    border-radius: 5px;*/
    /*    background-color: #6843FF;*/
    /*    color: #fff;*/
    /*    font-weight: 600;*/
    /*    transform: all .2s ease;*/
    /*    -webkit-transform: all .2s ease;*/
    /*    -moz-transform: all .2s ease;*/
    /*    width: 100%;*/
    /*    margin-top: 1.5em;*/
    /*}*/

    /*button.submit[disabled] {*/
    /*    background-color: #5a5a5a;*/
    /*    -webkit-box-shadow: none;*/
    /*    box-shadow: none;*/
    /*    opacity: 0.5;*/
    /*}*/

    .mr-4 span{
        color: white;
    }
    label {
        color: white !important;
    }
    .text-field  input{
        color: white!important;
    }

</style>
