<template>
  <div id="chat" class="columns no-scroll">
    <div id="rooms-users" class="column  has-scroll">
      <RoomsAndUsers :loadingRooms="loadingRooms"></RoomsAndUsers>
    </div>
    <div id="chat-main" class="column  no-scroll">
      <!--ChatMessageInfoBubble></ChatMessageInfoBubble-->
      <div id="chat-loader" class="overlay-loader vcentered" v-if="loadingNewRoom">
      <!--        <b-progress type="is-primary" style="width: 40%;margin: 0 auto;background-color: transparent;">Loading</b-progress>-->
        <b-loading :is-full-page="false" v-model="loadingNewRoom" :can-cancel="false"></b-loading>
      </div>

      <div id="chat-overlay" v-if="!selectedRoom && !loadingNewRoom" class="row  boot-row chat-scroll">
        <!--              <div class="overlay-loader" v-if="loadingNewRoom">-->
        <!--                <b-progress type="is-primary" style="width: 40%;margin: 0 auto;background-color: transparent;">Loading</b-progress>-->
        <!--              </div>-->
        <div class="overlay-inner" v-if="selectedServer && !selectedRoom && !loadingNewRoom">
          <h1 class="server-heading">Welcome to the {{ selectedServer.name }} Server</h1>
          <!--div><img :src="commentsDollar"  :alt="selectedServer.name"  style="width: 250px; height: auto; opacity: 0.5" /></div-->
          <h3 v-if="selectedServer && selectedServer.description" class="server-desc">
            {{ selectedServer.description }}</h3>
          <h3 v-if="selectedServer && !selectedServer.description" class="server-desc">This server is about discussing
            profit news and tips ...</h3>
          <div style="display: table;margin-top: 0.25em">
            <img class="grunge" :src="selectRoom" alt="Select Room"/>
            <h3 class="room-select">
              <b-button size="is-small">Please Select a Room</b-button>
            </h3>
          </div>
          <!--h3 class="server-desc"><b-button style="background-color: #1c432b;color: #1dd466;border: 0px solid;">+ Invite users</b-button></h3-->
        </div>
      </div>
      <div id="chat-window" v-if="selectedRoom" class="row  boot-row chat-scroll"
           :class="{'has-quote' : chatHasQuote}">
        <!--              <ChatMessageGroup></ChatMessageGroup>-->
        <ChatMessagesList
            :autoSelect="autoSelect"
            :focus="toFocus"
            :scrollLock="scrollLock"
            :scrollToLastMessage="scrollToLastMessage"
            :loadingPrevious="loadingPrevious"
            :loadingNext="loadingNext"
        ></ChatMessagesList>
      </div>
      <div id="chat-bar" v-if="selectedRoom"
           :class="{'has-quote' : chatHasQuote}"
      >
        <MessageBarComponent :chatHasQuote="chatHasQuote" v-on:toggleQuote="toggleQuote"></MessageBarComponent>
      </div>
    </div>
    <JoinServer :server="serverFromSlug" v-if="showJoinServer"
                v-on:closeJoinServer="handleCloseJoinServer"></JoinServer>
    <!--div v-if="sidebarOpen"  id="chat-sidebar" class="column is-2 no-scroll">
      <div class="row sidebar-header">
        Sidebar
      </div>
    </div-->

  </div>
</template>
<script>
// import $socket from '../socket'
import RoomsAndUsers from "../components/ChatCore/RoomsAndUsers";
import MessageBarComponent from "../components/MessageBarComponent";
//import ChatMessageInfoBubble from "../components/ChatCore/ChatMessageInfoBubble";
// import ChatMessageGroup from "../components/ChatCore/ChatMessageGroup";
import JoinServer from "../components/ServersCore/JoinServer";
import ChatMessagesList from "@/components/ChatCore/ChatMessagesList";
import axios from "@/axios";
import EventBus from '@/eventBus';
import socket from "@/socket";

export default {
  components: {
    MessageBarComponent,
    RoomsAndUsers,
    //ChatMessageInfoBubble,
    // ChatMessageGroup,
    ChatMessagesList,
    JoinServer,
  },
  data() {
    return {
      sidebarOpen: false,
      loadingPrevious: false,
      loadingNext: false,
      // autoSelection: null,
      autoSelect: null,
      expandOnHover: false,
      mobile: "reduce",
      reduce: true,
      overlay: false,
      fullHeight: true,
      fullWidth: false,
      right: true,
      serverFromSlug: null,
      userServersIds: [],
      showJoinServer: false,
      loadingRooms: false,
      loadingNewRoom: false,
      loader: true,
      chatHasQuote: false,
      roomToSelect: null,
      messageToSelect: null,
      toFocus: null,
      loadMore: null,
      scrollLock: false,
      scrollToLastMessage: false,
      timeout: null,
    }
  },
  watch: {
    loadMore(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.getMoreMessages(newVal);
        }, 150);
      }
    },
    // selectedRoomMessages(newVal){
    //   console.log('selectedRoomMessages watch @chat', newVal);
    // },
    // scrollLock(newVal) {
    //   console.log('scrollLock chat watch', newVal);
    // },
    // toFocus(newVal) {
    //   console.log('toFocus chat watch', newVal);
    // },
  },
  computed: {
    userServers() {
      return this.$store.getters.get_userServers;
    },
    room() {
      return this.$store.getters.get_selectedRoom;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    selectedServer() {
      return this.$store.getters.get_selectedServer;
    },
    selectedRoom() {
      return this.$store.getters.get_selectedRoom;
    },
    commentsDollar() {
      return require('@/assets/icons/comments-dollar-solid.svg');
    },
    selectRoom() {
      return require('@/assets/icons/grunge-arrow-white.png');
    },
    selectedRoomGroups() {
      return this.$store.getters.get_selectedRoomGroups;
    },
    selectedRoomMessages() {
      return this.$store.getters.get_selectedRoomMessages;
    },
    // autoSelect() {
    //   return this.$store.getters.get_autoSelect;
    // }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  async created() {
    if (!this.userDataAfterLogIn) {
      this.$router.replace('/');
      this.$store.commit('set_openCloseLogIn');
    }

    socket.on('subscribedToServer', (payload) => {
      if (this.selectedServer && payload.serverId && payload.serverId === this.selectedServer._id) {
        // todo find this function
        //this.reloadSelectedServerUsers();
      }
    });

    /*** Handle Reload with server slug */
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'set_userServers') {
        // console.log('set_userServers @chat');
        if (this.userDataAfterLogIn && this.userDataAfterLogIn._id && this.userServers) {
          this.loadServerFromUrl();
        }
      }

      if (mutation.type === 'set_selectedServer') {
        // TODO MAYBE CHECK IN NOT NEEDED IN CASE PAYLOAD IS NULL
        this.$store.commit('set_selectedRoom', null); // Check and Ask to uncomment
        this.$store.commit('set_selectedServerRooms', null); // Check Temp
        this.$store.commit('set_selectedServerUsers', null); // Check Temp
        this.$store.commit('set_selectedRoomMessages', []); // Check Temp
        this.$store.commit('set_selectedRoomGroups', []);
        if (this.selectedServer) {
          if (this.selectedServer.rooms.length) {
            this.loadingRooms = true;
            this.$store.dispatch('setSelectedServerRooms', this.selectedServer);
          }
          if (this.selectedServer.subscriptions.length) {
            this.$store.commit('set_selectedServerUsers', this.selectedServer.subscriptions);
          }
        }
      }

      if (mutation.type === 'set_selectedServerRooms') {
        this.loadingRooms = false;
      }
      if (mutation.type === 'set_selectedRoom' && mutation.payload) {
        // console.log('set_selectedRoom @chat', mutation.payload.slug);
        this.addRoomToUrl(mutation.payload.slug);
        this.onRoomSelectedLoadMessages(mutation.payload);
      }

      if (mutation.type === 'set_autoSelect' && mutation.payload) {
        console.log('set_autoSelect', mutation.payload)
        this.autoSelect = mutation.payload;
        if (this.autoSelect.searchRequest) {
          this.autoSelectionFromSearch();
        } else {
          this.autoSelection();
        }
      }

      if (mutation.type === 'prepend_selectedRoomMessages' && mutation.payload) {
        // console.log('prepend_selectedRoomMessages @ chat', mutation.payload);
        //this.scrollToCursor();
      }

      if (mutation.type === 'append_selectedRoomMessages' && mutation.payload) {
        // console.log('append_selectedRoomMessages @ chat', mutation.payload);
        //this.scrollToCursor();
        //EventBus.$emit('FOCUS_MESSAGE', true);
      }

      if (mutation.type === 'update_selectedRoomGroups') {
        // console.log('update_selectedRoomGroups @ chat', mutation.payload);
        //   this.loadingRooms = false;
        //   this.handleAutoSelect(mutation.payload);
      }

    });

    console.log('this.$route.params', this.$route.params)

    if (this.$route.params && this.$route.params.roomToSelect) {
      this.roomToSelect = this.$route.params.roomToSelect;
    }

    if (this.$route.params && this.$route.params.server_id) {

      if (this.$route.params.room) {
        this.roomToSelect = this.$route.params.room;
      } else {
        this.roomToSelect = null;
      }

      if (this.$route.params.mention) {
        this.messageToSelect = this.$route.params.mention;
      } else {
        this.messageToSelect = null;
      }

      if (this.userServers && this.userServers[this.$route.params.server_id]) {
        this.$store.commit('set_selectedServer', this.userServers[this.$route.params.server_id]);
      } else {
        this.loadServerFromUrl();
      }
    } else {
      //this.loadServerFromUrl();
    }

  },
  mounted() {
    this.$store.dispatch('set_openCloseSidebar', 'open');
    EventBus.$on('NEW_ROOM_LOADING', (payload) => {
      // console.log('NEW_ROOM_LOADING', payload);
      // this.loadingNewRoom = payload;
      this.loadingNewRoom = true;
    });

    EventBus.$on('NEW_ROOM_LOADED', (payload) => {
      console.log('NEW_ROOM_LOADED listen', payload);
      // this.loadingNewRoom = payload;
      this.loadingNewRoom = false;
    });
  },

  methods: {
    addRoomToUrl(params) {
      // console.log('addRoomToUrl', params, this.$route);
      const url = '/servers/' + this.selectedServer.slug + '/' + encodeURIComponent(params);
      history.pushState(
          {},
          null,
           url
      )
    },
    async getMoreMessages(options) {
      const index = options.index
      const direction = options.direction;
      const indicator = options.indicator;
      console.log('getMoreMessages', index, direction, indicator);
      if (this.selectedRoomGroups.indexOf(index) > -1) {
        // console.log('alreadyLoaded List: return ');
        return false;
      }
      if (direction === 'past') {
        this.afterLoadMoreFocus = document.getElementsByClassName('message-separator')[0].id;
        this.loadingPrevious = true;
      } else {
        const els = document.getElementsByClassName('chat-message');
        this.afterLoadMoreFocus = els[els.length - 1].id;
        this.loadingNext = true;
      }
      this.loadingNewRoom = true;
      let messagesWithPagination = await this.$store.dispatch('messagesWithPagination',
          {
            roomId: this.room._id,
            flag: 1,
            direction: direction,
            dates: [index]
      });

      if (indicator) {
        this.scrollLock = true;
        setTimeout(() => {
          this.toFocus = indicator;
        }, 300);
      }

      // console.log('his.afterLoadMoreFocus ', this.afterLoadMoreFocus);
      this.loadingPrevious = false;
      this.loadingNext = false;
      // this.loadingNewRoom = false;
    },
    async onRoomSelectedLoadMessages(selectedRoom) {
      clearTimeout(this.timeout);
      this.toFocus = null;
      let dates = [];
      // todo check why null
      if (this.autoSelect && this.autoSelect.date) {

        const d = this.autoSelect.date.split("T")[0];
        dates = [d];
      }
      // else {
      //   let firstAvailableDates = await this.getFirstAvailableMessageDates(selectedRoom);
      //   if (firstAvailableDates.length) {
      //     dates = firstAvailableDates;
      //   }
      // }

      // EventBus.$emit('NEW_ROOM_LOADING', true);
      this.loadingNewRoom = true;

      const params = {
        roomId: selectedRoom._id,
        flag: 1,
        direction: 'future',
        //messageId: (autoSelect && autoSelect.messageId) ? autoSelect.messageId : null,
        dates: dates,
        action: (this.autoSelect && this.autoSelect.action) ? this.autoSelect.action : 'load'
      };
      console.log('onRoomSelectedLoadMessages', params);
      let messagesWithPagination = await this.$store.dispatch('messagesWithPagination', params );

      // EventBus.$emit('NEW_ROOM_LOADING', false);
      // EventBus.$emit('NEW_ROOM_LOADED', true);

      // todo maybe needed only on autoselect
      this.scrollLock = true;
      this.scrollToLastMessage = true;
      let toFocus = 'last';
      let delay = 400;
      if (this.autoSelect && this.autoSelect.messageId) {
        delay = 1000;
        toFocus = this.autoSelect.messageId;
      }
      this.$store.commit('set_autoSelect', null);
      this.timeout = setTimeout(() => {
        // console.log('setFocus delay', delay);
        this.toFocus = toFocus;
      }, delay);
      //this.autoSelect = null;
    },
    setFocus(toFocus) {
      console.log('setFocus', toFocus);
      this.toFocus = toFocus;
    },
    autoSelectionFromSearch() {
      this.$store.commit('set_selectedRoomMessages', []);
      this.$store.commit('set_selectedRoomGroups', []);
      this.$store.commit('set_previousMessagesIndex', null);
      this.$store.commit('set_nextMessagesIndex', null);
      this.onRoomSelectedLoadMessages(this.room);
    },
    autoSelection() {
      if (this.autoSelect && this.autoSelect.server) {
        // console.log('autoSelection', this.autoSelect.server);
        const autoSelect = this.autoSelect;
        this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'set_selectedServer'
              && mutation.payload && mutation.payload._id === autoSelect.server._id) {
            let roomChosen;
            autoSelect.server.rooms.forEach((room, i) => {
              if (room._id === autoSelect.roomId) {
                roomChosen = room;
              }
            });
            if (roomChosen) {
              this.onRoom(roomChosen);
            }
          }
        });
        this.$store.commit('set_selectedServer', this.autoSelect.server);
      }
    },
    async onRoom(selectedRoom) {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.$store.commit('set_autoSelect', null);
      this.$store.commit('set_selectedRoom', null);
      this.$store.commit('set_selectedRoomMessages', []);
      this.$store.commit('set_selectedRoomGroups', []);
      this.$store.commit('set_previousMessagesIndex', null);
      this.$store.commit('set_nextMessagesIndex', null);
      //if (this.userRooms && this.userRooms.length && this.userRooms.indexOf(selectedRoom._id) !== -1 ) {
      this.$store.commit('set_selectedRoom', selectedRoom);

      //} else {
      //this.roomToJoin = selectedRoom;
      //}
    },
    async loadServerFromUrl() {

      if (this.$route.params && this.$route.params.id) {
        let server = await this.getServerFromSlug(this.$route.params.id);
        if (server.data && server.data._id) {
          this.serverFromSlug = server.data;
          if (this.userServers && this.userServers[this.serverFromSlug._id]) {
            this.$store.commit('set_selectedServer', this.serverFromSlug);
          } else {
            this.showJoinServer = true;
          }
        } else {
          this.$router.replace('/404');
        }
      } else {
        this.$router.replace('/');
      }
    },
    // handleAutoSelect(rooms) {
    //   let selectedRoom;
    //   rooms.forEach((room, i) => {
    //     if (room._id === this.$store.autoSelect.roomId) {
    //       selectedRoom = room;
    //     }
    //   });
    //   if (selectedRoom) {
    //     //console.log('handleAutoSelect', selectedRoom );
    //     this.$store.commit('set_selectedRoom', selectedRoom);
    //   }
    // },
    toggleQuote() {

    },
    async getServerFromSlug(slug) {
      this.loading = true;
      try {
        let response = await axios.get('/servers/slug/' + slug);
        this.loading = false;
        return response;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    async getFirstAvailableMessageDates(selectedRoom) {
      let dates = [];
      let {data} = await axios.get(`/rooms/get/messages/room/${selectedRoom._id}/1/Mon Dec 31 3000 03:24:00 GMT+0200 (Eastern European Standard Time)/`);
      // console.log('set_selectedRoom Now loading response', data);
      if (data.rooms && data.rooms.messages && data.rooms.messages.length) {
        data.rooms.messages.forEach((message) => {
          let msgDate = message.date.split("T")[0];
          //console.log('set_selectedRoom Now loading response', msgDate);
          if (dates.indexOf(msgDate) === -1) {
            dates.push(msgDate);
          }
        });
      }
      return dates;
    },
    handleCloseJoinServer() {
      this.showJoinServer = false;
      this.serverFromSlug = null;
    },
  }

}
</script>

<style lang="scss">

#chat {
  /*display: inline-block;*/
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  position: relative;
  margin: 0;
  display: flex;
  /**display: inline-flex;*/
}

#rooms-users {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  background-color: #23272a;
  overflow: hidden;
  padding: 0.5em 0;
  width: 255px; /** zeplin */
  max-width: 255px;
}

#chat-main {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  position: relative;
  /*padding: 0.75em 0.75em 0.75em;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #1d2023;
  width: calc(100% - 256px);
  max-width: calc(100% - 256px);
  padding: 0.5em;
}

#chat-overlay {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  position: relative;
  /*padding: 0.75em 0.75em 1.25em;*/
  /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);*/
  background-color: #1d2023;
  width: 100%;
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
}

#chat-sidebar {
  display: inline-block;
  position: relative;
}

#chat-window {
  /**height: 91%;
  max-height: 91%;*/
  padding: 1em 0;
  overflow: hidden;
  height: calc(100% - 75px);
  max-height: calc(100% - 75px);
}

#chat-window.has-quote {
  height: calc(100% - 110px);
  max-height: calc(100% - 110px);
}

#chat-bar {
  /**height: 9%;
  max-height: 9%;*/
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  /**
  height: 110px;
  max-height: 110px;
  */
}

#chat-bar.has-quote {

}


.overlay-inner {
  width: 100%;
  overflow: hidden;
  top: 35%;
  left: 0;
  /**
  position: absolute;
  transform: translate(0%, -50%);
  text-align: center;
  padding-bottom: 2em;
  border-bottom: 1px solid gray;
  margin: 2em;
  */
}

h1.server-heading {
  width: 100%;
  display: inline-block;
  white-space: normal;
  padding-right: 45%;
  padding-left: 5%;
  font-family: 'Circular Std Black', sans-serif;
  font-size: 48px;
  font-weight: 900;
  text-align: left;
  color: #ffffff;
  line-height: 1;
}


.grunge {
  display: table-cell;
  width: auto;
  max-width: 120px;
  margin: 1.2em 0.5em 1.2em 1.2em;
}

.room-select {
  display: table-cell;
}

.room-select button {
  /**background-color: #1c432b;
  color: #1dd466;*/
  border: 0px solid;
  cursor: default;
  background-color: #7957d5;
  color: #ffffff;
}

.room-select button:hover {
  background-color: #7957d5;
  color: #ffffff;
  cursor: default;
}

h3.server-desc {
  text-align: left;
  margin-top: 1em;
  padding-left: 5%;
  padding-right: 40%;
}

.server-notes {
  text-align: left;
  padding-left: 5%;
  margin-top: 3em;
}



.sidebar-header {
  padding: 0.5em;
}

.main-scroll,
.has-scroll {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

.chat-scroll {
  padding: 0;
  margin: 0;
}


/*** responsive */

/*@media (max-width: 1440px) {*/

/*}*/

/*@media (max-width: 1200px) {*/

/*}*/

/*@media (max-width: 992px) {*/

/*}*/


/*@media (max-width: 792px) {*/

/*}*/

/*@media (max-width: 768px) {*/

/*}*/

/*@media (max-width: 480px) {*/

/*}*/


</style>
