<template>
  <section>
    <b-modal id="signup" v-model="showSignUp" :width="540" scroll="keep" :on-cancel="handleClose">
      <div id="signup-header" ref="signupHeader">
        <h4 class="brand" style="padding: 0 50px;">PROFIT</h4>
        <h2 class="modal-title medium" style="padding: 0 50px;">{{titles[activeStep]}}</h2>
      </div>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode">
        <b-step-item step="0" label="Account" :clickable="isStepsClickable">
          <ValidationObserver tag="div" class="form-observer" ref="form1Validator" v-slot="{ invalid }">
            <!-- https://codesandbox.io/s/veevalidate-backend-driven-validation-ynrp9?from-embed=&file=/src/App.vue -->
            <form @submit.prevent="handleFirstSubmit">
              <ValidationProvider tag="div" class="validation-field padx50" name="username" rules="required|min:4" v-slot="{ errors, valid }">
                <b-field label="Username"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="username" v-model="username" :readonly="loading"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field padx50" name="password" rules="required|min:8" v-slot="{ errors, valid }">
                <b-field label="Password"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input type="password"
                           v-model="password"
                           class="signup-input"
                           vid="password"
                           :readonly="loading"
                           password-reveal>
                  </b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field padx50" name="password confirm" rules="required|confirmed:password"
                                  v-slot="{ errors, valid }">
                <b-field label="Confirm Password"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }">
                  <b-input type="password"
                           class="signup-input"
                           v-model="password2"
                           :readonly="loading"
                           password-reveal>
                  </b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field" name="date" :rules="`required|age:${maxDate},${maxValidDate}`"
                                  v-slot="{ errors, valid }">
                <b-field label="Date of Birth"
                         class="form-field"
                         id="signup-birthday"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-datepicker
                      placeholder="Type or select a date..."
                      id="signup-date"
                      ref="signUpDatepicker"
                      icon=""
                      :locale="locale"
                      v-model="date"
                      :min-date="minDate"
                      :max-date="maxDate"
                      :readonly="loading"
                      :append-to-body="false"
                      class="signup-date"
                      position="is-top-left"
                      mobile-native
                      @change-month="handleChangeMonth"
                      @change-year="handleChangeYear"

                  >
                  </b-datepicker>
                </b-field>
                <b-field
                    class="form-field margx50"
                    :class="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input v-for="(seg, i) in dateArray"
                           :value="seg" :key="i"
                           class="calendar-input"
                           @focus="onFocus($event)"
                           :readonly="true"

                  ></b-input>
                  <b-button
                      class="calendar-toggle"
                      @click="$refs.signUpDatepicker.toggle()"
                      icon-right="calendar-today"
                      type="" />
                </b-field>
                <p class="help field-help padx50" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field  padx50" name="email" rules="required|email" v-slot="{ errors, valid }">
                <b-field label="Email"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="email" v-model="email" type="email" :readonly="loading"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>

              <p class="signup-note padx50" style="margin-top: 2em;" v-if="!emailError">
                <span>Already Have an account? </span><a style="margin-left: 3px;" href="#" @click.prevent="openLogin()">Login</a></p>
              <p class="signup-note" v-if="emailError"><span>Use another or try </span><a href="#" @click.prevent="openLogin()">Login/Reset</a>
              </p>
              <footer id="signup-footer" class="footer padx50">
                <p class="signup-note product-sans">By creating an account you agree to Profit's</p>
                <p class="signup-note product-sans"><a href="#" @click.prevent="activeStep = 3">Community Guidelines</a></p>
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid"
                    @click="handleFirstSubmit"
                >Create Account
                </b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item step="1" class="padx50" label="Profile" :clickable="isStepsClickable" :type="{'is-success': isProfileSuccess}">
          <section>
            <h1 v-if="get_user && get_user.username" style="margin:1em auto;">Welcome {{ get_user.username }}!</h1>
            <!--h3 style="margin:1em auto;" >Please fill in the your details.</h3-->
            <ValidationObserver tag="div" class="form-observer" ref="form2Validator" v-slot="{ invalid }">
              <form @submit.prevent="handleSubmit">
                <ValidationProvider tag="div" class="validation-field" name="First Name" vid="firstName" rules="required" v-slot="{ errors, valid }">
                  <b-field label="First Name"
                           :class="{ 'is-danger': errors[0], 'is-success': valid }"
                           :type="{ 'is-danger': errors[0], 'is-success': valid }"
                           class="form-field"
                  >
                    <b-input type="text"
                             autocomplete="off"
                             class="signup-input"
                             v-model="firstName"
                             vid="firstName"
                             :readonly="loading"
                    >
                    </b-input>
                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>
                <ValidationProvider tag="div" class="validation-field" name="Last Name" vid="lastName" rules="required" v-slot="{ errors, valid }">
                  <b-field label="Last Name"
                           class="form-field"
                           :class="{ 'is-danger': errors[0], 'is-success': valid }"
                           :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  >
                    <b-input type="text"
                             autocomplete="off"
                             class="signup-input"
                             v-model="lastName"
                             vid="lastName"
                             :readonly="loading"
                    >
                    </b-input>

                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>
                <ProfileImage :tempImage="tempImage"
                              :uploading="uploading"
                              :imageUploaded="imageUploaded"
                              v-on:previewImageUpdate="previewImageUpdate"
                />
                <footer id="account-details-footer" class="footer">
                  <b-button
                      class="submit"
                      type="submit"
                      :loading="loading"
                      :disabled="invalid"
                      @click="handleSubmit"
                  >Continue
                  </b-button>
                </footer>
              </form>
            </ValidationObserver>
          </section>
        </b-step-item>
        <b-step-item step="2"  class="padx50" label="Confirm" :clickable="isStepsClickable">
<!--          <p class="font-24" >Your Account has been Created!</p>-->
<!--          <sweetalert-icon v-if="activeStep === 2" icon="success"></sweetalert-icon>-->
          <p class="head-24" style="margin:24px 0 0;">Email confirmation is required</p>
<!--          <h1 style="text-align: center;font-size: 120%;">Email confirmation is <span>required.</span></h1>-->
          <div class="xcentered" style="margin:24px 0 24px;"><img :src="emailIcon"  /></div>
          <footer id="account-confirm-footer"  class="footer">
            <p class="head-19 product-sans" style="margin:0 0 24px;">Please open the welcome email and follow the activation link.</p>
            <p class="head-13 product-sans" style="color: #A1A5AA;">Have not received email?</p>
            <p class="head-13 product-sans" style="">Please check the spam folder</p>
            <p class="head-13 product-sans" style="margin:24px 0 14px;"><a href="#" @click.prevent="handleResend()">Resend Email?</a></p>
            <b-button
                class="submit signup-submit"
                type="submit"
                :loading="loading"
                @click="handleClose()"
            >Close
            </b-button>
          </footer>
        </b-step-item>
        <b-step-item
            id="terms-step" step="3"
            class="padx50"
            :clickable="isStepsClickable">
          <div id="terms-text"><Guidelines /></div>
          <footer id="signup-terms-footer" class="footer">
            <p class="head-13" style=""><a href="#" @click.prevent="activeStep = 0">Back</a></p>
          </footer>
        </b-step-item>
      </b-steps>
    </b-modal>
  </section>
</template>

<script>
import axios from "../axios";
// import SweetalertIcon from 'vue-sweetalert-icons';
import ProfileImage from "../components/Traits/ProfileImage";
import Guidelines from "../components/Traits/Guidelines";

import { extend, ValidationObserver, ValidationProvider, } from 'vee-validate/dist/vee-validate.full';
import moment from "moment";



extend('age', {
  validate: (value, field) => {
    let maxDate = new Date(field.maxDate);
    let maxValidDate = new Date(field.maxValidDate);
    return (value.valueOf() <= maxValidDate.valueOf());
  },
  params: ['maxDate', 'maxValidDate'],
  message: 'You must be over 18 years old!'
});


extend('emailAvailable', {
  validate: (value, field) => {
    //this.$validator.validate
    if (value.length < 4) {
      return false
    }
    let check = axios.get('/users/check/' + value);
    return check.status === 200;
  },
  params: [],
  message: 'Email is not Valid'
});

export default {
  name: "SignUp",
  components: {
    ValidationObserver,
    ValidationProvider,
    Guidelines,
    // SweetalertIcon,
    ProfileImage
  },
  data() {
    const today = new Date();
    return {
      tokens: null,
      accessToken: null,
      refreshToken: null,
      validToken: null,
      username: '',
      email: '',
      password: '',
      password2: '',
      firstName: '',
      lastName: '',
      tempImage: null,
      showLoginLink: false,
      usernameError: false,
      emailError: false,
      userInfo: null,
      date: null,
      minDate: new Date(1950, 1, 1),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      maxValidDate: new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      activeStep: 0,
      locale: this.$defaultLocale,  // browser locale
      loading: false,
      uploading: false,
      imageUploaded: false,
      showSignUp: true,
      isProfileSuccess: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      dateArray: [],
      titles: ['Sign Up', 'Your Details', '', 'Terms' ],
      thisMonth: new Date().getMonth(),
      selectedMonth: new Date().getMonth(),
      thisYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
    }
  },
  computed: {
    open_signUp1() {
      return this.$store.getters.get_openSignUp;
    },
    get_user() {
      return this.$store.getters.get_user;
    },
    emailIcon() {
      return require('@/assets/img/Email.svg');
    },
    axios() {
      return this.$store.getters.get_axios;
    },
  },
  watch:{
    date(val) {
      this.formatDateDisplay();
    }
  },
  created() {
    this.formatDateDisplay();
  },
  mounted() {
    this.$store.commit('set_user', {});
    this.$refs.form1Validator.setErrors({date: ['Please enter a valid birth date']});
    this.date = new Date();
    this.disableNextButton();
    // this.$refs.form1Validator.fields.find({name: 'email'}).persist = false;
  },
  methods: {
    formatDateDisplay() {
      const d = moment(this.date).format("DD MM YYYY").split(' ');
      this.dateArray = d;
    },
    handleChangeMonth(m){
      this.selectedMonth = m;
      this.disableNextButton();
    },
    handleChangeYear(y){
      this.selectedYear = y;
      this.disableNextButton();
    },
    disableNextButton() {
      const n = document.querySelectorAll(".pagination-next")[0];
      if (this.selectedMonth === this.thisMonth && this.selectedYear === this.thisYear) {
        n.style.visibility = "hidden";
      } else {
        n.style.visibility = "visible";
      }
    },
    onFocus(e) {
      e.stopPropagation();
      // const el = e.target;
      // console.log('onFocus', el);
      setTimeout(()=> {
        this.$refs.signUpDatepicker.toggle();
      }, 150);

    },
    async handleFirstSubmit() {
      this.userInfo = {
        username: this.username,
        emailValidated: false,
        password: this.password,
        email: this.email,
        firstName: '',
        lastName: '',
        image: {},
        dateOfBirth: this.dateToLocalISO(this.date),
      };
      let username = await axios.get('/users/check/' + this.username);
      let email = await axios.get('/users/check/' + this.email);
      if (username.status === 200 && email.status === 200) {
        this.$store.commit('set_user', this.userInfo);
        let response = await this.handleCreateUser();
        this.handleResponse(response);
        //this.activeStep = 1;
      } else {
        if (username.status === 409) {
          let username = this.username;
          //this.username = username + ' is taken';
          this.$refs.form1Validator.setErrors({username: ['The username is taken, please try another!']})
          this.usernameError = true;
        }
        if (email.status === 409) {
          let email = this.email;
          this.emailError = true;
          this.$refs.form1Validator.setErrors({email: ['This Email is already assigned to an account!']})
        }
      }
    },
    handleResend() {
      if (this.get_user && this.get_user._id) {
        this.loading = true;
        axios.post('/auth/resent-verification-email/' + this.get_user._id)
            .then(response => {
              this.loading = false;
            })
            .catch(function (error) {
              this.loading = false;
              console.log(error);
            });
      }
    },
    previewImageUpdate(val) {
      this.tempImage = val;
    },
    gotoTerms() {
      //this.activeStep = 2; // Temp
    },
    gotoConfirm() {
      this.activeStep = 2;
    },
    openLogin() {
      this.$store.commit('set_openSignUp');
      this.$store.commit('set_openCloseLogIn');
    },
    async handleCreateUser() {
      let user = this.get_user;
      user.image = '';
      this.loading = true;
      try {
        let response = await axios.post('/auth/register', user, {});
        this.loading = false;
        return response;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    handleResponse(response) {
      if (response.status && response.status >= 200 && response.status < 300 && response.data) {
        this.$store.commit('set_user', response.data);
        //this.activeStep = 2;
        this.activeStep = 1;
      } else {
        //fff
      }
    },
    async handleSubmit() {
      //this.$store.commit('set_user', this.userInfo);
      let user = {};
      user['firstName'] = this.firstName;
      user['lastName'] = this.lastName;
      //user['image'] = '';
      //user.firstName = this.firstName;
      //user.lastName = this.lastName;
      //user.image = '';
      if (this.tempImage) {
        let upload = await this.handleUpload();
        if (upload.status && upload.status >= 200 && upload.status < 300 && upload.data && upload.data.uploadedImage && upload.data.uploadedImage.filename) {
          user['image'] = upload.data.uploadedImage.filename;
          this.imageUploaded = true;
        }
      }
      this.loading = true;
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'application/json';
      axios.put('/users/' + this.get_user._id, user, {
        headers: axHeaders
      })
          .then(response => {
            this.loading = false;
            if (response.status && response.status >= 200 && response.status < 300) {
              this.activeStep = 2;
            } else {
              return new Error(response.data.message);
            }
          })
          .catch(function (error) {
            this.loading = false;
            console.log(error);
          });

    },
    async handleUpload() {
      let formData = new FormData();
      //formData.append('file', this.previewImage);
      formData.append('image', this.tempImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      //this.loading = true;
      this.uploading = true;
      try {
        let response = await axios.post('/images/profile', formData, {
          headers: axHeaders
        });
        this.uploading = false;
        return response;
      } catch (e) {
        this.uploading = false;
        console.log('error:', e);
        return e;
      }
    },

    async handleUpdate() {
      let user = this.get_user;
      user.firstName = this.firstName;
      user.lastName = this.lastName;
      //user.emailValidated = true; // RBW for DEV
      let tokens = await this.fetchTokens();
      if (this.validToken) {
        let updated = await this.updateUser(user);
        if (updated && updated.statusText === 'OK') {
          this.activeStep = 2;
        }
      }
    },

    async updateUser(user) {
      this.loading = true;
      try {
        let response = await this.axios.put('/users/' + user._id, user, {});
        this.loading = false;
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    dateToLocalISO(date) {
      const off = date.getTimezoneOffset()
      const absoff = Math.abs(off)
      return (new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
          (off > 0 ? '-' : '+') +
          (absoff / 60).toFixed(0).padStart(2, '0') + ':' +
          (absoff % 60).toString().padStart(2, '0'))
    },
    async fetchTokens() {
      try {
        let response = await this.axios.get('/auth/gen-admin-token/' + this.get_user._id);
        if (response.data && response.data.accessToken) {
          this.validToken = response.data.accessToken;
          //this.$store.commit('set_axios_token', this.validToken);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return error;
      }
    },
    changeStep(step) {
      this.activeStep = step;
    },
    handleClose() {
      this.$store.commit('set_openSignUp');
    },
  },
}
</script>

<style>

/*#signup {*/
/*  width: 414px;*/
/*  height: 726px;*/
/*  padding: 51px;*/
/*  border-radius: 17px;*/
/*  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);*/
/*  background-color: #1e2124;*/
/*}*/


#signup .modal-content {
  padding: 50px 0 !important;
  position: relative;
}

#signup-terms-footer {
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}


#signup-footer,
#account-confirm-footer{
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}

#account-details-footer {
  /*margin-top: 1em;*/
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}

/*#account-confirm-footer {*/
/*  !*margin-top: 1em;*!*/
/*  background-color: transparent;*/
/*  padding: 0;*/
/*  width: 100%;*/
/*  text-align: center;*/
/*}*/

#signup-birthday {
  border-bottom: 0 !important;
}

#signup-birthday .dropdown-menu {
  right: 0;
  left: 0;
  margin: 0 10px;
  /*position: absolute;*/
}

#signup-birthday label {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.signup-date .dropdown-menu,
.datepicker .dropdown {
  /*background-color: #1e2124 !important;*/
  background-color: #0f1011 !important;

}

#signup-birthday .datepicker .dropdown-content {
  background-color: transparent;
  border-radius: 4px;
  /*border: 1px solid rgba(45, 48, 52, 1);*/
  -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

#signup-birthday .datepicker select,
#signup-birthday .datepicker select:hover {
  background-color: transparent;
  border-color: transparent;
  color: #ffffff;
  border-width: 1px;
}

.datepicker .pagination-previous,
.datepicker .pagination-next,
.datepicker .pagination-link {
  border-color: transparent;
  color: #FFFFFF;
}


.datepicker .pagination-previous:hover,
.datepicker .pagination-next:hover,
.datepicker .pagination-link:hover,
.datepicker .pagination-previous:focus,
.datepicker .pagination-next:focus,
.datepicker .pagination-link:focus{
  border-color: transparent;
  color: #7957d5;
}

.datepicker .pagination-previous,
.datepicker .pagination-next {
  display: block !important;
}

.pagination-previous[disabled],
.pagination-next[disabled] {
  opacity: 0.5;
}

#signup-birthday .dropdown-item,
#signup-birthday .dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: transparent;

}

#signup-birthday .input
{
  display: none;
}

#signup-birthday .datepicker.control {
  max-height: 1px;
}

button.calendar-toggle {
  background-color: transparent;
  color: #5c6064;
  border-color: transparent;
  /*font-size: 1.2em;*/
  padding-top: calc(0.25em - 1px);
  padding-bottom: calc(0.25em - 1px);
}


button.calendar-toggle:hover, button.calendar-toggle.is-hovered {
  border-color: transparent;
}

button.calendar-toggle .icon i {
  /*height: 2em;*/
  /*width: 2em;*/
}

button.calendar-toggle .icon i {
  font-size: 1.2rem;
  line-height: 1.5;
}

/*.signup-note {*/
/*  color: #A1A5AA;*/
/*  font-family: 'Product Sans', sans-serif;*/
/*  font-weight: normal;*/
/*  font-size: 13px;*/
/*  text-align: left;*/
/*  letter-spacing: -0.30px;*/
/*}*/

/*.signup-note a  {*/
/*  font-family: 'Product Sans', sans-serif;*/
/*  color: #7957d5;*/
/*  font-weight: normal;*/
/*  font-size: 13px;*/
/*}*/


/*.signup-note a:hover  {*/
/*  color: #7957d5;*/
/*}*/

.datepicker .dropdown .input[readonly],
.datepicker .dropdown-trigger .input[readonly] {
  color: #fff;
}



.form-field .calendar-input input {
  color: rgba(125, 124, 136, 0.84);
  font-size: 17px;
}


input.is-danger {
  color: #747474;
}


.mr-4 span {
  color: white;
}

label {
  color: white !important;
}

.text-field input {
  color: white !important;
}

.pagination-previous span.icon path,
.pagination-next span.icon path {
  fill: #FFFFFF;
}
.pagination .pagination-list .field .field-body .has-addons .control .select::after {
  border-color: #FFFFFF;
}

.pagination-previous span:hover,
.pagination-next span:hover
{
  transform: scale(1.5);
}

#terms-text {
  overflow: hidden;
  height: 360px;
  max-height: 360px;
}

</style>
