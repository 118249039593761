import serversGetters from "./getters";
import serversMutations from "./mutations";
import serversActions from "./actions";

export default {
  state: {
    servers:[],
    selectedServer: null,
    serverUsers: null,
    selectedServerUsers: null,
    selectedCategory:null
  },
  getters:serversGetters ,
  mutations:serversMutations ,
  actions: serversActions
}
