<template>
  <section>
    <b-modal id="stream-modal" v-model="showStreamModal" :width="540" scroll="keep" :on-cancel="handleClose">
      <h4>PROFIT</h4>
      <h2 class="modal-title">Start Streaming</h2>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode">

        <b-step-item step="0" label="Stream Details" :clickable="isStepsClickable">

          <ValidationObserver tag="div" class="form-observer" ref="streamFormValidator" v-slot="{ invalid }">
            <form id="create-stream-form" @submit.prevent="handleStreamSubmit">
              <ValidationProvider tag="div" class="validation-field" name="Rooms" rules="required" v-slot="{ errors, valid }">
                <treeselect
                    id="stream-servers-tree"
                    class="v-treeselect"
                    v-if="filteredServers"
                    :multiple="true"
                    :options="filteredServers"
                    :value-consists-of="valueConsistsOf"
                    :show-count="false"
                    :open-on-click="true"
                    :open-on-focus="true"
                    :always-open="false"
                    @input="handleInput"
                    @select="handleSelect"
                    :flat="false"
                    @deselect="handleDeselect"
                    :flatten-search-results="false"
                    placeholder="Streaming available on ..."
                    v-model="allSelected"
                />
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{errors[0]}}&nbsp;</p>
              </ValidationProvider>

              <!--treeselect-value :value="value" /-->
              <footer id="create-stream-footer" class="footer">
                <p class="signup-note">By creating this server you agree to Profit's</p>
                <p class="signup-note"><a href="#" @click.prevent="gotoTerms()">Community Guidelines</a></p>
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid || loading || !userServersIsOwner"
                    @click="handleStreamSubmit"
                >Continue</b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>

        <b-step-item step="1" label="Stream Details" :clickable="isStepsClickable">
          <ValidationObserver tag="div" class="form-observer" ref="streamDetailsFormValidator" v-slot="{ invalid }">
          <form id="create-stream-details-form"  @submit.prevent="handleUpdate">
            <ValidationProvider tag="div" class="validation-field" name="Title" rules="required|min:4" v-slot="{ errors, valid }">
              <b-field label="Stream Title"
                       class="form-field"
                       :class="{ 'is-danger': errors[0], 'is-success': valid }"
                       :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-input class="signup-input" vid="title" v-model="title" :readonly="loading"></b-input>
              </b-field>
              <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{errors[0]}}&nbsp;</p>
            </ValidationProvider>
            <ValidationProvider tag="div" class="validation-field" name="Description" >
              <b-field label="Stream Description"
                       class="form-field"
              >
                <b-input class="signup-input" vid="description" v-model="description" :readonly="loading"></b-input>
              </b-field>
              <!--p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{errors[0]}}&nbsp;</p-->
            </ValidationProvider>
            <StreamImage :tempImage="tempImage"
                         :uploading="uploading"
                         :imageUploaded="imageUploaded"
                         :stream="createdStream"
                         :streamUploadedImage="streamUploadedImage"
                         v-on:previewImageUpdate="previewImageUpdate"
            ></StreamImage>
            <footer id="create-stream-details-footer" class="footer">
              <b-button
                  @click="handleUpdate()"
                  class="submit"
                  type="submit"
                  :disabled="invalid || loading || uploading"
              >Continue</b-button>
            </footer>
          </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item step="2" label="Stream Created" :clickable="isStepsClickable">

        </b-step-item>
        <b-step-item step="3" label="No Servers" :clickable="isStepsClickable">
          <footer id="create-stream-noservers-footer" class="footer">
            <p>In order to Start Streaming you should own at least 1 server!</p>
            <p>Please create one and try again.</p>
            <b-button
                @click="handleCreateServerFirst()"
                class="submit"
                type="submit"
            >Continue</b-button>
          </footer>
        </b-step-item>
     </b-steps>
    </b-modal>
  </section>
</template>

<script>
  import Treeselect from '@riophae/vue-treeselect';
  //import treeselect-value from '@riophae/vue-treeselect-value';
  //import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css';
  import EventBus from "@/eventBus";
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate/dist/vee-validate.full';

  import StreamImage from "../Traits/StreamImage";
  import axios from "@/axios";
  //import RTCMultiConnection from "rtcmulticonnection";
  //import io from 'socket.io-client';
  //import socket from "@/socket";


export default {
  name: "StreamCreate",
  components: {
    Treeselect,
    ValidationObserver,
    ValidationProvider,
    StreamImage
    //treeselect-value
  },
  data(){
    return{
      showStreamModal: true,
      filteredServers: null,
      selectedServers: [],
      selectedRooms: [],
      allSelected:[],
      availableServers: null,
      availableRooms: null,
      rooms:[],
      loading: false,
      iBroadcasting:  false,
      broadcastId: null,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      createdStream: null,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      valueConsistsOf : 'ALL_WITH_INDETERMINATE',
      titles: ['Create Stream', 'Stream Details',  'Create Category'],
      tempImage: null,
      uploading: false,
      imageUploaded: false,
      streamUploadedImage: null,
      title: "",
      description: ''
    }
  },
  watch:{
    allSelected(selected) {
     /**
      if(selected.length) {
        selected.forEach((child) => {
          console.log('allSelected  child' , child);
          //if (this.selectedRooms.indexOf(child.id) === -1) {
          //  this.selectedRooms.push(child.id);
         // }
        });

      } else {
        this.selectedServers = [];
        this.selectedRooms = [];
      }
      */

    },
    selectedServers(newval) {
      console.log('selectedServers' , newval);
    },
    selectedRooms(newval) {
     console.log('selectedRooms' , newval);
    },
    get_connectionInfo(newval) {
      console.log('get_connectionInfo' , newval);
    },
  },
  created () {
    //console.log('userServersIsOwner' , this.userServersIsOwner);
    //this.$store.commit('set_selectedStream', null);
    if (this.userServersIsOwner.length) {
      let tempServers = [];
      let availableServers = [];
      let availableRooms = [];
       this.userServersIsOwner.forEach(function(server, key) {
         availableServers.push(server._id);
         let tempServer = {};
         tempServer.id = server._id;
         tempServer.label = server.name;
         tempServer.isNew = true;
         tempServer.children = [];
         if (server.rooms.length) {
           tempServer.isDefaultExpanded = true;
           server.rooms.forEach(function(room, k) {
             availableRooms.push(room._id);
             let tempRoom = {};
             tempRoom.id = room._id;
             tempRoom.label = room.name;
             tempServer.children.push(tempRoom);
           });
         } else {
           //tempServer.isDisabled = true;
           tempServer.isDefaultExpanded = false;
         }
         tempServers.push(tempServer);
       });
      this.filteredServers = tempServers;
      this.availableServers = availableServers;
      this.availableRooms = availableRooms;
    } else {
      this.activeStep = 3;
    }

  },
  methods: {
    setArrays() {

    },
    handleInput(value, instanceId) {
      //console.log('handleInput val id', value, instanceId);
    },
    handleSelect(node, instanceId) {
/**
      if (node.children) {
        console.log('is parent->  node id', node, instanceId);
        //if (this.selectedServers.indexOf(node.id) === -1) {
          //this.selectedServers.push(node.id);
        //}
        node.children.forEach((child) => {
          if (this.selectedRooms.indexOf(child.id) === -1) {
            this.selectedRooms.push(child.id);
          }
        });
      } else {
        console.log('is child ->  node id', node, instanceId);
        if (this.selectedRooms.indexOf(node.id) === -1) {
          this.selectedRooms.push(node.id);
        }
      }
*/

    },
    handleDeselect(node, instanceId) {
      //console.log('handleDeselect node id', node, instanceId);

      /**
      if (node.children || node.isNew) {
        console.log('handleDeselect node id', node, instanceId, this.selectedServers.indexOf(node.id));
        let i = this.selectedServers.indexOf(node.id);
        if (i >= 0) {
          this.selectedServers.splice(i, 1);
        }
        console.log('selectedServers ', this.selectedServers);
      }
      */

    },
    previewImageUpdate(val) {
      console.log('previewImageUpdate', val);
      this.tempImage = val;
    },
    handleStreamSubmit () {
      this.activeStep = 1;
    },
    submitGoLive() {
      console.log('selectedRooms', this.selectedRooms);
    },
    gotoTerms() {

    },
    async handleUpdate () {
      let stream = {};
      if (this.tempImage) {
        let upload = await this.handleUpload();
        console.log('upload', upload);
        if (upload.status && upload.status >= 200 && upload.status < 300 && upload.data && upload.data.uploadedImage && upload.data.uploadedImage) {
          this.streamUploadedImage = upload.data.uploadedImage;
          this.imageUploaded = true;
          console.log('uploadok', this.streamUploadedImage);
        } else {
          this.streamUploadedImage = null;
        }
      }
      this.openRoom();

    },
    async openRoom() {
      let selectedServers = [];
      let selectedRooms = [];
       if(this.allSelected) {
         this.allSelected.forEach((child) => {
           if (this.availableServers.indexOf(child) > -1) {
             selectedServers.push(child);
           }
           if (this.availableRooms.indexOf(child) > -1) {
             selectedRooms.push(child);
           }
        });
      }

      //console.log('this.selectedServers openRoom' , selectedServers);
      //console.log('this.selectedRooms openRoom', selectedRooms );

      //let connection = this.$store.getters.get_connectionInfo;
      let self = this;
      this.$store.commit('set_ServersAndRoomsForStreaming' , {
        servers:  selectedServers,
        rooms:  selectedRooms
      });

      //let axHeaders = axios.defaults.headers;
      //axHeaders['Content-Type'] = 'multipart/form-data';

      let options = {
        name : this.title,
      }

      if (this.description) {
        options['description'] = this.description;
      }

      if (this.streamUploadedImage && this.streamUploadedImage.filename) {
        options['image'] = this.streamUploadedImage.filename;
      }
      let connection =  this.$store.getters.get_connectionInfo;

      let broadcastId;

      //console.log('Sudroom.', this.userDataAfterLogIn.broadcast);
      if (!this.userDataAfterLogIn.broadcast) {
        let data = {
          roomid: this.userDataAfterLogIn._id,
        }
        let create = await self.$store.dispatch('createStream'  );
        console.log('create', create);
        if (create.data && create.data._id) {
          broadcastId = create.data._id;
        }
      } else {
        broadcastId = this.userDataAfterLogIn.broadcast;
      }

      connection.open(broadcastId, function(isRoomOpened, roomid, error) {
        if(error) {
          console.log(error);
        }
        if(isRoomOpened === true) {
          console.log('Successfully created the room.', roomid);
          let data = {
            roomid: roomid,
            options: options
          }
          self.$store.dispatch('createUpdateStream', data  );
        }
      });
      //EventBus.$emit('createStream', this.userDataAfterLogIn.broadcast); //Temp
      this.handleClose();
    },
    async handleUpload () {
      let formData = new FormData();
      formData.append('image', this.tempImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      console.log('axHeaders' , axHeaders);
      //this.loading = true;
      this.uploading = true;
      try {
        let response = await axios.post('/images/broadcast', formData, {
          headers: axHeaders
        });
        this.uploading = false;
        return response;
      }
      catch (error) {
        this.uploading = false;
        console.log(error);
        return error;
      }
    },
    handleClose() {
      this.$store.commit('set_openStreamOptions');
    },
    handleCreateServerFirst() {
      this.$store.commit('set_openStreamOptions');
      this.$store.commit('set_openCloseCreateServer');
    }
  },
  computed:{
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    connectionInfo() {
      return this.$store.getters.get_connectionInfo;
    },
    userServersIsOwner() {
      if (this.userDataAfterLogIn) {
        return this.$store.getters.get_userDataAfterLogIn.servers.filter(server => server.owner === this.$store.getters.get_userDataAfterLogIn._id);
      }
      return [];
    },
    userRoomsIsOwner() {
      if (this.userDataAfterLogIn) {
        this.$store.getters.get_userDataAfterLogIn.servers.filter(server => server.owner === this.$store.getters.get_userDataAfterLogIn._id)
        this.$store.getters.get_userDataAfterLogIn.servers.forEach(x => {
          x.rooms.forEach(y => this.rooms.push(y))
        });
        return this.rooms
      }
      return [];

    },
    stream () {
      return require('@/assets/icons/stream.svg');
    },
  },


}
</script>

<style>

  #stream-modal {
    /**
    width: 414px;
    height: 726px;
    padding: 51px;
    border-radius: 17px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    background-color: #1e2124;

    */
  }

  /*h2.modal-title {*/
  /*  margin: 0.25em 0 0.1em;*/
  /*  font-family: Circular, sans-serif;*/
  /*  font-size: 41px;*/
  /*  font-weight: 500;*/
  /*  font-stretch: normal;*/
  /*  font-style: normal;*/
  /*  line-height: normal;*/
  /*  letter-spacing: -1.16px;*/
  /*  color: rgba(255, 255, 255, 0.84);*/
  /*  overflow: hidden;*/
  /*  white-space: normal;*/
  /*}*/

  #stream-modal h4 {
    margin: 0;
    font-family: Circular, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3.07px;
    color: #8356ff;
  }

  #stream-modal.modal .modal-content {
    background-color: #1e2124 !important;
    max-width: 420px !important;
    padding: 1.5em 1em 1em;
    border-radius: 17px!important;
    /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
    max-height: 100%;
    height: auto;
    display: block;
  }

  #stream-modal.modal .modal-content .modal-card {
    max-height: 100%;

  }

  #stream-modal-footer {
    margin-top: 1em;
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  #go-live-button2 {
    background-color: #22c161;
    border: 0 solid transparent !important;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  }

  /*#stream-servers-tree {*/

  /*}*/

  /*#stream-servers-tree .vue-treeselect__control {*/
  /*  background-color: #23272a;*/
  /*  border-color: #7c7c7c;*/
  /*  border-color: #373737;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__menu-container {*/

  /*}*/

  /*#stream-servers-tree .vue-treeselect__menu {*/
  /*  background-color: #23272a;*/
  /*  border-color: #7c7c7c;*/
  /*  border-color: #373737;*/

  /*}*/

  /*#stream-servers-tree .vue-treeselect__option--highlight {*/
  /*  background-color: #303538;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__label {*/
  /*  color: #7c7c7c !important;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__multi-value-item {*/
  /*  background-color: #303538;*/
  /*  color: #7c7c7c !important;*/
  /*}*/

  /*#stream-servers-tree  .vue-treeselect__value-remove {*/
  /*  color: #7c7c7c !important;*/
  /*  border-left: 1px solid #373737;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__checkbox--unchecked {*/
  /*  border-color: #303538;*/
  /*  background: #303538;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__checkbox--checked {*/
  /*  border-color: #303538;*/
  /*  background: #303538;*/
  /*}*/

  /*#stream-servers-tree .vue-treeselect__checkbox--indeterminate {*/
  /*  border-color: #303538;*/
  /*  background: #303538;*/
  /*}*/



  #stream-modal.footer,
  #create-stream-footer,
  #create-stream-details-footer,
  #create-stream-noservers-footer{
    margin-top: 1em;
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  /*h2.modal-title.medium {*/
  /*  margin: 11px 0 17px 0;*/
  /*  font-family: Circular, sans-serif;*/
  /*  font-size: 38px;*/
  /*  font-weight: 500;*/
  /*  font-stretch: normal;*/
  /*  font-style: normal;*/
  /*  line-height: normal;*/
  /*  letter-spacing: -1.16px;*/
  /*  color: rgba(255, 255, 255, 0.84);*/
  /*  white-space: nowrap;*/
  /*}*/

  /*h4.brand {*/
  /*  margin: 0;*/
  /*  font-family: Circular, sans-serif;*/
  /*  font-size: 11px;*/
  /*  font-weight: 500;*/
  /*  font-stretch: normal;*/
  /*  font-style: normal;*/
  /*  line-height: normal;*/
  /*  letter-spacing: 3.07px;*/
  /*  color: #8356ff;*/
  /*}*/


  #stream-modal .upload-preview {
    /**width: 74px;
    height: 74px;
    border-radius: 26px;*/
    background-color: #632ff3;
    display: inline-block;
    margin-right: 1em;
  }

  #stream-modal .file {
    display: inline-block;
    width: auto;
  }


  .categories-select {
    margin-top: 1em;
    width: 100%;
  }

</style>
