import Vue from 'vue';
import Router from 'vue-router';
//import store from './store/store';

Vue.use(Router);
const isProd = process.env.NODE_ENV === 'production';
const base =  isProd ? process.env.VUE_APP_BASE_URL : '';
console.log('process.env.BASE_URL', isProd, base)

  // Import Component
 import Home from "./views/Home";
 //import Browse from "./views/Browse";
 //import BrowseOld from "./views/Browse";
 //import News from "./views/News";
 import Chat from './views/Chat';
 //import Live from './views/Live';
 //import Account from './views/Account';
 import Logout from './views/Logout';
 import PageNotFound from './views/PageNotFound';
import UserProfile from './views/UserProfile';
 //import Verification from './views/Verification';
 // import SingleRoom from "@/components/RoomsCore/SingleRoom";

 // import SignUp from "./views/SignUp";
 // import LogIn from "./views/LogIn";

    const routes = [
        {
            path: "/",
            name: "Home",
            component: Home

        },
        {
            path: "/browse",
            name: "Browse",
            component: () => import('./views/Browse.vue')
        },
        {
            path: "/live",
            name: "Live",
            component: () => import('./views/Live.vue')
        },
        {
            path: "/news",
            name: "News",
            component: () => import('./views/News.vue')
        },
        {
            path: "/search/:query",
            name: "Search",
            props: true,
            component: () => import('./views/SearchResults.vue')
        },
        {
            path: "/account/",
            name: "Account",
            component: () => import('./views/Account.vue'),
            children: [
                {
                    path: "verify",
                    name: "verify",
                    component: () => import('./views/Verification.vue')
                }
            ]
        },
        {
            path: "/user-profile/:name/",
            name: "UserProfile",
            component: UserProfile,
            props: true,
        },
        {
            path: "/verification",
            name: "Verification",
            component: () => import('./views/Verification.vue')

        },
        {
            path: "/logout",
            name: "Logout",
            component: Logout
        },
        {
            path: "/servers/:id/:room?",
            name: "Chat",
            component: Chat,
        },
        {
            path: '*',
            component: PageNotFound
        },
        {
            path: '/404',
            component: PageNotFound
        },
    ];

    const router = new Router({
        mode: 'history',
        base: base,
        routes
    });


 export default router;
