import axios from "../../../axios";
export default {
 async allServers({commit}){
   try{
     let responseServers = await axios.get('/servers');
     commit('set_servers',responseServers.data);
   }catch(e){
     console.log('GET SERVERS ERROR   ',e);
   }
 },


    async getServerUsers({commit}, users) {
        let populated = {};
        for (let user of users) {
            try{
                let response = await axios.get('/users/' + user );
                if (response.status === 200 && response.data && response.data._id) {
                    populated[response.data._id] = response.data;
                }
            }catch(e){
                console.log('GET SERVER ERROR   ', e);
            }
        }
        commit('set_serverUsers', populated);
    },

    async getServersByIdArray({commit}, users) {
        let populated = {};
        for (let user of users) {
            try{
                let response = await axios.get('/users/' + user );
                if (response.status === 200 && response.data && response.data._id) {
                    populated[response.data._id] = response.data;
                }
            }catch(e){
                console.log('GET SERVER ERROR   ', e);
            }
        }
        commit('set_serverUsers', populated);
        //console.log('getUser', populated);
    }

}
