<template>
  <section>
    <b-modal id="join-room" v-model="showJoinRoom" :width="540" scroll="keep" :on-cancel="handleCancel">
      <h4>PROFIT</h4>
      <h2 v-if="room" class="modal-title-thin">{{titles[activeStep]}}</h2>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode">
        <b-step-item step="0" label="Join Room" :clickable="isStepsClickable">
<!--          <p class="signup-note">You are not member of this room yet.</p>-->
<!--          <p v-if="room.price > 0" class="signup-note">-->
<!--            Access to room requires a subscription.<br/>-->
<!--            Price: {{room.price}} € / per {{room.intervalBillingCycleCount === 1 ? room.billingCycle : room.intervalBillingCycleCount + ' ' + room.billingCycle + 's'}}-->
<!--          </p>-->
<!--          <p v-else class="signup-note">This Room is Free of Charge.</p>-->
          <div class="row vcentered" style="padding: 12px; background-color: #2D3034; border-radius: 10px; height: 60px;">
            <div id="join-room-modal-thumb"
                :style="{ 'background-image': `url(${setThumb(selectedServer.image)})` }"
            ></div>
            <div id="join-room-modal-text">
              <p class="title">{{room.name}}</p>
              <p class="subtitle">{{room.subscriptions.length}} members</p>
            </div>
          </div>
          <footer id="join-room-footer" class="footer join-room-footer">
            <b-button
                v-if="room.price > 0"
                class="submit"
                type="submit"
                @click="activeStep = 1"
            >Subscribe</b-button>
            <b-button
                v-else
                class="submit"
                type="submit"
                @click="handleJoin()"
            >Join</b-button>
            <p class="bottom-link" style="margin-top: 21px;"><a style="color: white" href="#" @click.prevent="handleClose()">Cancel</a></p>
          </footer>
        </b-step-item>

        <b-step-item step="1" label="Set Payment" :clickable="isStepsClickable">
            <StripePayment
                :product="room"
                :isServer="false"
                v-on:subscriptionCreated="subscriptionCreated()"
                v-on:closeJoin="handleClose()"
            />
        </b-step-item>
        <b-step-item step="2" label="Joined Room" :clickable="isStepsClickable">
          <h1 style="text-align: center;font-size: 120%;">Welcome to {{room.name}}</h1>
          <sweetalert-icon icon="success"></sweetalert-icon>
          <footer id="join-room-success-footer" class="footer join-room-footer">
            <p class="signup-note"></p>
            <b-button
                class="submit"
                type="submit"
                @click="handleGoToRoom()"
            >Close</b-button>
          </footer>
        </b-step-item>
        <b-step-item step="3" label="Join Room Error" :clickable="isStepsClickable">
          <h1 v-if="errorMsg" style="text-align: center;font-size: 120%;">{{errorMsg}}</h1>
          <h1 v-if="!errorMsg"  style="text-align: center;font-size: 120%;">Error in Joining Room</h1>
          <sweetalert-icon icon="error"></sweetalert-icon>
          <footer id="joined-room-failed-footer" class="footer join-room-footer">
            <b-button
                class="submit"
                type="submit"
                @click="handleClose()"
            >Close</b-button>
          </footer>
        </b-step-item>
      </b-steps>
    </b-modal>
  </section>
</template>

<script>
import axios from "@/axios";
import EventBus from "@/eventBus";
import SweetalertIcon from "vue-sweetalert-icons";
//import { loadStripe } from '@stripe/stripe-js';
import StripePayment from '@/components/Payments/StripePayment';

export default {
  name: "JoinRoom",
  props:['room'],
  components: {
    SweetalertIcon,
    StripePayment
  },
  data(){
    return {
      showJoinRoom: true,
      paid: false,
      loading: false,
      today : new Date(),
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      errorMsg: null,
      selectedServer: null,
      titles: ['Join Room', '', '', '', '']
    }
  },
  computed: {
    userRooms() {
      return this.$store.getters.get_userRooms;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    serverThumbDefault () {
      return require('@/assets/img/server-thumb-default.png');
    },

  },
  created() {
    if (this.room.price === 0) {
      this.paid =  true;
    }
    console.log('this.room',  this.room);
    this.selectedServer = this.$store.getters.get_selectedServer;
  },
  methods: {
    async handleJoin() {

      if(this.room.price === 0) {
        try {
          this.loading = true;
          let data = {
              "user": this.userDataAfterLogIn._id,
              "amountPaid": 0,
              "room": this.room._id,
              "endDate": null,
              "isOwner" : false,
              "indefinite": true,
          };
          let response = await axios.post('/subscriptions', data);
          //console.log('/subscriptions/', response);
          this.loading = false;
          if (response.status && response.status >= 200 && response.status < 300) {
              this.activeStep = 2;
              let user = JSON.parse(this.$localStorage.get('user'));
              user.token = this.$localStorage.get('accessToken');
              let result = this.$store.dispatch('login', user); // Temp
          } else {
            if (response.data.message) {
              this.errorMsg = response.data.message;
            }
            this.activeStep = 3;
          }
        } catch (e) {
          this.loading = false
          console.log('error:', e);
        }
      }
      this.loading = false
    },
    async onRoomAfterJoin(){
        console.log('Im NOW in this room ' , this.room );
        this.$store.commit('set_selectedRoom', null);
        EventBus.$emit('NEW_ROOM_LOADING', true);
        try {
          let response = await axios.get('/rooms/' + this.room._id );
          EventBus.$emit('NEW_ROOM_LOADING', false);
          EventBus.$emit('NEW_ROOM_LOADED', true);
          if (response.status >=  200 && response.status <  300 && response.data) {
            this.$store.commit('set_selectedRoom', response.data);
          }
        } catch (error) {
          EventBus.$emit('NEW_ROOM_LOADING', false);
          console.log(error);
          return error
        }
    },
    dateToLocalISO(date) {
      const off    = date.getTimezoneOffset()
      const absoff = Math.abs(off)
      return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
          (off > 0 ? '-' : '+') +
          (absoff / 60).toFixed(0).padStart(2,'0') + ':' +
          (absoff % 60).toString().padStart(2,'0'))
    },
    async subscriptionCreated() {
      this.activeStep = 2;
      let user = JSON.parse(this.$localStorage.get('user'));
      user.token = this.$localStorage.get('accessToken');
      let result = await this.$store.dispatch('login', user); // Temp

    },
    setThumb(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('server' === image.slice(0, 6)) {
            return this.$imageurl + '/' + image;
          } else {
            return image;
          }
        }
      }
      return this.serverThumbDefault;
    },
    handleGoToRoom() {
      this.onRoomAfterJoin();
      this.handleClose();
    },
    handleCancel() {
      this.$router.back();
    },
    handleClose() {
      this.$parent.roomToJoin = null;
    },

  }
}
</script>

<style>

/*#join-room {*/
/*    width: 414px;*/
/*  height: 726px;*/
/*  padding: 51px;*/
/*  border-radius: 17px;*/
/*  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);*/
/*  background-color: #1e2124;*/
/*}*/

/*h2.modal-title {*/
/*  margin: 0.25em 0 0.1em;*/
/*  font-family: Circular, sans-serif;*/
/*  font-size: 41px;*/
/*  font-weight: 500;*/
/*  font-stretch: normal;*/
/*  font-style: normal;*/
/*  line-height: normal;*/
/*  letter-spacing: -1.16px;*/
/*  color: rgba(255, 255, 255, 0.84);*/
/*  overflow: hidden;*/
/*  white-space: normal;*/
/*}*/

#join-room h4 {
  margin: 0;
  /*font-family: Circular, sans-serif;*/
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3.07px;
  color: #8356ff;
}

#join-room.modal .modal-content {
  background-color: #1e2124 !important;
  max-width: 420px !important;
  padding: 1.5em 1em 1em;
  border-radius: 17px!important;
  /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
}

#join-room-modal-thumb {
  width: 29px;
  height: 29px;
  border-radius: 13px;
  background-color: #232728;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 3px;
}

#join-room-modal-text {
  font-family: 'Circular Std Book', sans-serif;
  margin-top: 2px;
  margin-left: 9px;
}

#join-room-modal-text .title {
  color: #CCCBCB;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Circular Std Book', sans-serif;
  margin: 0;
  width: 100%;
}

#join-room-modal-text .subtitle {
  font-family: 'Circular Std Book', sans-serif;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  width: 100%;
  letter-spacing: -0.34px;
}


/**
#join-room-footer {
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}
 */

#join-room .footer {
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}


</style>
