<template>
  <div :id="message._id" class="cje" @mouseenter="hover = true" @mouseleave="hover = false">
    <div class="msg-content"
         v-if="message.message  && ( !message.quote || (message.quote && !message.quote.length) )"
         ref="msg"
         v-html="parsedMessage"
    ></div>
    <!--    <p>{{message.date}}</p>-->
    <div class="msg-content" v-if="message.quote && message.quote.length">
      <QuoteMessage
          :quoteObject="message.quote[0]"
          :message="parsedMessage">
      </QuoteMessage>
    </div>
    <div class="msg-images-wrapper" v-if="message.images && message.images.length && ( !message.quote || (message.quote && !message.quote.length) )">
      <div v-for="image in message.images" :key="image._id">
        <img class="msg-images" v-if="image" :src="`${baseUrl}/${image}`" />
      </div>
    </div>
    <b-dropdown aria-role="list" class="chat-message-actions"
                :class="{'is-pulled-right is-top-left': isUsersMessage , 'is-pulled-left is-bottom-right': !isUsersMessage}"
    >
      <!--      <b-icon  style="color: #4a4a4a;" icon="dots-vertical" slot="trigger"></b-icon>-->
      <fa-icon icon="ellipsis-v"  slot="trigger" style="color: #4a4a4a;margin: 0 0.5em; cursor: pointer;"  :size="$faDefaultIconSize" />
      <b-dropdown-item v-if="isUsersMessage" aria-role="listitem" @click="MessageEdit">Edit</b-dropdown-item>
      <b-dropdown-item v-if="isUsersMessage" aria-role="listitem" @click="deleteMessage">Delete</b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="quoteMessage">Quote</b-dropdown-item>
    </b-dropdown>
    <!--    <b-dropdown v-if="!isUsersMessage" aria-role="list" class="chat-message-reactions is-top-right"-->
    <!--    >-->
    <!--      <fa-icon icon="smile" slot="trigger" scale="1.2" />-->
    <!--      <b-icon icon="heart" type="is-danger"></b-icon>-->
    <!--      <b-icon icon="thumb-up" type="is-info"></b-icon>-->
    <!--      <b-icon icon="thumb-down" type="is-warning"></b-icon>-->
    <!--      <b-icon icon="emoticon-cool"></b-icon>-->
    <!--    </b-dropdown>-->
  </div>
</template>


<script>
import socket from "../../socket";
import QuoteMessage from "../QuoteMessage";
//import ChatMsg from "@/components/Traits/ChatMsg";
import Vue from 'vue';

// import Quill from 'quill';
// import 'quill/dist/quill.core.css';
// import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
// import { quillEditor } from 'vue-quill-editor';

import { parse } from 'node-html-parser';

//const Msg = Vue.extend(ChatMsg);
//const instance = new ComponentClass();


export default {
  name: "ChatSingleMessage",
  props:['message'],
  components:{
    QuoteMessage,
  },
  data(){
    return{
      hover:false,
      parsedMessage: null,
      ready: false,
      baseUrl: '',
      // instance: new Msg ({
      //
      // })
    }
  },
  computed:{
    messageUser() {
      if (this.message.user.length) {
        return this.message.user[0];
      }
      return this.message.user;
    },
    isUsersMessage(){
      return this.messageUser._id === this.$store.getters.get_userDataAfterLogIn._id;
    },
  },
  methods:{
    deleteMessage() {
      console.log('DELETE MESSAGE the messageId and roomId before emit the deleteMessage event is ',this.message._id,this.$store.getters.get_selectedRoom._id)
      socket.emit('deleteMessage', {
        messageId: this.message._id,
        roomId: this.$store.getters.get_selectedRoom._id,
      });
    },
    MessageEdit(){
      this.$store.commit('INIT_EDIT_MESSAGE', this.message);
    },
    quoteMessage(){
      this.$store.commit('QUOTE_MESSAGE', this.message);
    },
  },
  created() {
    this.baseUrl = process.env.VUE_APP_API_IMAGES_URL;
    //console.log('chat single', this.message);
    let final = '';
    const messageArray = this.message.message.split(' ');
    messageArray.forEach(str => {
      const t = str.trim();
      if (t.slice(0, 1) === '@') {
        // TODO check if a user actually exists
        final += `<span class="mention">${t}</span> `;
      }
      else if (t.slice(0, 1) === '#') {
        final += `<span class="hashtag">${t}</span> `;
      } else {
        final += `${t} `;
      }
    });
    this.parsedMessage = parse(final);
  },
}
</script>

<style lang="scss">

.cje {
  margin-bottom: 0.5em;

}

.msg-content {
  margin-top: 1px;
  margin-bottom: 4px;
  margin-left: 3px;
  word-wrap: break-word;
  font-size: 15px;
  border-radius: 15px;
  padding: 6px 15px 6px 15px;
  width: auto;
  max-width: calc(80% - 1px);
  display: inline-block;
  background-color: #594b6f;
  color: #dddddd;
  overflow: hidden;
  white-space: normal;
}
.msg-wrapper {
  background-color: white;
}

.msg-images-wrapper {
  border-radius: 5px;
  width: auto;
  padding: 25px;
  max-width: calc(100% - 90px);
  display: inline-block;

  background-color: #594b6f;
  color: #dddddd;
}

.message-body {
  padding: 0.95em 1.5em 1.25em !important;
}

.dropdown.message-dropdown {
  padding-top: 0.35em;
}

.chat-message-actions .dropdown-menu {
  padding: 0;
}

.chat-message-reactions .dropdown-menu {
  padding: 0;
  background-color: #black;
  margin-bottom: 2px;
  margin-left: 4px;
}

.chat-message-reactions .dropdown-content {
  padding: 0.25em;
  background-color: transparent;
  display: table;
}

.chat-message-reactions .dropdown-content .icon {
  display: table-cell;
}

.users-message .message-wrapper .cje:first-child .msg-content {
  border-radius: 15px 0 15px  15px;
}

.non-users-message .message-wrapper .cje:first-child .msg-content {
  border-radius: 0 15px 15px  15px ;
}


span.mention {
  color: #674cf4;
}

span.hashtag {
  color: #a3a3a4;
}

.chat-message-actions .dropdown-content {
  background: #202428;
}
.chat-message-actions .dropdown-item {
  color: rgba(255, 255, 255, 1);
}
.chat-message-actions .dropdown-item:hover,
.chat-message-actions .dropdown-item.selected {
  background-color: #5D4AB8;
  color: rgba(255, 255, 255, 1);
}

.chat-message-actions .dropdown-item span {
  display: inline-block;
}
</style>
