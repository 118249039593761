<template>
  <section>
    <b-modal id="server-settings" v-model="showServerSettingsModal" :on-cancel="handleDeselect">
      <h2 class="modal-title medium">Server Settings</h2>
      <ValidationObserver tag="div" class="form-observer" ref="serverUpdateFormValidator" v-slot="{ invalid }" style="display: flex; flex-wrap: wrap;">

        <form id="update-server-form" @submit.prevent="handleServerSubmit" class="columns">
          <div class="column is-three-fifths">
            <ValidationProvider tag="div" class="validation-field" name="name" rules="required|min:3"
                                v-slot="{ errors, valid }">
              <b-field label="Server Name"
                       class="form-field"
                       :class="{ 'is-danger': errors[0], 'is-success': valid }"
                       :type="{ 'is-danger': errors[0], 'is-success': valid }">
                <b-input class="signup-input" vid="name" v-model="servername" :readonly="loading"></b-input>
              </b-field>
              <p v-if="errors && errors[0]" class="help field-help"
                 :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}</p>
              <p v-else class="help field-help"
                 >&nbsp;</p>
            </ValidationProvider>
            <ValidationProvider tag="div" class="validation-field" name="Description" rules="required|min:20"
                                v-slot="{ errors, valid }">
              <b-field label="Description"
                       class="form-field"
                       :class="{ 'is-danger': errors[0], 'is-success': valid }"
                       :type="{ 'is-danger': errors[0], 'is-success': valid }"
              >
                <b-input class="signup-input" vid="description" v-model="description" :readonly="loading"></b-input>
              </b-field>
              <p v-if="errors && errors[0]" class="help field-help"
                 :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}</p>
            </ValidationProvider>
<!--            <ValidationProvider tag="div" class="validation-field" name="Private" rules="" v-slot="{ }">-->
<!--              <b-field label=""-->
<!--                       class="form-field vcentered"-->
<!--              >-->
<!--               <p style="margin-right:40px">Channel Type:</p> <b-switch id="private-switch" class="switch-basic" v-model="isPrivate">Private Server</b-switch>-->
<!--              </b-field>-->
<!--            </ValidationProvider>-->
          </div>

          <div class="column is-two-fifths">
            <EditServerImageUpload
                :tempImage="tempImage"
                :uploading="uploading"
                :imageUploaded="imageUploaded"
                :server="server"
                :servername="servername"
                :serverUploadedImage="serverUploadedImage"
                v-on:previewImageUpdate="previewImageUpdate"
            />
          </div>
        </form>
        <footer id="server-settings-footer" class="footer">
          <b-button
              size="is-small"
              @click="handleServerSubmit"
              :loading="loading"
              :disabled="invalid"
              type="is-primary"
              style="padding: 8px 24px;border-radius: 4px;"
          >Done</b-button>
        </footer>
      </ValidationObserver>

    </b-modal>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full';
import EditServerImageUpload from "../Traits/EditServerImageUpload";
import axios from '../../axios';

export default {
  name: 'ServerSettings',
  components: {
    ValidationObserver,
    ValidationProvider,
    EditServerImageUpload
  },
  props: [
    'server'
  ],
  data() {
    return {
      showServerSettingsModal: true,
      servername: '',
      loading: false,
      isPrivate: false,
      tempImage: null,
      uploading: false,
      imageUploaded: false,
      serverUploadedImage: null,
      description: '',
    }
  },
  created() {
    // console.log('Selected Server: ', this.server);
    this.servername = this.server.name;
    this.description = this.server.description;
    this.isPrivate = this.server.is_private;
    this.tempImage = this.server.image ? this.server.image : '';
  },
  watch: {
    servername(val) {
      // console.log('new server name: ', val);
    },
    isPrivate(val) {
      // console.log('new server isPrivate: ', val);
    }
  },
  computed: {
    serverDefault() {
      return require('@/assets/img/server-default.png');
    },
  },
  methods: {
    handleDeselect() {
      this.showServerSettingsModal = false;
      this.$emit('serverSettingsFormClose');
    },
    async handleServerSubmit() {
      this.loading = true;

      const image = this.tempImage ? this.tempImage.filename : '';

      const postData = {
        name: this.servername,
        description: this.description,
        // is_private: this.isPrivate,
        image
      }

      const axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'application/json';

      try {
        const {data} = await axios.put(`/servers/${this.server._id}`, postData, {
          headers: axHeaders
        });
        console.log(`Server update: `, data);
        this.loading = false;

        this.$store.commit('set_selectedServer', data);
        this.handleDeselect();

        this.$store.dispatch('login', {
          token: this.$store.getters.get_token
        });
      } catch (e) {
        console.log(e.response);
      }
    },
    previewImageUpdate(val) {
      console.log('previewImageUpdate', val);
      this.tempImage = val;
    }
  }
}
</script>

<style>
#server-settings.modal .modal-content {
  background-color: #1e2124 !important;
  max-width: 691px !important;
  /*max-height: 330px !important;*/
  /*padding: 1.5em 1em 1em;*/
  border-radius: 11px !important;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
  padding: 42px 42px 27px !important;
}

#update-server-form {
  flex: 1;
}

#server-settings-footer {
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: right;
}

</style>
