<template>
      <div id="main-left-nav">
        <!--b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading-->
        <vuescroll :ops="ops" v-if="user_id && userServers && isAuthenticated"
          id="main-left-buttons">
          <ul v-if="rendered">
            <li v-for="(server) in userServers" :key="server._id"
                :id="'server-thumb-'+server._id"
                class="server-thumb"
                @mouseover="showTooltip(server)"
                @mouseleave="hideTooltip(server)"
            >
              <div
                class="server-thumb-tooltip"
                         :class="{
                                  'selected' : theSelectedServer && theSelectedServer._id === server._id,
                                  'own' : userDataAfterLogIn._id === server.owner
                          }"
              >
<!--                <b-tooltip ref="serverTooltip" :id="'server-tooltip-'+server._id" class="server-tooltip" :label="server.name" :position="position" :append-to-body="appendToBody">-->
              <router-link
                  :to="getLink(server)" @click.native="selectedServer(server)"
                  class="server-thumb-tag"
                  :style="{ 'background-image': `url(${setThumb(server.image)})` }"
                  :class="{
                            'selected' : theSelectedServer && theSelectedServer._id === server._id,
                            'no-avatar  vcentered' : !server.image
                  }">
              </router-link>
<!--                </b-tooltip>-->
              </div>
            </li>
          </ul>
        </vuescroll>
      <div id="main-left-buttons-bottom" v-if="user_id && isAuthenticated">
          <button @click="createServer()" ><span>+</span></button>
      </div>
        <b-tooltip
            ref="serverTooltip"
            id="servers-tooltip"
            class="server-tooltip"
            :label="tooltipText"
            :position="position"
            :style="tooltipText.length ? `display: block; top:${tooltipTop}px` : `display: none; top:-100px`"
        ></b-tooltip>
        <CreateServer v-if="openCloseCreateServer" v-on:createServerFormClose="showCreateServer = false" v-on:createdServerReturn="afterCreatedServer"></CreateServer>
        <!--CreateRoom v-if="showCreateRoom" v-on:createRoomFormClose="showCreateRoom = false"></CreateRoom-->
    </div>
</template>
<script>
   //import CreateServer from '../ServersCore/CreateServer.vue';
   import vuescroll from 'vuescroll';
   // import Popper from 'vue-popperjs';
   // import 'vue-popperjs/dist/vue-popper.css';
   import EventBus from "@/eventBus";

    export default {
      components: {
        //CreateServer,
        vuescroll,
        // 'popper': Popper,
        'CreateServer': () => import('../ServersCore/CreateServer.vue'),
      },
      data(){
        return{
          showCreateServer: false,
          tooltipText: '',
          tooltipTop: 0,
          rendered: false,
          showCreateRoom: false,
          user_id: null,
          //user_servers: null,
          isLoading: false,
          isConnected: false,
          position: 'is-right',
          appendToBody: false,
          ops: {
            vuescroll: {
              mode: 'native',
              sizeStrategy: 'percent',
              detectResize: true,
              /** Enable locking to the main axis if user moves only slightly on one of them at start */
              locking: false,
            },
            scrollPanel: {
              initialScrollY: '0%',
              scrollingX: false,
              scrollingY: true,
              speed: 200,
              //easing: undefined,
              //verticalNativeBarPos: 'left'
            },
            rail: {},
            bar: {}
          },
        }
      },
      created() {
        this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'set_userDataAfterLogIn' && state.auth && state.auth.userDataAfterLogIn) {
            this.user_id = state.auth.userDataAfterLogIn._id;
          }
          if (mutation.type === 'set_userServers' && this.connectedToSocket) {
            this.isLoading = false;
          }
          if (mutation.type === 'set_connectedToSocket') {
            this.isConnected = state.auth.connectedToSocket;
            //this.resizer();
            if (this.userDataAfterLogIn && this.userDataAfterLogIn.servers) {
              this.isLoading = false;
            }
            //let parsed = JSON.parse(JSON.stringify(state.servers));
          }

        });

        if (this.userDataAfterLogIn) {
          this.user_id = this.userDataAfterLogIn._id;
        }

        if (this.connectedToSocket){
          this.isConnected = this.connectedToSocket;
        }
        if (!this.connectedToSocket && (!this.userDataAfterLogIn || !this.userDataAfterLogIn.servers)) {
          this.isLoading = true;
        }
      },
      mounted() {
        this.rendered = true;
        this.resizer();
        window.addEventListener('resize', this.resizer);
        document.body.appendChild(document.getElementById('servers-tooltip'));
      },
      methods:{
        showTooltip(server) {
          const thumb = document.getElementById('server-thumb-'+server._id);
          if (thumb) {
            this.$nextTick(()=>{
              const thumbOffset = thumb.getBoundingClientRect();
              const h = thumbOffset.height / 2;
              this.tooltipTop = thumbOffset.top + h;
              this.tooltipText = server.name;
            });
          } else {
            this.tooltipText = '';
          }
        },
        hideTooltip(server) {
          this.tooltipText = '';
        },
        setImage(url) {
          return this.$imageurl + '/' + url;
        },
        letters(name) {
          let letters = 'PR';
          if (name.length) {
            const arr = name.match(/\b(\w)/g);
            if (arr.length > 2) {
              arr.splice(2);
              letters = arr.join('').toUpperCase();
            } else {
              letters = arr.join('').toUpperCase();
            }
            // console.log('servername', arr, this.letters);
          }
          return letters;
        },
        async afterCreatedServer(createdServer) {
          // Emit to Handle after create here cause modal might be closed earlier
          console.log('afterCreatedServer emmit', createdServer, this.$route);
          let user = JSON.parse(this.$localStorage.get('user'));
          user.token = this.$localStorage.get('accessToken');
          let login = await this.$store.dispatch('login', user);
          this.$router.push('/servers/' + createdServer.slug);
          window.location.reload();

          //this.$store.commit('set_selectedServer', createdServer);
          //this.$store.dispatch('set_latestServers', true);


        },
        setThumb(image) {
          if (image && image.length) {
            if ('http' === image.slice(0, 4)) {
              return image;
            } else {
              if ('server' === image.slice(0, 6)) {
                return this.$imageurl + '/' + image;
              } else {
                return image;
              }
            }
          }
          return this.serverThumbDefault;
        },
        getImage(image) {
          let link;
          if (!image) {
            link = `background-image: url(${this.serverThumbDefault}); background-size: 80%;`;
          } else {
            link = `background-image: url(${image})`;
          }
          return link;
        },
        getLink(server) {
          if (this.isAuthenticated && (server.slug || server.owner)) {
            let slug = (server.slug) ? server.slug : server.name + '-' + server.owner.username;
            return {
              name: 'Chat',
              params:{
                id:`${decodeURI(encodeURI(slug))}`
              }
            };
          } else {
            return {};
          }
        },
        createServer() {
          this.$store.commit('set_openCloseCreateServer');
        },
        createRoom() {
          this.$store.commit('set_openCloseCreateRoom');
        },
        selectedServer(server){
          console.log('selectedServer', server);
          //this.$router.replace('/servers/' + server.slug);
          //this.$router.go(this.$router.currentRoute);
          EventBus.$emit('RELOAD');
          //window.location.reload();

            if (this.isAuthenticated) {
              //this.$store.dispatch('setSelectedServerRooms', []);
              this.$store.commit('set_selectedServer', server);
              //if (server.slug) {
                //this.$router.replace('servers/' + server.slug);
                //window.location.reload();
              //}
            } else {
              this.$store.commit('set_openCloseLogIn');
            }
        },
        resizer() {
          let main, left, bottom, leftnav;
          main = document.getElementById("main");
          //bottom = document.getElementById("main-left-buttons-bottom");
          if (main) {
            leftnav = main.offsetHeight - 100;
            document.documentElement.style.setProperty('--leftNav', `${leftnav}px`);
          } else {
            document.documentElement.style.setProperty('--leftNav', `500px`);
          }
        },
      },
      computed:{
        servers(){
          return this.$store.getters.get_servers;
        },
        userServers() {
          return this.$store.getters.get_userServers;
        },
        isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
        openCloseCreateServer() {
          return this.$store.getters.get_openCloseCreateServer;
        },
        openCloseCreateRoom() {
          return this.$store.getters.get_openCloseCreateRoom;
        },
        userDataAfterLogIn() {
          return this.$store.getters.get_userDataAfterLogIn;
        },
        connectedToSocket() {
          return this.$store.getters.get_connectedToSocket;
        },
        serverThumbDefault () {
          return require('@/assets/img/server-thumb-default.png');
        },
        theSelectedServer() {
          return this.$store.getters.get_selectedServer
        }

      },


    }
</script>
<style lang="scss">
    #servers-tooltip {
      position: absolute;
      top: 0;
      left: 65px;
      transform: translateY(50%);
      z-index: 9999;
    }

    #servers-tooltip .tooltip-content {
      display: block !important;
    }


    .image {
      background-size: 100% 100%;
    }

    //.server-thumb {
    //  position: relative;
    //}

    //.server-thumb-tag{
    //  position: absolute;
    //}

    #main-left-nav {
      height: 100%;
      max-height: 100%;
      position: relative;
    }

    #main-left-nav ul li {
      //margin-top: 20px;
      margin: 10px auto;
      position: relative;
      text-align: center;
    }


    //#main-left-nav ul li a span {
    //    display: block;
    //    width: 57px;
    //    height: 57px;
    //    color: #fff;
    //    border-radius: 22px;
    //    /**padding: 23px 24px;*/
    //    background-color: #2A2F33;
    //    line-height: 1;
    //    margin: auto;
    //    background-size: cover;
    //    background-position: 50%;
    //    background-repeat: no-repeat;
    //    padding: 0;
    //    box-shadow: 0px 2px 4px 0px #00000080;
    //    border: 4px solid transparent;
    //}
    //
    //#main-left-nav ul li a span.selected {
    //  border: 4px solid #5E59FF
    //}



    //.server-thumb-tag {
    //  display: block;
    //  width: 57px;
    //  height: 57px;
    //  color: #fff;
    //  border-radius: 22px;
    //  /**padding: 23px 24px;*/
    //  background-color: #2A2F33;
    //  line-height: 1;
    //  margin: auto;
    //  background-size: cover;
    //  background-position: 50%;
    //  background-repeat: no-repeat;
    //  padding: 0;
    //  box-shadow: 0px 2px 4px 0px #00000080;
    //  border: 4px solid transparent;
    //}
    //
    //.server-thumb-tag.selected {
    //  border: 4px solid #5E59FF
    //}

    #main-left-nav ul li:nth-child(2) a span {
        color: #2A2F33;
    }

    #main-left-nav ul li:nth-child(3) a span {
        color: #2A2F33;
    }

    #main-left-buttons .router-link-active .image {
      border: 2px dotted #674cf4;
      /**
      -moz-box-shadow:    3px 3px 5px 6px #674cf4;
      -webkit-box-shadow: 3px 3px 5px 6px #674cf4;
      box-shadow:         3px 3px 5px 6px #674cf4;
      */
    }

    #main-left-buttons-bottom {
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 20px 0;
    }

    #main-left-buttons-bottom button  {
      width: 60px;
      height: 60px;
      border-radius: 24px;
      font-size: 22px;
      font-weight: 500;
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-image: linear-gradient(196deg, #a083fa 161%, #674cf4 11%);
      display: inline-block;
      background-color: transparent;
      border-color: transparent;

    }

    #main-left-buttons-bottom button span {
      vertical-align: top;
      /**display: table-cell;*/
    }

    .b-tooltip .tooltip-trigger {
      width: 100%;
      height: 100%;
    }

    .server-thumb-tooltip a {
      display: block;
      width: 57px;
      height: 57px;
      border-radius: 22px;
      background-color: #2A2F33;
      line-height: 1;
      margin: auto;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      padding: 0;
      //box-shadow: 0px 2px 4px 0px #00000080;
      //border: 4px solid transparent;
      //box-sizing: border-box;
      border: 4px transparent;
    }

    .server-thumb-tooltip.own a {
      border: 4px solid #63628D;
    }

    .server-thumb-tooltip.selected a {
      border: 4px solid #5E59FF;
    }


    @media (max-width: 1200px)  {
      #main-left-nav ul li a span,
      #main-left-buttons-bottom button span  {
        width: 60px;
        height: 60px;
      }
    }

    @media (max-width: 992px)  {
      #main-left-nav ul li a span,
      #main-left-buttons-bottom button span  {
        width: 55px;
        height: 55px;
      }
    }

    @media (max-width: 480px)  {
      #main-left-nav ul li a span,
      #main-left-buttons-bottom button span {
        width: 50px;
        height: 50px;
        border-radius: 18px;
      }
    }

    @media (max-width: 360px)  {
      #main-left-nav ul li a span,
      #main-left-buttons-bottom button span {
        width: 45px;
        height: 45px;
        border-radius: 18px;
      }
    }

    #main-left-buttons {
      overflow: hidden;
      height: calc( var(--main) - 100px );
      max-height: calc( var(--main) - 100px );
    }

    .buttons-scroll {
      overflow: auto;
    }


    #main-left-buttons ul {
      /**
      Scrollbar Module
      overflow: auto;
      */

      height: 100%;
      max-height: 100%;
    }
    .__bar-is-vertical {
      background-color:  #4a4a4a !important;
    }

    .tooltip {
        &.info {
         $color: rgba(#004499, .9);

        .tooltip-inner {
          background: $color;
          color: white;
          padding: 24px;
          border-radius: 5px;
          box-shadow: 0 5px 30px rgba(black, .1);
          max-width: 250px;
        }

        .tooltip-arrow {
            border-color: $color;
        }
      }
    }

    //.popper.toolip-popper {
    //  background-color: #7957d5;
    //  color: #ffffff;
    //  border-color: #7957d5;
    //  padding: 0.35rem 0.75rem;
    //  border-radius: 6px;
    //  font-size: 0.85rem;
    //  font-weight: 400;
    //  -webkit-box-shadow: 0px 1px 2px 1px rgb(0 1 0 / 20%);
    //  box-shadow: 0px 1px 2px 1px rgb(0 1 0 / 20%);
    //  white-space: nowrap;
    //
    //}
    //
    //.popper.toolip-popper[x-placement^="right"] {
    //  margin-left: 60px;
    //}
    //
    //.popper.toolip-popper  .popper__arrow {
    //  border-color: transparent #7957d5 transparent transparent;
    //}



    //.server-thumb-tag {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  background: none;
    //}



</style>
