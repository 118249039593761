<template>
  <b-button id="chat-message-textbox-button-upload"
            class="chat-message-textbox-button upload"
            @click="uploadFile">
    <b-image
        :src="upload"
    >
    </b-image>
    <input type="file" v-show="false" ref="input" @change="fileChanged" :accept="accept='image/*'" />
  </b-button>
</template>

<script>
    export default {
        data(){
            return{
                files:[],
            }
        },
        computed: {
          upload () {
            return require('@/assets/icons/upload.svg');
          }
        },
        methods:{
            uploadFile(event) {
                this.$refs.input.click();
            },
            fileChanged(event) {
                this.files = this.$refs.input.files;
                console.log('in fileChanged function , files is  ',this.files)
                this.$emit('change', {
                    files: this.files,
                });
            }
        },
    }
</script>
