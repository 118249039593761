<template>
    <div>
        <div class="image-wrapper" v-if="typeof src === 'string'">
            <img :src="src" class="uploaded-file-img" />
            <b-button class="remove-image" @click="removeFile">
                <!--v-icon color="#2196f3">mdi-close-circle</v-icon-->
              <fa-icon icon="times-circle" class="navbar-button-icon" :size="$faDefaultIconSize" />
            </b-button>
        </div>
        <div class="card file-wrapper uploaded-file" v-else>
            <!--v-icon color="rgba(255, 255, 255, .7)">mdi-file</v-icon-->

            <fa-icon icon="file-import" class="navbar-button-icon" :size="$faDefaultIconSize" />
            <div class="card-content">{{src.name}}</div>
            <b-button class="remove-image" @click="removeFile">
                <!--v-icon color="#2196f3">mdi-close-circle</v-icon-->
              <fa-icon icon="times-circle" class="navbar-button-icon" :size="$faDefaultIconSize" />
            </b-button>
        </div>
    </div>
</template>

<script>

    export default {
        props:['src','index'],
        methods:{
            removeFile() {
                this.$parent.uploadedFiles.splice(this.index, 1);
            }
        }

    }
</script>

<style scoped>
    .uploaded-file-img {
        border: 1px #1a1c1f solid;
        border-radius: 3px;
        max-width: 200px;
        max-height: 200px;
    }
    .uploaded-file {
        border-radius: 3px;
        max-width: 200px;
        margin-left: 10px;
    }
    .file-wrapper {
        background-color: #2a2c31 !important;
        padding: 10px;
        position: relative;
    }
    /*.remove-image {*/
    /*    position: absolute;*/
    /*    top: 5px;*/
    /*    right: 5px;*/
    /*}*/

    .image-wrapper {
        padding: 10px;
        position: relative;
    }
    .remove-image {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 0;
        background-color: transparent;
        border: 0;
    }

</style>
