<template>
 <div>
  <div v-if="quoteObject.images.length === 0">
      <div v-html="parsedQuoteMessage"></div>
      <p class="quote-info">{{quoteObject.user[0].username}} <span>{{quoteObject.date | moment('LT')}}</span></p>
      <hr style="margin: 0.5rem 0;" />
      <div v-html="message"></div>
  </div>
  <div v-else>
     <div><img  class="msg-images" :src="quoteObject.images[0].content" /></div>
      <p class="quote-info">{{quoteObject.user[0].username}} <span>{{quoteObject.date | moment('LT')}}</span></p>
      <hr style="margin: 0.5rem 0;" />
    <div v-html="message"></div>
  </div>
 </div>
</template>

<script>
 import axios from "../axios";
 import {parse} from "node-html-parser";
    export default {
        name: "QuoteMessage",
        props:['quoteObject','message'],
        data(){
         return{
          userNameQuote:'',
          parsedQuoteMessage: '',
         }
        },

        created() {
          // TODO check what if is image
          let final = '';
          const messageArray = this.quoteObject.message.split(' ');
          messageArray.forEach(str => {
            const t = str.trim();
            if (t.slice(0, 1) === '@') {
              // TODO check if a user actually exists
              final += `<span class="mention">${t}</span> `;
            }
            else if (t.slice(0, 1) === '#') {
              final += `<span class="hashtag">${t}</span> `;
            } else {
              final += `${t} `;
            }
          });
          this.parsedQuoteMessage = parse(final);
        },
        async mounted() {
          //console.log('this.quoteObject', this.quoteObject);
          // TODO Request just for a username?
          // try {
          //    let user = await axios.get(`/users/${this.quoteObject.user}`);
          //    if(user.data._id === this.quoteObject.user){
          //       this.userNameQuote = user.data.username;
          //    }
          // } catch (e) {
          //   console.log(e)
          // }
        },
   }
</script>

<style scoped>
  .quote-info {

  }

</style>
