<template>
  <div id="app" class=" is-light-passive is-bold is-fullheight">
<!--    <resize-observer @notify="handleResize"/>-->
    <Navbar></Navbar>
    <div id="main" class="columns main">
      <div id="main-left" class="column">
        <MembersHomeLayout></MembersHomeLayout>
      </div>
      <div id="main-right" class="column"
           :class="{'open-sidebar' : sidebar, 'closed-sidebar' : !sidebar }"
      >
        <router-view></router-view>
      </div>


      <div v-show="userDataAfterLogIn && userDataAfterLogIn._id"
           id="sidebar"
           class="column no-scroll"
           :class="[
               sidebarClass,
               {'hidden' : !openSidebar}
           ]"
      >
        <Sidebar></Sidebar>
      </div>

<!--      <b-sidebar-->
<!--          id="slidebar"-->
<!--          position="static"-->
<!--          :mobile="mobile"-->
<!--          :expand-on-hover="expandOnHover"-->
<!--          :reduce="!openSidebar"-->
<!--          :delay="expandWithDelay ? 500 : null"-->
<!--          type="is-dark"-->
<!--          :right="true"-->
<!--          open-->
<!--      >-->
<!--        <b-menu class="is-custom-mobile">-->
<!--        <b-menu-list label="">-->
<!--          <b-menu-item icon="comment" label="">-->
<!--            <PrivateChatTab></PrivateChatTab>-->
<!--          </b-menu-item>-->

<!--        </b-menu-list>-->
<!--        </b-menu>-->
<!--&lt;!&ndash;        <template :v-slot="'default'">&ndash;&gt;-->
<!--&lt;!&ndash;          <Sidebar></Sidebar>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->


<!--      </b-sidebar>-->



    </div>
    <SignUp v-if="openSignUpComp"></SignUp>
    <LogIn v-if="openLogInComp"></LogIn>
    <StreamCreate v-if="openStreamOptions"></StreamCreate>
    <UserSettings v-if="openUserSettings"></UserSettings>
  </div>

</template>

<script>
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/SidebarCore";
import MembersHomeLayout from "./components/MembersHomeLayout/MembersHomeLayout";
import StreamCreate from "./components/StreamCore/StreamCreate";
import SignUp from "./views/SignUp";
import LogIn from "./views/Login";
import UserSettings from "./views/UserSettings";
import EventBus from "@/eventBus";
// import { ResizeObserver } from 'vue-resize';
// import * as _ from 'lodash';
import _filter from 'lodash/filter';
import PrivateChatTab from "@/components/PrivateRoomsCore/PrivateChatTab";
//import socket from "@/socket";

export default {
  components: {
    // PrivateChatTab,
    Navbar,
    Sidebar,
    MembersHomeLayout,
    SignUp,
    LogIn,
    StreamCreate,
    UserSettings,
    // 'resize-observer': ResizeObserver,
    // 'SignUp': () => import('./views/SignUp'),
    // 'LogIn': () => import('./views/Login'),
    // 'UserSettings': () => import('./views/UserSettings'),
    // 'StreamCreate': () => import('./components/StreamCore/StreamCreate')
  },
  data() {
    return {
      expandOnHover: false,
      mobile: "reduce",
      reduce: true,
      expandWithDelay: false,
      overlay: false,
      fullHeight: true,
      fullWidth: false,
      right: true,
      sidebarOpen: false,
      sidebar: true,
      connected: null,
      sidebarClass: 'normal',
    }
  },

  computed: {
    openSignUpComp() {
      return this.$store.getters.get_openSignUp;
    },
    openStreamOptions() {
      return this.$store.getters.get_openStreamOptions;
    },
    openUserSettings() {
      return this.$store.getters.get_openUserSettings;
    },
    openLogInComp() {
      return this.$store.getters.get_openCloseLogIn;
    },
    window_width() {
      return this.$store.getters.get_window_width;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    connectedToSocket() {
      return this.$store.getters.get_connectedToSocket;
    },
    openSidebar() {
      return this.$store.getters.get_openCloseSidebar;
    },
  },
  async created() {
    this.$store.commit('firstload');
    EventBus.$on('sidebarClass', (payload) => {
      this.sidebarClass = payload;
    });

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'set_token') {
        this.$localStorage.set('accessToken', mutation.payload);
      }

      if (mutation.type === 'set_userDataAfterLogIn') {
        //console.log('set_userDataAfterLogIn subs', mutation);
        let user = (mutation.payload) ? JSON.stringify(mutation.payload) : null;
        this.$localStorage.set('user', user);
      }

      if (mutation.type === 'set_userServers') {
        this.$localStorage.set('userServers', JSON.stringify(state.auth.userServers));
        this.resizer();
      }
      // if (mutation.type === 'set_openCloseSidebar') {
      //   this.sidebar = state.openSidebar;
      // }

    });
    this.handleAuth();
    this.timer = setInterval(this.handleAuth, 50 * 60 * 1000);
  },
  mounted() {
    this.resizer();
    window.addEventListener('resize', this.resizer);
    if (this.$route.path === '/') {
      this.sidebar = false;
    } else {
      this.sidebar = true;
      if (!this.isAuthenticated) {
        //this.$router.replace('/');
      }
    }
  },
  /**
   beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
      },

   beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNav);
      },
   */
  watch: {
    '$route'(to, from) {
      if (from.name === 'Chat') {
        this.$store.commit('set_selectedRoom', null); // Check and Ask to uncomment
        this.$store.commit('set_selectedServerRooms', null);
        this.$store.commit('set_selectedServer', null);
        this.$store.commit('set_selectedServerUsers', null);
      }
    }
  },
  methods: {
    // handleResize({width, height}) {
    //   console.log('handleResize app', width);
    // },
    async handleAuth() {
      if (this.$localStorage.get('accessToken') && this.$localStorage.get('user')) {
        let user = JSON.parse(this.$localStorage.get('user'));
        if (user) {
          user.token = this.$localStorage.get('accessToken');
          let result = await this.$store.dispatch('login', user);
        }
        //if(result.status === 201 && result.data && result.data.tokens && result.data.user) {
        //this.$localStorage.set('accessToken', result.data.tokens.accessToken);
        //this.$localStorage.set('user', JSON.stringify(result.data.user)); // tempo
        //}
      } else {
        this.$store.commit('set_token', null);
        this.$store.commit('set_userDataAfterLogIn', null);
        this.$store.commit('set_userServers', null);
      }
    },
    resizer() {
      let app, nav, main;
      this.$store.commit('set_window_width', window.innerWidth);
      app = document.getElementById("app");
      nav = document.getElementById("navbar");
      if (app && nav) {
        main = app.offsetHeight - nav.offsetHeight;
        document.documentElement.style.setProperty('--nav', `${nav.offsetHeight}px`);
        document.documentElement.style.setProperty('--main', `${main}px`);
      }
    },
    streamer() {
      // let serversStreaming = _.filter(this.$store.getters.get_userDataAfterLogIn.servers, function (server) {
      let serversStreaming = _filter(this.$store.getters.get_userDataAfterLogIn.servers, function (server) {
        return server.broadcast !== null && server.broadcast;
      });
      let roomsStreaming = [];
      this.$store.getters.get_userDataAfterLogIn.servers.forEach((server) => {
        server.rooms.forEach((room) => {
          if (room.broadcast !== null && room.broadcast) {
            roomsStreaming.push(room)
          }
        })
      });
      this.$store.commit('set_ServersAndRoomsForStreaming', {
        servers: serversStreaming,
        rooms: roomsStreaming
      });
    },
    storageAvailable(type) {
      var storage;
      try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
      } catch (e) {
        return e instanceof DOMException && (
                // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
      }
    },
    preventNav(event) {
      console.log('preventNav');
      event.preventDefault();
      event.returnValue = ""
    }
  }
}
</script>

<style>







</style>
