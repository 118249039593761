export default {
  get_streamId: state => state.streamId,
  get_RoomsForStreaming: state => state. RoomsForStreaming,
  get_ServersForStreaming: state => state.ServersForStreaming,
  get_ServersAndRoomsForStreaming: state => state.ServersAndRoomsForStreaming,
  get_cameraOpen: state => state.cameraOpen,
  get_connectionInfo: state => state.connectionInfo,
  get_FirstBroadcastCreationCompleted: state => state.FirstBroadcastCreationCompleted,
  get_liveNowStreamers:  state => state.liveNowStreamers,
  get_selectedStream:  state => state.selectedStream,
  get_myStreamingLive:  state => state.myStreamingLive,
  get_myBroadcasting:  state => state.myBroadcasting,
  get_allowedBroadcasts:  state => state.allowedBroadcasts,

  // get_serversStreamEnd: state => state.serversStreamEnd,
  // get_roomsStreamEnd: state => state.roomsStreamEnd,
  // get_roomsStreamStart: state => state.roomsStreamStart,
  // get_serversStreamStart: state => state.serversStreamStart,
}


