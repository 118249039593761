<template>
        <div class="browse-one">
            <div class="browse-iframe" :style="{ 'background-image': `url(${setThumb()})` }">
              <a @click.prevent="handleJoin(server)">
              <img
                :src="placeholder"
                :alt="server.name" picture-in-picture />
                <div class="browse-one-desc vcentered">
                  {{ server.description }}
                </div>
              </a>
            </div>
            <div class="browse-content">
                <div class="browse-content-inner vc-left">
                  <b-tooltip
                      class="tooltip-content"
                      :label="Array.isArray(server.owner) ? server.owner[0].username : server.owner.username"
                      position="is-right"
                  >
                    <img
                        @click="handleClickAvatar(server)"
                        :src="(server.owner.image) ? setAvatar(server.owner.image) : defaultOwnerImage"
                        class="profile-small"
                        :alt="(server.owner.username) ? server.owner.username : 'Server'"
                    />
                  </b-tooltip>
                </div>
                <div class="vc-right">
                  <p><a href="#" style="color: #fff;" @click.prevent="handleJoin(server)">{{server.name}}</a>
<!--                    <b-button @click="handleJoin(server)" size="is-small"-->
<!--                              class="join-server"-->
<!--                              :class="{'paid' : server.price > 0}">-->
<!--                      <span v-if="!loading && !isUserInServer">-->
<!--                        Join-->
<!--                      </span>-->
<!--                      <span v-if="!loading && isUserInServer">Visit</span>-->
<!--                      <span v-if="loading && !isUserInServer">...</span>-->
<!--                    </b-button>-->
                  </p>

                    <!--span>{{server.rooms.length}} room </span-->
                  <span v-if="server.subscriptionsLength || server.subscriptions ||server.length">
                    {{server.subscriptionsLength ? server.subscriptionsLength
                      : server.subscriptions ? server.subscriptions.length
                      : server.length ? server.length : 0 }}
                    Users
                    {{server.dateCreated | moment("D MMMM YYYY")}}</span>
<!--                  <p v-if="server.owner && server.owner.length > 0">{{server.owner[0].username}} </p>-->
                  <p v-if="server.broadcast && server.broadcast.length > 0" style="color: red">Broadcasting now</p>
                </div>
            </div>
        </div>
</template>

<script>
    import axios from "@/axios";
    import moment from 'moment';

    export default {
        name: "BrowseServersChild",
        props:['server', 'user'],
        data() {
          return{
            today : new Date(),
            loading: false,
            isUserInServer: false,
            //defaultImage: this.$serverDefault,
            //defaultOwnerImage: this.$profileDefault,
          }
       },
      computed: {
        serverDefault () {
          return require('@/assets/img/server-default.png');
        },
        defaultOwnerImage () {
          // return require('@/assets/img/user-profile-default.png');
          return this.$userDefaultImage;
        },
        placeholder () {
          return require('@/assets/img/famous-placeholder.png');
        },
        userDataAfterLogIn() {
          return this.$store.getters.get_userDataAfterLogIn;
        },
        token() {
          return this.$store.getters.get_token;
        },
        userServers() {
          return this.$store.getters.get_userServers;
        },
      },
      created() {
          if (this.userServers && Object.keys(this.userServers).indexOf(this.server._id) > -1) {
            this.isUserInServer = true;
          }
        if (this.user) {
          this.server.owner = this.user;
        }


      },
      mounted() {

      },
      methods: {
        handleJoin(server) {
          if (this.userDataAfterLogIn && this.userDataAfterLogIn._id) {

            if (this.server.slug) {
              if (this.isUserInServer) {
                // todo adapt url  /servers/slug?action=visit
                this.$router.push({ name: 'Chat', params: { id: this.server.slug , action: 'visit',  server_id: this.server._id } });
              } else {
                this.$router.push({ name: 'Chat', params: { id: this.server.slug , action: 'join',  server_id: this.server._id } });
              }
            }
          } else {
            this.$localStorage.set('redirectAfterLogin', '/servers/' +  server.slug + '?action=join');
            this.$store.commit('set_openCloseLogIn');
          }
        },
        setThumb() {
          if (this.server.image && this.server.image.length) {
            if ('http' === this.server.image.slice(0, 4)) {
              return this.server.image;
            } else {
              if ('server' === this.server.image.slice(0, 6)) {
                return this.$imageurl + '/' + this.server.image;
              } else {
                return this.serverDefault;
              }
            }
          }
          return this.serverDefault;
        },
        setAvatar(image) {
          if (image && image.length) {
            if ('http' === image.slice(0, 4)) {
              return image;
            } else {
              if ('profile' === image.slice(0, 7)) {
                return  this.$imageurl + '/' + image;
              } else {
                this.defaultOwnerImage;
              }
            }
          }

          return this.defaultOwnerImage;
        },
        dateToLocalISO(date) {
          const off    = date.getTimezoneOffset()
          const absoff = Math.abs(off)
          return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
              (off > 0 ? '-' : '+') +
              (absoff / 60).toFixed(0).padStart(2,'0') + ':' +
              (absoff % 60).toString().padStart(2,'0'))
        },
        handleClickAvatar(server){
          console.log('username:::::');
          console.log(server);
          let username = '';
          username = Array.isArray(server.owner) ? server.owner[0].username : server.owner.username
          this.$router.push(`/user-profile/${username.toLowerCase()}`)
        }
      }

    }
</script>

<style>


img.profile-small {
  border-radius: 50%;
}

.browse-content {
  display: flex;
  /**display: inline-block;
  width: 80%; */
}

.browse-content .vc-right a:hover {
  text-decoration: none;
  color: #7957d5 !important;
}


.browse-actions {
  display: inline-block;
  width: 20%;
}

.vc-left {
  width: 15%;
}

button.join-server {
  margin: 0 1em;
  border-color: rgba(104, 67, 255, 0.77) !important;
  padding: 5px 11px 5px;
  float: right;
  background-color: rgba(104, 67, 255, 0.77) !important;
  color: #ffffff;
  font-weight: bold;
  border-radius: 15px;
  background: rgb(103, 76, 244);
  background: linear-gradient(90deg, rgba(103, 76, 244, 1) 23%, rgba(160, 131, 250, 1) 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

button.join-server span {
  color: #ffffff;
}

button.join-server.paid span {
  color: #fcc04d;
}


button.join-server:hover {
  background-color: rgba(104, 67, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.35) !important;
  color: rgba(255, 255, 255, 0.35) !important;
}

button.join-server:hover span {
  color: rgba(255, 255, 255, 0.35) !important;
}

.vc-left img {
  display: inline-block;
  width: 45px;
  height: 45px;
}

.vc-right {
  padding-left: 0.5em;
  width: 85%;
}

.vc-right p {
  margin-bottom: 0;
  color: #fff;
}

.vc-right span {
  display: block;
  color: #797979;
  font-size: 14px;
}

.browse-iframe {
  /**display: flex;*/
  margin-bottom: 20px;
  background-position: center;
  background-size: cover;
}

.browse-iframe a{
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.browse-iframe img {
  display: block;
  width: 100%;
  height: auto;

  /**max-height: 200px;*/
}

.browse-one {
  margin-top: 25px;
}

.browse-one-desc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.68);
  color: #fff;
  transition: opacity 0.08s linear 0.1s;

}


.browse-iframe a:hover .browse-one-desc {
  opacity: 1;
  transition: opacity 0.05s linear 0.08s;
}

.browse-content-inner {

}

@media (max-width: 1080px) {
  .latest-browse img {
    max-height: 150px;
  }

  img.profile-small {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 992px) {
  .browse-iframe img {
    height: 160px;
  }

  img.profile-small {
    width: 36px;
    height: 36px;
  }

}


@media (max-width: 792px) {
  img.profile-small {
    width: 32px;
    height: 32px;
  }

  .vc-right {
    font-size: 14px;
  }
}
</style>
