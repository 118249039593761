<template>
  <div class="row boot-row message-box-full">
    <div id="private-chat-file-upload-preview" class="message-bar-images" v-if="uploadedFiles.length !== 0 ">
      <div class="row">
        <UploadedFile
            v-for="(uploaded_file, index) in uploadedFiles"
            :key="index"
            :index="index"
            :src="uploaded_file"
            :tempImage="tempImage"
        ></UploadedFile>
      </div>
    </div>
    <!--div id="private-chat-message-quote-preview" class="quote">
      <QuoteMessageBar v-if="quote" :quote="quote"></QuoteMessageBar>
    </div-->
    <div class="row boot-row users-typing-label">{{ isTypingText }}</div>

    <div id="private-message-box-container" class="columns message-box-container" :class="{'has-quote' : quote}">
      <div id="quote-private-message-box-container" v-if="quote">
              <div class="quoted-message" v-if="quote.images.length === 0">
                <span><fa-icon icon="quote-left" class="messagebar-button-icon" :size="$faDefaultIconSize" /> {{ this.quote.message }} <fa-icon
                    icon="quote-right" class="messagebar-button-icon" :size="$faDefaultIconSize" /></span>
                <b-button class="remove-quote" size="is-small" @click="removeQuote"> <fa-icon style="margin-left: 4px;" icon="times-circle" class="messagebar-button-icon" :size="$faDefaultIconSize" /></b-button>
              </div>
              <div v-else>
                <div class="quoted-message image-wrapper" >
                  <img :src="quote.images[0].content" class="uploaded-file-img" />
                  <b-button class="remove-image" @click="removeQuote"><fa-icon icon="times-circle" style="margin-left: 4px;" class="messagebar-button-icon" :size="$faDefaultIconSize" /></b-button>
                </div>
              </div>
      </div>
      <b-field id="private-chat-message-textbox" class="chat-message-textbox"
               :class="{'editing' : edit, 'has-quote' : chatHasQuote}">
        <b-input id="private-chat-message-textbox-input" class="chat-message-textbox-input"
                 placeholder="Write Message..."
                 type="text"
                 v-model="message"
                 @click:append="submitMessage"
                 @keyup.enter.native="submitMessage"
                 @focus="emitIsTyping"
                 @input="emitIsTyping"
                 @keyup.esc.native="stopEdit"
                 ref="focusInputPrivate"
        ></b-input>
      </b-field>
      <UploadFile @change="changedFile" :class="{'has-quote' : chatHasQuote}"></UploadFile>
      <!--b-button id="private-chat-message-textbox-button-upload"  class="chat-message-textbox-button upload"><b-image
          :src="upload"
      ></b-image></b-button-->
      <!--b-button id="private-chat-message-textbox-button-emoticon" class="chat-message-textbox-button emoticon"><b-image
          :src="smilee"
      ></b-image></b-button-->
      <b-button @click="submitMessage" id="private-chat-message-textbox-button-send"
                class="chat-message-textbox-button send" :class="{'has-quote' : chatHasQuote}">
        <b-image :src="send"></b-image>
      </b-button>
    </div>
  </div>
</template>

<script>
import socket from '../socket';
import axios from '@/axios';
import socketClass from "../socketClass";
import UploadFile from "./UploadFile";
import UploadedFile from "./UploadedFile";
//import QuoteMessageBar from "./QuoteMessageBar"
export default {
  name: "PrivateMessageBarComponent",
  components: {
    UploadFile,
    UploadedFile,
    //QuoteMessageBar
  },
  data() {
    return {
      message: '',
      canSend: false,
      sending: false,
      uploadedFiles: [],
      isTypingText: '',
      // images:[],
      // filesToUpload:[]
      messageToEdit: null,
      quote: null,
      quoteImage: [],
      mentionedUser: null,
      tempImage: null,


    }
  },
  props: ['chatHasQuote'],
  computed: {
    edit() {
      return !!this.messageToEdit;
    },
    send() {
      return require('@/assets/icons/send.svg');
    },
    smilee() {
      return require('@/assets/icons/smilee.svg');
    },
    upload() {
      return require('@/assets/icons/upload.svg');
    },
    placeholder() {
      if (this.chatHasQuote) {
        return 'Follow up quote...'
      }
      return 'Write Message...';
    }
  },
  watch: {
    message(val) {
      this.canSend = val.length > 0;
    },
    uploadedFiles(val) {
      this.canSend = val.length > 0;
    },
    quote(val) {
      if (val && val._id) {
        console.log('is quote', val);
        this.$parent.chatHasQuote = true;
      } else {
        console.log('is not quote', val);
        this.$parent.chatHasQuote = false;
      }
    }

  },
  methods: {
    async handleUpload() {
      let formData = new FormData();
      formData.append('image', this.tempImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      //this.loading = true;
      this.uploading = true;
      try {
        let response = await axios.post(`/images/chat/${this.$store.getters.get_privateSelectedRoom._id}`, formData, {
          headers: axHeaders
        });
        console.log('response', response)
        this.uploading = false;
        return response;
      } catch (e) {
        this.uploading = false;
        console.log('error:', e);
        return e;
      }
    },
    async submitMessage() {
      console.log('inside submitMessage uploadedFiles  ', this.uploadedFiles)
      console.log('canSend' + this.canSend);
      if (this.canSend) {
        this.sending = true;
        let filesToUpload = [];
        let images = [];
        // for (const file of this.uploadedFiles) {
        //     if (file instanceof File) {
        //         console.log('RECOGNIZE THAT IS A FILE NOT IMAGE')
        //         let reader = new FileReader();
        //         reader.onload = event => {
        //             filesToUpload.push(reader.result);
        //         };
        //         reader.readAsBinaryString(file);
        //     } else images.push(file);
        // }

        if (this.tempImage) {
          let upload = await this.handleUpload();
          if (upload.status && upload.status >= 200 && upload.status < 300 && upload.data && upload.data.uploadedImage && upload.data.uploadedImage.filename) {
            images.push(upload.data.uploadedImage.filename);
            this.imageUploaded = true;
          }
        }

        if (!this.messageToEdit) {
          let message = {
            message: this.message,
            images: images,
            files: filesToUpload,
            room: this.$store.getters.get_privateSelectedRoom._id,
          }

          if (this.quote) {
            message['quote'] = this.quote._id;
            // message.images = this.quoteImage; // Todo: check if needed
          }

          console.log('FILESSSS', message.files)
          console.log('MESSAGEEE' + message.message);
          console.log('MESSAGE ROOM ID' + message.roomId);
          console.log('step 2 before emit event publishMessage with message ', message);


          socket.emit(
              'publishMessage',
              message,
              (response) => {
                this.sending = false;
              }
          );
        } else {
          let message = {
            messageId: this.messageToEdit._id,
            message: this.message,
            room: this.$store.getters.get_privateSelectedRoom._id,
            mentionedUser: this.mentionedUser
          };
          this.messageToEdit = null;
          this.$iv.socket.emit(
              'editMessage',
              message,
              (response) => {
                this.sending = false;
              }
          );
        }
        this.quote = null
        this.message = '';
        this.uploadedFiles = [];
        this.quoteImage = []
        this.mentionedUser = null
        // this.images = [] ;
        // this.filesToUpload = [];
      }
    },
    emitIsTyping() {
      //console.log(' this.$store.getters.get_privateSelectedRoom._id',  this.$store.getters.get_privateSelectedRoom._id)
      socket.emit(
          'isTyping',
          this.$store.getters.get_privateSelectedRoom._id,
          (response) => {

          }
      );
    },

    changedFile(event) {
      console.log('ADD FILES event.files =  ', event.files);
      this.addFilesToUpload(event.files);
      this.$refs.focusInputPrivate.focus();
    },
    addFilesToUpload(files) {
      console.log('INSIDE addFilesToUpload ', files);
      for (const file of files) {
        console.log('file.type', file.type)
        if (file.type.indexOf('image') === -1) {
          console.log('FILE')
          if (file) {
            this.uploadedFiles.push(file)
            // let reader = new FileReader();
            // reader.onload = event => {
            //     console.log('inside submitMessage reader result  ',reader.result )
            //     this.filesToUpload.push(reader.result);
            // };
            // reader.readAsBinaryString(file);
          }
        } else {
          // convert image file to base64 string
          console.log('IMAGE');
          this.tempImage = file;
          let reader = new FileReader();
          reader.onload = event => {
            this.uploadedFiles.push(reader.result);
            // this.images.push(reader.result)
          };
          reader.readAsDataURL(file);
        }
      }
    },
    stopEdit() {
      if (this.messageToEdit) {
        this.messageToEdit = null;
        this.message = '';
      }

    },
    removeQuote() {
      this.quote = null;
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'USER_IS_TYPING_PRIVATE') {
        let typingUsers = mutation.payload.target.usersTyping;
        switch (typingUsers.length) {
          case 0:
            this.isTypingText = '';
            break;
          case 1:
            this.isTypingText = `${typingUsers[0]} is typing...`;
            break;
          case 2:
            this.isTypingText = `${typingUsers[0]} and ${typingUsers[1]} are typing...`;
            break;
          case 3:
            this.isTypingText = `${typingUsers[0]}, ${typingUsers[1]} and 1 other are typing...`;
            break;
          default:
            this.isTypingText = `${typingUsers[0]}, ${
                typingUsers[1]
            } and ${typingUsers.length - 2} other are typing...`;
        }
      }

      if (mutation.type === 'INIT_EDIT_MESSAGE_PRIVATE') {
        console.log('INIT_EDIT_MESSAGE_PRIVATE mutation payload is ', mutation.payload);
        this.messageToEdit = mutation.payload;
        this.message = this.messageToEdit.message;
      }

      if (mutation.type === 'QUOTE_MESSAGE_PRIVATE') {
        console.log('QUOTE_MESSAGE_PRIVATE mutation payload is ', mutation.payload);
        this.quote = mutation.payload
        if (this.quote.images.length !== 0) {
          this.quoteImage.push(this.quote.images[0].content)
        }
      }

      // if (mutation.type == 'rooms/SET_SELECTED_ROOM') {
      //     this.stopEdit();
      //     this.message = '';
      // }
    });
  }
}
</script>

<style>
#private-message-box-container {
  display: block;
  height: 48px;
  max-height: 48px;
  width: 100%;
  margin: 0 2.5em;
  padding: 0.25em 1em;
  opacity: 1;
  border-radius: 8px;
  border: solid 1px #313131;
  background-color: #2f3338;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.57px;
  color: #797986;
}

#private-message-box-container.has-quote {
  min-height: 85px;
  height: 85px;
  max-height: 85px;
}

#quote-private-message-box-container {
  display: block;
  min-height: 35px;
  height: 35px;
  max-height: 35px;
  width: 100%;
  margin: 0;
  padding: 0.25em 1em;
  opacity: 1;
  border-radius: 0;
  background-color: transparent;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6064;
  border-color: #5c6064;
  border-style: solid;
  border-width: 0 0 1px 0;
  text-align: left;
  position: relative;

  /*display: block;*/
  /*min-height: 35px;*/
  /*height: 35px;*/
  /*max-height: 35px;*/
  /*width: 100%;*/
  /*margin: 0;*/
  /*padding: 0.25em 1em;*/
  /*opacity: 1;*/
  /*border-radius: 0;*/
  /*background-color: #202428;*/
  /*white-space: nowrap;*/
  /*font-weight: normal;*/
  /*font-stretch: normal;*/
  /*font-style: normal;*/
  /*line-height: normal;*/
  /*letter-spacing: normal;*/
  /*color: #5c6064;*/
  /*border-color: #313131;*/
  /*border-style: solid;*/
  /*border-width: 0 0 1px 0;*/
  /*text-align: left;*/
  /*position: relative;*/
}


.chat-message-textbox {
  display: inline-block;
  margin: 0 !important;
  position: relative;
}


#private-chat-message-textbox {
  width: 84%;
  max-width: 84%;
  height: 100%;
  max-height: 100%;
}

#private-chat-message-textbox.has-quote {
  height: calc(100% - 36px);
  max-height: calc(100% - 36px);
}


.chat-message-textbox-input,
.chat-message-textbox-input:visited {
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  width: 100%;
  height: 100%;
  color: #5c6064;
  position: absolute;
  top: 0;
  left: 0;
  /**display: table;*/
  display: inline-block;
}


.chat-message-textbox-input input,
.chat-message-textbox-input input:visited {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
}

button.chat-message-textbox-button {
  display: inline-block;
  height: 100%;
  max-height: 100%;
  background-color: transparent;
  border: 0px solid transparent;
  outline: none;
  padding: 0;
  text-align: center;
}

button.chat-message-textbox-button figure {
  text-align: center;
}

button.chat-message-textbox-button figure img {
  max-height: 20px;
  max-width: 20px;
  float: right;
}

#private-chat-bar .users-typing-label {
  height: 1.6em;
  max-height: 1.6em;
  min-height: 1.6em;
  font-size: 0.8em;
  padding: 0.25em 3em;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.35em;
  text-align: left;
  color: #dddddd;
}

#private-chat-message-textbox-input {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  color: #5c6064;
  height: 100%;
  max-height: 100%;
}


/***
#private-chat-message-textbox-input::placeholder,
#private-chat-message-textbox-input:-moz-placeholder,
#private-chat-message-textbox-input::-moz-placeholder {
  color: #5c6064;
  opacity: 1;
  font-weight: 500;
}
*/

#private-chat-message-textbox-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #5c6064;
}

#private-chat-message-textbox-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #5c6064;
  opacity: 1;
}

#private-chat-message-textbox-input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #5c6064;
  opacity: 1;
}

#private-chat-message-textbox-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #5c6064;
}

#private-chat-message-textbox-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #5c6064;
}

#private-chat-message-textbox-input::placeholder { /* Most modern browsers support this now. */
  color: #5c6064;
}

#private-chat-message-textbox-button-upload,
#private-chat-message-textbox-button-emoticon,
#private-chat-message-textbox-button-send {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  width: auto;
  max-width: 8%;
  margin-left: 0.5em;
  height: 100%;
  max-height: 100%;
}

#private-chat-message-textbox-button-upload.has-quote,
#private-chat-message-textbox-button-emoticon.has-quote,
#private-chat-message-textbox-button-send.has-quote {
  height: calc(100% - 36px);
  max-height: calc(100% - 36px);
}


#private-chat-file-upload-preview {
  position: absolute;
  bottom: 60px;
  left: 30px;
}

#private-chat-message-quote-preview {
  position: absolute;
  bottom: 60px;
  left: 30px;
}

button.remove-quote {
  margin-bottom: 5px;
  padding: 0;
  background-color: transparent;
  border: 0;
}

</style>
