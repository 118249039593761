import RoomsClass from "./RoomsClass";
import socket from "@/socket";

export class SocketClass {
    constructor() {
        this.rooms = new RoomsClass();
    }
    init(socket, store) {
        this.socket = socket;
        this.store = store;
        this.initialized = true;
    }

    disconnectSocket() {
        console.log(' disconnectSocket @ class ');
        this.socket.close((resolve, reject) => {
            console.log(' disconnectSocket resolve ', resolve);
        });
    }

    connectSocket(token) {
        return new Promise((resolve, reject) => {
            this.socket.once('connect',() => {
                this.socket.emit('init', {token}, (subs) => {
                    console.log(' this.socket.emit(init, {token}, (subs) =>  check what subs contains  ', subs);
                    resolve(subs);
                });
            });

            this.socket.on('disconnect',(a) => {
                console.log('disconnect @ socket class', a);
            });

            this.socket.on('connect_error', (err) => {
                console.log('connect_error', err);
                reject(err);
            });

            this.socket.on('newMessage', async (payload) => {
                console.log('step 3 into connectSocket, on the newMessage event with payload  ',payload);
                await this.rooms.onNewMessageSocketHandler(payload);
            });

            this.socket.on('isTyping', (payload) =>
                this.rooms.onIsTypingSocketHandler(payload)
            );

            this.socket.on('deletedMessage', async (payload) => {
                await this.rooms.onMessageDeletedSocketHandler(payload);
            });

            this.socket.on('editedMessage', async (payload) => {
                await this.rooms.onMessageEditedSocketHandler(payload);
            });

             this.socket.on('createdRoom', async (payload) => {
                 // console.log(`createdRoom payload is: `, payload)
                 await this.rooms.onRoomCreation(payload);
             })

            this.socket.on('cameraOpened', async (payload ) => {
                console.log('cameraOpened socket works', payload);
                this.store.dispatch('handleNewCameraOpened', payload);
                await this.rooms.onCameraStarted();
            });

            this.socket.on('cameraClosed', async (payload ) => {
                console.log('cameraClosed socket works', payload);
                this.store.dispatch('handleExistingCameraClosed', payload);
                await this.rooms.onCameraStopped();
            });
            this.socket.open();
        });
    }


}
export default new SocketClass();
