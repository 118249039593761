import axios from "../../../axios";
import socketClass from "../../../socketClass";
// import * as _ from "lodash";
import _filter from 'lodash/filter';

import EventBus from "@/eventBus";


export default {

  async signUp({getters}){
    try {
      let response = await axios.post('/auth/register', getters.get_user
      );
      if (response.status >= 200 && response.status < 300) {
        return response ;
      } else {
        return new Error(response.data.message);
      }
    }
    catch (error) {
      console.log(error);
      return error
    }
  },

  async login(
      { dispatch, commit, state, getters, rootState, rootGetters },
      userInfo
  ) {
    try {
      let login = {};
      let user;
      let token;
      let accessToken = userInfo.token;
      //dispatch('disconnectSocket');
      if (!accessToken) {
        login = await axios.post('/auth/login', {
          usernameOrEmail: userInfo.usernameOrEmail,
          password: userInfo.password,
        });
        if (login.data && login.data.user && login.data.tokens) {
          user = login.data.user;
          token = login.data.tokens.accessToken;
          //console.log('first login ');
        }
      } else {
        login = await axios.post('/auth/refresh-token', { refreshToken: accessToken });
        if(login.data && login.data.user && login.data.tokens) {
          user = login.data.user;
          token = login.data.tokens.accessToken;
          //console.log('login with token refresh', login.data);
        } else {
          // TODO HANDLE ERROR
          //user = userInfo;
          //token = accessToken;
        }
      }


      if (login instanceof Error) {
        console.log('login error', login);
      } else {
        if (token && user) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          console.log('login OK', user);
          commit('set_token', token);
          commit('set_userDataAfterLogIn', user);
          if (user.servers && user.servers.length) {
            let userServers = {};
            let userRooms = {};
            let servers = Object.values(user.servers);
            for (let server of servers) {
              userServers[server._id] = server;
            }
            commit('set_userServers', userServers);
          }

          if (user.rooms && user.rooms.length) {
            //let userRooms = {};
            let userRooms = [];
            let rooms = Object.values(user.rooms);
            for (let room of rooms) {
              //userRooms[room._id] = room;
              userRooms.push(room._id);
            }
            commit('set_userRooms', userRooms);
          }

          //console.log('axios.defaults.headers', axios.defaults.headers);
          //console.log('axios.defaults.headers after', axios.defaults.headers);
          //dispatch('disconnectSocket', token);

          let connect = await dispatch('connectToSocket', token);
          console.log('connectedToSocket @ login', connect.connected);
          //EventBus.$emit('createRTC');
          //let userBroadcasts = await dispatch('getUserBroadcasts');  // Temp
          dispatch('reloadStreamers');

          // await dispatch('populatePrivateRooms'); // TEMP OFF Duplicated call on new room creation
        } else {
          // TODO HANDLE
          commit('set_token', null);
          commit('set_userDataAfterLogIn', null);
        }

      }
      return login;
    } catch (err) {
      console.log(err);
    }
  },


  async disconnectSocket ({commit , dispatch}, store) {
    socketClass.disconnectSocket(store);
  },

  async connectToSocket({commit , dispatch}, token) {
    console.log('connectingToSocket', token,  new Date());
    let data = await socketClass.connectSocket(
        token
    );
    if (data) {
      commit('set_connectedToSocket', true);
      //let allServers = dispatch('allServers');
      //let allRooms = dispatch('allRooms');
      //dispatch('reloadStreamers');  // Temp
      //dispatch('setStreamer'); // Check
    } else {
      commit('set_connectedToSocket', false);
    }
    return data;
  },

  async getMyBroadcasting ({ dispatch, commit, state, getters, rootState, rootGetters }, streamId) {
    try {
      return await axios.get('/broadcasting/' +  streamId);
    } catch (error) {
      console.log(error);
      return error
    }
  },

  async setStreamer({ dispatch, commit, state, getters }) {
    let serversStreaming = _filter(getters.get_userDataAfterLogIn.servers,function(server) {
      return  server.broadcast !== null && server.broadcast;
    });
    let roomsStreaming = [];
    getters.get_userDataAfterLogIn.servers.forEach((server)=>{
      server.rooms.forEach((room)=>{
        if ( room.broadcast !== null && room.broadcast) {
          roomsStreaming.push(room)
        }
      })
    });

    console.log('setstreamer serversStreaming ', serversStreaming );

    console.log('setstreamer  roomsStreaming', roomsStreaming );
    commit('set_ServersAndRoomsForStreaming',{
      servers:serversStreaming,
      rooms:roomsStreaming
    });
  },

  logOut({commit}){
    console.log('clear_authData');
    commit('clear_authData');
  },
}
