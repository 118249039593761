<template>
  <div class="streamer-single"
    :class="{'istream' : myStreamingLive}"
  >
    <div class="streamer-single-overlay" v-if="myStreamingLive">&nbsp;</div>
    <div class="video-thumb"
         :style="{ 'background-image': `url(${setThumb()})` }"
    ><a href="#" @click.prevent="joinStream(streamer)">&nbsp;</a></div>
    <div class="video-info">
      <p class="video-info-title" ><a href="#" @click.prevent="joinStream(streamer)">{{streamer.name}}</a></p>
      <!--p class="video-info-metrics"><fa-icon icon="eye" scale="1" />&nbsp;<span>2303</span></p-->
      <!--p v-if="streamer.description"  class="video-info-desc">{{streamer.description}}</p-->
<!--      <popper-->
<!--          trigger="hover"-->
<!--          :options="{-->
<!--                      placement: 'bottom',-->
<!--                      modifiers: {-->
<!--                        offset: { offset: '0, 0', enabled: true }-->
<!--                      }-->
<!--                  }">-->
<!--        <div v-if="streamer.description" class="popper  toolip-popper streamer-desc">{{streamer.description}}</div>-->
<!--        <span v-if="streamer.description"  class="video-info-desc" slot="reference" >{{streamer.description}}</span>-->
<!--        <span v-if="!streamer.description"  class="video-info-desc" slot="reference" >This is a streaming session created by {{streamer.name}}</span>-->
<!--      </popper>-->
      <p class="video-info-user"><span class="streamer streamer-image" :style="{ 'background-image': `url(${setAvatar(streamer.owner.image)})` }" >&nbsp;</span>
        <span class="streamer streamer-username">{{streamer.owner.username}}</span></p>
      <!--p v-if="!streamer.description"  class="video-info-desc">This is a streaming session created by {{streamer.name}}</p-->
      <!--b-button class="streamer streamer-button streamer-watch" size="is-small" type="is-dark" @click="joinStream(streamer)">Watch</b-button-->
    </div>
    <div class="video-misc">&nbsp;</div>
  </div>
</template>

<script>
  // import Popper from 'vue-popperjs';
  import CreateServer from "@/components/ServersCore/CreateServer";
  import vuescroll from "vuescroll";

export default {
  name: "StreamerSingle",
  props:['streamer'],
  components: {
    // 'popper': Popper
  },
  computed: {
    selectedStream() {
      return this.$store.getters.get_selectedStream;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    myBroadcasting() {
      return this.$store.getters.get_myBroadcasting;
    },
    defaultOwnerImage () {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    },
    videoThumbDefault () {
      return require('@/assets/img/profit-video-placeholder-1x.png');
    },
    myStreamingLive() {
      return this.$store.getters.get_myStreamingLive;
    },

  },
  methods: {
    joinStream(stream) {
      if (!this.myStreamingLive) {
        this.$store.commit('set_selectedStream', stream);
      } else {
        this.$buefy.snackbar.open({
          duration: 5000,
          message: 'You cannot browse to other channels while you are streaming.',
          type: 'is-danger',
          position: 'is-top',
          actionText: 'Got It',
          queue: false,
          onAction: () => {

          }
        })

      }

    },
    async stopStream(stream) {
      await this.$store.dispatch('END_STREAM', stream._id);
    },
    streamSettings(stream) {
      console.log('streamSettings', stream);
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return  this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },
    setThumb() {
      if (this.streamer.image) {
        if ('http' === this.streamer.image.slice(0, 4)) {
          return this.streamer.image;
        } else {
          if ('broadcast' === this.streamer.image.slice(0, 9)) {
            return  this.$imageurl + '/' + this.streamer.image;
          } else {
            return this.videoThumbDefault;
          }
        }
      }
      return this.videoThumbDefault;
    },

  }

}
</script>

<style>



</style>
