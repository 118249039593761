<template>
  <section id="chat-group-list-section">
    <div id="welcome-msg" class="d-flex message-separator" v-if="!selectedRoomMessages.length">
      <div class="date-side-line flex-grow-1"></div>
      <span class="date-text align-self-center">Welcome to {{this.room.name}}</span>
      <div class="date-side-line flex-grow-1"></div>
      <hr />
    </div>
    <div id="chat-indicator">
      <b-button  v-if="indicator && !loadingPrevious && !loadingNext"
                 rounded >{{indicatorDate()}}</b-button>
      <b-button ref="loadMoreButton"
                rounded
                v-if="loadingPrevious || loadingNext"
      >Loading...</b-button>
    </div>
    <vueScroll @handle-scroll="handleScroll"
               @handle-scroll-complete="handleScrollCompleted"
               v-if="selectedRoomMessages.length"
               :ops="ops" id="chat-group-list" ref="messageDisplay" class="messages">

      <div
          v-for="(dayMessages, group) in selectedRoomMessages"
          :key="group"
          :id="dayMessages[0].date.split('T')[0]"
          class="message-container"
      >
        <div :id="'sep-' + dayMessages[0].date.split('T')[0]" class="d-flex message-separator">
          <div class="date-side-line flex-grow-1"></div>
          <span
              class="date-text align-self-center"
          >{{ dayMessages[0].date | moment("dddd[,] D MMMM YYYY")  }}</span>
          <div class="date-side-line flex-grow-1"></div>
        </div>

        <div v-for="(dayMessage, g) in dayMessages" :key="g">
          <ChatMessage
              v-if="dayMessage"
              :key="dayMessage._id"
              :data-index="g"
              :id="'chat-message-' + dayMessage._id"
              :message="dayMessage"
          ></ChatMessage>
        </div>

      </div>
    </vueScroll>
  </section>
  <!--/ul-->
</template>

<script>
import ChatMessage from "./ChatMessage";
import vueScroll from 'vuescroll';
// import ChatSingleMessage from "@/components/ChatCore/ChatSingleMessage";
//import "vue-custom-scrollbar/dist/vueScrollbar.css";
import moment from 'moment';
// import * as _ from 'lodash';
import EventBus from '@/eventBus';
// import axios from "@/axios";


export default {
  data(){
    return{
      activeRoom: null,
      bookmark: null,
      messagesList: {},
      bottom: null,
      enableScroll: true,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      // loadingPrevious: false,
      // loadingNext: false,
      reloadingRoom: false,
      afterLoadMoreFocus: null,
      indicator: null,
      showIndicator: false,
      timer: null,
      ops: {
        vueScroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          //initialScrollY: '100%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'right'
        },
        rail: {},
        bar: {},
        scrollButton: {
          enable: true,
          background: 'rgb(141,141,215)',
          opacity: 1,
          step: 280,
          mousedownStep: 120
        }
      },
    }
  },
  name: "ChatMessagesList",
  components:{
    ChatMessage,
    // ChatSingleMessage,
    vueScroll
  },
  props:['focus', 'scrollLock', 'scrollToLastMessage', 'loadingPrevious', 'loadingNext', 'autoSelect'],
  watch: {
    focus(newVal) {
      if (newVal && newVal === 'last') {
        this.scrollToLast();
      }
      if (newVal && newVal !== 'last') {
        this.focusMessage(newVal);
      }
    },
    // selectedRoomMessages(newVal) {
    //     // console.log('selectedRoomMessages watch', newVal);
    //     // if (this.bookmark) {
    //     //   this.focusMessage(this.bookmark);
    //     // }
    // },
    // scrollLock(newVal) {
    //   console.log('scrollLock list watch', newVal);
    // },
    // scrollToLastMessage(newVal) {
    //   console.log('scrollToLastMessage list watch: ', newVal, this.scrollLock)
    //   if(newVal)
    //     this.scrollToLast();
    // }
  },
  computed:{
    showLoadMoreMessages(){
      return this.$store.getters.get_showLoadMoreMessages;
    },
    room(){
      return this.$store.getters.get_selectedRoom;
    },
    selectedServer() {
      return this.$store.getters.get_selectedServer;
    },
    selectedRoomMessages() {
      return this.$store.getters.get_selectedRoomMessages;
    },
    previousMessagesIndex() {
      return this.$store.getters.get_previousMessagesIndex;
    },
    nextMessagesIndex() {
      return this.$store.getters.get_nextMessagesIndex;
    },
    //
    // autoSelect() {
    //   return this.$store.getters.get_autoSelect;
    // },

  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'add_messageToGroup') {
        console.log('add_messageToGroup @ list', mutation.payload);
        // Giving time for new message to get attached in dom
        setTimeout(()=> {
          this.scrollToLast();
        }, 120);
      }
      if (mutation.type === 'append_selectedRoomMessages') {
        console.log('append_selectedRoomMessages @ list', mutation.payload);
        // Giving time for new message to get attached in dom
        setTimeout(()=> {
          this.scrollToLast();
        }, 120);
      }



    });
  },
  mounted() {
    if (document.getElementById('chat-bar') ) {
      this.bottom = document.getElementById('chat-bar').offsetTop;
    }
  },
  // updated() {
  //   console.log('dom updated');
  // },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      // console.log('handleScroll', this.$refs["messageDisplay"]);
      clearTimeout(this.timer);
      let current = this.$refs["messageDisplay"].getCurrentviewDom()[0];
      if (current) {
        this.indicator =  current.id;
        this.timer = setTimeout(()=>{ this.indicator =  null; }, 1200);
      } else {
        this.indicator =  null;
      }

      if(this.scrollLock) {
        console.log('this.scrollLock on : returns false');
        return false;
      }

      let toFocus;
      const scroll = this.$refs["messageDisplay"].$el;
      const view = this.$refs["messageDisplay"].$children[0].$el.firstChild;

      if(vertical.scrollTop === 0 && this.previousMessagesIndex && !this.loadingPrevious && !this.loadingNext) {
        // console.log('this.previousMessagesIndex', this.previousMessagesIndex , toFocus);
        this.$parent.scrollLock = true;
        let elems = this.$el.getElementsByClassName("chat-message");
        if (elems.length) {
          toFocus =  elems[0].id;
        } else {
          toFocus =  null;
        }

        this.bookmark = toFocus;
        this.$parent.loadMore = {
          index : this.previousMessagesIndex,
          direction: 'past',
          indicator: toFocus
        };
        // setTimeout(()=> {
        //   this.getMoreMessages(this.previousMessagesIndex, 'past');
        // }, 150);
      } else if (scroll && view && (vertical.scrollTop + scroll.clientHeight) > view.clientHeight ) {
        //if (this.nextMessagesIndex && this.indicator && this.nextMessagesIndex !== this.indicator && !this.loadingPrevious && !this.loadingNext ) {
        if (this.nextMessagesIndex && !this.loadingPrevious && !this.loadingNext ) {
          // this.$parent.scrollLock = true;
          let elems = this.$el.getElementsByClassName("chat-message");
          if (elems.length) {
            toFocus =  elems[elems.length -1].id;
          } else {
            toFocus =  null;
          }
          // console.log('this.nextMessagesIndex', toFocus);
          this.$parent.loadMore = {
            index : this.nextMessagesIndex,
            direction: 'future',
            indicator: toFocus
          };
        }
      }
    },
    indicatorDate() {
      if ( moment(this.indicator).format("D MMMM YYYY") === moment().format("D MMMM YYYY") ) {
        return 'Today'
      } else if ( moment(this.indicator).format("D MMMM YYYY") === moment().add(-1, 'days').format("D MMMM YYYY")) {
        return 'Yesterday'
      } else {
        return moment(this.indicator).format("dddd[,] D MMMM YYYY");
      }
    },
    scrollToCursor() {
      this.$nextTick(() => {
        const elem = document.getElementById(this.afterLoadMoreFocus);
        // console.log('append_selectedRoomMessages @ list', this.afterLoadMoreFocus, elem);
        // this.$refs["vs"].scrollIntoView("#d3", 500);
        if (elem) {
          elem.classList.add("cursor");
          elem.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        }
      });
      //this.afterLoadMoreFocus = null; // Check
      return true;
    },
    async focusMessage(id) {
      console.log('focusMessage', id );
      const elem = document.getElementById(id);
      this.$nextTick(() => {
        if (elem) {
          if (this.autoSelect) {
            elem.classList.add('cursor');
          }

          // const window_offset = elem.offset().top - window.scrollTop();
          elem.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
          // this.$refs["messageDisplay"].scrollIntoView(elem, true);

          // this.$refs["messageDisplay"].scrollBy(
          //     {
          //       dy: -100
          //     },
          //     500,
          //     "easeInQuad"
          // );
          // this.$refs["messageDisplay"].scrollBy("50px", 300);

          // this.$parent.scrollLock = false;
          // this.$parent.autoSelect = null;
          // this.$parent.toFocus = null;
        }
        this.$parent.scrollLock = false;
        this.$parent.autoSelect = null;

        // console.log('focusMessage end', this.getScrollXY() );
        // EventBus.$emit('NEW_ROOM_LOADING', false);
      });
      await this.$sleep(1100);
      EventBus.$emit('NEW_ROOM_LOADED', true);
    },
    getScrollXY() {
      let scrOfX = 0, scrOfY = 0;
      if (typeof (window.pageYOffset) == 'number') {
        //Netscape compliant
        scrOfY = window.pageYOffset;
        scrOfX = window.pageXOffset;
      } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
        //DOM compliant
        scrOfY = document.body.scrollTop;
        scrOfX = document.body.scrollLeft;
      } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
        //IE6 standards compliant mode
        scrOfY = document.documentElement.scrollTop;
        scrOfX = document.documentElement.scrollLeft;
      }
      return [scrOfX, scrOfY];
    },
    scrollToLast() {
      let elems = this.$el.getElementsByClassName("chat-message");
      const messagesLength = this.selectedRoomMessages;
      if (elems.length && this.$refs["messageDisplay"]) {
        const last = elems[elems.length - 1];
        // console.log('scrollToLastq', elems, last)
        this.$refs["messageDisplay"].scrollIntoView(last, true);
        //last.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        //this.$parent.scrollLock = false;
        // console.log('scrol2last end' );
        // EventBus.$emit('NEW_ROOM_LOADING', false);
      }
      this.$parent.scrollLock = false;
      this.$parent.autoSelect = null;
      this.$parent.scrollToLastMessage = false;
      EventBus.$emit('NEW_ROOM_LOADED', true);
    },
    handleScrollCompleted() {
      console.log('handleScrollCompleted:');
      this.$parent.scrollLock = false;
      this.$parent.toFocus = null;
      this.bookmark = null;
      // EventBus.$emit('NEW_ROOM_LOADED', true);
    },
  },
}
</script>

<style>

#chat-group-list-section {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
}

#chat-messages-overlay {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
}

#chat-indicator {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;

}

#chat-indicator button {
  margin: 0;
  color: #dddddd;
  /**background-color: #1a1c1f;*/
  background-color: #303538;
  border: 0px solid #1a1c1f;
  z-index: 99;
}


#chat-group-list {

}

#chat-group-list ul {
  /**overflow: auto;
  height: 100%;
  max-height: 100%;*/
}


/*#chat-group-list .message {*/
/*  background-color: transparent;*/
/*}*/

.overlay-inner {
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  transform: translate(0%, -50%);
  text-align: center;
}

ul.chat-group-list {
  width: 100%;
}

.message-separator .date-text {
  font-size: 13px;

  color: rgba(255, 255, 255, 0.35);
}
.date-side-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.35);
  margin: 30px;
}

.__bar-is-vertical {
  background-color:  #4a4a4a !important;
}


.cje.cursor {
  /**background-color: #6f6d4b;*/
}

</style>
