<template>
  <b-modal id="user-settings"  v-model="showUserSettingsModal" scroll="keep" :on-cancel="handleClose">
    <h4 class="brand">PROFIT</h4>
    <h2 class="modal-title medium">User Settings</h2>
    <b-tabs :position="atRight ? 'is-right' : ''"
            :size="size"
            :type="type"
            vertical
            :expanded="expanded">
      <b-tab-item label="Profile" icon-pack="fas" icon="user"><UserProfile></UserProfile></b-tab-item>
      <b-tab-item label="Appearance" icon-pack="fas" icon="eye"><h2>My Appearance Settings</h2></b-tab-item>
      <b-tab-item label="Privacy & Safety" icon-pack="fas" icon="lock"><h2>My Privacy Settings</h2></b-tab-item>
      <b-tab-item label="Notifications" icon-pack="fas" icon="bell"><h2>Notifications</h2></b-tab-item>
    </b-tabs>
  </b-modal>
</template>

<script>

import UserProfile from "../components/SettingsTabs/UserProfile";

export default {
  name: "UserSettings",
  components: {
    UserProfile,
  },
  data(){
    return{
      showUserSettingsModal: true,
      expanded: false,
      atRight: false,
      size: null,
      type: null

    }
  },
  created() {

  },
  mounted() {
   console.log(this.userDataAfterLogIn);
  },
  computed: {
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
  },
  methods: {
    handleClose() {
      //this.$store.commit('set_openCloseLogIn');
    }

  }
}
</script>

<style>
  #user-settings {}

  #user-settings.modal > .modal-content {
    background-color: #1e2124 !important;
    width: 100% !important;
    /**max-width: 100% !important;*/
    height: 100%;
    padding: 1.5em 1em 1em;
    border-radius: 7px!important;
    letter-spacing: 0.2px;
    font-weight: normal;
    /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
  }

  .b-tabs.is-vertical > .tabs ul li a {
    font-weight: normal;
  }


</style>
