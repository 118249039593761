import { AllHtmlEntities } from 'html-entities';
let entities = new AllHtmlEntities();
// import * as _ from 'lodash';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
export default {

  set_rooms: (state,rooms)=>{
     state.rooms = rooms ;
  },
  set_selectedServerRooms:(state, rooms ) => {
    //console.log('SELECTED SERVER ROOMS:  ', rooms);
    state.selectedServerRooms = rooms;
  },
  update_selectedServerRooms:(state, rooms ) => {
    //console.log('update SELECTED SERVER ROOMS:  ', rooms);
    state.selectedServerRooms.push(rooms);
  },
  set_selectedRoom: (state, room )=>{
      state.selectedRoom = room;
  },

  set_selectedMessage: (state, message )=>{
    state.selectedMessage = message;
  },


  set_selectedRoomHashtags: (state, hashtags ) => {
    state.selectedRoomHashtags = hashtags;
  },

  ADD_MESSAGE_TO_ROOM(state, { messageInfo, target, isCurrentlySelected }) {
      console.log('step 7 inside ADD_MESSAGE_TO_ROOM and messageInfo is  ', messageInfo);
      if (target.messages) {
        messageInfo.message.message = entities.decode(
          messageInfo.message.message
        );
        target.messages.push(messageInfo.message);
        if (!isCurrentlySelected) target.newMessages = true;
      }
  },

    // SET_ROOM_LIST(state, roomList) {
    // // let settedSelected = false;
    // state.list = roomList.map((room, index) => {
    //   room.messages.map(
    //       (message) =>
    //           (message.message = entities.decode(message.message))
    //   );
    //   return Object.assign(room, {
    //   disabled: false,
    //   newMessages: false,
    //   currentlySelected: false,
    //   loading: false,
    //   canLeave: false,
    //   notifications: true,
    //   // shouldAppearOnList: !room.is_private,
    //   usersTyping: [],
    // });
    // });
    //
    //   (state.list).reverse();
    //     },

    USER_IS_TYPING(state, { target, isNowTyping, from }) {
      let indexOfUser = target.usersTyping.indexOf(from);
      try {
        if (isNowTyping && indexOfUser === -1) {
        target.usersTyping.push(from);
        }
        if (!isNowTyping && indexOfUser !== -1) {
        target.usersTyping.splice(indexOfUser, 1);
        }
      } catch (ex) {
      console.log(ex)
      }
  },
  USER_IS_TYPING_PRIVATE(state, { target, isNowTyping, from }) {
    let indexOfUser = target.usersTyping.indexOf(from);
    try {
      if (isNowTyping && indexOfUser === -1) {
        target.usersTyping.push(from);
      }
      if (!isNowTyping && indexOfUser !== -1) {
        target.usersTyping.splice(indexOfUser, 1);
      }
    } catch (ex) {
      console.log(ex)
    }
  },

  DELETE_ROOM_MESSAGE(state, { messageInfo, target }) {
    //console.log('STEP INSIDE DELETE_ROOM_MESSAGE mutation AND MessageInfo is  ',messageInfo);
    //console.log('STEP INSIDE DELETE_ROOM_MESSAGE mutation AND target is  ',target);
    //console.log('STEP INSIDE DELETE_ROOM_MESSAGE mutation AND    messageInfo.message._id   ',messageInfo.message._id);
    //console.log('STEP INSIDE DELETE_ROOM_MESSAGE mutation AND   target.messages  ',target.messages);

    _remove(
        target.messages,
        (message) => message._id === messageInfo.message._id
    );

    let temp = Object.assign([], target.messages);

    target.messages = temp;

    // if (!target.currentlySelected) target.newMessages = true;
  },

  INIT_EDIT_MESSAGE(state,{message}){},
  QUOTE_MESSAGE(state,{message}){},
  INIT_EDIT_MESSAGE_PRIVATE(state,{message}){},
  QUOTE_MESSAGE_PRIVATE(state,{message}){},

  EDIT_ROOM_MESSAGE(state, { messageInfo, target, isCurrentlySelected,messages }) {
    messageInfo.message.message = entities.decode(
        messageInfo.message.message
    );

    let messageToChange = _find(
        messages,
        (message) => message._id === messageInfo.message._id
    );

    messageToChange.message = messageInfo.message.message;

    if (!isCurrentlySelected) target.newMessages = true;
  },

  // SET_PRIVATE_ROOMS(state,privateRooms){
  //     state.privateRooms = privateRooms;
  // },

  set_privateSelectedRoom: (state,privateRoom)=>{
    state.privateSelectedRoom = privateRoom ;
  },

  set_openPrivateChat(state){
    state.openPrivateChat = true ;
  },

  set_openPrivateChatList(state){
    state.openPrivateChatList = true ;
  },

  set_privateChatWithMember(state,member){
      state. privateChatWithMember = member
  },
  set_messagesWithPagination(state,messages){
    state.messagesPagination = messages
  },
  set_nextCursor(state,nextCursor){
    state.nextCursor = nextCursor
  },
  set_flag(state,flag){
    state.flag = flag
  },
  set_showLoadMoreMessages(state,showLoadMoreMessages){
    state.showLoadMoreMessages = showLoadMoreMessages
  },
  set_messagesWithPaginationPrivate(state,messagesPrivate){
    state.messagesPaginationPrivate = messagesPrivate
  },
  set_nextCursorPrivate(state,nextCursorPrivate){
    state.nextCursorPrivate = nextCursorPrivate
  },
  set_flagPrivate(state,flagPrivate){
    state.flagPrivate = flagPrivate
  },
  set_showLoadMoreMessagesPrivate(state,showLoadMoreMessagesPrivate){
    state.showLoadMoreMessagesPrivate = showLoadMoreMessagesPrivate
  },

  set_autoSelect (state, data) {
    state.autoSelect = data
  },

  set_selectedRoomMessages (state, messages) {
    state.selectedRoomMessages = messages;
  },

  prepend_selectedRoomMessages (state, messages) {
    state.selectedRoomMessages.unshift(messages);
  },

  append_selectedRoomMessages (state, messages) {
    state.selectedRoomMessages.push(messages);
  },

  set_previousMessagesIndex (state, index) {
    state.previousMessagesIndex = index;
  },

  set_nextMessagesIndex (state, index) {
    state.nextMessagesIndex = index;
  },

  add_messageToGroup(state, data) {
    state.selectedRoomMessages[data.index].push(data.message);
  },

  set_selectedRoomGroups(state, data) {
    state.selectedRoomGroups = data;
  },

  update_selectedRoomGroups(state, group) {
    state.selectedRoomGroups.push(group);
  },

  set_previousPrivateMessagesIndex(state, index) {
    state.previousPrivateMessagesIndex = index;
  },

  set_nextPrivateMessagesIndex(state, index) {
    state.nextPrivateMessagesIndex = index;
  },

  set_selectedPrivateRoomGroups(state, array) {
    state.selectedPrivateRoomGroups = array;
  },

  update_selectedPrivateRoomGroups(state, group) {
    state.selectedPrivateRoomGroups.push(group);
  },

  append_selectedPrivateRoomMessages(state, messages) {
    state.selectedPrivateRoomMessages.push(messages);
  },

  prepend_selectedPrivateRoomMessages (state, messages) {
    state.selectedPrivateRoomMessages.unshift(messages);
  },

  set_selectedPrivateRoomMessages(state, array) {
    state.selectedPrivateRoomMessages = array;
  },

  add_privateMessageToGroup(state, data) {
    state.selectedPrivateRoomMessages[data.index].push(data.message);
  },

  set_privateMessageFocus(state, value) {
    state.privateMessageFocus = value;
  },

  set_privateRooms(state, data) {
    state.privateRooms = data;
  },

  add_privateRooms(state, data) {
    state.privateRooms.push(...data);
  },

  unset_privateRooms(state) {
    state.privateRooms = [];
  }
}
