import Vue from 'vue'
import App from './App.vue'

import VueCroppie from 'vue-croppie';
import 'croppie/croppie.css'
Vue.use(VueCroppie);

import SocketClass from "./socketClass";
import $socket from './socket';
SocketClass.init($socket,store);
Vue.prototype.$iv = SocketClass;

Vue.prototype.$imageurl = process.env.VUE_APP_API_IMAGES_URL;
Vue.prototype.$defaultLocale = 'en-GB';
Vue.prototype.$userDefaultImage = require('@/assets/img/User.svg');
Vue.prototype.$faDefaultIconSize = 'lg';
Vue.prototype.$mobileBreakpoint = 768;

Vue.prototype.$stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
Vue.prototype.loggerEnabled = false;


Vue.prototype.$sleep = async (ms)=> {
  return new Promise(resolve => setTimeout(resolve, ms));
};

//import VueMoment from 'vue-moment';
// Use as this.$moment
//import moment from 'moment-timezone';
//import VueCookies from 'vue-cookies';
//Vue.use(VueCookies);
//Vue.$cookies.config('30d');

import VueLocalStorage from 'vue-localstorage';
Vue.use(VueLocalStorage, {
  name: 'localStorage',
  bind: true
});

import axios from './axios.js';
Vue.prototype.$http = axios;

import {store} from './store/store';
Vue.config.productionTip = false;

Vue.use(require('vue-moment'));

import router from './router';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
// Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('fa-icon', FontAwesomeIcon);

import Buefy from 'buefy';
// Off if needed to override vars
// import 'buefy/dist/buefy.css';

Vue.use(Buefy, {
  defaultIconPack: 'fa',
  defaultIconComponent: "fa-icon",
  // defaultIconPrev: 'chevron-left',
  // defaultIconNext: 'chevron-right',
});

//https://github.com/Kelin2025/vue-responsive-components
//https://github.com/ktsn/vue-sfc-parser

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
