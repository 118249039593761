<template>
  <div>
    <div class="latest-one">
      <div class="latest-iframe" :style="{ 'background-image': `url(${setThumb()})` }">
        <img
            :src="placeholder" :alt="server.name"/><!--/a-->
      </div>
      <div class="latest-content">
        <div class="vc-left">
          <img
              :src="(server.owner && server.owner.image) ? setAvatar(server.owner.image) : defaultOwnerImage"
              class="profile-small"
              :alt="(server.owner.length && server.owner.username) ? server.owner.username : 'server'"
              @click="goToUserProfile(server.owner.username)"
              style="cursor: pointer;"
          />

        </div>
        <div class="vc-right">
          <p><a href="#" style="color: #fff;" @click.prevent="handleJoin(server)">{{ server.name }}</a>
            <b-button
                :loading="loading"
                @click="handleJoin(server)" size="is-small" class="join-server"
            ><span v-if="!loading && !isUserInServer">Join</span><span
                v-if="!loading && isUserInServer">Visit</span><span v-if="loading && !isUserInServer">...</span>
            </b-button>
          </p>
          <!--p>In Server: {{isUserInServer}}</p-->
          <!--p>{{server.name}} <b-button @click="handleGotoServer(server)" size="is-small" class="join-server">Go to Server</b-button></p-->
          <!--span> Date: {{server.latestRoomServerDate.slice(0,10)}}  </span-->
          <!--span> Time: {{server.latestRoomServerDate.slice(11,19)}}</span-->
          <p
              v-if="server.owner"
              @click="goToUserProfile(server.owner.username)"
              style="cursor: pointer;"
          >
            {{ server.owner.username }}
          </p>
          <!--p v-if="!server.owner.length" >{{server.name}}</p-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "HomeLatestServersChild",
  props: ['server', 'isUserInServer'],
  data() {
    return {
      //defaultImage: '',
      today: new Date(),
      loading: false,
    }
  },
  computed: {
    serverDefault() {
      return require('@/assets/img/server-default.png');
    },
    defaultOwnerImage() {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    },
    placeholder() {
      return require('@/assets/img/famous-placeholder.png');
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    token() {
      return this.$store.getters.get_token;
    }
  },
  mounted() {

  },
  methods: {
    handleJoin(server) {
      if (this.userDataAfterLogIn && this.userDataAfterLogIn._id) {
        console.log('handleJoin famous', this.server);
        //this.$store.commit('set_selectedServer', this.server);
        if (this.server.slug) {
          if (this.isUserInServer) {
            this.$router.push({
              name: 'Chat',
              params: {id: this.server.slug, action: 'visit', server_id: this.server._id}
            });
          } else {
            this.$router.push({
              name: 'Chat',
              params: {id: this.server.slug, action: 'join', server_id: this.server._id}
            });
          }
        }
      } else {
        this.$localStorage.set('redirectAfterLogin', '/servers/' + server.slug + '?action=join');
        this.$store.commit('set_openCloseLogIn');
      }
    },
    setThumb() {
      if (this.server.image && this.server.image.length) {
        if ('http' === this.server.image.slice(0, 4)) {
          return this.server.image;
        } else {
          if ('server' === this.server.image.slice(0, 6)) {
            return this.$imageurl + '/' + this.server.image;
          } else {
            return this.serverDefault;
          }
        }
      }
      return this.serverDefault;
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }

      return this.defaultOwnerImage;
    },
    dateToLocalISO(date) {
      const off = date.getTimezoneOffset()
      const absoff = Math.abs(off)
      return (new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
          (off > 0 ? '-' : '+') +
          (absoff / 60).toFixed(0).padStart(2, '0') + ':' +
          (absoff % 60).toString().padStart(2, '0'))
    },
    goToUserProfile(username) {
      this.$router.push(`/user-profile/${username.toLowerCase()}`)
    },
  },
}
</script>

<style>

#home-latest-carousel .carousel-arrow .icon {
  /**background-color: rgba(0, 0 , 0, 0.55) !important;*/
  background-color: rgba(104, 67, 255, 0.77) !important;
  color: #ffffff !important;
  cursor: pointer;
  border: 1px solid transparent;
  width: 45px;
  height: 45px;
  padding: 9px 14px;
}

#home-latest-carousel .carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}

.carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}


.latest-one {
  padding: 0.5em;
}

.latest-content {
  display: flex;
}


.latest-content .vc-right a:hover {
  text-decoration: none;
  color: #7957d5 !important;
}

button.join-server {
  margin: 0 1em;
  background-color: rgba(104, 67, 255, 0.77) !important;
  border-color: rgba(104, 67, 255, 0.77) !important;
  padding: 5px 11px 5px;
  float: right;
  color: #ffffff;
}

button.join-server:hover {
  background-color: rgba(104, 67, 255, 0) !important;
}

.vc-left {
  width: 15%;
}

.vc-left img {
  display: inline-block;
  width: 45px;
  height: 45px;
}

.vc-right {
  padding-left: 0.5em;
  width: 85%;
}

.vc-right p {
  margin-bottom: 0;
  color: #fff;
}

.vc-right span {
  display: block;
  color: #797979;
  font-size: 14px;
}

.latest-iframe {
  margin-bottom: 20px;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.latest-iframe img {
  display: block;
  width: 100%;
  height: auto;
}

.latest-one {
  margin-top: 25px;
}

img.profile-small {
  border-radius: 50%;
}

@media (max-width: 1080px) {
  .latest-iframe img {
    max-height: 150px;
  }

  img.profile-small {
    width: 40px;
    height: 40px;
  }
}


@media (max-width: 992px) {
  .famous-iframe img {
    height: 160px;
  }

  img.profile-small {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 792px) {
  img.profile-small {
    width: 32px;
    height: 32px;
  }

  .vc-right {
    font-size: 14px;
  }
}

</style>


