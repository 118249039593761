var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.message)?_c('div',{class:{
          'chat-message': true,
          'last-message': true,
          'users-message': _vm.usersMessage,
          'non-users-message': !_vm.usersMessage,
      }},[_c('b-message',{staticClass:"b-message",class:{
                        'user': _vm.usersMessage,
                        'non-user': !_vm.usersMessage,
                        },scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.messageUser.image)?_c('div',{staticClass:"message-avatar"},[_c('img',{attrs:{"src":_vm.setAvatar(_vm.messageUser.image)}})]):_vm._e(),(!_vm.messageUser.image)?_c('div',{staticClass:"message-avatar"},[_c('img',{attrs:{"src":_vm.defaultOwnerImage}})]):_vm._e(),_c('div',{staticClass:"message-info"},[_c('span',{staticClass:"message-date"},[_vm._v(_vm._s(_vm._f("moment")(_vm.message.date,'LT')))]),_c('span',{staticClass:"message-username"},[_vm._v(_vm._s(_vm.messageUser.username))])])]},proxy:true}],null,false,1867568613)},[_c('div',{staticClass:"message-wrapper"},[_c('ChatSingleMessage',{attrs:{"message":_vm.message}})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }