<template>
  <section id="sidebar-tabs-section" class="sidebar-section">
<!--    <div id="sidebar-tabs-header" class="sidebar-header">-->
<!--            <b-button class="sidebar-button close darker"-->
<!--              style="position: absolute; left: 10px; background-color: transparent;"-->
<!--              @click="closeSidebar"-->
<!--            >-->
<!--              <img :src="closeSidebarIcon" style="" />-->
<!--      &lt;!&ndash;              <fa-icon icon="sign-in-alt" class="navbar-button-icon" :size="$faDefaultIconSize" />&ndash;&gt;-->
<!--            </b-button>-->
<!--      &lt;!&ndash;b-button class="sidebar-button close darker"-->
<!--                v-if="selectedTab === 1"-->
<!--                style="position: absolute; right: 35px;"-->
<!--                @click="switchStream"><fa-icon v-if="!loadingStreamers" name="eye" class="navbar-button-icon" :size="$faDefaultIconSize" /></b-button&ndash;&gt;-->
<!--      <b-button class="sidebar-button close darker"-->
<!--                v-if="selectedTab === 1"-->
<!--                style="position: absolute; right: 10px;"-->
<!--                @click="reloadStreamers"><fa-icon v-if="!loadingStreamers" icon="sync-alt" class="navbar-button-icon" :size="$faDefaultIconSize" /></b-button>-->
<!--      <p v-if="selectedTab === 0 && privateChatWithMember && privateChatWithMember.user">Chat With {{privateChatWithMember.user.username}}</p>-->
<!--      <p v-if="selectedTab === 0 && (!privateChatWithMember || !privateChatWithMember.user)" style="font-size: 90%;" >Choose User for Private Chat</p>-->
<!--      <p v-if="selectedTab === 7" style="text-transform: capitalize;">-->
<!--        <span v-if="selectedRoom">{{selectedRoom.name}}</span>-->
<!--        <span v-else>{{sidebarTitles[selectedTab]}}</span>-->
<!--      </p>-->
<!--      <p v-if="selectedTab !== 0 && selectedTab !== 7">{{sidebarTitles[selectedTab]}}</p>-->
<!--    </div>-->
      <b-tabs v-model="selectedTab"
          id="sidebar-tabs"
          size="is-small"
          type="default"
          position="is-right"
          class="vertical-tabs"
          vertical
          @input="onTabChange"
  >

    <b-tab-item >
      <template #header class="tab-header">
        <p class="tab-label"
           id="tab-private"
           @mouseover="showTooltip('tab-private', 'Private')"
           @mouseleave="hideTooltip('tab-private', 'Private')"
        >
          <!--          <b-icon icon-pack="fa" icon="comment"></b-icon>-->
          <fa-icon icon="comment" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <PrivateChatTab></PrivateChatTab>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        <p class="tab-label"
           id="tab-stream"
           @mouseover="showTooltip('tab-stream', 'Stream')"
           @mouseleave="hideTooltip('tab-stream', 'Stream')"
        >
          <!--          <b-icon icon-pack="fa" icon="video"></b-icon>-->
          <fa-icon icon="video" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <!--CameraPlayerNew v-if="userLogIn"></CameraPlayerNew-->
      <StreamTab></StreamTab>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        <p class="tab-label"
           id="tab-market"
           @mouseover="showTooltip('tab-market', 'Market')"
           @mouseleave="hideTooltip('tab-market', 'Market')"
        >
          <!--          <b-icon icon-pack="fa" icon="eye"></b-icon>-->
          <fa-icon icon="eye" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <p style="display: none">Market Overview</p>
      <MarketOverview v-if="selectedTab === 2"></MarketOverview>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        <p class="tab-label"
           id="tab-screener"
           @mouseover="showTooltip('tab-screener', 'Screener')"
           @mouseleave="hideTooltip('tab-screener', 'Screener')"
        >
          <!--          <b-icon icon-pack="fas" icon="archive"></b-icon>-->
          <fa-icon icon="archive" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <p style="display: none">Market Screener</p>
      <Screener v-if="selectedTab === 3"></Screener>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        <p class="tab-label"
           id="tab-calendar"
           @mouseover="showTooltip('tab-calendar', 'Calendar')"
           @mouseleave="hideTooltip('tab-calendar', 'Calendar')"
        >
          <!--          <b-icon icon-pack="fa" icon="calendar-week"></b-icon>-->
          <fa-icon icon="calendar-week" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <p style="display: none">Trading Calendar</p>
      <TradingVueCalendar v-if="selectedTab === 4"></TradingVueCalendar>
    </b-tab-item>

    <b-tab-item>
      <template #header>
        <p class="tab-label"
           id="tab-forex"
           @mouseover="showTooltip('tab-forex', 'Forex')"
           @mouseleave="hideTooltip('tab-forex', 'Forex')"
        >
          <!--          <b-icon  icon-pack="fa" icon="wallet"></b-icon>-->
          <fa-icon icon="wallet" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <p style="display: none">Forex Heat Map</p>
      <ForexHeatMap v-if="selectedTab === 5"></ForexHeatMap>
    </b-tab-item>

    <b-tab-item v-if="userDataAfterLogIn">
          <template #header>
            <p class="tab-label"
               id="tab-mentions"
               @mouseover="showTooltip('tab-mentions', 'Mentions')"
               @mouseleave="hideTooltip('tab-mentions', 'Mentions')"
            >
            <!--              <b-icon  icon-pack="fa" icon="at"></b-icon>-->
              <fa-icon icon="at" class="navbar-button-icon" :size="iconSize"  />
            </p>
          </template>
          <span style="display: none">#</span>
          <UserMentions v-if="showMentions" :user="userDataAfterLogIn"></UserMentions>
    </b-tab-item>
    <b-tab-item v-if="selectedRoom && selectedRoom._id">
      <template #header>
        <p class="tab-label"
           id="tab-search"
           @mouseover="showTooltip('tab-search', 'Search Room')"
           @mouseleave="hideTooltip('tab-search', 'Search Room')"
        >
          <fa-icon icon="search" class="navbar-button-icon" :size="iconSize"  />
        </p>
      </template>
      <span style="display: none">#</span>
      <RoomSearch v-if="selectedRoom && selectedRoom._id" />
    </b-tab-item>
    <!--b-tab-item icon-pack="fa" icon="dollar">
      <VueTradingView :options="tradingViewOptions"></VueTradingView>
    </b-tab-item-->

        <b-tab-item >
          <template #header class="tab-header">
            <p class="tab-label"
               id="tab-private-list"
               @mouseover="showTooltip('tab-private-list', 'Private Message List')"
               @mouseleave="hideTooltip('tab-private-list', 'Private Message List')"
            >
              <!--          <b-icon icon-pack="fa" icon="comment"></b-icon>-->
              <fa-icon icon="comments" class="navbar-button-icon" :size="iconSize"  />
            </p>
          </template>
          <PrivateChatList/>
        </b-tab-item>

  </b-tabs>
    <b-tooltip
        ref="tabsTooltip"
        id="tabs-tooltip"
        class="tabs-tooltip"
        :label="tooltipText"
        :position="position"
        :style="tooltipText.length ? `display: block; top:${tooltipTop}px` : `display: none; top:-100px`"
    ></b-tooltip>
  </section>
</template>

<script>
import PrivateChatTab from "../PrivateRoomsCore/PrivateChatTab";
//import CameraPlayerNew from '../MediaPlayers/CameraPlayerNew';
import StreamTab from '../StreamCore/StreamTab';
// import ForexHeatMap from '../Widgets/ForexHeatMap';
// import Screener from '../Widgets/Screener';
// import TradingVueCalendar from '../Widgets/TradingVueCalendar';
// import MarketOverview from '../Widgets/MarketOverview';
import UserMentions from "@/components/Widgets/UserMentions";
import EventBus from "@/eventBus";
import RoomSearch from '../Widgets/RoomSearch';
import PrivateChatList from '../PrivateRoomsCore/PrivateChatList';
//import VueTradingView from 'vue-trading-view';
//import CameraPlayerNew from '../MediaPlayers/CameraPlayerNew';

export default {
  name: "SidebarCore",
  components: {
    PrivateChatList,
    RoomSearch,
    PrivateChatTab,
    //CameraPlayerNew,
    StreamTab,
    //ForexHeatMap,
    //Screener,
    //TradingVueCalendar,
    //MarketOverview,
    UserMentions,
    //VueTradingView,
    'ForexHeatMap': () => import('../Widgets/ForexHeatMap'),
    'Screener': () => import('../Widgets/Screener'),
    'TradingVueCalendar': () => import('../Widgets/TradingVueCalendar'),
    'MarketOverview': () => import('../Widgets/MarketOverview'),

  },
  data() {
    return {
      open: true,
      showWidgets: true,
      tooltipText: '',
      tooltipTop: 0,
      showMentions: false,
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      right: true,
      appendToBody: true,
      position: 'is-left',
      selectedTab: 0,
      tradingViewOptions: {
        height: '100%',
        width: '100%',
        allow_symbol_change: true,
        theme: 'dark'
      },
      loadingStreamers: false,
      sidebarTitles: ['Private Chat', 'Now Live', 'Market', 'Screener', 'Calendar', 'Forex', 'Mentions', 'Search'],
      iconScale: 1.2,
      iconSize: "lg",
    };
  },
  created() {
    // this.$store.subscribe((mutation, state) => {
    //   if (mutation.type === 'set_openCloseSidebar') {
    //     const t = document.querySelector("#sidebar-tabs .tab-content");
    //     if (mutation.payload === 'open') {
    //       t.classList = 'tab-content'
    //     } else {
    //       t.classList = 'tab-content hidden'
    //     }
    //     console.log('side', mutation.payload, t)
    //     // this.sidebar = state.openSidebar;
    //   }
    // });

    EventBus.$on('selectedTab', (payload) => {
      if (payload === 'stream') {
        this.selectedTab = 1;
      }
      if (payload === 'chat') {
        this.selectedTab = 0;
      }
    });
  },
  mounted() {
    document.body.appendChild(document.getElementById('tabs-tooltip'));
  },
  watch: {
    selectedTab(val) {
      this.showMentions = false;
      if (val === 0 || val === 7) {
        EventBus.$emit('sidebarClass', 'normal');
      } else {
        EventBus.$emit('sidebarClass', 'wide');
        if (val === 6) {
          this.showMentions = true;
        }
      }
    },
    // selectedRoom(val) {
    //   console.log('selroom', val)
    // }
  },
  computed: {
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    privateChatWithMember(){
      return this.$store.getters.get_privateChatWithMember
    },
    liveNowStreamers(){
      return this.$store.getters.get_liveNowStreamers
    },
    selectedRoom() {
      return this.$store.getters.get_selectedRoom
    },
    // closeSidebarIcon () {
    //   return require('@/assets/icons/close-side.svg');
    // },
  },
  methods:{
    showTooltip(tab, title) {
      const thumb = document.getElementById(tab);
      if (thumb) {
        this.$nextTick(()=>{
          const thumbOffset = thumb.getBoundingClientRect();
          const h = thumbOffset.height / 2;
          this.tooltipTop = thumbOffset.top + h;
          this.tooltipText = title;
        });
      } else {
        this.tooltipText = '';
      }
    },
    hideTooltip(tab, title) {
      this.tooltipText = '';
    },
    async reloadStreamers() {
      this.$store.dispatch('reloadStreamers');
      //this.$store.dispatch('getUserBroadcasts');
    },
    onTabChange(e) {

    },
    switchStream() {
      EventBus.$emit('switchStream');
      //this.$store.dispatch('set_livePositionCenter');
    },
    closeSidebar() {
      this.$store.dispatch('set_openCloseSidebar', 'close');
    }
  },
}
</script>

<style>

#tabs-tooltip {
  position: absolute;
  top: 0;
  right: 35px;
  transform: translateY(50%);
  z-index: 9999;
}

#tabs-tooltip .tooltip-content {
  display: block !important;
}


.tab-label {
  width: 100%;
  text-align: center;
}

#sidebar-tabs-section {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

#sidebar-tabs {
  /*height: calc(100% - 51px);*/
  /*max-height: calc(100% - 51px);*/
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  background-color: #23272a;
  position: relative;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;

}

#sidebar-tabs nav {
  /**height: 62px;
  max-height: 62px;*/
  padding: 0;
  overflow: hidden;
  text-align: center;
  background-color: #293035;
  display: inline-block;
  width: 54px;
  max-width: 54px;
  height: 100%;
  max-height: 100%;

}

#sidebar-tabs ul {
  border-bottom-color: transparent;

}


#sidebar-tabs .tabs a {
  border-bottom-color: transparent;
  /**position: relative;
  width: 100%;
  display: block;*/
}

#sidebar-tabs .tabs li.is-active a {
  border-bottom: 0px solid;
}

#sidebar-tabs .tabs a .b-tooltip {
  width: 100%;
  max-width: 100%;
}

#sidebar-tabs.is-vertical ul li {
  width: 100%;
  max-width: 100%;
}

#sidebar-tabs.is-vertical ul li a {
  width: 100%;
  max-width: 100%;
  padding: 0.28em;
}

#sidebar-tabs .tab-content {
  /**height: calc(var(--main) - 63px);
  max-height: calc(var(--main) - 63px);*/
  height: 100%;
  max-height: 100%;
  padding: 0;
  display: inline-block;
  width: calc(100% - 54px);
  max-width: calc(100% - 54px);
}

#sidebar-tabs .tab-content.hidden {
  display: none;
}

#sidebar-tabs .tab-item {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}

#sidebar-tabs-header {
  /**height: 6%;
  max-height: 6%;*/
  height: 50px;
  max-height: 50px;
  padding: 0.5em;
  overflow: hidden;
  text-align: center;
  background-color: #293035;
  display: table;
  width: 100%;
  max-width: 100%;
  color: #bcbcbc;
  position: relative;
  display: none;
}
#sidebar-tabs-header p {
  display: table-cell;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

/*.sidebar-header {*/

/*  height: 50px;*/
/*  max-height: 50px;*/
/*  padding: 0.5em;*/
/*  overflow: hidden;*/
/*  text-align: center;*/
/*  background-color: #293035;*/
/*  display: table;*/
/*  width: 100%;*/
/*  max-width: 100%;*/
/*  color: #bcbcbc;*/
/*  position: relative;*/

/*}*/

.widget-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.widget-script-container {
  height: 100%;
  max-height: 100%;

}


</style>
