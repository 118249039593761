export default {
 set_servers:(state,servers) => {
   console.log(typeof servers);
   state.servers = servers;
 },
 set_selectedServer:(state, server) => {
     //state.selectedServerRooms = null; // Test
     state.selectedServer = server;
 },
 set_selectedServerUsers:(state, users ) => {
        state.selectedServerUsers = users;
 },
 set_serverUsers:(state, users) => {
     state.serverUsers = users;
 },
  set_selectedCategory:(state, selectedCategory) => {
    state.selectedCategory = selectedCategory;
  },

}
