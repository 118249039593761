<template>
  <div :id="'pm-' + message._id " class="pcje" @mouseenter="hover = true" @mouseleave="hover = false">

    <div class="msg-content"
         v-if="message.message && ( !message.quote || (message.quote && !message.quote.length) )"
         ref="msg"
         v-html="parsedMessage"
    ></div>
    <div class="msg-content" v-if="message.quote && message.quote.length">
      <QuoteMessage
          :quoteObject="message.quote[0]"
          :message="parsedMessage">
      </QuoteMessage>
    </div>
    <div class="msg-images-wrapper"
         v-if="message.images && message.images.length && ( !message.quote || (message.quote && !message.quote.length) )">
      <div v-for="image in message.images" :key="image._id">
        <img class="msg-images" v-if="image" :src="`${baseUrl}/${image}`"/>
      </div>
    </div>
    <b-dropdown aria-role="list" class="chat-message-actions"
                :class="{'is-pulled-right is-top-left': isUsersMessage , 'is-pulled-left is-bottom-right': !isUsersMessage}">
      <!--            <b-icon style="color: #4a4a4a;" icon="dots-vertical" slot="trigger"></b-icon>-->
      <fa-icon icon="ellipsis-v" slot="trigger" style="color: #4a4a4a;margin: 0 0.5em; cursor: pointer;" :size="$faDefaultIconSize"/>
      <b-dropdown-item v-if="isUsersMessage" aria-role="listitem" @click="MessageEdit">Edit</b-dropdown-item>
      <b-dropdown-item v-if="isUsersMessage" aria-role="listitem" @click="deleteMessage">Delete</b-dropdown-item>
      <b-dropdown-item aria-role="listitem" @click="quoteMessage">Quote</b-dropdown-item>
    </b-dropdown>
  </div>
</template>


<script>
import socket from "../../socket";
import QuoteMessage from "../QuoteMessage";
import { parse } from 'node-html-parser';

export default {
  name: "PrivateChatSingleMessage",
  props: ['message'],
  components: {
    QuoteMessage
  },
  data() {
    return {
      hover: false,
      parsedMessage: null,
      ready: false,
      baseUrl: '',
    }
  },
  created() {
    this.baseUrl = process.env.VUE_APP_API_IMAGES_URL;
    let final = '';
    const messageArray = this.message.message.split(' ');
    messageArray.forEach(str => {
      const t = str.trim();
      if (t.slice(0, 1) === '@') {
        // TODO check if a user actually exists
        final += `<span class="mention">${t}</span> `;
      } else if (t.slice(0, 1) === '#') {
        final += `<span class="hashtag">${t}</span> `;
      } else {
        final += `${t} `;
      }
    });
    this.parsedMessage = parse(final);
  },
  computed: {
    messageUser() {
      if (this.message.user.length) {
        return this.message.user[0];
      }
      return this.message.user;
    },
    isUsersMessage() {
      return this.messageUser._id === this.$store.getters.get_userDataAfterLogIn._id;
    },
    // isUsersMessage(){
    //       return this.message.user[0]._id === this.$store.getters.get_userDataAfterLogIn._id;
    // }
  },
  methods: {

    deleteMessage() {
      console.log('DELETE MESSAGE the messageId and roomId before emit the deleteMessage event is ', this.message._id, this.$store.getters.get_privateSelectedRoom._id)
      socket.emit('deleteMessage', {
        messageId: this.message._id,
        roomId: this.$store.getters.get_privateSelectedRoom._id,
      });
    },

    MessageEdit() {
      this.$store.commit('INIT_EDIT_MESSAGE_PRIVATE', this.message);
    },

    quoteMessage() {
      this.$store.commit('QUOTE_MESSAGE_PRIVATE', this.message)

    }


  },
  // mounted() {
  //     console.log('step 1,8 the message  arrived in chaSingleMessage and the result we show  in the screen is  ', this.message)
  // }
}
</script>

<style lang="scss">
.msg-content {
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 3px;
  word-wrap: break-word;
  font-size: 15px;
  border-radius: 5px;
  padding: 10px 20px;
  width: auto;
  max-width: calc(100% - 90px);
  display: inline-block;
  direction: ltr;
  /*background-color: #ddf0ff;*/
}

.msg-wrapper {
  background-color: white;
}

.msg-images-wrapper {

  border-radius: 5px;
  width: auto;
  padding: 25px;
  max-width: calc(100% - 90px);
  display: inline-block;

  background-color: #594b6f;
  color: #dddddd;
}

.chat-message-actions .dropdown-menu {
  padding: 0;
}

.users-message .message-wrapper .pcje:first-child .msg-content {
  border-radius: 15px 0 15px 15px;
}

.non-users-message .message-wrapper .pcje:first-child .msg-content {
  border-radius: 0 15px 15px 15px;
}

.msg-wrap {

}

</style>
