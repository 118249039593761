import axios from "../../../axios";
// import * as _ from 'lodash';
import _filter from 'lodash/filter';
import EventBus from "@/eventBus";
export default {

  async createUpdateStream (
    { dispatch, commit, state, getters, rootState, rootGetters },  params ){

    let name, image, description = null;
    //console.log('createUpdateStream params', params);

    let axHeaders = axios.defaults.headers;
    axHeaders['Content-Type'] = 'application/json';

    let data = {
      name : (params.options && params.options.name) ? params.options.name : rootGetters.get_userDataAfterLogIn.username + " 's Channel",
      broadcastServers: [...getters.get_ServersForStreaming],
      broadcastRooms: [...getters.get_RoomsForStreaming],
      streamingServiceId: (params.roomid) ? params.roomid : rootGetters.get_userDataAfterLogIn.broadcast,
      //streamingServiceUserId: (streamingServiceUserId) ? streamingServiceUserId : ''
    }

    if (params && params.option && params.options.image) {
      data['image'] = params.options.image;
    }

    if (params && params.option && params.options.description) {
      data['description'] = params.options.description;
    }
    //console.log('createUpdateStream data', data);

    let streamId = (params.roomid) ? params.roomid : rootGetters.get_userDataAfterLogIn.broadcast;
    let exists;

    if(streamId) {
      exists = await dispatch('checkStreamExists', streamId);
    }

    try {
      let response;
      if (exists && exists.data && exists.data._id) {
      //if (exists && exists.data && exists.data._id) {
      //if(rootGetters.get_userDataAfterLogIn.broadcast || getters.get_FirstBroadcastCreationCompleted ) {
        response = await axios.put(`broadcasting/${streamId}`, data, {
          headers: axHeaders
        });
        //console.log('createUpdateStream', response);
      } else {
        //console.log('getters.get_RoomsForStreaming', getters.get_RoomsForStreaming)
        response = await axios.post('broadcasting', data, {
          headers: axHeaders
        });
        //with this  commit solve the problem if a stream owner stop stream and create new
        //without refresh , but get_userDataAfterLogIn.broadcast is null
        commit('set_OwnerFirstBroadcastCreationCompleted'); // Check if needed
      }

      //console.log('createUpdateStream RESPONSE after PUT or POST' , response);
      if (response.status >= 200 && response.status < 300) {
        commit('set_streamId' , response.data._id);
        dispatch('cameraStarted');
        //commit('set_myStreamingLive', true);
      } else {
        console.log('response.data.message', response.data.message);
      }

      dispatch('reloadStreamers'); // Temp
      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  },

  async createStream (
      { dispatch, commit, state, getters, rootState, rootGetters } ){
    let axHeaders = axios.defaults.headers;
    axHeaders['Content-Type'] = 'application/json';
    let response;
    try {
      let data = {
        name : "Channel",
      }

      response = await axios.post('broadcasting',  data, {
          headers: axHeaders
     });

      return response;
    }
    catch (error) {
      console.log(error);
      return error;
    }
  },


  async checkStreamExists({ dispatch } , broadcastId) {
    try {
      return await axios.get('broadcasting/' + broadcastId);
    } catch (error) {
      console.log(error);
      return error
    }
  },

  async cameraStarted(
    { dispatch, commit, state, getters, rootState, rootGetters },
  ) {

    console.log('cameraStarted actions');
    let  responseUserData;
    let  serversStreaming;
    let  roomsStreaming = [];
    try {
      //console.log('cameraStarted ACTIONNNN');
      responseUserData = await axios.get(`users/${rootGetters.get_userDataAfterLogIn._id}`)
      if (responseUserData.status >= 200 && responseUserData.status < 300) {
        serversStreaming = _filter(responseUserData.data.servers,function(server) {
          return  server.broadcast !== null && server.broadcast;
        });
       responseUserData.data.servers.forEach((server)=>{
           server.rooms.forEach((room)=>{
             if ( room.broadcast !== null && room.broadcast) {
               roomsStreaming.push(room)
             }
           })
        });
        //console.log('Broadcasting servers cameraStarted 1', serversStreaming)
        //console.log('Broadcasting rooms cameraStarted 2', roomsStreaming);
        //console.log('cameraStarted RESPONSE 3' , responseUserData)
        await commit('CAMERA_STARTED',);
        await commit('set_ServersAndRoomsForStreaming', {
          servers:serversStreaming,
          rooms:roomsStreaming
        });
      } else {
        console.log('response.data.message' , responseUserData.data.message);
      }
      //dispatch('reloadStreamers'); // Check
    }catch (e) {
      console.log('error', e)
    }
  },

  async cameraEnded(
    { dispatch, commit, state, getters, rootState, rootGetters },

  ) {
    let  responseUserData;
    let  serversStreaming;
    let  roomsStreaming= [];
    try {
      //console.log('cameraEnded ACTIONNNN');

      if(getters.get_cameraOpen){
        await commit('CAMERA_WORKING', false);
      }

      responseUserData = await axios.get(`users/${rootGetters.get_userDataAfterLogIn._id}`)


      if (responseUserData.status >= 200 && responseUserData.status < 300) {
        serversStreaming = _filter(responseUserData.data.servers,function(server) {
          return  server.broadcast !== null && server.broadcast;
        });
        responseUserData.data.servers.forEach((server)=>{
          server.rooms.forEach((room)=>{
            if ( room.broadcast !== null && room.broadcast) {
              roomsStreaming.push(room)
            }
          })
        });


        //console.log('Broadcasting servers cameraEnded 1', serversStreaming)
        //console.log('Broadcasting rooms cameraEnded 2', roomsStreaming);
        //console.log('cameraEnded RESPONSE 3' , responseUserData);
        await commit('CAMERA_ENDED');
        await commit('set_ServersAndRoomsForStreaming', {
          servers:serversStreaming,
          rooms:roomsStreaming
        })
      } else {
        console.log('response.data.message' , responseUserData.data.message);
      }
      //dispatch('reloadStreamers'); // Check

    }catch (e) {
      console.log('error', e)
    }
  },


  async END_STREAM (
      { dispatch, commit, state, getters, rootState, rootGetters }, streamId ){
      let streamToStop;
      if (streamId)  {
         streamToStop = streamId;
      } else {
        streamToStop =  (getters.get_streamId)  ? getters.get_streamId : rootGetters.get_userDataAfterLogIn.broadcast;
      }

    if (streamToStop) {
      try {
        let response = await axios.delete(`/broadcasting/${streamToStop}`);
        if (response.status && response.status >= 200 && response.status < 300) {
          console.log('END_STREAM ACTION HOST && RESPONSE DELETE STREAM ACTION ', response);
          //commit('set_selectedStream', null); // Check
          commit('set_myStreamingLive', false); // Check
          // Check Maybe  to reload only if  has to do with user
          dispatch('reloadStreamers'); // Temp Should be Reloaded on socket closeCamera event
        }
      } catch (e) {
        console.log('err')
      }
    } else {
      console.log('END_STREAM ACTION CLIENT');
    }
  },

  async handleNewCameraOpened ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {

    //await dispatch('reloadStreamers'); //Maybe with await
    console.log('handleNewCameraOpened actions');
    if (payload.broadcast && rootGetters.get_userDataAfterLogIn  &&  rootGetters.get_userDataAfterLogIn.broadcast && payload.broadcast._id === rootGetters.get_userDataAfterLogIn.broadcast) {
      //console.log('myStreamingLive opened');
      //dispatch('reloadStreamers'); //Maybe with await
      commit('set_myStreamingLive', true);
      //commit('set_selectedStream', payload.broadcast); // Check
      dispatch('set_openCloseSidebar', 'open');
      EventBus.$emit('selectedTab', 'stream');
      //return true;
    } else {
      // Waiting new endpoint
      dispatch('reloadStreamers');
      //return false;
    }
  },

  async handleExistingCameraClosed ({ dispatch, commit, state, getters, rootState, rootGetters }, payload) {
    console.log('handleExistingCameraClosed actions', payload.broadcast, getters);
    if (payload.broadcast && getters.get_selectedStream && payload.broadcast._id === getters.get_selectedStream._id) {
      //commit('set_selectedStream', null);
    }
    if (payload.broadcast && rootGetters.get_userDataAfterLogIn && rootGetters.get_userDataAfterLogIn.broadcast && payload.broadcast._id === rootGetters.get_userDataAfterLogIn.broadcast) {
      commit('set_myStreamingLive', false); // Check
      //dispatch('reloadStreamers');
      //return true;
    } else {
      //dispatch('reloadStreamers');
      //return false;
    }
    dispatch('reloadStreamers');
  },


  async getUserBroadcasts({ dispatch, commit, state, getters, rootState, rootGetters }) {
    let broadcasts = [];
    try {
      let response = await axios.get('/users/allowed-broadcasts/' + rootGetters.get_userDataAfterLogIn._id);
      //let response = await axios.get('/users/allowed-broadcasts-servers/' + rootGetters.get_userDataAfterLogIn._id);
      if (response.data) {


        let broadcastsSet = new Set();
        if (response.data.serverBroadcasts && response.data.serverBroadcasts.length) {
          response.data.serverBroadcasts.forEach((broadcast)=> {
            //broadcasts.push(broadcast._id);
            broadcastsSet.add(broadcast._id);
          });
        }
        if (response.data.roomBroadcasts && response.data.roomBroadcasts.length) {
          response.data.roomBroadcasts.forEach((broadcast)=> {
            //broadcasts.push(broadcast._id);
            broadcastsSet.add(broadcast._id);
          });
        }
        if (broadcastsSet.size) {
          broadcasts = Array.from(broadcastsSet);
          commit('set_allowedBroadcasts', broadcasts);
        } else {
          commit('set_allowedBroadcasts', null);
        }
      } else {
        commit('set_allowedBroadcasts', null);
      }
    } catch (error) {
      console.log(error);
      //return error
    }
    return broadcasts;
  },

  async reloadStreamers({ dispatch, commit, state, getters, rootState, rootGetters }) {
    let liveNowStreamers, myStreaming;
    if (rootGetters.get_userDataAfterLogIn && rootGetters.get_userDataAfterLogIn.broadcast) {
      let result = await dispatch('getSingleBroadcasting', rootGetters.get_userDataAfterLogIn.broadcast);
      if (result.data && result.data._id) {
        commit('set_myBroadcasting', result.data);
        myStreaming = result.data;
      } else {
        commit('set_myBroadcasting', null);
      }
      //console.log('myStreaming' , myStreaming);
    }

    //let userBroadcasts = await dispatch('getUserBroadcasts');  // Temp
    //console.log('userBroadcasts @ reloadStreamers' , userBroadcasts);

    try {
      //let response = await axios.get('/broadcasting' );
      let response = await axios.get('/broadcasting/available-broadcasts-per-user/' + rootGetters.get_userDataAfterLogIn._id );
       //console.log ('get all liveNowStreamers @ actions', response);
       if (response.data && response.data.length) {
        commit('set_liveNowStreamers', response.data);
        liveNowStreamers = response.data;
      } else {
        commit('set_liveNowStreamers', null);
      }

       /**
      if (response.data && response.data.broadcasts && response.data.broadcasts.length) {
        commit('set_liveNowStreamers', response.data.broadcasts);
        liveNowStreamers = response.data.broadcasts;
      } else {
        commit('set_liveNowStreamers', null);
      }
      */
    } catch (error) {
      console.log(error);
      return error
    }
    return liveNowStreamers
  },

  async getSingleBroadcasting ({ dispatch, commit, state, getters, rootState, rootGetters }, streamId) {
    try {
      return await axios.get('/broadcasting/' +  streamId);
    } catch (error) {
      console.log(error);
      return error
    }
  },

  // async filterRoomsServersStreamingArray(
  //   { dispatch, commit, state, getters, rootState, rootGetters },
  //   {servers}
  // ){
  //   console.log('filterRoomsServersStreamingArray' , servers)
  //   let serversStreaming = _filter(servers,function(server) {
  //     return  server.broadcast !== null && server.broadcast;
  //   });
  //   let roomsStreaming = [];
  //   servers.forEach((server)=>{
  //     server.rooms.forEach((room)=>{
  //       if ( room.broadcast !== null && room.broadcast) {
  //         roomsStreaming.push(room)
  //       }
  //     })
  //   });
  //   this.$store.commit('set_ServersAndRoomsForStreaming',{
  //     servers:serversStreaming,
  //     rooms:roomsStreaming
  //   })
  //
  // }




}
