<template>
  <div
      v-if="message"
      :class="{
            'chat-message': true,
            'last-message': true,
            'users-message': usersMessage,
            'non-users-message': !usersMessage,
        }"
  >
    <b-message class="b-message"
               :class="{
                          'user': usersMessage,
                          'non-user': !usersMessage,
                          }"
    >
      <template #header >

        <div v-if="messageUser.image" class="message-avatar"><img
            :src="setAvatar(messageUser.image)"
        /></div>
        <div v-if="!messageUser.image" class="message-avatar"><img
            :src="defaultOwnerImage"  /></div>

        <div class="message-info">
          <span class="message-date">{{ message.date | moment('LT')}}</span>
          <span class="message-username">{{ messageUser.username }}</span>
        </div>

      </template>
      <div class="message-wrapper">
        <ChatSingleMessage
            :message="message"
        ></ChatSingleMessage>
      </div>
    </b-message>

  </div>
</template>

<script>
import ChatSingleMessage from "./ChatSingleMessage";
export default {
  data(){
    return{}
  },
  name: "ChatMessageNew",
  components:{
    ChatSingleMessage
  },
  props:['message'],
  computed:{
    messageUser() {
      if (this.message.user.length) {
        return this.message.user[0];
      }
      return this.message.user;
    },
    usersMessage(){
      return this.messageUser._id === this.userDataAfterLogIn._id;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    isBase64() {
      return this.messageUser.image.slice(0, 4) !== 'http';
    },
    defaultOwnerImage () {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    }
  },
  methods:{
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return  this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },

  },
  created() {
    // console.log('this.message', this.message);

  }
}
</script >

<style lang="scss">

.b-message {
  width: 50%;
}

.message-header {
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: normal !important;
  padding: 0;
}

.users-message .message-header {
  padding: 0 3.7em 0 0 !important;
}

.non-users-message .message-header {
  padding: 0 0 0 3.8em !important;
}


.message-header > div {
  display: table !important;
}

.message-info {
  display: inline-block;
}

.message-username {
  display: inline-block;
  margin: 0 5px;
  color: #bcbcbc;
}

.message-date {
  display: inline-block;
  margin: 0 3px;
  float: right;
  color: #757575;
}

.message-avatar {
  display: inline-block;
}

.message-avatar img {
  border-radius: 14px;
  width: 37px;
  height: 37px;

}


.users-message .message-avatar {
  /**float: right;*/
  margin-left: 0.75em;
}

.non-users-message .message-avatar {

  margin-right: 0.75em;
}


button.delete {
  display: none;
}

.chat-message {
  margin: 0;
  margin-bottom: 30px;

  .message {
    background-color: transparent;
    padding: 20px 0 0;
  }

  .message-wrapper {
    max-width: 75%;
    margin-bottom: 0;
  }

  &.users-message {
    border-radius: 14px;
    direction: rtl;
    text-align: right;
    .header-wrap {
      direction: rtl;
    }
    .message-wrapper {
      margin-left: auto;
    }
    .message-date {
      direction: ltr;
    }
  }
  &.non-users-message {
    border-radius: 14px;
    text-align: left;
  }
  &.cursor {
    background-color: rgba(130, 130, 130, 0.2);
    border-radius: 1px;
    .message{
      //background-color: rgba(6, 6, 6, 0.2);
    }
  }
  .message-divider {
    margin-top: 10px;
    max-width: calc(100% - 12px);
  }

  .container {
    padding-top: 0px;
  }

  .msg-wrap {
    .header-wrap {
      position: absolute;
      display: inline-block;
      vertical-align: top;
      .username {
        margin-left: 0px;
        /**font-size: 15px;*/
        font-weight: normal;
        color: #bcbcbc;
      }
      .date-sent {
        color: rgb(117, 117, 117);
        margin: 5px 6px 0 6px;
        padding: 5px 0 0 0px;
        font-size: 90%;
      }
    }
    .message-avatar-container {
      img {
        -webkit-filter: drop-shadow(1px 1px 0 black)
        drop-shadow(-1px -1px 0 black);
        filter: drop-shadow(1px 1px 0 black)
        drop-shadow(-1px -1px 0 black);
      }
      margin-bottom: 8px;
    }
    .msg-content-wrap {
      display: inline-block;
      position: relative;
      max-width: 95%;
      vertical-align: text-top;
      left: 2px;
      top: 15px;
      padding-right: 12px;

      .msg-content {
        margin-bottom: 0;
        overflow-wrap: break-word;
        font-size: 15px;
      }

      .msg-images {
        border: 1px black solid;
        border-radius: 3px;
      }
      &.pulled-aside {
        top: -7px;
        left: 48px + 7px;
      }
    }
  }
}

</style>
