<template>
  <b-navbar :transparent="true"
            :fixed-top="false"
            id="navbar"
            class="header"
            :class="{
        'mobile' : window_width <= mobileBreakpoint,
        'search-active'  : showSearch
      }"
  >
    <template slot="brand" >
      <b-navbar-item style="padding: 0.5em 0.75em;"
                     class="logo" tag="router-link" :to="{ path: '/' }"
                     :class="{
                        'mobile' : window_width <= mobileBreakpoint,
                      }"
      >
        <img :src="logo" />
      </b-navbar-item>
      <b-navbar-item v-if="showSearch" tag="div" id="mobile-navbar-overlay">
        <b-button class="navbar-button darker"
                  style="background-color: transparent"
                  @click="showSearch = false"
        ><fa-icon icon="arrow-left"
                  style="margin-bottom: 0; font-size: 17px; line-height: 20px; color: white" class="navbar-button-icon" scale="0.9" /></b-button>
        <SearchBarNative id="mobile-search-bar" ></SearchBarNative>
      </b-navbar-item>
      <b-navbar-item id="navbar-mobile-actions">
        <b-button v-show="!showSearch" class="navbar-button darker" @click="showSearch = !showSearch">
          <fa-icon icon="search" style="margin-bottom: 0; font-size: 17px;" class="navbar-button-icon"/>
        </b-button>
        <b-button type="is-dark" v-if="!isAuthenticated" style="margin-left:0.5em" @click="onLogIn">Log In</b-button>
        <b-button type="is-primary" v-if="!isAuthenticated" style="margin-left:0.5em" @click="onSignUp">Sign Up</b-button>
<!--        <b-button type="is-dark" v-if="isAuthenticated" style="margin-left:0.5em" @click="onLogIn">Log Out</b-button>-->
      </b-navbar-item>
    </template>

    <template slot="start">
      <b-navbar-item tag="div" class="navbar-item-left">
        <div class="header-link">
          <ul class="header-list-mobile">
            <li><router-link to="/browse">Browse</router-link></li>
            <li v-if="isAuthenticated"><a   @click.prevent="onLogOut">Logout</a></li>
          </ul>
        </div>
      </b-navbar-item>

    </template>
    <template slot="end">
    </template>
  </b-navbar>
</template>

<script>
import SearchBarNative from "@/components/Search/SearchBarNative";
export default {
  name: "NavbarMobile",
  components: {SearchBarNative},
  props:['window_width', 'mobileBreakpoint', 'avatar', 'logo'],
  data(){
    return{
      showSearch: false,
      // mobileBreakpoint: 768
    }
  },
  computed:{
    isAuthenticated(){
      return this.$store.getters.isAuthenticated;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    selectedRoom(){
      return this.$store.getters.get_selectedRoom
    }
  },
  methods: {
    onLogIn(){
      this.$store.commit('set_openCloseLogIn');
    },
    onSignUp(){
      this.$store.commit('set_openSignUp');
    },
    onLogOut(){
      this.$localStorage.remove('accessToken');
      this.$localStorage.remove('user');
      this.$localStorage.remove('userServers');
      this.$localStorage.remove('redirectAfterLogin');
      this.$store.dispatch('logOut');
      if (this.$route.path !== '/')
        this.$router.replace('/');

      // window.location.reload();
      this.$iv.socket.disconnect();
    },
    openUserSettings() {
      this.$store.commit('set_openUserSettings');
    },
    onUserProfile() {
      this.$router.push(`/user-profile/${this.userDataAfterLogIn.username.toLowerCase()}`)
    },
  }
}
</script>

<style>
/*.navbar-burger {*/
/*  margin-right: auto;*/
/*  margin-left: 0;*/
/*}*/

/*#navbar .navbar-item.logo.mobile {*/
/*  display: none;*/
/*  margin-left: auto;*/
/*}*/

.header-list-mobile li {
  width: 100%;

}

.header-list-mobile li a {
  font-size: 33px;
  line-height: 37px;
  letter-spacing: -0.05px;
  color: rgba(255, 255, 255, 0.340117);
}

</style>
