<template>
  <section>
  <div v-if="famousServers.length" class="row  boot-row famous-servers-row">
    <b-carousel-list
        id="home-famous-carousel"
        :data="famousServers"
        :items-to-show="itemsToShow"
        :arrow="arrow"
        :items-to-list="increment"
        :repeat="repeat"
        :has-drag="drag"
    >
      <template slot="item" slot-scope="list">
        <div class="card carousel-card">
          <BrowseServersChild :isUserInServer="isUserInServer(famousServers[list.index]._id)" :server="famousServers[list.index]"></BrowseServersChild>
        </div>
      </template>
    </b-carousel-list>
  </div>
    </section>
</template>

<script>
  import axios from "@/axios";
  // import HomeFamousServersChild from "./HomeCore/HomeFamousServersChild";
  import BrowseServersChild from "./BrowseCore/BrowseServersChild";
  export default {
    name: "HomeFamousServersParent",
    components:{
      BrowseServersChild,
      // HomeFamousServersChild,
    },
    data(){
      return{
        itemsToShow: 3,
        arrow: true,
        arrowHover: true,
        drag: true,
        gray: false,
        opacity: false,
        values: 1,
        increment: 2,
        repeat: true,
        famousServers: [],
        userServersIds: [],
      }
    },
    computed:{
      userServers() {
        return this.$store.getters.get_userServers;
      },
      userDataAfterLogIn() {
        return this.$store.getters.get_userDataAfterLogIn;
      },
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      },
      famousServers1(){
        let servers = JSON.parse(JSON.stringify(this.$store.getters.get_servers));
        //sort descending the servers array by servers.users length
        servers.sort((a, b) => {
          const bandA = a.subscriptions.length;
          const bandB = b.subscriptions.length;
          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison * -1;

        });
        return servers.slice(0, 8);
      },
    },
    async created(){
      if (this.userDataAfterLogIn && this.userDataAfterLogIn._id && this.userServers) {
        this.userServersIds =  Object.keys(this.userServers);
      }
      this.fetchFamous();

      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'set_connectedToSocket') {
          if (this.userDataAfterLogIn && this.userDataAfterLogIn._id && this.userServers) {
            this.userServersIds = Object.keys(this.userServers);
          }
        }
      });
    },
    mounted() {
      this.setCarouselCols();

      this.$nextTick(()=>{
        window.addEventListener('resize', this.setCarouselCols);
      });
    },
    methods: {
      isUserInServer(server_id) {
        if (this.userServersIds.length) {
          if (this.userServersIds.includes(server_id)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
      async fetchFamous() {
        try {
          let response = await axios.get('/servers/famous/8/desc');
          //console.log('fetchFamous#########',response)
          if (response.status === 200 && response.data) {
            this.famousServers = response.data;
          }
        }
        catch (error) {
          console.log(error);
          return error;
        }
      },
      changedSlide(e) {
        console.log(e);
      },
      setCarouselCols() {
        if (window.innerWidth > 1280) {
          this.itemsToShow = 4;
        } else if (window.innerWidth < 992 && window.innerWidth > 480) {
          this.itemsToShow = 2;
        } else if (window.innerWidth <= 480) {
          this.itemsToShow = 1;
        } else {
          this.itemsToShow = 3;
        }
      }
    }
  }
</script>

<style>

#home-famous-carousel .carousel-arrow .icon {
  /**background-color: rgba(0, 0 , 0, 0.55) !important;*/
  background-color: rgba(104, 67, 255, 0.77) !important;
  color: #ffffff !important;
  cursor: pointer;
  border: 1px solid transparent;
  width: 45px;
  height: 45px;
  padding: 9px 14px;
}

#home-famous-carousel .carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}

.carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}


.famous-servers-row {
    margin-top: 0.75em;
  }
  .carousel-card {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    border: 0px solid rgba(0, 0, 0, 0.125);
  }


  .carousel-slide {
    padding-right: 1em;
  }

  .famous-server-col{

  }

  @media (max-width: 992px)  {
    .carousel-slide {
      padding-right: 0.75em;
    }

  }

  @media (max-width: 768px)  {
    .famous-server-col{
      padding: 6px;
    }
    .carousel-slide {
      padding-right: 0.5em;
    }

  }

</style>
