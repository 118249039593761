<template>
  <section>
  <div v-if="categories.length" class="row  boot-row famous-servers-row">
    <b-carousel-list
        id="home-famous-carousel"
        :data="categories"
        :items-to-show="itemsToShow"
        :arrow="arrow"
        :items-to-list="increment"
        :repeat="repeat"
        :has-drag="drag"
    >
      <template slot="item" slot-scope="list">
        <div class="card carousel-card">
          <HomeCategoryYouLike :category="categories[list.index]"></HomeCategoryYouLike>
        </div>
      </template>
    </b-carousel-list>
  </div>
    </section>
</template>




<script>
  import axios from "@/axios";
  import HomeCategoryYouLike from "./HomeCore/HomeCategoryYouLike";

  export default {
    name: "HomeFamousServersParent",
    components:{
      HomeCategoryYouLike
    },
    data(){
      return{
        categories: [],
        itemsToShow: 6,
        arrow: true,
        arrowHover: true,
        drag: true,
        gray: false,
        opacity: false,
        values: 1,
        increment: 2,
        repeat: true,
        famousServers: [],
        userServersIds: [],
      }
    },
    computed:{
      userServers() {
        return this.$store.getters.get_userServers;
      },
      userDataAfterLogIn() {
        return this.$store.getters.get_userDataAfterLogIn;
      },
      isAuthenticated() {
        return this.$store.getters.isAuthenticated;
      },
    },
    created(){
       this.getCategories();
    },
    mounted() {
      this.setCarouselCols();

      this.$nextTick(()=>{
        window.addEventListener('resize', this.setCarouselCols);
      });
    },
    methods: {
      getCategories() {
        axios.get('/categories')
            .then(response => {
              if (response.data && response.data.length) {
                this.categories = response.data;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      },
      changedSlide(e) {
        console.log(e);
      },
      setCarouselCols() {
        if (window.innerWidth > 1280) {
          this.itemsToShow = 6;
        } else if (window.innerWidth < 992 && window.innerWidth > 480) {
          this.itemsToShow = 4;
        } else if (window.innerWidth <= 480) {
          this.itemsToShow = 1;
        } else {
          this.itemsToShow = 4;
        }
      }
    }
  }
</script>

<style>
  .famous-servers-row {
    margin-top: 0.75em;
  }
  .carousel-card {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    border: 0px solid rgba(0, 0, 0, 0.125);
  }


  .carousel-slide {
    padding-right: 1em;
  }

  .famous-server-col{

  }

  @media (max-width: 992px)  {
    .carousel-slide {
      padding-right: 0.75em;
    }

  }

  @media (max-width: 768px)  {
    .famous-server-col{
      padding: 6px;
    }
    .carousel-slide {
      padding-right: 0.5em;
    }

  }

</style>
