import Quill from "quill";
import Emitter from "quill/core/emitter";
// import Editor from 'quill/core/editor';
import Vue from 'vue'

const Embed = Quill.import("blots/embed");
//const Keyboard = Quill.import('modules/keyboard');

class MentionBlot extends Embed {
    static create(data) {
        const node = super.create();
        const denotationChar = document.createElement("span");
        denotationChar.className = "ql-mention-denotation-char";
        denotationChar.innerHTML = data.denotationChar;
        node.appendChild(denotationChar);
        node.innerHTML += data.value;
        return MentionBlot.setDataValues(node, data);
    }

    static setDataValues(element, data) {
        const domNode = element;
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key];
        });
        return domNode;
    }

    static value(domNode) {
        return domNode.dataset;
    }
}

MentionBlot.blotName = "mention";
MentionBlot.tagName = "span";
MentionBlot.className = "mention";

Quill.register(MentionBlot);


export default class AtPeople {
    constructor(quill, options = {}) {

        this.quill = quill;
        this.options = options;
        this.mentionDom = null;
        this.mentionCharPos = 0;
        this.cursorIndex = 0;
        this.optionsList = null;

        this.onClose = options.onClose;
        this.onOpen = options.onOpen;

        //成员列表的DOM
        this.atMerberListDom = null;
        this.nodeList = null;
        this.totalLen = 0;

        // quill.keyboard.addBinding({ key: 13 }, {
        //     empty: false,    // implies collapsed: true and offset: 0
        //     format: ['list']
        // }, function(range, context) {
        //     console.log('enter pressed');
        //     //this.quill.format('list', false);
        // });

        if (options.list && (options.list.mentions.length || options.list.hashtags.length) ) {
            //@成员列表初始化
           console.log('constructor', Vue.prototype.$imageurl);
            this.createMentionContainer();
            // quill.keyboard.bindings[13].unshift({
            //     key: 13,
            //     handler: (range, context) => {
            //         //console.log('enter');
            //     }
            // });
            quill.on("text-change", this.onTextChange.bind(this));
            quill.on("selection-change", (e)=> {
                // console.log("selection-change", e);
                this.onTextChange.bind(this);
            });
        }
    }

    createMentionContainer() {
        // let divNode = document.createElement('div');
        let divNode = document.getElementById('mentions-list');
        divNode.className = 'quill-at-member';
        divNode.style.display = "none";
        // divNode.id = "mentions-list";
        this.mentionDom = divNode;
        // let mentionList = document.createElement("ul");
        // mentionList.id = "mentions-list-scroll";
        this.mentionList = document.getElementById('mentions-list-scroll');
        // this.mentionDom.appendChild(this.mentionList);
        this.quill.container.appendChild(divNode);
        this.quill.container.addEventListener('keydown', this.atPeople.bind(this), false);
    }

    /**
     * 生成列表
     * @param data
     */
    renderList(list, searchKey, mentionChar) {
        let data;
        if (mentionChar === "@") {
            data = list.mentions;
            console.log('mentionChar @', data);
        } else {
            data = list.hashtags;
            console.log('mentionChar #', data);
        }

        data = data.filter(item => {
            // Stefanos Added Lowercase
            return item.name.toLowerCase().includes(searchKey.toLowerCase());
        }) || [];

        if (data && data.length) {
            this.optionsList = data;
            this.totalLen = data.length;
            this.mentionList.innerHTML = "";
            let fragment = document.createDocumentFragment();

            for (let i = 0; i < data.length; i++) {
                let li = document.createElement('li');
                // let avatar = document.createElement('img');
                // avatar.classList = 'avatar-xs';
                li.classList = 'mentions-popup-item';
                li.dataset.index = i;
                li.dataset.value = data[i].name;
                li.dataset.denotationChar = mentionChar;
                li.setAttribute('data-member', JSON.stringify(data[i]));
                const imgUrl = (data[i].image) ?  Vue.prototype.$imageurl + '/' + data[i].image : Vue.prototype.$userDefaultImage ;
                li.innerHTML = `<span class="mentions-list-item-avatar" style="background-image: url('${imgUrl}')"></span><span class="mentions-list-item-title">${data[i].name}</span>`;
                li.onclick = this.onItemClick.bind(this);
                fragment.appendChild(li);
            }
            this.nodeList = fragment
            this.mentionList.appendChild(fragment);
            this.nodeList = this.mentionList.getElementsByTagName('li');
            this.itemIndex = 0;
            this.highlightItem();
            this.showMentionList();
        } else {
            this.hideMentionList()
        }
    }

    showMentionList() {
        this.mentionDom.style.display = '';
        this.updateMentionDomPos();
        // if (this.onOpen) {
        //     this.onOpen('open');
        // }
        // TODO:显示mentionList回调
    }

    // update mentionDom position
    updateMentionDomPos() {
        const mentionCharPos = this.quill.getBounds(this.mentionCharPos);
        this.mentionDom.style.top = mentionCharPos.top + 10 + 'px';
        this.mentionDom.style.left = mentionCharPos.left + 10 + 'px';
    }

    hideMentionList() {
        this.mentionDom.style.display = "none";
        this.mentionList.innerHTML = '';
        // if (this.onClose) {
        //     this.onClose('closed hideMentionList');
        // }
        // TODO: 隐藏mentionList回调
    }

    getTextBeforeCursor() {
        const startPos = Math.max(0, this.cursorIndex - 31);
        const textBeforeCursorPos = this.quill.getText(startPos, this.cursorIndex - startPos)
        return textBeforeCursorPos
    }

    getMentionCharIndex(text, mentionDenotationChars) {
        return mentionDenotationChars.reduce((prev, mentionChar) => {
            const mentionCharIndex = text.lastIndexOf(mentionChar);
            if (mentionCharIndex > prev.mentionCharIndex) {
                return {
                    mentionChar,
                    mentionCharIndex
                };
            }
            return {
                mentionChar: prev.mentionChar,
                mentionCharIndex: prev.mentionCharIndex
            };
        }, {mentionChar: null, mentionCharIndex: -1})
    }

    hasValidMentionCharIndex(mentionCharIndex, text, isolateChar) {
        if (mentionCharIndex > -1) {
            console.log('hasValidMentionCharIndex3333', isolateChar,
                !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g)))
            if (isolateChar &&
                !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))) {
                return false
            }
            return true
        }
        return false
    }

    hasValidChars(text, allowedChars) {
        return allowedChars.test(text);
    }

    onTextChange() {
        const range = this.quill.getSelection();
        if (range === null) return;
        this.cursorIndex = range.index;
        //  判断是否
        const textBeforeCursor = this.getTextBeforeCursor();
        //console.log('getTextBeforeCursor', textBeforeCursor, this.options)
        const {mentionChar, mentionCharIndex} =
            this.getMentionCharIndex(textBeforeCursor, this.options.mentionDenotationChars)
        //console.log('mentionChar:', mentionChar, 'mentionCharIndex:', mentionCharIndex);

        if (this.hasValidMentionCharIndex(mentionCharIndex, textBeforeCursor, this.options.isolateCharacter)) {
            const mentionCharPos = this.cursorIndex - (textBeforeCursor.length - mentionCharIndex);
            //console.log('mentionCharPos', mentionCharPos);

            this.mentionCharPos = mentionCharPos;

            const textAfter = textBeforeCursor.substring(mentionCharIndex + mentionChar.length);

            if (textAfter.length >= this.options.minChars && this.hasValidChars(textAfter, this.options.allowedChars)) {
                this.renderList(this.options.list, textAfter, mentionChar);
            } else {
                this.hideMentionList();
            }
        } else {
            this.hideMentionList();
        }
    }

    highlightItem() {
        let itemList = this.mentionList.childNodes;
        for (let i = 0; i < itemList.length; i++) {
            itemList[i].classList.remove('selected');
        }
        itemList[this.itemIndex].classList.add("selected");
        // TODO: 超过高度：滚动优化
    }

    insertItem(data) {
        const render = data;
        if (render === null) {
            return
        }
        const prevMentionCharPos = this.mentionCharPos;

        this.quill.deleteText(this.mentionCharPos,
            this.cursorIndex - this.mentionCharPos,
            Quill.sources.USER);

        this.quill.insertEmbed(
            prevMentionCharPos,
            this.options.blotName,
            render,
            Quill.sources.USER
        );

        if (this.options.spaceAfterInsert) {
            this.quill.insertText(prevMentionCharPos + 1, ' ', Quill.sources.USER);
            this.quill.setSelection(prevMentionCharPos + 2, Quill.sources.USER);
        } else {
            this.quill.setSelection(prevMentionCharPos + 1, Quill.sources.USER);
        }

        this.mentionDom.style.display = "none";
        // if (this.onClose) {
        //      this.onClose('closed insertItem');
        // }


    }

    // insertEmbed(index, embed, value, source = Quill.sources.API) {
    //     return modify.call(this, () => {
    //         return this.editor.insertEmbed(index, embed, value);
    //     }, source, index);
    // }

    getItemData() {
        const {link} = this.mentionList.childNodes[this.itemIndex].dataset;
        const hasLinkValue = typeof link !== "undefined";
        const itemTarget = this.mentionList.childNodes[this.itemIndex].dataset
            .target;
        if (hasLinkValue) {
            this.mentionList.childNodes[
                this.itemIndex
                ].dataset.value = `<a href="${link}" target=${itemTarget ||
            this.options.linkTarget}>${
                this.mentionList.childNodes[this.itemIndex].dataset.value
            }`;
        }
        return this.mentionList.childNodes[this.itemIndex].dataset;
    }

    selectItem() {
        const data = this.getItemData();
        this.insertItem(data);
    }

    onItemClick(e) {
        //console.log('onItemClick', e);
        e.preventDefault();
        e.stopImmediatePropagation();
        this.itemIndex = e.currentTarget.dataset.index;
        this.highlightItem();
        this.selectItem()
    }

    onEnterSelect(itemIndex) {
        this.itemIndex = itemIndex;
        this.highlightItem();
        this.selectItem();
    }

    /**
     * 获取成员列表的位置
     */
    getPosition(){
        if(!this.quill.getSelection(true)){
            return;
        }
        let length = this.quill.getSelection(true).index;
        this.cursorIndex = length;

        let top = this.quill.getBounds(length).top,
            left = this.quill.getBounds(length).left;
        this.mentionDom.style.top = top + 10 + 'px';
        this.mentionDom.style.left = left + 10 + 'px';
    }

    /**
     * 插入成员名称
     * @param item
     */
    insertMemberName(item){
        this.quill.insertText(this.cursorIndex + 1, '@' + item.name + ' ', {
            'color': '#356ccb'
        });
        this.quill.deleteText(this.cursorIndex, 1);
        this.quill.update();
        this.quill.setSelection(this.cursorIndex + item.name.length + 2);
        this.quill.format('color', '#2c3e50');

        this.mentionDom.style.display = 'none';
        this.index = 0;
    }

    atPeople(e){
        if (e.code == 'Digit2' && e.shiftKey) {
            // 获取成员列表的位置
            this.getPosition();
            //显示成员列表
            this.mentionDom.style.display = "block";
            // if (this.onOpen) {
            //     this.onOpen('open');
            // }
        } else {
            let selectedItem;
            if ((e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 13)
                && this.mentionDom.style.display === '') {
                e.preventDefault();
                for (let i = 0; i < this.totalLen; i++) {
                    this.nodeList[i].classList.remove('selected');
                }
                switch (e.keyCode) {
                    case 38://up
                        this.index--;
                        if (this.index < 0) {
                            this.index = this.totalLen - 1
                        }
                        break;
                    case 40://down
                        this.index++;
                        if (this.index > this.totalLen - 1) {
                            this.index = 0
                        }
                        break;
                    case 13: //enter
                        selectedItem = this.optionsList[this.index];
                        //this.insertMemberName(selectedItem);
                        //console.log('enter', this.nodeList[this.index] );
                        //this.nodeList[this.index].click();
                        this.onEnterSelect(this.index);
                        if (this.options.atOneMemberAction) {
                            this.options.atOneMemberAction(selectedItem);
                        }
                        break;
                }
                this.commonPart(this.index);
                this.nodeList[this.index].classList.add('selected');
            } else {
                this.mentionDom.style.display = 'none';
                this.index = 0;
                // if (this.onClose) {
                //     this.onClose('closed 2');
                // }
            }
        }

    }

    commonPart(i) {
        let itemEl = this.nodeList[i];
        let parentEl = itemEl.parentNode;
        let parentH = parentEl.offsetHeight;
        let positionTop = itemEl.offsetTop;
        let itemH = itemEl.offsetHeight;
        parentEl.scrollTop = itemH * itemEl.prevListAll().length - parentH + itemH;
    }


}

HTMLElement.prototype.prevListAll = HTMLElement.prototype.prevListAll || function () {
    var _parent = this.parentElement;
    var _child = _parent.children;
    var arr = [];
    for (var i = 0; i < _child.length; i++) {
        var _childI = _child[i];
        if (_childI == this) {
            break;
        }
        arr.push(_childI);
    }
    return arr;
};


// Handle selection preservation and TEXT_CHANGE emission
// common to modification APIs
// function modify(modifier, source, index, shift) {
//     if (this.options.strict && !this.isEnabled() && source === Emitter.sources.USER) {
//         return new Delta();
//     }
//     let range = index == null ? null : this.getSelection();
//     let oldDelta = this.editor.delta;
//     let change = modifier();
//     if (range != null) {
//         if (index === true) index = range.index;
//         if (shift == null) {
//             range = shiftRange(range, change, source);
//         } else if (shift !== 0) {
//             range = shiftRange(range, index, shift, source);
//         }
//         this.setSelection(range, Emitter.sources.SILENT);
//     }
//     if (change.length() > 0) {
//         let args = [Emitter.events.TEXT_CHANGE, change, oldDelta, source];
//         this.emitter.emit(Emitter.events.EDITOR_CHANGE, ...args);
//         if (source !== Emitter.sources.SILENT) {
//             this.emitter.emit(...args);
//         }
//     }
//     return change;
// }
