<template>
  <section id="streaming-tab">
    <div class="streaming-overlay" v-if="!liveNowStreamers || (liveNowStreamers && !liveNowStreamers.length)">
      <div class="streaming-overlay-center">
        <h1>N<fa-icon style="margin-bottom: 2px;margin-left: 1.5px; font-size: inherit;" icon="exclamation-circle" slot="trigger" :size="$faDefaultIconSize" /></h1>
        <h1>STREAMING</h1>
        <h1>AVAILABLE</h1>
      </div>
    </div>
    <CameraPlayerNew ></CameraPlayerNew>
    <div id="now-live-streamers" v-if="liveNowStreamers && liveNowStreamers.length">
      <!--div class="streaming-list-header" >
        <h1>Select Streaming</h1>
      </div-->
      <vuescroll :ops="ops">
        <div class="streamers owner"
             v-if="myBroadcasting && myStreamingLive">
          <StreamerOwnerSingle :streamer="myBroadcasting" ></StreamerOwnerSingle>
        </div>
        <!--div class="streamers-wrapper" v-if="!myStreamingLive"-->
          <div
              class="streamers"
              :class="{
                'hidden' : streamer._id === userDataAfterLogIn.broadcast,
                'chosen' : selectedStream && selectedStream._id === streamer._id
              }"
              v-for="streamer in liveNowStreamers"
              :key="streamer._id"
          >
            <StreamerSingle v-if="streamer._id !== userDataAfterLogIn.broadcast" :streamer="streamer" ></StreamerSingle>
         </div>
      <!--/div-->
      </vuescroll>
    </div>
  </section>
</template>

<script>
  import vuescroll from 'vuescroll';
  import CameraPlayerNew from '../MediaPlayers/CameraPlayerNew';
  import StreamerSingle from './StreamerSingle';
  import StreamerOwnerSingle from './StreamerOwnerSingle';
  import axios from "@/axios";


export default {
  name: "StreamTab",
  components: {
    CameraPlayerNew,
    vuescroll,
    StreamerSingle,
    StreamerOwnerSingle
  },
  data(){
    return{
      connection: null,
      streamId: null,
      streamingChosen: null,
      streamToWatch: null,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {}
      },
    }
  },

  watch: {
    liveNowStreamers(val){
      console.log('^^^^ liveNowStreamers watch ^^^^ ', val);
    },
    selectedStream(newval, oldval) {
      console.log('selectedStream watch at TABS', newval, oldval);
    },
  },
  computed: {
    liveNowStreamers() {
      return this.$store.getters.get_liveNowStreamers
    },
    selectedStream() {
      return this.$store.getters.get_selectedStream;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    myBroadcasting() {
      return this.$store.getters.get_myBroadcasting;
    },
    myStreamingLive() {
      return this.$store.getters.get_myStreamingLive;
    },
    cameraOpened(){
      if(this.$store.getters.get_cameraOpen) {
        return true
      }else{
        return false
      }
    },
  },
  created() {

  },
  methods: {
    joinStream(stream) {
      //this.$store.commit('set_selectedStream', stream);
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return  this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },
  }

}
</script>

<style lang="scss">
#streaming-tab {

  width: 100%;
  padding: 0;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;

}

.streaming-overlay {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: relative;
  overflow: hidden;
  /**display: table;*/
  /**
  padding: 1em;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  */
}

.streaming-overlay-center {
  /**display: table-cell;
  vertical-align: middle;*/
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
}


.streaming-overlay-center h1 {
  text-align: center;
  width: 100%;
  font-family: 'Product Sans',  sans-serif;
  font-size: 24px;
  color : #5c6064;
  /**font-weight: bold;*/
  letter-spacing: 0.25px;

}


.streaming-overlay-center img {
  width: 100%;
  height: auto;
  max-height: 250px;
}

.streaming-list-header {
  padding: 1em;
}


.streaming-list-header  h1 {
  text-align: center;
  width: 100%;
  font-family: 'Product Sans',  sans-serif;
  font-size: 24px;
  color : #5c6064;
  /**font-weight: bold;*/
  letter-spacing: 0.25px;

}


.streamers {
  padding: 6px ;
  list-style: none;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}


.streamers.hidden {
  display: none;
}

.streamers.chosen {
  background-color: #303538;
}


.streamers.owner {
  background-color: #202428;
}


.streamers a {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

#now-live-streamers {
  height: 50%;
  max-height: 50%;
  overflow: hidden;
}

/**** Children */

.streamer-single {
  width: 100%;
  display: flex;
  position: relative;
  /**
  height: 90px;
  max-height: 90px;*/
}

.streamer-single.istream {

}

.streamer-single-overlay {
  position: absolute;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.streamer {
  /** display: table-cell;*/
}


.streamer-username {
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 9px;
  min-width: 100px;
}

.streamer-image {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  margin: 0 9px 0 0;
  padding: 8px 8px 8px 9px;
  border-radius: 50%;
  background-color: #5f5bfc;
  background-position: center;
  background-size: cover;
  display: table-cell;
}
.streamer-button {
  margin: 0 12px;
}



.video-thumb {
  height: 90px;
  max-height: 90px;
  width: 30%;
  max-width: 30%;
  display: inline-block;
  background-position: center;
  background-size: cover;
  border: 1px solid #373737;
}

.video-thumb a {
  height: 100%;
  width: 100%;
  display: block !important;
}


.video-info {
  display: inline-block;
  width: 60%;
  max-width: 60%;
  padding: 0 8px;
  position: relative;
}

.video-info p {
  line-height: 1;
}

.video-info a {
  color: #7957d5;
}

.video-info a:hover {
  color: #7c7c7c;
  text-decoration: none;
}

.video-info-title {
  font-size: 103%;
  margin: 3px 0;
}

.video-info-user {
  position: absolute;
  bottom: 3px;
}

.video-info-desc {
  font-size: 90%;
  color: #7c7c7c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.video-info-metrics {
  margin-top: 5px;
  padding-left: 3px;
  display: table;
}

.video-info-metrics .fa-icon,
.video-info-metrics span {
  display: table-cell;
  color: #606060;
  vertical-align: middle;
  margin-right: 5px;
}


.video-misc {
  display: inline-block;
  /**width:  calc(100% - 360px);*/
  width: 10%;
  max-width: 10%;
}

.toolip-popper.streamer-desc {
  width: 240px;
  height: 240px;
  max-width: 240px;
  overflow: hidden;
  word-wrap: anywhere;
  white-space: normal;

  display: none;




}



</style>
