var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-modal',{attrs:{"id":"server-settings","on-cancel":_vm.handleDeselect},model:{value:(_vm.showServerSettingsModal),callback:function ($$v) {_vm.showServerSettingsModal=$$v},expression:"showServerSettingsModal"}},[_c('h2',{staticClass:"modal-title medium"},[_vm._v("Server Settings")]),_c('ValidationObserver',{ref:"serverUpdateFormValidator",staticClass:"form-observer",staticStyle:{"display":"flex","flex-wrap":"wrap"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"columns",attrs:{"id":"update-server-form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleServerSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"column is-three-fifths"},[_c('ValidationProvider',{staticClass:"validation-field",attrs:{"tag":"div","name":"name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"form-field",class:{ 'is-danger': errors[0], 'is-success': valid },attrs:{"label":"Server Name","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{staticClass:"signup-input",attrs:{"vid":"name","readonly":_vm.loading},model:{value:(_vm.servername),callback:function ($$v) {_vm.servername=$$v},expression:"servername"}})],1),(errors && errors[0])?_c('p',{staticClass:"help field-help",class:{ 'is-danger': errors[0], 'is-success': valid }},[_vm._v(_vm._s(errors[0]))]):_c('p',{staticClass:"help field-help"},[_vm._v(" ")])]}}],null,true)}),_c('ValidationProvider',{staticClass:"validation-field",attrs:{"tag":"div","name":"Description","rules":"required|min:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"form-field",class:{ 'is-danger': errors[0], 'is-success': valid },attrs:{"label":"Description","type":{ 'is-danger': errors[0], 'is-success': valid }}},[_c('b-input',{staticClass:"signup-input",attrs:{"vid":"description","readonly":_vm.loading},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),(errors && errors[0])?_c('p',{staticClass:"help field-help",class:{ 'is-danger': errors[0], 'is-success': valid }},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"column is-two-fifths"},[_c('EditServerImageUpload',{attrs:{"tempImage":_vm.tempImage,"uploading":_vm.uploading,"imageUploaded":_vm.imageUploaded,"server":_vm.server,"servername":_vm.servername,"serverUploadedImage":_vm.serverUploadedImage},on:{"previewImageUpdate":_vm.previewImageUpdate}})],1)]),_c('footer',{staticClass:"footer",attrs:{"id":"server-settings-footer"}},[_c('b-button',{staticStyle:{"padding":"8px 24px","border-radius":"4px"},attrs:{"size":"is-small","loading":_vm.loading,"disabled":invalid,"type":"is-primary"},on:{"click":_vm.handleServerSubmit}},[_vm._v("Done")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }