export default {
  get_rooms: state => state.rooms,
  get_selectedRoom: state => state.selectedRoom,
  get_selectedServerRooms: state => state.selectedServerRooms,
  // get_PrivateRooms: state => state.privateRooms,
  get_privateSelectedRoom: state => state.privateSelectedRoom,
  get_privateChatWithMember: state => state.privateChatWithMember,
  get_openPrivateChat: state => state.openPrivateChat,
  get_openPrivateChatList: state => state.openPrivateChatList,
  get_messagesWithPagination: state => state.messagesPagination,
  get_nextCursor: state => state.nextCursor,
  get_flag: state => state.flag,
  get_showLoadMoreMessages: state => state.showLoadMoreMessages,
  get_messagesWithPaginationPrivate: state => state.messagesPaginationPrivate,
  get_nextCursorPrivate: state => state.nextCursorPrivate,
  get_flagPrivate: state => state.flagPrivate,
  get_showLoadMoreMessagesPrivate: state => state.showLoadMoreMessagesPrivate,
  get_selectedRoomMessages: state => state.selectedRoomMessages,
  get_selectedRoomHashtags: state => state.selectedRoomHashtags,
  get_selectedMessage: state => state.selectedMessage,
  get_autoSelect: state => state.autoSelect,
  get_previousMessagesIndex: state => state.previousMessagesIndex,
  get_nextMessagesIndex: state => state.nextMessagesIndex,
  get_selectedRoomGroups: state => state.selectedRoomGroups,
  get_previousPrivateMessagesIndex: state => state.previousPrivateMessagesIndex,
  get_nextPrivateMessagesIndex: state => state.nextPrivateMessagesIndex,
  get_selectedPrivateRoomGroups: state => state.selectedPrivateRoomGroups,
  get_selectedPrivateRoomMessages: state => state.selectedPrivateRoomMessages,
  get_privateMessageFocus: state => state.privateMessageFocus,
  get_privateRooms: state => state.privateRooms,
}
