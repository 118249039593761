import broadcastingActions from './actions';
import broadcastingMutations from './mutations';
import broadcastingGetters from './getters';

export default {
  state: {
    streamId:'',
    RoomsForStreaming:[],
    ServersForStreaming:[],
    ServersAndRoomsForStreaming:[],
    cameraOpen:false,
    connectionInfo:null,
    liveNowStreamers: null,
    selectedStream: null,
    myStreamingLive: false,
    myBroadcasting: null,
    allowedBroadcasts: null,
    // serversStreamEnd:[],
    // roomsStreamEnd:[],
    // roomsStreamStart:[],
    // serversStreamStart:[],
    OwnerFirstBroadcastCreation:false


  },
  getters: broadcastingGetters,
  mutations: broadcastingMutations,
  actions: broadcastingActions
}
