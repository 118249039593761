import roomsGetters from "./getters";
import roomsMutations from "./mutations";
import roomsActions from "./actions";

export default {
    state: {
        rooms: [],
        selectedRoom: {},
        selectedServerRooms: null,
        selectedRoomMessages: [],
        selectedRoomGroups: [],
        selectedRoomHashtags: null,
        selectedMessage: null,
        autoSelect: null,
        previousMessagesIndex: null,
        nextMessagesIndex: null,
        // list:[],
        // openMessageEdit:false
        // privateRooms:[],
        privateSelectedRoom: {},
        privateChatWithMember: {},
        previousPrivateMessagesIndex: null,
        nextPrivateMessagesIndex: null,
        selectedPrivateRoomMessages: [],
        selectedPrivateRoomGroups: [],
        openPrivateChat: false,
        openPrivateChatList: false,
        privateMessageFocus: null,
        privateRooms: [],
        messagesPagination: [],
        nextCursor: "",
        flag: 1,
        showLoadMoreMessages: true,
        messagesPaginationPrivate: [],
        nextCursorPrivate: "",
        flagPrivate: 1,
        showLoadMoreMessagesPrivate: true,
    },
    getters: roomsGetters,
    mutations: roomsMutations,
    actions: roomsActions
}
