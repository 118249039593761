<template>
  <section>
    <div class="category-left">
      <div class="cata-img" :style="{ 'background-image': `url(${setThumb()})` }">
        <img :src="placeholder" alt="Category">
      </div>
      <div class="cata-content">
        <p @click="goToCategory">{{ category.name }}</p>
        <span>IRL</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeCategoryYouLike",
  props: ['category'],
  computed: {
    categoryDefault() {
      return require('@/assets/img/profit-category-default.png');
    },
    placeholder() {
      return require('@/assets/img/profit-category-placeholder.png');
    },
  },
  created() {

  },
  methods: {
    setThumb() {
      if (this.category.icon && this.category.icon.length) {
        if ('http' === this.category.icon.slice(0, 4)) {
          return this.category.icon;
        } else {
          if ('category' === this.category.icon.slice(0, 8)) {
            return this.$imageurl + '/' + this.category.icon;
          } else {
            return this.categoryDefault;
          }
        }
      }
      return this.categoryDefault;
    },
    goToCategory() {
      console.log('category: ', this.category)
      this.$router.push({name: 'Browse', params: {category: this.category}});
    }
  }

}
</script>

<style scoped>

.cata-img {
  background-position: center;
  background-size: cover;
}

.cata-img img {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 10px;

}

.cata-content {
  padding: 0.75em;
}

.cata-content p {
  margin-bottom: 0;
  color: #fff;
}

.cata-content p:hover {
  margin-bottom: 0;
  cursor: pointer;
  color: rgb(103, 76, 244);
}

.cata-content span {
  display: inline-block;
  border-radius: 20px;
  background: rgb(103, 76, 244);
  background: linear-gradient(90deg, rgba(103, 76, 244, 1) 23%, rgba(160, 131, 250, 1) 100%);
  padding: 1px 14px;
  font-size: 13px;
  color: #fff;
}

.category-left {
  margin-top: 25px;
}
</style>
