<template>
  <section>
  <div v-if="latestServers.length" class="row  boot-row latest-servers-row">
        <b-carousel-list
            id="home-latest-carousel"
            :data="latestServers"
            :items-to-show="itemsToShow"
            :arrow="arrow"
            :items-to-list="increment"
            :repeat="repeat"
            :has-drag="drag"
        >
          <template slot="item" slot-scope="list">
            <div class="card carousel-card">
              <BrowseServersChild :isUserInServer="isUserInServer(latestServers[list.index]._id)" :server = "latestServers[list.index]"></BrowseServersChild>
            </div>
          </template>
        </b-carousel-list>
  </div>
  </section>
</template>

<script>
  import HomeLatestServersChild from "./HomeCore/HomeLatestServersChild";
  import axios from "@/axios";
  import BrowseServersChild from "./BrowseCore/BrowseServersChild";
    export default {
        name: "HomeLatestServersParent",
        components:{
          BrowseServersChild,
        },
      data() {
        return {
          Rooms:[],
          itemsToShow: 3,
          arrow: true,
          arrowHover: true,
          drag: true,
          gray: false,
          opacity: false,
          values: 1,
          increment: 2,
          repeat: true,
          animated: true,
          latestServers: [],
          userServersIds: [],

        }
      },
      async created(){
        if (this.userDataAfterLogIn && this.userDataAfterLogIn._id && this.userServers) {
          this.userServersIds =  Object.keys(this.userServers);
        }
        this.fetchLatest();

        this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'set_latestServers') {
            this.fetchLatest();
          }
          if (mutation.type === 'set_connectedToSocket') {
            if (this.userDataAfterLogIn && this.userDataAfterLogIn._id && this.userServers) {
              this.userServersIds =  Object.keys(this.userServers);
            }
          }
        });

      },
      mounted() {
        // await this.$store.dispatch('allServers');
        this.setCarouselCols();
        this.$nextTick(()=>{
          window.addEventListener('resize', this.setCarouselCols);
        });
      },
      methods:{
        isUserInServer(server_id) {
          if (this.userServersIds.length) {

            if (this.userServersIds.includes(server_id)) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        },
        async fetchLatest() {
          try {
            let response = await axios.get('/servers/latest/8'); // To be changed

            if (response.status === 200 && response.data) {
              this.latestServers = response.data;
            }
          }
          catch (error) {
            console.log(error);
            return error;
          }
        },
        setCarouselCols() {
          if (window.innerWidth > 1280) {
            this.itemsToShow = 4;
          } else if (window.innerWidth < 992 && window.innerWidth > 480) {
            this.itemsToShow = 2;
          } else if (window.innerWidth <= 480) {
            this.itemsToShow = 1;
          } else {
            this.itemsToShow = 3;
          }
        }
      },
      computed:{
        userServers() {
          return this.$store.getters.get_userServers;
        },
        userDataAfterLogIn() {
          return this.$store.getters.get_userDataAfterLogIn;
        },
        isAuthenticated() {
          return this.$store.getters.isAuthenticated;
        },
        latestServers1(){
            let servers = JSON.parse(JSON.stringify(this.$store.getters.get_servers));

            servers.forEach((server) => {
              if(server.rooms.length !== 0 ){
                JSON.parse(JSON.stringify(server))
                // sort the rooms of the same server in order to take the the first room which create to the server
                server.rooms.sort((a, b) => {
                  const date1 = a.created_at;
                  const date2 = b.created_at;

                  if (date1 > date2) return -1;
                  if (date1 < date2) return 1;
                  return 0;
                });
                this.Rooms.push({
                  latestRoomServerDate: server.rooms[0].created_at,
                  latestRoomServerName: server.name,
                  latestRoomServerImage: server.image,
                  latestRoomServerOwner: server.owner.username,
                  latestRoomServerOwnerImage: server.owner.image,
                  latestRoomId: server.rooms[0]._id
                })
              }

            })
            // filter this.Rooms array to have unique values
            let  resArr = []
            this.Rooms.forEach(function(item){
              let i = resArr.findIndex(x => x.latestRoomId == item.latestRoomId);
              if(i <= -1){
                resArr.push({
                  latestRoomId: item.latestRoomId,
                  latestRoomServerDate: item.latestRoomServerDate,
                  latestRoomServerName: item.latestRoomServerName,
                  latestRoomServerImage: item.latestRoomServerImage,
                  latestRoomServerOwner: item.latestRoomServerOwner,
                  latestRoomServerOwnerImage: item.latestRoomServerOwnerImage

                });
              }
            });
            return resArr.slice(0, 8);

          },
        },
    }
</script>

<style>
  .latest-servers-row {
    margin-top: 0.75em;
  }

  .carousel-slide {
    padding-right: 1em;
  }
  .carousel-card {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0), 0 0px 0 1px rgba(10, 10, 10, 0);
    border: 0px solid rgba(0, 0, 0, 0.125);
  }
  .latest-server-col {
    display: inline-block;
  }

  @media (max-width: 768px)  {
    .latest-server-col{
      padding: 6px;
    }
    .carousel-slide {
      padding-right: 0.75em;
    }
  }

</style>
