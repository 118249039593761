<template>
  <section id="mini-profile">
    <div class="mini-profile-top"
         :style="{ 'background-image': `url(${setAvatar()})`}"
    >
      <!--p class="mini-profile-image"><img  :src="setAvatar()" /></p-->
      <b-button @click="openPrivate" class="pm-user">Message</b-button>
    </div>
    <p
        class="mini-profile-user"
        @click="goToUserProfile"
        style="cursor: pointer;"
    >
      {{ user.user.username }}
    </p>
    <p class="mini-profile-date">
      <span>Member Since: </span><span v-if="loadedUser && loadedUser.created_at"
                                       style="color: #ffffff; font-size: 15px;padding-left: 5px;">{{ loadedUser.created_at | moment("MMMM YYYY") }}</span>
    </p>
    <p class="mini-profile-misc">
      <span>Servers: </span>
      <span v-if="userServers && !userServers.length"
            style="color: #ffffff; font-size: 15px;padding-left: 5px;"
      >No Servers</span>
      <b-dropdown class="mini-profile-servers" v-if="userServers && userServers.length"
                  :triggers="['click']" aria-role="list" position="is-top-right">
        <!--b-icon style="color: #4a4a4a;" icon="dots-vertical" slot="trigger"></b-icon-->
        <span slot="trigger" style="color: #ffffff; font-size: 15px;padding-left: 5px;">{{ userServers.length }}</span>
        <b-dropdown-item :value="true" aria-role="listitem">
          <b-button
              v-for="server in userServers"
              :key="server._id"
              class="button no-hover"
              expanded
              readonly="true"
          >{{ server.name }}
          </b-button>
        </b-dropdown-item>
      </b-dropdown>
    </p>
  </section>
</template>

<script>
import axios from "@/axios";

export default {
  name: "MiniProfileDiv",
  props: ['user'],
  data() {
    return {
      showMe: true,
      loadedUser: null,
      userServers: null
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      let response = await axios.get(`/users/${this.user.user._id}`);
      if (response.data && response.data._id) {
        this.loadedUser = response.data;
        if (response.data.servers && response.data.servers.length) {
          let userServers = [];
          response.data.servers.forEach((server) => {
            if (server.owner === response.data._id) {
              userServers.push(server);
            }
          });
          this.userServers = (userServers.length) ? userServers : [];
        }

      }
    },
    openPrivate() {
      this.$emit('openPrivate', this.user);
    },
    handleClose() {
      this.$parent.profileModalUser = null;
    },
    setAvatar() {
      let image = this.user.user.image;
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },
    goToUserProfile() {
      this.$router.push(`/user-profile/${this.user.user.username.toLowerCase()}`)
    },
  }
}
</script>

<style>


#mini-profile.modal .modal-content {
  /**box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.55);*/
}

.mini-profile-top {
  width: 100%;
  height: 180px;
  position: relative;
  background-position: center;
  background-size: cover;
}


.mini-profile-image {
  width: 100%;
  text-align: center;
  margin: 40px 0;
  display: table;
}

.mini-profile-user {
  width: 100%;
  font-size: 17px;
  border-bottom: solid 1px rgba(236, 232, 232, 0.04);
  background-color: #141319;
  padding: 12px;
}

.mini-profile-date {
  width: 100%;
  font-size: 15px;
  border-bottom: solid 1px rgba(236, 232, 232, 0.04);
  background-color: #141319;
  padding: 12px;
  color: #afafaf;
}

.mini-profile-misc {
  width: 100%;
  font-size: 15px;
  border-bottom: solid 1px rgba(236, 232, 232, 0.04);
  background-color: #141319;
  padding: 15px 16px;
  color: #afafaf;
}

.mini-profile-servers .dropdown-menu {
  height: 240px;
  max-height: 240px;
  overflow: hidden;
  background-color: #23272a;
  margin-left: -60px;
}

.mini-profile-servers .dropdown-content {
  height: 100%;
  max-height: 100%;
  width: 230px;
  overflow: auto;
  background-color: transparent;
}

.mini-profile-servers .dropdown-content .dropdown-item,
.mini-profile-servers .dropdown-content .dropdown-item:hover {
  background-color: transparent;
  padding: 0;
}

.mini-profile-servers .dropdown-menu button {
  background-color: transparent;
  border-color: transparent;
  color: #7c7c7c;
  padding: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}


.mini-profile-servers .dropdown-menu button:hover {
  background-color: #303538;
}


.mini-profile-servers .dropdown-menu button span {


}

button.pm-user {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 231px;
  height: 36px;
  padding: 7px 90px 10px 82px;
  border-radius: 5px;
  border-color: #5f5bfc;
  background-color: #5f5bfc;
  color: #ffffff;

}

button.pm-user:hover {
  border-color: #5f5bfc;

}


</style>
