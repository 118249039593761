<template>
  <b-navbar :transparent="true"
            :fixed-top="false"
            id="navbar"
            class="header"
            :class="{
        'mobile' : window_width <= mobileBreakpoint,
        'desktop' : window_width > mobileBreakpoint,
        'search-active'  : showSearch
      }" >

    <template slot="start">
      <b-navbar-item tag="div" class="navbar-item-left">
        <b-navbar-item style="" class="logo" tag="router-link" :to="{ path: '/' }">
          <img :src="logo" />
        </b-navbar-item>
        <div class="header-link">
          <ul class="header-list-desktop">
            <li><router-link to="/browse">Browse</router-link></li>
          </ul>
        </div>
      </b-navbar-item>

      <b-navbar-item tag="div"
                     id="navbar-center"
                     class="navbar-item-center"
                     :class="{
                        'mobile' : window_width <= mobileBreakpoint,
                        'desktop' : window_width > mobileBreakpoint,
                        'search-active'  : showSearch
                      }"
      >
<!--        <div id="room-navbar" v-if="selectedRoom && selectedRoom._id">{{selectedRoom.name}}</div>-->
        <SearchBarNative
            id="navbar-search"
            :showSearch="showSearch"
        ></SearchBarNative>
      </b-navbar-item>

      <b-navbar-item tag="div" v-if="!isAuthenticated" class="navbar-item-right" >
        <b-button v-if="!isAuthenticated" type="is-dark" style="margin-right: 10px" @click="onLogIn">Log In</b-button>
        <b-button v-if="!isAuthenticated" type="is-primary" style="margin-right: 10px" @click="onSignUp">Sign Up</b-button>
        <!--        <b-button v-if="isAuthenticated" type="is-dark" style="margin-right: 10px" @click="onLogOut">Logout</b-button>-->
      </b-navbar-item>

      <b-navbar-item tag="div" v-if="isAuthenticated && userDataAfterLogIn">
        <b-button id="widget-toggle" class="navbar-button darker"  @click="toggleSidebar"
        ><img :src="widgetIcon" /></b-button>
      </b-navbar-item>

      <b-navbar-item tag="div" v-if="isAuthenticated && userDataAfterLogIn">
        <b-button id="no-live-button" class="navbar-button darker"
                  v-if="!liveNowStreamers"
                  :disabled="true"
        ><fa-icon icon="tv" class="navbar-button-inner-icon" :size="$faDefaultIconSize" />Now Live</b-button>

        <b-button id="now-live-button" class="navbar-button darker"  @click="openStreamList"
                  :class="{'has-live' : hasLive}"

                  v-if="liveNowStreamers && liveNowStreamers.length"
        ><fa-icon style="color: #ffffff;" icon="tv" class="navbar-button-inner-icon" :size="$faDefaultIconSize" />Now Live</b-button>
      </b-navbar-item>

      <b-navbar-item tag="div" v-if="isAuthenticated && userDataAfterLogIn && userServersIsOwner.length">
        <b-button id="go-live-button" class="navbar-button darker"
                  v-if="!myStreamingLive"
                  @click="toggleStream"
                  :class="{'is-streaming' : myStreamingLive}"
        ><fa-icon icon="video" class="navbar-button-inner-icon" :size="$faDefaultIconSize" /><span>Go Live</span></b-button>

        <b-button id="my-live-button" class="navbar-button darker"
                  v-if="myStreamingLive"
                  :class="{'is-streaming' : myStreamingLive}"
        >
          <fa-icon icon="video" class="navbar-button-inner-icon" :size="$faDefaultIconSize" />
          <span>Live</span>
        </b-button>
      </b-navbar-item>


      <b-navbar-item tag="div" v-if="isAuthenticated && userDataAfterLogIn" class="navbar-item-right">
        <b-dropdown :triggers="['hover', 'click']" aria-role="list" position="is-bottom-left"
                    id="main-navbar-profile-dropdown">
          <b-button class="button" slot="trigger"
                    style="background-position: center; background-size: cover; width: 40px; height: 40px; margin-right: 0.5em;"
                    v-bind:style="{ 'background-image': 'url(' + avatar + ')' }"
          >
          </b-button>
          <b-dropdown-item :value="true" aria-role="listitem">
            <b-button v-if="isAuthenticated && userDataAfterLogIn" id="settings-toggle" class="button"
                      @click="openUserSettings()"
                      icon-pack="fas"

            ><span style="display: none;">Settings</span></b-button>
            <b-button
                class="button"
                v-if="isAuthenticated && userDataAfterLogIn"
                @click="onUserProfile()"
                expanded
            >My Profile
            </b-button>
            <b-button
                class="button"
                @click="onLogOut()"
                expanded
            >Log Out
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </b-navbar-item>
    </template>

    <template slot="end">
    </template>
  </b-navbar>
</template>

<script>
import SearchBarNative from "@/components/Search/SearchBarNative";
import EventBus from "@/eventBus";

export default {
  name: "NavbarDesktop",
  components: {SearchBarNative},
  props:['window_width', 'mobileBreakpoint', 'avatar', 'logo'],
  data(){
    return{
      showSearch: false,
      hasLive: false,
      // mobileBreakpoint: 768
    }
  },
  computed:{
    isAuthenticated(){
      return this.$store.getters.isAuthenticated;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    selectedRoom(){
      return this.$store.getters.get_selectedRoom
    },
    widgetIcon () {
      return require('@/assets/icons/widget-toggle.svg');
    },
    openSidebar() {
      return this.$store.getters.get_openCloseSidebar;
    },
    liveNowStreamers(){
      return this.$store.getters.get_liveNowStreamers
    },
    myStreamingLive () {
      return this.$store.getters.get_myStreamingLive;
    },
    userServersIsOwner() {
      if (this.userDataAfterLogIn && this.userDataAfterLogIn.servers) {
        return  this.userDataAfterLogIn.servers.filter(server => server.owner === this.userDataAfterLogIn._id)
      }
      return [];
    },
  },
  methods: {
    onLogIn(){
      this.$store.commit('set_openCloseLogIn');
    },
    onSignUp(){
      this.$store.commit('set_openSignUp');
    },
    onLogOut(){
      this.$localStorage.remove('accessToken');
      this.$localStorage.remove('user');
      this.$localStorage.remove('userServers');
      this.$localStorage.remove('redirectAfterLogin');
      this.$store.dispatch('logOut');
      this.$store.commit('unset_privateRooms');
      if (this.$route.path !== '/')
        this.$router.replace('/');

      // window.location.reload();
      this.$iv.socket.disconnect();
    },
    openUserSettings() {
      this.$store.commit('set_openUserSettings');
    },
    onUserProfile() {
      this.$router.push(`/user-profile/${this.userDataAfterLogIn.username.toLowerCase()}`)
    },
    toggleSidebar() {
      if (this.openSidebar) {
        this.$store.dispatch('set_openCloseSidebar', 'close');
      } else {
        this.$store.dispatch('set_openCloseSidebar', 'open');
      }
    },
    openStreamList() {
      this.$store.dispatch('set_openCloseSidebar', 'open');
      EventBus.$emit('selectedTab', 'stream');
    },
    toggleStream() {
      //if (!this.cameraOpened) {
      this.$store.commit('set_openStreamOptions');
      //}
    },
  }

}
</script>

<style scoped>

</style>
