<template>
  <div id="chat-private" class="sidebar-tab-inner">
    <div id="private-chat-header" class="sidebar-header" >
       <b-button class="sidebar-button close darker"
                    style="position: absolute; left: 10px; background-color: transparent;"
                    @click="closeSidebar"
                  >
                    <img :src="closeSidebarIcon" style="" />
                  </b-button>
      <p v-if="privateChatWithMember.user">Chat With {{privateChatWithMember.user.username}}</p>
      <p style="font-size: 90%;" v-if="!privateChatWithMember.user">Choose User for Private Chat</p>
    </div>
    <div id="private-chat-window" class="chat-scroll"  v-if="openPrivateChat"
         :class="{'has-quote' : chatHasQuote}">
      <!--PrivateChatMessageInfoBubble v-if="showBubble"></PrivateChatMessageInfoBubble-->
      <PrivateChatMessageGroup/>
    </div>
    <div id="private-chat-bar" v-if="openPrivateChat" :class="{'has-quote' : chatHasQuote}">
<!--      <PrivateMessageBarComponent-->
<!--          :chatHasQuote="chatHasQuote"-->
<!--          @toggleQuote="toggleQuote"-->
<!--          v-if="privateRoom.privateRoomAccept ||-->
<!--          (userDataAfterLogIn._id === privateRoom.owner && this.$store.getters.get_messagesWithPaginationPrivate.length < 1)"-->
<!--      />-->
      <PrivateMessageBarComponent
          :chatHasQuote="chatHasQuote"
          @toggleQuote="toggleQuote"
      />
    </div>
  </div>
</template>
<script>

//import PrivateChatMessageInfoBubble from "./PrivateChatMessageInfoBubble";
import PrivateChatMessageGroup from "./PrivateChatMessageGroup";
import PrivateMessageBarComponent from "../PrivateMessageBarComponent";


export default {
  name: "privateChatTab",
  components: {
    PrivateChatMessageGroup,
    PrivateMessageBarComponent
  },
  computed:{
    openPrivateChat(){
      return this.$store.getters.get_openPrivateChat;
    },
    privateRoom(){
      return this.$store.getters.get_privateSelectedRoom
    },
    privateChatWithMember(){
      return this.$store.getters.get_privateChatWithMember
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    closeSidebarIcon () {
      return require('@/assets/icons/close-side.svg');
    },
  },
  data() {
    return {
      open: true,
      showBubble: false,
      expandOnHover: false,
      mobile: "reduce",
      reduce: false,
      right: true,
      chatHasQuote: false,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'set_openPrivateChat') {
        console.log('set_openPrivateChat', state.rooms.openPrivateChat);
        this.$store.dispatch('set_openCloseSidebar', 'open');
      }
    });
  },
  methods:{
    toggleQuote(val) {

    },
    closeSidebar() {
      this.$store.dispatch('set_openCloseSidebar', 'close');
    }
  }


}
</script>

<style>
  #chat-private {
    height: 100%;
    max-height: 100%;
    display: inline-block;
    width: 100%;
  }

  #private-chat-header {
    /**height: 6%;
    max-height: 6%;*/
    height: 50px;
    max-height: 50px;
    padding: 0.5em;
    overflow: hidden;
    text-align: center;
    background-color: #293035;
    display: table;
    width: 100%;
    max-width: 100%;
    color: #bcbcbc;
    position: relative;
  }
  #private-chat-header p {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }

  .sidebar-header  {
    padding: 0.5em;
    overflow: hidden;
    text-align: center;
    background-color: #293035;
    display: table;
    width: 100%;
    max-width: 100%;
    color: #bcbcbc;
   /** max-height: 40px;*/
  }

  .sidebar-header p  {
    display: table-cell;
    margin: 0;
    padding: 0;
    vertical-align: middle;
  }


  #private-chat-window {
    /**height: 85%;
    max-height: 85%;*/
    padding: 0 0.2em;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    /** calculate header too */
    height: calc(100% - 75px  - 40px);
    max-height: calc(100% - 75px - 40px );
  }

  #private-chat-window.has-quote {
    /** calculate header too */
    height: calc(100% - 110px - 40px );
    max-height: calc(100% - 110px - 40px );
  }

  #private-chat-bar {
    /**height: 9%;
    max-height: 9%;*/
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
  }

  #private-chat-bar.has-quote {

  }

  .sidebar-tab-inner {
    padding: 0 0 1.5em;
  }

</style>
