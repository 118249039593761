// import * as _ from 'lodash';
export default {

  CAMERA_STARTED(state) {
    console.log('CAMERA_STARTED MUTATION');
    // console.log('broadcastInfo.broadcast.broadcastServers' , broadcastInfo.broadcast.broadcastServers)
    // // if(state.serversStreamEnd.length !== 0){
    // //   broadcastInfo.broadcast.broadcastServers.forEach((serverId, key) =>{
    // //     let index = state.serversStreamEnd.indexOf(serverId);
    // //     if (index >= 0 ) {
    // //       state.serversStreamEnd.splice(index, 1);
    // //     }
    // //     //console.log('state.serversStreamEnd1 CAMERA_STARTED' , JSON.parse(JSON.stringify(state.serversStreamEnd)));
    // //     //  console.log('serverId',serverId)
    // //     //  JSON.parse(JSON.stringify(state.serversStreamEnd)).filter(serverEndId => serverEndId !== serverId)
    // //
    // //   });
    // // }
    // if(state.serversStreamEnd.length !== 0){
    //   broadcastInfo.broadcast.broadcastServers.forEach((serverId) => {
    //     state.serversStreamEnd  = _.filter(state.serversStreamEnd, function(n) {
    //       return n !== serverId;
    //     });
    //   })
    // }
    //
    // console.log('state.serversStreamEnd CAMERA_STARTED 2' , state.serversStreamEnd);
    // // if(state.roomsStreamEnd.length !== 0){
    // //   broadcastInfo.broadcast.broadcastRooms.forEach((roomId) =>{
    // //         //console.log('state.roomsStreamEnd CAMERA_STARTED4' , state.roomsStreamEnd);
    // //     let index = state.roomsStreamEnd.indexOf(roomId);
    // //     if (index >= 0 ) {
    // //       state.roomsStreamEnd.splice(index, 1);
    // //     }
    // //         //console.log('roomId',roomId)
    // //       //SON.parse(JSON.stringify(state.roomsStreamEnd)).filter(roomEndId => roomEndId !== roomId)
    // //   });
    // // }
    //
    // if(state.roomsStreamEnd.length !== 0){
    //   broadcastInfo.broadcast.broadcastServers.forEach((roomId) => {
    //     state.roomsStreamEnd  = _.filter(state.roomsStreamEnd, function(n) {
    //       return n !== roomId;
    //     });
    //   })
    // }
    //
    // console.log('state.roomsStreamEnd CAMERA_STARTED 3' , state.roomsStreamEnd);
    // state.serversStreamStart= [...new Set([...state.serversStreamStart , ...broadcastInfo.broadcast.broadcastServers])];
    // state.roomsStreamStart = [...new Set([...state.roomsStreamStart,...broadcastInfo.broadcast.broadcastRooms])];
    // console.log('@@@@ state.serversStreamEnd CAMERA_STARTED 3' , state.serversStreamEnd);
    // console.log('@@@@ state.roomsStreamEnd CAMERA_STARTED 4' , state.roomsStreamEnd);
    // console.log('@@@@ state.roomsStreamStart CAMERA_STARTED 5 ', state.roomsStreamStart);
    // console.log('@@@@ state.serversStreamStart CAMERA_STARTED 6 ',state.serversStreamStart)
  },

  CAMERA_ENDED(state, ) {
    console.log('CAMERA_ENDED MUTATION' );
    // // if(state.serversStreamStart.length !== 0){
    // //   broadcastInfo.broadcast.broadcastServers.forEach((serverId, key) => {
    // //       let index = state.serversStreamStart.indexOf(serverId);
    // //       if (index >= 0 ) {
    // //         state.serversStreamStart.splice(index, 1);
    // //       }
    // //   });
    // // }
    // if(state.serversStreamStart.length !== 0){
    //   broadcastInfo.broadcast.broadcastServers.forEach((serverId) => {
    //     state.serversStreamStart  = _.filter(state.serversStreamStart, function(n) {
    //       return n !== serverId;
    //     });
    //   })
    // }
    // console.log('state.serversStreamStart CAMERA_ENDED 2' , state.serversStreamStart);
    //
    //
    //
    // if(state.roomsStreamStart.length !== 0){
    //   // broadcastInfo.broadcast.broadcastRooms.forEach((roomId, key ) =>{
    //   //   let index = state.roomsStreamStart.indexOf(roomId);
    //   //   if (index >= 0 ) {
    //   //     state.roomsStreamStart.splice(index, 1);
    //   //   }
    //   // });
    //
    //   broadcastInfo.broadcast.broadcastServers.forEach((roomId) => {
    //     state.roomsStreamStart = _.filter( state.roomsStreamStart, function(n) {
    //       return n !== roomId;
    //     });
    //   })
    // }
    //
    // console.log('state.roomsStreamStart CAMERA_ENDED 3' , state.roomsStreamStart);
    //
    // state.serversStreamEnd= [...new Set([...state.serversStreamEnd , ...broadcastInfo.broadcast.broadcastServers])];
    // state.roomsStreamEnd = [...new Set([...state.roomsStreamEnd,...broadcastInfo.broadcast.broadcastRooms])];
    // console.log('#### state.serversStreamStart CAMERA_ENDED 4' , state.serversStreamStart);
    // console.log('#### state.roomsStreamStart CAMERA_ENDED 5' , state.roomsStreamStart);
    // console.log('#### state.roomsStreamEnd CAMERA_ENDED 6', state.roomsStreamEnd);
    // console.log('#### state.serversStreamEnd CAMERA_ENDED 7',state.serversStreamEnd)
  },

  CAMERA_WORKING(state, status){
    console.log('CAMERA_WORKING MUTATION',  status)
    if (status) {
      state.cameraOpen = status;
    } else {
      state.cameraOpen = ! state.cameraOpen
    }

  },

  set_streamId(state,BroadcastId){
    state.streamId = BroadcastId
  },
  set_ServersAndRoomsForStreaming(state,{rooms,servers}) {
    state.ServersForStreaming = servers;
    state.RoomsForStreaming = rooms;
    state.ServersAndRoomsForStreaming = [...servers, ...rooms];
    //console.log('set_ServersAndRoomsForStreaming MUTATION serversStream',servers);
    //console.log('set_ServersAndRoomsForStreaming MUTATION roomsStream',rooms);
  },
  set_OwnerFirstBroadcastCreationCompleted(state){
    state.FirstBroadcastCreationCompleted = true
  },

  CONNECTION_INFO(state,RTC_INFO){
    //console.log('RTC CONNECTION_INFO', RTC_INFO)
    state.connectionInfo = RTC_INFO
  },

  set_ServersAndRoomsForStreamingOwner(state,{rooms,servers}) {
    //state.serversForStreaming = serversForStreaming
    //console.log('serversStream',servers);
    //console.log('roomsStream',rooms);
    state.ServersForStreaming = servers;
    state.RoomsForStreaming = rooms;
    // state.RoomsForStreaming = ['5e70ac82b5c25b0012715532'];
    //const arr3 = [...arr1, ...arr2] //arr3 ==> [1,2,3,4,5,6]
    state.ServersAndRoomsForStreaming = [...servers, ...rooms];

  },
  set_liveNowStreamers(state, streamers){
    state.liveNowStreamers = streamers
  },
  set_selectedStream(state, stream){
    state.selectedStream = stream
  },

  set_myStreamingLive(state, status){
    state.myStreamingLive = status
  },

  set_myBroadcasting(state, broadcasting){
    state.myBroadcasting = broadcasting
  },

  set_allowedBroadcasts(state, broadcasts){
    state.allowedBroadcasts = broadcasts
  },




  // set_joinButton(state){},


}
