<template>
  <div>
    <NavbarMobile :window_width="window_width" :mobileBreakpoint="mobileBreakpoint" :avatar="avatar" :logo="logo" v-if="window_width <= mobileBreakpoint" />
    <NavbarDesktop :window_width="window_width" :mobileBreakpoint="mobileBreakpoint" :avatar="avatar" :logo="logo" v-else />
  </div>
</template>

<script>
 import EventBus from "@/eventBus";
 // import SearchBarNative from "@/components/Search/SearchBarNative";
 import NavbarDesktop from "@/components/Navbar/NavbarDesktop";
 import NavbarMobile from "@/components/Navbar/NavbarMobile";
    export default {
        data(){
            return{
              connected: false,
              showUserSettings: false,
              loaded: false,
              avatar: null,
              hasLive: false,
              isStreaming: false,
              showSearch: false,
              mobileBreakpoint: 768

            }
        },
        name: "Navbar",
        components: {
          // SearchBarNative,
          NavbarDesktop,
          NavbarMobile
        },
        watch:{
          userDataAfterLogIn() {
            this.setAvatar();
          },
        },
        props: {
          sidebar: null,
        },
        computed:{
          cameraOpened(){
            if(this.$store.getters.get_cameraOpen){
              return true
            }else{
              return false
            }
          },
          myStreamingLive () {
            return this.$store.getters.get_myStreamingLive;
          },
          selectedStream() {
            return this.$store.getters.get_selectedStream;
          },
          liveNowStreamers(){
            return this.$store.getters.get_liveNowStreamers
          },
          RoomsStreaming(){
            return this.$store.getters.get_RoomsForStreaming;
          },
          ServersStreaming(){
            return this.$store.getters.get_ServersForStreaming
          },
          openSignUp(){
                return this.$store.getters.get_openSignUp ;
          },
          isAuthenticated(){
            return this.$store.getters.isAuthenticated;
          },
          userDataAfterLogIn() {
            return this.$store.getters.get_userDataAfterLogIn;
          },
          openSidebar() {
            return this.$store.getters.get_openCloseSidebar;
          },
          connectedToSocket() {
            return this.$store.getters.get_connectedToSocket;
          },

          logo () {
            return require('@/assets/img/profit-logo.png');
          },
          stream () {
            return require('@/assets/icons/stream.svg');
          },
          settings () {
            return require('@/assets/icons/settings.svg');
          },
          searchIcon () {
            return require('@/assets/icons/search-room.svg');
          },
          defaultOwnerImage () {
            // return require('@/assets/img/user-profile-default.png');
            return this.$userDefaultImage;
          },
          window_width() {
            return this.$store.getters.get_window_width;
          },
          userServersIsOwner() {
            if (this.userDataAfterLogIn && this.userDataAfterLogIn.servers) {
              return  this.userDataAfterLogIn.servers.filter(server => server.owner === this.userDataAfterLogIn._id)
            }
            return [];
          },
          selectedRoom(){
            return this.$store.getters.get_selectedRoom
          }
        },
        methods: {
            // onLogIn(){
            //     this.$store.commit('set_openCloseLogIn');
            // },
            // onSignUp(){
            //     this.$store.commit('set_openSignUp');
            // },
            // onLogOut(){
            //     this.$localStorage.remove('accessToken');
            //     this.$localStorage.remove('user');
            //     this.$localStorage.remove('userServers');
            //     this.$localStorage.remove('redirectAfterLogin');
            //     this.$store.dispatch('logOut');
            //     if (this.$route.path !== '/')
            //       this.$router.replace('/');
            //
            //     // window.location.reload();
            //     this.$iv.socket.disconnect();
            // },
            toggleSearch() {

            },
            openStreamList() {
              this.$store.dispatch('set_openCloseSidebar', 'open');
              EventBus.$emit('selectedTab', 'stream');
            },
            openUserSettings() {
              this.$store.commit('set_openUserSettings');
            },
            onUserProfile() {
              this.$router.push(`/user-profile/${this.userDataAfterLogIn.username.toLowerCase()}`)
            },
            toggleStream() {
              //if (!this.cameraOpened) {
              this.$store.commit('set_openStreamOptions');
              //}
            },

            userAvatarStyle() {
              if (this.userDataAfterLogIn.image && 'http' === this.userDataAfterLogIn.image.slice(0, 4) ) {
                return this.userDataAfterLogIn.image;
              }
              return this.defaultOwnerImage;
            },
            // checkConnection() {
            //   console.log('checkConnection', this.get_connectedToSocket);
            //   this.connected = this.get_connectedToSocket;
            // },
            setAvatar() {
              if (this.userDataAfterLogIn && this.userDataAfterLogIn.image) {
                if ('http' === this.userDataAfterLogIn.image.slice(0, 4)) {
                  this.avatar = this.userDataAfterLogIn.image;
                } else {
                  if ('profile' === this.userDataAfterLogIn.image.slice(0, 7)) {
                    this.avatar = this.$imageurl + '/' + this.userDataAfterLogIn.image;
                  } else {
                    this.avatar = this.userDataAfterLogIn.image;
                  }
                }
              } else {
                this.avatar = this.defaultOwnerImage;
              }
            },
        },
        created() {
          this.mobileBreakpoint = this.$mobileBreakpoint;
          this.setAvatar();
        },
    }
</script>

<style>

    .navbar-brand {
      position: relative !important;
    }

    @media (min-width: 769px) {
      .navbar-brand {
        display: none !important;
      }
    }

    .navbar-start{
      flex-grow: 1;
      justify-content: center !important;
      /*background-color: #e8a243;*/
    }


    .navbar-item.navbar-item-left {
      margin-right: auto;
    }

    #navbar-center.mobile {
      display: none;
    }


    .navbar-item.navbar-item-center {
      margin-left: auto;
      margin-right: auto;
    }

    .navbar-item.navbar-item-right {
      margin-left: auto;
    }

    /*.header {*/
    /*  background-color: #26292c;*/
    /*  padding: 0 0.5em 0 0;*/
    /*}*/

    #navbar {
      background-color: #26292c;
      /*background-color: #074c91;*/
      padding: 0;
    }

    #navbar .navbar-menu {
      background-color: transparent;
      -webkit-box-shadow: 0 8px 16px rgba(10, 10, 10, 0);
      box-shadow: 0 8px 16px rgba(10, 10, 10, 0);
      /*position: relative;*/
    }

    .navbar-menu.is-active .widget-toggle {
      display: none;
    }



    #navbar .navbar-item {
      background-color: transparent;
      border-color: transparent;
      padding: 0.5em 0.25em;
    }



    #navbar .navbar-end {
      text-align: right;
    }

    #navbar .navbar-button {
      padding-left: calc(0.75em - 1px);
      padding-right: calc(0.75em - 1px);
    }

    #navbar .center-col {
        padding: 8px;
    }

    #navbar .right-col {
        padding: 8px;
    }

    #navbar.search-active .navbar-burger {
      display: none;
    }


    #navbar-search {
      /*position: absolute;*/
      /*left: 50%;*/
      /*transform: translateX(-50%);*/
      /*top: 0;*/
      /*display: flex;*/
      /*align-items: center;*/
      /*!*margin-top: -1px;*!*/
    }

    #mobile-navbar-overlay.navbar-item {
      z-index: 999;
      background-color: #26292C;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      display: flex;
      height: 100%;
    }

    #mobile-search-bar  {
      width: calc(100% - 50px);
      display: block;
      text-align: center;
    }
    #navbar-search-back {
      width: 50px;
    }

    /*#navbar-search.mobile {*/
    /*  width: 100%;*/
    /*  background-color: #26292C;*/
    /*  left: 0%;*/
    /*  transform: translateX(0%);*/
    /*  display: none;*/
    /*}*/

    #navbar-search.mobile.search-active {
      /*display: flex;*/
    }

    #navbar-search.mobile .navbar-search-section {
      /*margin-left: 50%;*/
      /*transform: translateX(-50%);*/
    }



    /*@media (max-width: 1200px) {*/
    /*  #navbar-search {*/
    /*    transform: translateX(-55%);*/
    /*  }*/
    /*}*/

    /*@media (max-width: 992px) {*/
    /*  #navbar-search {*/
    /*    transform: translateX(-65%);*/
    /*  }*/
    /*}*/

    /*@media (max-width: 896px) {*/
    /*  #navbar-search {*/
    /*    transform: translateX(-70%);*/
    /*  }*/
    /*}*/

    /*@media (max-width: 768px) {*/
    /*  #navbar-search {*/
    /*    transform: translateX(-50%);*/
    /*  }*/
    /*}*/

    .main-navbar-dropdown {

    }

    #settings-toggle {
      /*color: #7b7b7b;*/
      background-color: transparent !important;
    }

    #settings-toggle.navbar-button:hover {
      color: #fff;
    }

    #main-navbar-profile-dropdown .dropdown-item:hover .button {
      background-color: transparent;
      /*color: #ffffff;*/
    }

    #main-navbar-profile-dropdown .dropdown-item:hover .button.no-hover,
    button.no-hover {
      background-color: transparent;
      color: inherit;
    }

    button.toggler img {
      display: table-cell;
      vertical-align: text-top;
    }

   #main-navbar-profile-dropdown .dropdown-menu {
     background-color: rgba(0, 0, 0, 0.55);
     text-align: center;
     padding: 0;
     margin-top: 0;
   }

    #main-navbar-profile-dropdown a.dropdown-item {
      margin-left: 0;
      border-radius: 0;
      /*padding: 5px 10px;*/
      padding: 0;
      background-color: transparent;
    }

    #main-navbar-profile-dropdown a.dropdown-item:hover {
      background-color: transparent;
    }

    #main-navbar-profile-dropdown .dropdown-item .button {
      background-color: transparent;
      /*background-color: #444;*/
      border-color: transparent;
      /**color: #7c7c7c;*/
      color: #ffffff;
      border-radius: 0px;
      padding: 1.2em 0 1.2em;
    }

    #main-navbar-profile-dropdown .dropdown-item .button span {
      font-weight: bold;
    }

    #main-navbar-profile-dropdown .dropdown-item:hover .button {
      /*background-color: #6458ff;*/
      background-color: transparent;
      /*color: #ffffff;*/
    }

    #main-navbar-profile-dropdown .dropdown-item:hover .button:hover {
      background-color: #6458ff;
      color: #ffffff;
    }



    #main-navbar-profile-dropdown .dropdown-content {
      background-color: #293035;
      width: 100%;
      padding: 0;
      border-radius: 0;
    }


    #main-navbar-profile-dropdown .dropdown-content .has-link a{
      padding: 0em;
      margin: 0;
      border-radius: 0;
      background-color: transparent;
      color: #6843FF;
      font-size: 18px;
    }

    #widget-toggle {
      border: 0 solid transparent !important;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
    }

    #widget-toggle span {
      width: 100%;
      height: 100%;
      display: flex;
    }

    #widget-toggle img {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }

    #go-live-button {
      color: #7b7b7b;
      border: 0 solid transparent !important;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
    }

    #my-live-button {
      color: #7b7b7b;
      border: 0 solid transparent !important;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
    }


    #go-live-button.is-streaming,
    #go-live-button.is-streaming:hover
    {
      color: #ffffff;
      background-color: #22c161;
    }

    #my-live-button.is-streaming,
    #my-live-button.is-streaming:hover
    {
      color: #ffffff;
      background-color: #22c161;
    }

    #go-live-button.is-streaming .navbar-button-icon,
    #my-live-button.is-streaming .navbar-button-icon {
      color: #ffffff;
    }

    #go-live-button.is-streaming:hover span,
    #my-live-button.is-streaming:hover span{
      color: #ffffff;
    }


    #no-live-button {
      color: #7b7b7b;
      border: 0 solid transparent !important;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
      cursor: default;
    }

    #now-live-button {
      color: #ffffff;
      border: 0 solid transparent !important;
      box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
      background-color: #ff0000;
    }


    #now-live-button.navbar-button:hover,
    #now-live-button.navbar-button:hover span  {
      color: #ffffff;
    }

    #now-live-button.has-live  {
       color: #ffffff;
       background-color: #ff0000;
    }



   #navbar-mobile-actions {
     display: none;
     position: absolute;
     right: 45px;
     /*height: 100%;*/
     align-items: center;
     top: 50%;
     transform: translateY(-50%);
   }

   @media (max-width: 768px) {
      #navbar-mobile-actions {
        display: flex;
      }
   }

    #navbar-mobile-actions button {

    }

    button.navbar-button[disabled] {
      background-color: transparent;
      color: #7b7b7b;
      opacity: 1;
    }


    .navbar-button .icon:first-child:not(:last-child) {
      margin-left: auto !important;
      margin-right: auto;
    }




    .navbar-button img {
      margin-right: 0.35em;
      margin-bottom: 0.25em;
    }

    .header-left {
        display: flex;
        align-items: center;
    }

    .logo a {
        display: inline-block;
        letter-spacing: 4px;
        font-weight: 500;
        color: #fff;
        padding: 0 8px;
    }

    .logo img {
      width: auto;
      height: auto;
      max-width: 110px;
    }

    .logo a p {
        margin-bottom: 0;
        margin-right: 40px;
        font-size: 18px;
    }


    .header-link ul li {
        display: inline-block;
    }

    .header-link a:hover{
        text-decoration: none;
    }

    .header-link ul li a {
        display: inline-block;
        color: rgba(255, 255, 255, 0.34);
        font-size: 15px;
        font-weight: 400;
        margin-right: 25px;
        padding: 0px 10px;
        position: relative;
    }

    .header-link ul li a.active:after {
        width: 100%;
    }

    .header-link ul li a:after {
        content: "";
        display: block;
        width: 0%;
        /**height: 4px;*/
        height: 3px;
        background-color: transparent;

        position: absolute;
        left: 0;
        bottom: -20px;
        transition: all .2s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
    }

    .header-link ul li a.router-link-exact-active:after {
      background-color: #8064F6;
      width: 100%;
      bottom: -14px !important;
    }

    .header-link ul li a:hover:after  {
        width: 100%;
    }

    .header-search {
        background-color: #2E3135;
        border-radius: 8px;
        max-width: 600px;
        padding: 12px 12px;
        margin-left: auto;
        /** margin-right: 90px; */
        margin-right: auto;
    }

    .header-search input {
        display: inline-block;
        width: 91%;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255,255,255,.5);
        background-color: transparent;
        border: 0;
        outline: none;
    }

    .header-search button {
        border: 0;
        outline: none;
        background: none;
        padding: 0px;
        float: right;
        position: relative;
    }

    .header-search button:after {
        content: "";
        display: inline-block;
        width: 1px;
        height: 51px;
        /**background-color: #424447;*/
        background-color: transparent;
        position: absolute;
        left: -24px;
        top: -12px;
    }

    .header-search button img {
        display: inline-block;
        width: 15px;
    }

    /** Stef */
    .header-right ul {
        margin-bottom: 0;
    }
    .header ul {
        margin-bottom: 0;
    }

    .header button {
        font-size: 16px;

    }

    .header-right ul li {
        display: inline-block;
        margin: 5px 0;
    }

    .header-right ul li a  {
        display: inline-block;
        color: rgba(255,255,255,.7);
        font-weight: 500;
        font-size: 16px;
        transition: all .2s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
        padding: 10px 20px;
    }

    .header-right button {
        padding: 10px 18px 14px 18px;
        margin-left: 1em;
    }

    .header-right ul li:last-child a {
        background-color: #6843FF;
        border-radius: 8px;
        padding: 10px 20px;
        margin-left: 34px;
    }

    .header-right ul li a:hover {
        opacity: .7;
    }

    @media (max-width: 1152px)  {
        .logo a p {
            margin-right: 25px;
            font-size: 18px;
        }
        .header-link ul li a {
            font-size: 14px;
            margin-right: 5px;
        }
        .header-search input {
            font-size: 14px;
        }
        .header button,
        .header-right ul li a,
        .header a {
            font-size: 14px;
        }
    }

    @media (max-width: 830px)  {
        .logo a p {
            margin-right: 20px;
            font-size: 16px;
        }
        .header-link ul li a {
            font-size: 13px;
            margin-right: 4px;
        }
        .header-search input {
            font-size: 13px;
        }
        .header button,
        .header-right ul li a
        .header a {
            font-size: 13px;
        }
        .header-right ul li:last-child a {
            margin-left: 22px;
        }
    }

    @media (min-width: 768px) and (max-width: 780px)  {
        .logo a p {
            margin-right: 10px;
        }
    }

    /*-- header area end --*/
    /*-- sm-header area start --*/

    .sm-header {
        background-color: #2E3135;
        padding: 15px 0;
    }

    .sm-header-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sm-left {
        width: 32px;
        cursor: pointer;
    }

    .sm-left span {
        display: block;
        width: 100%;
        height: 3px;
        margin: 6px 0;
        background-color: rgba(255,255,255,.7);
        border-radius: 10px;
    }

    .sm-logo a {
        color: #fff;
        letter-spacing: 4px;
        font-weight: 600;
    }

    .sm-logo p {
        margin-bottom: 0;
        margin-right: 60px;
        font-size: 18px;
    }

    .sm-ser a img {
        display: inline-block;
        width: 17px;
    }

    .sm-link ul li a {
        display: inline-block;
        color: rgba(255, 255, 255, 0.34);
        font-size: 16px;
        font-weight: 500;
        position: relative;
        padding: 6px 0;
    }

    .sm-link ul li a:after {
        content: "";
        display: block;
        width: 0%;
        height: 2px;
        background-color: #8064F6;
        position: absolute;
        left: 0;
        bottom: -2px;
        transition: all .2s ease;
        -webkit-transition: all .2s ease;
        -moz-transition: all .2s ease;
    }

    .sm-link ul li a.active:after {
        width: 120%;
    }

    .sm-link {
        margin-top: 25px;
    }

    .sm-link li.has-button {
        padding: 1em 0 0;
    }

    .sm-link button {
        background-color: #6843FF !important;
    }


    .sm-link ul li a:hover:after {
        width: 120%;
    }

    .sm-link ul li:last-child a {
        background-color: #6843FF;
        border-radius: 8px;
        padding: 10px 35px;
        color: #fff;
        margin-top: 15px;
    }

    .sm-link ul li:last-child a:after {
        content: none;
    }

    .sm-link ul li:last-child a:hover {
        opacity: .7;
    }

    .sm-link {
        display: none;
    }

    .serbox .header-search {
        max-width: 100%;
        margin-right: 0;
        position: absolute;
        left: 0;
        top: 8px;
        display: none;
    }

    .serbox .header-search input {
        width: 80%;
    }

    .sm-header {
        position: relative;
    }

    .serbox .header-search button:after {
        content: none;
    }


    /*-- sm-header area end --*/
</style>
