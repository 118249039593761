<template>
  <!-- modal for cropping -->
  <b-modal v-model="modalShow" :width="640" scroll="keep" :on-cancel="onCancel"  id="cropper-modal">
    <!--    <template v-slot:modal-title id="modal-crop-header">-->
    <!--      Crop-->
    <!--    </template>-->
    <b-field class="file is-primary">
      <input
          ref="cropperInput"
          type="file"
          style="display: none;"
          @input="pickFile"/>
    </b-field>
    <vue-croppie
        v-if="!cropped_image"
        ref="croppieRef"
        :enableOrientation="false"
        :enableResize="false"
        :mouseWheelZoom="false"
        :enableExif="true"
        :enableZoom="true"
        :boundary="{ width: 300, height: 300}"
        :viewport="{ width: 200, height: 200, type: 'square' }"
        @result="result"
        @update="update"
    />
    <!-- the result -->
    <div class="row">
      <div class="col-md-12 text-center">
        <img alt v-if="cropped_image" :src="croppedImage">
      </div>
    </div>
    <br>
    <div class="row" style="margin: 0.75em;text-align: center;">
      <div class="col-md-12 text-center">
        <!-- Button for save and upload-->
        <b-button v-if="cropped_image" class="btn btn-primary mx-sm" type="is-success" @click="cropperSave()">Save</b-button>
        <b-button v-if="cropped_image" class="btn btn-primary mx-sm" type="is-primary" variant="outline-danger" @click="back()">Edit</b-button>
        <b-button v-if="origImageRead && !cropped_image" class="btn btn-info mx-sm" type="is-dark" @click="crop()">Crop</b-button>
        <b-button v-if="!origImageRead" class="btn mx-sm"  type="is-primary" @click="$refs.cropperInput.click()">Select Image</b-button>
        <!--        <b-button class="btn btn-danger mx-sm" @click="close()">Close</b-button>-->
      </div>
    </div>
  </b-modal>
</template>

<script>


export default {
  name: "Cropper",
  props:['original'],
  data() {
    return {
      modalShow: true,
      cropped_image: null,
      cropped: null,
      origImage: null,
      origImageRead: null,
      croppedImage: null,
      previewImage: null,
    }
  },
  beforeDestroy() {
    this.close();
  },
  computed: {
    defaultOwnerImage() {
      return this.$userDefaultImage;
    },
  },
  mounted() {
    this.$refs.croppieRef.bind({
      url: this.defaultOwnerImage,
    });
    this.$refs.cropperInput.click();

  },
  methods: {
    pickFile() {
      let input = this.$refs.cropperInput;
      let file = input.files;
      console.log('pickFile @ cropper', file);
      if (file && file[0]) {
        this.origImageRead = file[0];
        // this.previewImage = file[0];
        const reader = new FileReader();
        reader.readAsDataURL(this.origImageRead);
        this.cropped_image = false;
        // this.modalShow = true;
        // this.origImageRead = file[0];
        reader.onload = e => {
          this.croppedImage = e.target.result;
          // this.origImage = e.target.result;
          this.$refs.croppieRef.bind({
            url: this.croppedImage
          });
        }
        this.$emit('input', this.origImageRead);
      } else {
        console.log('pickFile @ no image');
      }
    },
    cropperSave() {
      const image = this.convertDataUrlToBlob(this.croppedImage);
      // const objectUrl = URL.createObjectURL(image);
      const file = new File([image], this.origImageRead.name, {type: this.origImageRead.type});
      console.log('file: ', file);
      this.$emit('cropperSave', file);
    },
    crop() {
      if(!this.origImageRead) {
        this.$refs.cropperInput.click();
        return false;
      }
      const options = {
        format: this.origImageRead.name.split('.').pop(),
        type: 'base64',
      };
      this.$refs.croppieRef.result(options, output => {
        this.cropped_image = true;
        // this.origImage = this.croppedImage =  output;
        this.croppedImage =  output;
        // console.log('croppedImage', this.croppedImage);
      });
    },
    // EVENT USAGE
    result(output) {
      this.cropped = output;
      this.$refs.croppieRef.bind({
        url: this.croppedImage,
      });
    },
    update(val) {

    },
    rotate(rotationAngle) {
      const reader = new FileReader();
      reader.readAsDataURL(this.origImageRead);
      reader.onload = e => {
        this.$refs.croppieRef.rotate(rotationAngle);
      };
    },
    back() {
      this.cropped_image = false;
      const reader = new FileReader();
      reader.readAsDataURL(this.origImageRead);
      reader.onload = e => {
        this.croppedImage = e.target.result;
        this.$refs.croppieRef.bind({
          url: this.croppedImage,
        });
      };
    },
    convertDataUrlToBlob(dataUrl) {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {type: mime});
    },
    onCancel() {
      console.log('onCancel Cropper');
    },
    close() {
      console.log('closing Cropper');
      this.$emit('cropperClose');
    },
  }
}
</script>

<style>

.mx-sm {
  margin: 0 0.2em;
}

#cropper-modal.modal .modal-content {
  background-color: #1e2124;
  width: 100%;
  height: auto;
  padding: 1.5em 1em;
  border-radius: 7px;
  letter-spacing: 0.2px;
  font-weight: normal;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
}

#cropper-modal.modal .row {
  text-align: center;
}

.croppie-container {
  width: 100%;
  height: auto !important;
}

.cr-slider {
  border: 1px solid transparent  !important;
  width: 300px !important;
  max-width: 100% !important;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: transparent !important;

}

</style>
