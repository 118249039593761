import Vue from 'vue';
import vuex from 'vuex';
//import createPersistedState from "vuex-persistedstate";
import axios from "../axios";

import auth from './modules/auth/index';
import servers from './modules/servers/index';
import rooms from  './modules/rooms/index';
import broadcasting from './modules/broadcasting/index';

// import * as actions from './actions';
// import * as mutations from './mutations';
// import * as getters from './getters';

Vue.use(vuex);

export const store = new vuex.Store({
    state:{
        openSignUp:false,
        openStreamOptions:false,
        openUserSettings: false,
        openCloseLogIn: false,
        openSidebar: false,
        openCloseCreateServer: false,
        openCloseCreateRoom: false,
        axios: axios,
        latestServers: null,
        window_width: 1200,
        user:{},
    },
    getters:{
        get_openSignUp: state => state.openSignUp,
        get_user: state => state.user, // Changed
        get_open_close_SignUp2: state => state.openSignUp2,
        get_openCloseLogIn : state => state.openCloseLogIn,
        get_openCloseSidebar : state => state.openSidebar,
        get_axios : state => state.axios,
        get_window_width: state => state.window_width,
        get_openCloseCreateServer: state => state.openCloseCreateServer,
        get_openCloseCreateRoom: state => state.openCloseCreateRoom,
        get_openStreamOptions: state => state.openStreamOptions,
        get_openUserSettings: state => state.openUserSettings,
        // get_token: state => state.token,
        // get_refreshToken: state => state.refreshToken,
        // get_userDataAfterLogIn: state => state.userDataAfterLogIn,
        // isAuthenticated :state => state.token === null
    },
    mutations:{
        set_openSignUp: (state) => {
            state.openSignUp = !state.openSignUp;
        },
        firstload:(state)=>{
            state.openSignUp = false;
            state.openSignUp2 = false;
            state.openCloseLogIn = false
        },
        set_user:(state, user) => {
            state.user = user;
        },
        set_axios_token: (state, token) => {
            state.axios.defaults.headers['Authorization'] = `Bearer ${token}`;
            state.axios.defaults.headers['Bearer'] = token;
            state.axios.defaults.headers['accessToken'] = token;
            //state.axios = settings;
        },
        set_open_close_SignUp2: (state) => {
            state.openSignUp2 = !state.openSignUp2;
        },
        set_openCloseLogIn: (state) =>{
            state.openCloseLogIn = !state.openCloseLogIn;
        },
        set_openCloseSidebar: (state, position) => {

            if (position === 'open') {
                state.openSidebar = true;
            } else if (position === 'close') {
                console.log('set_openCloseSidebar', position);
                state.openSidebar = false;
            } else {
                state.openSidebar = !state.openSidebar;
            }
        },
        set_window_width: (state, width) => {
            state.window_width = width;
        },
        set_openCloseCreateServer: (state) => {
            state.openCloseCreateServer = !state.openCloseCreateServer;
        },
        set_openCloseCreateRoom: (state) => {
            state.openCloseCreateRoom = !state.openCloseCreateRoom;
        },
        set_latestServers: (state, act) => {
            state.latestServers = act;
        },
        set_openStreamOptions: (state) => {
            state.openStreamOptions = !state.openStreamOptions;
        },
        set_openUserSettings: (state) => {
            state.openUserSettings = !state.openUserSettings;
        },
    },
    actions:{
        set_openCloseSidebar ({commit} , val) {
            commit('set_openCloseSidebar', val);
        },
        set_latestServers  ({commit}) {
            commit('set_latestServers', true);
        }






    },
    modules:{
        auth,
      servers,
        rooms,
        broadcasting

    },
    //plugins: [createPersistedState()]
});
