<template>
  <form id="payment-form">
    <div id="card-element">
      <!-- Elements will create input elements here -->
    </div>

    <!-- We'll put the error messages in this element -->
    <!--p id="card-element-errors" ref="errormsg" >&nbsp;</p-->
    <input v-model="errorMessage" id="card-element-errors" class="card-element-errors" readonly />

    <div id="stripe-button"><b-button class="submit"
              type="submit"

              :loading="loading"
              :disabled="buttonDisabled"
              @click="createPaymentMethod()"
    >Subscribe</b-button></div>

  </form>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import axios from '@/axios';

export default {
  data() {
    return {
      loading: false,
      stripe: null,
      elements: null,
      card: null,
      buttonDisabled: true,
      errorMessage: ' ',
    }
  },
  props: [
      'product',
      'isServer'
  ],
  name: 'StripePayment',
  async created() {
    // console.log(this.product);
    const self = this;
    this.stripe = await loadStripe(this.$stripePublishableKey);
    this.elements = this.stripe.elements();
    const style = {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      style: style
    });
    this.card.mount('#card-element');
    this.card.on("change", function (event) {
      if (event.complete)
        self.buttonDisabled = false;
      // Disable the Pay button if there are no card details in the Element
      self.displayError(event);
    });
  },
  computed: {
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
  },
  methods: {
    async createPaymentMethod() {
      this.loading = true;
      const customerId = this.userDataAfterLogIn.stripeId;

      // Set up payment method for recurring usage
      const billingName = this.userDataAfterLogIn.email;
      const priceId = this.product.stripePriceId;

      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: billingName,
        },
      });



      if (result.error) {
        this.displayError(result);
      }

      if (result.paymentMethod && result.paymentMethod.id ) {
        await this.createSubscription(customerId, result, priceId);
      }
      this.loading = false;
    },
    async createSubscription(customerId, result, priceId) {

      //console.log('createSubscription', result.paymentMethod);
      const paymentMethodId  = result.paymentMethod.id;
      const body = {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      };
      const response = await axios.post('/subscriptions/stripe/create-subscription', body, {
        headers: {
          'Content-type': 'application/json',
        }
      }).catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        this.displayError(error);
        this.loading = false;
      });

      // todo tweak if conditions
      if (response.status !== 402 && response.data) {
          const subscription = response.data;
          //console.log('/subscriptions/stripe/create-subscription', subscription);

          if (subscription.status !== "active") {
            if (subscription.latest_invoice.payment_intent.status === "requires_action") {
              // Some payment methods require a customer to be on session
              // to complete the payment process. Check the status of the
              // payment intent to handle these actions.
              const responseRequireCustomerAction = await this.handlePaymentThatRequiresCustomerAction(
                  subscription,
                  subscription.latest_invoice,
                  subscription.plan.id,
                  paymentMethodId,
                  true
              );
              // console.log(`responseRequireCustomerAction `, responseRequireCustomerAction);
              // if(responseRequireCustomerAction.error) {
              //   console.log(`Message `, responseRequireCustomerAction.error.message);
              //   this.displayError(responseRequireCustomerAction);
              //   this.loading = false;
              // }
            }
            if (subscription.latest_invoice.payment_intent.status === "requires_payment_method") {
              // If attaching this card to a Customer object succeeds,
              // but attempts to charge the customer fail,
              // you get a requires_payment_method error.
              const responseRequiresPaymentMethod = await this.handleRequiresPaymentMethod(subscription, paymentMethodId, priceId);
              console.log(`responseRequiresPaymentMethod `, responseRequiresPaymentMethod);
              await this.retryInvoiceWithNewPaymentMethod(customerId, paymentMethodId, subscription.latest_invoice.id, priceId)
            }

          } else {
            await axios.post('/subscriptions/stripe/complete-subscription', subscription);
            this.onSubscriptionComplete();
          }

      }

      document.getElementById('card-element-errors').textContent = response.data.message;
      this.loading = false;
    },
    onSubscriptionComplete() {
      // Payment was successful.
      // if (result.subscription.status === 'active') { // We don't need this if, prolly
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.


      // if(this.isServer){
      //   this.$store.commit('set_selectedServer', this.product);
      // } else {
      //   this.$store.commit('set_selectedRoom', this.product);
      // }

      //this.handleClose();
      // }

      this.$emit('subscriptionCreated');
    },
    async handlePaymentThatRequiresCustomerAction(subscription, invoice, priceId, paymentMethodId, isRetry,) {
      if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return {subscription, priceId, paymentMethodId};
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

      if (
          paymentIntent.status === 'requires_action' ||
          (isRetry === true && paymentIntent.status === 'requires_payment_method')
      ) {
        const result = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        }).catch((error) => {
          this.displayError(error);
          this.loading = false;
        });

        if (result.error) {
          // Start code flow to handle updating the payment details.
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc).
          // throw result;
          this.displayError(result);
          this.loading = false;
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            await axios.post('/subscriptions/stripe/complete-subscription', subscription);

            this.onSubscriptionComplete(); // no need to await
            // do we need to show all this stuff?
            // Show a success message to your customer.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId,
            };
          }
        }
      } else {
        // No customer action needed.
        return {subscription, priceId, paymentMethodId};
      }
    },
    handleRequiresPaymentMethod(subscription, paymentMethodId, priceId) {
      if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return {subscription, priceId, paymentMethodId};
      } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
            'latestInvoicePaymentIntentStatus',
            subscription.latest_invoice.payment_intent.status
        );
        // Todo: make a pop up window with the error? or show this inside component
        throw {error: {message: 'Your card was declined.'}};
      } else {
        return {subscription, priceId, paymentMethodId};
      }
    },
    async retryInvoiceWithNewPaymentMethod(customerId, paymentMethodId, invoiceId, priceId) {
      const response = await axios.post('/subscriptions/stripe/retry-invoice', {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      }, {
        headers: {
          'Content-type': 'application/json',
        }
      }).catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        this.displayError(error);
      })

      // If the card is declined, display an error to the user.
      if (response.error) {
        // The card had an error when trying to attach it to a customer.
        this.displayError(response);
        throw response;
      }
      if (response.data) {
        const subscription = response.data;
        if (subscription.status !== "active") {
          if (subscription.latest_invoice.payment_intent.status === "requires_action") {
            // Some payment methods require a customer to be on session
            // to complete the payment process. Check the status of the
            // payment intent to handle these actions.
            const responseRequireCustomerAction = await this.handlePaymentThatRequiresCustomerAction(
                subscription,
                subscription.latest_invoice,
                subscription.plan.id,
                paymentMethodId,
                true
            );
            // console.log(`responseRequireCustomerAction `, responseRequireCustomerAction);
            this.onSubscriptionComplete();
          }
        } else {
          await axios.post('/subscriptions/stripe/complete-subscription', subscription);
          this.onSubscriptionComplete(); // no need to await
        }
      }
    },
    displayError(event) {
      //let displayError = document.getElementById('card-element-errors');
      if (event.error) {
        //displayError.textContent = event.error.message;
        this.errorMessage = (event.error.message) ? event.error.message : 'An error has been occurred.Please check you card.';
        //console.log('create subs displayError', event.error, this.errorMessage);
      } else {
        //displayError.textContent = '...';
        this.errorMessage = '';
      }
    },
    handleClose() {
      this.$emit('closeJoin');
    },
  }
}
</script>

<style scoped>
input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#stripe-button {


}

#payment-request-button {
  margin-bottom: 32px;
}

#card-element-errors {
  background-color: transparent;
  width: 100%;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: auto;
  color: red;
  font-size: 12px;
  cursor: default;
}


</style>
