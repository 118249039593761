<template>
  <div class="home-live-carousel-item">
    <div class="live-one">
      <div class="live-one-left" v-bind:style="{ 'background-image': `url(${setThumb()})` }">
        <!-- img :src="item.image" :alt="item.title" -->
        <div class="live-one-left-box">
          <span><i class="fas fa-expand"></i></span>
        </div>
      </div>
      <div class="live-one-right">
        <div class="live-one-right-heading">
          <div class="liveorh-left">
            <img
                v-if="item.broadcastServers.length"
                :src="(item.broadcastServers.length && item.broadcastServers[0].owner.length && item.broadcastServers[0].owner[0].image) ? setAvatar(item.broadcastServers[0].owner[0].image) : defaultOwnerImage"
                :alt="(item.broadcastServers[0].owner.length && item.broadcastServers[0].owner[0].username) ? item.broadcastServers[0].owner[0].username : 'server'"
                @click="goToUserProfile(item.owner[0].username)"
                style="cursor: pointer;"
            />
            <img
                v-else
                :src="defaultOwnerImage"
                :alt="`${item.owner[0].username}-slider_images`"
                @click="goToUserProfile(item.owner[0].username)"
                style="cursor: pointer;"
            />
          </div>
          <div class="liveoh-right">
            <p v-if="item.broadcastServers.length">{{ item.broadcastServers[0].name }}</p>
            <span>{{ item.owner[0].username }}</span>
            <p v-if="item.broadcastServers.length">{{ item.broadcastServers[0].subscriptionsLength }} viewers</p>
          </div>
        </div>
        <div class="liveoh-button">
          <ul v-if="item.broadcastServers.length">
            <li
                v-for="category in item.broadcastServers[0].categories"
                :key="`${item.broadcastServers[0].name}-${category._id}`"
            >
              <a href="#">{{category.name}}</a>
            </li>
          </ul>
        </div>
        <div class="liveoh-text">
          <p v-if="item.broadcastServers.length">{{item.broadcastServers[0].description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCarouselItem",
  props: [
    'item'
  ],
  data() {
    return {}
  },
  created() {
  },
  computed: {
    leftStyle() {
      return "background: url(" + this.item.image + ")";
    },
    serverDefault() {
      return require('@/assets/img/server-default.png');
    },
    defaultOwnerImage () {
      return this.$userDefaultImage;
    },
  },
  methods: {
    setThumb() {
      if (this.item.broadcastServers && this.item.broadcastServers.length && this.item.broadcastServers[0].image && this.item.broadcastServers[0].image.length) {
        if ('http' === this.item.broadcastServers[0].image.slice(0, 4)) {
          return this.item.broadcastServers[0].image;
        } else {
          if ('server' === this.item.broadcastServers[0].image.slice(0, 6)) {
            return this.$imageurl + '/' + this.item.broadcastServers[0].image;
          } else {
            return this.serverDefault;
          }
        }
      }
      return this.serverDefault;
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return  this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }

      return this.defaultOwnerImage;
    },
    goToUserProfile(username) {
      this.$router.push(`/user-profile/${username.toLowerCase()}`)
    },
  }

}
</script>

<style>
.home-live-carousel-item {
  /**width: 100%;
  display: flex;*/

}

.live-one {
  width: 100%;
  max-width: 100%;
  display: table;
}

.live-one-left {
  display: table-cell;
  width: 50%;
  background-size: 100%;
  background-position: center;
  position: relative;

}

.live-one-right {
  width: 50%;
  display: table-cell;
  background-color: #282D33;
  padding: 15px 20px;
}


.live-one-left-box span {
  display: inline-block;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.live-one-left-img {
  position: relative;

}

.live-one-left-box span {
  display: inline-block;
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.liveorh-left img {
  border-radius: 20px;
  width: 60px !important;
}

.live-one-right-heading {
  display: flex;
}

.liveoh-right {
  margin-left: 15px;
}

.liveoh-right p {
  margin-bottom: 0;
  font-size: 17px;
  color: #fff;
}

.liveoh-right span {
  display: block;
  color: #a09e9e;
  font-size: 17px;
}

.liveoh-button ul {
  margin-top: 25px;
}

.liveoh-button ul li {
  display: inline-block;

}


.liveoh-button ul li a {
  display: inline-block;
  color: #fff;
  border-radius: 36px;
  background: rgb(103, 76, 244);
  background: linear-gradient(90deg, rgba(103, 76, 244, 1) 23%, rgba(160, 131, 250, 1) 100%);
  font-size: 15px;
  /**padding: 10px 25px;
  margin-right: 6px;*/
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  padding: 0.5em 1em;
  margin: 0.2em 0.25em 0.2em 0;
  text-transform: capitalize;
}

.liveoh-button ul li a:hover {
  opacity: .7;
}

.liveoh-text p {
  margin-bottom: 0;
  color: #fff;
  opacity: .8;
  margin-top: 30px;
}

.liveoh-button ul li a {
  font-family: inherit;
  font-weight: 600;
}

</style>

