<template>
  <section>
    <b-modal id="create-server" v-model="showCreateServerModal" :width="540" scroll="keep" :on-cancel="handleDeselect">
      <h4 class="brand">PROFIT</h4>
      <h2 class="modal-title medium">{{ titles[activeStep] }}</h2>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode">
        <b-step-item step="0" label="Create Server" :clickable="isStepsClickable">
          <ValidationObserver tag="div" class="form-observer" ref="serverFormValidator" v-slot="{ invalid }">
            <form id="create-server-form" @submit.prevent="handleServerSubmit">
              <ValidationProvider tag="div" class="validation-field" name="name" rules="required|min:3"
                                  v-slot="{ errors, valid }">
                <b-field label="Server Name"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }">
                  <b-input class="signup-input" vid="name" v-model="servername" :readonly="loading"></b-input>
                </b-field>
                <p v-if="errors && errors[0]" class="help field-help"
                   :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}</p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field" name="categories" rules="required|min:1"
                                  v-slot="{ errors, valid }">
                <b-field label="Categories"
                         class="form-field"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >

                  <!--                  <b-taginput-->
                  <!--                      v-model="selectedCategories"-->
                  <!--                      class="v-taginput"-->
                  <!--                      :data="categories"-->
                  <!--                      autocomplete-->
                  <!--                      :allow-new="false"-->
                  <!--                      :open-on-focus="true"-->
                  <!--                      field="label"-->
                  <!--                      icon="label"-->
                  <!--                      placeholder="Select Categories"-->
                  <!--                      @typing="getFilteredTags">-->
                  <!--                  </b-taginput>-->

                  <treeselect
                      id="server-categories-tree"
                      class="v-treeselect"
                      :multiple="true"
                      :options="categories"
                      :value-consists-of="valueConsistsOf"
                      :show-count="false"
                      :open-on-click="true"
                      :open-on-focus="true"
                      :always-open="false"
                      @input="handleInput"
                      @select="handleSelect"
                      :flat="false"
                      @deselect="handleDeselect"
                      :flatten-search-results="false"
                      placeholder="Select Categories"
                      v-model="selectedCategories"
                  />
                </b-field>
                <p v-if="errors && errors[0]" class="help field-help"
                   :class="{ 'is-danger': errors[0], 'is-success': valid }">
                  <!--                  {{ errors[0] }}&nbsp;-->
                  Please select at least one category
                </p>
              </ValidationProvider>

              <ValidationProvider tag="div" class="validation-field" name="Description" rules="required|min:20"
                                  v-slot="{ errors, valid }">
                <b-field label="Description"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="description" v-model="description" :readonly="loading"></b-input>
                </b-field>
                <p v-if="errors && errors[0]" class="help field-help"
                   :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}</p>
              </ValidationProvider>

              <ServerImage :tempImage="tempImage"
                           :uploading="uploading"
                           :imageUploaded="imageUploaded"
                           :server="createdServer"
                           :servername="servername"
                           :serverUploadedImage="serverUploadedImage"
                           v-on:previewImageUpdate="previewImageUpdate"

              ></ServerImage>

              <!--              <ValidationProvider tag="div" class="validation-field" name="Private" rules="" v-slot="{ }">-->
              <!--                <b-field label=""-->
              <!--                         class="form-field vcentered"-->
              <!--                >-->
              <!--                  <b-switch id="private-switch" class="switch-basic" v-model="isPrivate">Private Server</b-switch>-->
              <!--                  &lt;!&ndash;                  <b-input class="signup-input" vid="description" v-model="description" :readonly="loading"></b-input>&ndash;&gt;-->
              <!--                </b-field>-->
              <!--                &lt;!&ndash;                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}</p>&ndash;&gt;-->
              <!--              </ValidationProvider>-->

              <footer id="create-server-footer" class="footer">
                <p class="signup-note" style="color: #54595e">By creating this server you agree to Profit's</p>
                <p class="signup-note"><a href="#" @click.prevent="gotoTerms()">Community Guidelines</a></p>
                <!--p class="signup-note"><a href="#" @click.prevent="activeStep = 1">Create Category</a></p-->
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid || loading"
                    @click="activeStep = 1"
                >Let's Go
                </b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>

        <b-step-item step="1" label="Server Details" :clickable="isStepsClickable">
          <b-field label=""
                   class="form-field has-toggle">
            <b-switch id="free-switch" class="switch-basic" v-model="isFree">Free Server</b-switch>
          </b-field>


          <ValidationObserver tag="div" class="form-observer" ref="editServerFormValidator" v-slot="{ invalid }">
            <form id="edit-server-form" @submit.prevent="handleServerSubmit">
              <div v-show="!isFree">
                <ValidationProvider tag="div" class="validation-field" name="Billing Cycle" rules="required"
                                    v-slot="{ errors, valid }">
                  <b-field
                      label="Billing Cycle"
                      class="form-field">
                    <b-dropdown v-model="selectedBillingCycle"
                                aria-role="list"
                                class="categories-select2"
                    >
                      <button class="button" id="buttonCycle" type="button" slot="trigger" :expanded="true">
                        <template>
                          <span style="color: rgba(255, 255, 255, 0.84)">{{ selectedBillingCycle }}</span>
                        </template>
                        <b-icon style="color: rgba(255, 255, 255, 0.84)" icon="menu-down"></b-icon>
                      </button>
                      <b-dropdown-item v-for="(billingCycle, i) in billingCycleOptions" :key="i" :value="billingCycle"
                                       aria-role="listitem"
                      >{{ billingCycle }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>

                <ValidationProvider
                    tag="div"
                    class="validation-field"
                    name="Server Price"
                    rules="required|min_value:0|double:2"
                    v-slot="{ errors, valid }"
                >
                  <b-field label="Price"
                           class="form-field"
                           :class="{ 'is-danger': errors[0], 'is-success': valid }"
                           :type="{ 'is-danger': errors[0], 'is-success': valid }">
                    <b-input
                        class="signup-input"
                        type="text"
                        vid="server_price"
                        v-model="server_price"
                        :readonly="isFree"
                    />
                  </b-field>
                  <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
                </ValidationProvider>
              </div>

              <footer id="create-server-details-footer" class="footer">
                <b-button
                    @click="handleServerSubmit()"
                    class="submit"
                    type="submit"
                    :disabled="invalid"
                >Create
                </b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>
        <b-step-item step="2" label="Created" :clickable="isStepsClickable">
          <h1 style="text-align: center; font-size: 120%;">Your Server has been Created!</h1>
          <sweetalert-icon icon="success"></sweetalert-icon>
          <footer id="account-confirm-footer" class="footer">
            <b-button
                class="submit"
                type="submit"
                @click="handleClose"
            >Close
            </b-button>
          </footer>
        </b-step-item>
        <b-step-item step="3" label="Category Details" :clickable="isStepsClickable">
          <ValidationObserver tag="div" class="form-observer" ref="categoryFormValidator" v-slot="{ invalid }">
            <form @submit.prevent="handleCatSubmit">
              <ValidationProvider tag="div" class="validation-field" name="category" rules="required|min:2"
                                  v-slot="{ errors, valid }">
                <b-field label="Category Name"
                         class="form-field"
                         :class="{ 'is-danger': errors[0], 'is-success': valid }"
                         :type="{ 'is-danger': errors[0], 'is-success': valid }"
                >
                  <b-input class="signup-input" vid="category" v-model="servername" :readonly="loading"></b-input>
                </b-field>
                <p class="help field-help" :class="{ 'is-danger': errors[0], 'is-success': valid }">{{ errors[0] }}&nbsp;</p>
              </ValidationProvider>
              <footer class="footer">
                <p class="signup-note"><a href="#" @click.prevent="activeStep = 0">Create Server</a></p>
                <b-button
                    class="submit signup-submit"
                    type="submit"
                    :loading="loading"
                    :disabled="invalid"
                    @click="handleCatSubmit()"
                >Create
                </b-button>
              </footer>
            </form>
          </ValidationObserver>
        </b-step-item>
      </b-steps>
    </b-modal>
  </section>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ServerImage from "../Traits/ServerImageUpload";
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full';
import SweetalertIcon from "vue-sweetalert-icons";
import axios from "@/axios";


export default {
  name: "CreateServer",
  components: {
    Treeselect,
    ValidationObserver,
    ValidationProvider,
    SweetalertIcon,
    ServerImage,
  },
  data() {
    return {
      showCreateServerModal: true,
      server_price: '0.01',
      isPrivate: false,
      isFree: false,
      servername: '',
      serverImage: null,
      description: '',
      server: null,
      tempImage: null,
      uploading: false,
      imageUploaded: false,
      serverUploadedImage: null,
      activeStep: 0,
      categories: [],
      selectedCategories: [],
      category: null,
      loading: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      createdServer: null,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      titles: ['Create Server', 'Server Details', 'Server Created', 'Create Category'],
      title: "Create Server",
      // billingCycleOptions: [
      //   {label: 'Month', value: 'month'},
      //   {label: 'Quarter', value: 'quarter'},
      //   {label: 'Semi-annual', value: 'semi-annual'},
      //   {label: 'Annual', value: 'annual'},
      // ],
      billingCycleOptions: ['Month', 'Quarter', 'Semi-annual', 'Annual'],
      selectedBillingCycle: "Month",
      openBillingCycle: false,
      intervalBillingCycleCount: 1,
      valueConsistsOf: 'ALL_WITH_INDETERMINATE',
    }
  },
  watch: {
    activeStep(step) {
      this.title = this.titles[step];
    },
    isFree(val) {
      if (val) {
        this.server_price = '0.00';
        this.selectedBillingCycle = 'Month';
      } else {
        this.server_price = '0.01';
      }
    },
    server_price(newValue) {
      // console.log('server_price1', typeof newValue)
      this.openBillingCycle = (newValue === "0" || newValue === "") ? false : true;
    },
    // selectedCategories(selected) {
    //
    // },
  },
  created() {

  },
  mounted() {
    this.getCategories();
  },
  computed: {
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    token() {
      return this.$store.getters.get_token;
    },
    userServers() {
      return this.$store.getters.get_userServers;
    }
  },
  methods: {
    previewImageUpdate(val) {
      console.log('previewImageUpdate', val);
      this.tempImage = val;
    },
    getFilteredTags(text) {
      this.selectedCategories = this.categories.filter((option) => {
        return option.label
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    gotoCreateRoom() {
      if (this.createdServer) {
        this.$emit('createdServerReturn', this.createdServer);
      } else {
        this.$emit('createdServerReturn', false);
      }
    },
    gotoServer() {
      if (this.createdServer) {
        this.$router.push('/servers/' + this.createdServer.slug);
        window.location.reload();
      }
    },
    async handleServerSubmit() {
      this.loading = true;

      const categories = [];
      if (this.selectedCategories) {
        this.selectedCategories.forEach(category => {
          categories.push(category);
        })
      }
      const image = (this.tempImage && this.tempImage.filename) ? this.tempImage.filename : '';
      // if (this.tempImage && this.serverUploadedImage.filename) {
      //   image = this.tempImage.filename;
      // }

      const selectedCycle = this.selectedBillingCycle.toLowerCase()
      switch (selectedCycle) {
        case "quarter":
          this.intervalBillingCycleCount = 3;
          break;
        case "semi-annual":
          this.intervalBillingCycleCount = 6;
          break;
        case "annual":
          this.intervalBillingCycleCount = 12;
          break;
        default:
          this.intervalBillingCycleCount = 1;
      }
      const postData = {
        name: this.servername,
        description: this.description,
        is_private: this.isPrivate,
        price: parseFloat(this.server_price),
        billingCycle: "month",
        intervalBillingCycleCount: this.intervalBillingCycleCount,
        relatedDiscounts: "",
        image: image,
        categories: categories,
        stripeId: "",
        stripePlanId: ""
      }

      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'application/json';
      // console.log('before created server', data);
      // console.log('typeof this.server_price ', typeof data.price)
      try {
        const {data} = await axios.post('/servers', postData, {
          headers: axHeaders
        });
        this.loading = false;
        console.log('after created server', data);
        if (data.statusCode && data.statusCode === 409) {
          this.activeStep = 0;
          const message = data.message ? data.message : 'An Error has Occurred';
          this.$refs.serverFormValidator.setErrors({name: message});
          return;
        }
        this.createdServer = data;
        this.activeStep = 2;

        await this.$store.dispatch('login', {
          token: this.$store.getters.get_token
        });
      } catch (e) {
        this.loading = false;
        console.log('after created server error');
        console.log(e.response);
        const message = (e.response.data.message) ? e.response.data.message : 'An Error has Occured';
        this.$refs.serverFormValidator.setErrors({name: message})
      }
    },
    async handleUpdate() {
      const selectedCycle = this.selectedBillingCycle.toLowerCase()
      switch (selectedCycle) {
        case "quarter":
          this.intervalBillingCycleCount = 3;
          break;
        case "semi-annual":
          this.intervalBillingCycleCount = 6;
          break;
        case "annual":
          this.intervalBillingCycleCount = 12;
          break;
        default:
          this.intervalBillingCycleCount = 1;
      }

      const server = {
        price: parseFloat(this.server_price),
        billingCycle: 'month',
        intervalBillingCycleCount: this.intervalBillingCycleCount,
      };

      //server.description = 'This server was created by....';

      if (this.createdServer && this.createdServer._id) {
        console.log('after uploadok', this.server, server);
        this.loading = true;
        let axHeaders = axios.defaults.headers;
        axHeaders['Content-Type'] = 'application/json';
        try {
          const {data} = await axios.put('/servers/' + this.createdServer._id, server, {
            headers: axHeaders
          });
          this.loading = false;
          console.log('AFTER submit update server ', data);
          //this.activeStep = 2;
          this.reFetch();
        } catch (e) {
          this.loading = false;
          console.log(e.response);
          //return new Error(e.response.data.message);
        }
      }
    },
    async handleUpload() {
      let formData = new FormData();
      formData.append('image', this.tempImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      console.log('axHeaders', axHeaders);
      //this.loading = true;
      this.uploading = true;
      try {
        let response = await axios.post('/images/server', formData, {
          headers: axHeaders
        });
        this.uploading = false;
        return response;
      } catch (error) {
        this.uploading = false;
        console.log(error);
        return error;
      }
    },

    async reFetch() {
      let user = {
        token: this.token
      };
      this.activeStep = 2;
      this.$emit('createdServerReturn', this.createdServer);
    },
    async handleCatSubmit() {
      this.loading = true;
      const postData = {
        name: this.servername,
      };

      try {
        const {data} = await axios.post('/categories', postData);
        this.loading = false;
        console.log(data);
        this.activeStep = 0;
      } catch (e) {
        this.loading = false;
        console.log(e.response);
        return new Error(e.response.data.message);
      }
    },
    removeUpload() {

    },
    gotoTerms() {
      //this.activeStep = 1;
    },
    imageSelected() {
      console.log('selected');
      //this.handleUpload();
    },
    async getCategories() {
      try {
        const {data} = await axios.get('/categories');
        data.forEach(child => {
          const category = {};
          category.id = child._id;
          category.label = child.name;
          this.categories.push(category);
        });
      } catch (e) {
        console.log(e.response);
      }
    },
    handleClose() {
      console.log('closing');
      this.$store.commit('set_openCloseCreateServer');
      this.$store.commit('set_selectedServer', this.createdServer);
      this.$router.push({
        name: 'Chat',
        params: {id: this.createdServer.slug, action: 'visit', server_id: this.createdServer._id}
      });
    },
    handleInput(value, instanceId) {
      //console.log('handleInput val id', value, instanceId);
    },
    handleSelect(node, instanceId) {
      //  if (node.children) {
      //   console.log('is parent->  node id', node, instanceId);
      //   //if (this.selectedServers.indexOf(node.id) === -1) {
      //     //this.selectedServers.push(node.id);
      //   //}
      //   node.children.forEach((child) => {
      //     if (this.selectedRooms.indexOf(child.id) === -1) {
      //       this.selectedRooms.push(child.id);
      //     }
      //   });
      // } else {
      //   console.log('is child ->  node id', node, instanceId);
      //   if (this.selectedRooms.indexOf(node.id) === -1) {
      //     this.selectedRooms.push(node.id);
      //   }
      // }
    },
    handleDeselect(node, instanceId) {
      this.$store.commit('set_openCloseCreateServer');
      //console.log('handleDeselect node id', node, instanceId);

      // if (node.children || node.isNew) {
      //   console.log('handleDeselect node id', node, instanceId, this.selectedServers.indexOf(node.id));
      //   let i = this.selectedServers.indexOf(node.id);
      //   if (i >= 0) {
      //     this.selectedServers.splice(i, 1);
      //   }
      //   console.log('selectedServers ', this.selectedServers);
      // }
    },
  }
}
</script>

<style>
/*#create-server.modal .modal-content {*/
/*  background-color: #1e2124 !important;*/
/*  max-width: 420px !important;*/
/*  padding: 1.5em 1em 1em;*/
/*  border-radius: 17px !important;*/
/*  overflow: hidden;*/
/*  !*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*!*/
/*}*/

#create-server-form {
}

#create-server.modal .dropdown-menu {

}

#create-server.modal .dropdown-content {

}

#create-server .footer {
  /*margin-top: 1em;*/
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}


#create-server .upload-preview {
  /**width: 74px;
  height: 74px;
  border-radius: 26px;*/
  background-color: #632ff3;
  display: inline-block;
  margin-right: 1em;
}

#create-server .file {
  display: inline-block;
  width: auto;
}

/*#create-server-footer {*/
/*  margin-top: 1em;*/
/*  background-color: transparent;*/
/*  padding: 0;*/
/*  width: 100%;*/
/*  text-align: center;*/
/*}*/


#private-switch .check {
  width: 2.5em;
  height: 1.2em;
  padding: 0.1em;
}

#private-switch .control-label {
  margin-left: 0.75em;
  font-size: 0.85em;
  color: white;
}


.categories-select2 {
  margin-top: 1em;
  width: 100%;

}

#buttonCat {
  /*width: 540px;*/
  background-color: transparent;
  border-color: transparent;
}

#buttonCycle {
  /*width: 540px;*/
  background-color: transparent;
  border-color: transparent;
}


</style>
