<template>
  <div id="stream-image-upload" class="image-upload">
    <div id="stream-image-upload-preview-wrapper">
    <b-tag
        type="is-primary"
        id="stream-image-upload-preview"
        :style="{ 'background-image': `url(${avatar})` }"
        :closable="closable"
        aria-close-label="Close tag"
        @close="removeUpload"></b-tag>

    <!--span class="preview-file-name" v-if="previewImage">{{ previewImage.name }}</span-->
    <b-field class="file is-primary" :class="{'has-name': !avatar}">
      <input
          ref="fileInput"
          type="file"
          style="display: none;"
          @input="pickFile" />
    </b-field>
    </div>
    <div id="stream-image-upload-button-wrapper"
         :class="{ 'expanded': imageUploaded }"
    >
      <b-button id="stream-image-upload-button" class="server-image-upload-button"
                :loading="uploading" v-if="!imageUploaded" @click="selectImage">+ Upload Stream Picture</b-button>
      <!--b-button id="server-image-uploaded" class="server-image-upload-button" :loading="uploading" v-if="imageUploaded && serverUploadedImage" >{{serverUploadedImage.originalName}}</b-button-->
    </div>
      <!--b-button class="profile-update-button" v-if="serverImage" @click="handleUpload">Upload</b-button-->
      <!--b-button class="profile-update-button" v-if="previewImage && needSave" @click="updateImage()">Update Image</b-button-->
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  name: "ServerImage",
  props:['stream', 'tempImage', 'uploading', 'imageUploaded', 'streamUploadedImage'],
  data() {
    return {
      showUserSettingsModal: true,
      streamImage: null,
      previewImage: null,
      needSave: false,
      avatar: null,
      loading: false,
      updatedStreamData: null,
    }
  },
  watch: {
    previewImage(newval) {
      console.log('previewImage watch', newval );
      this.$emit('previewImageUpdate', newval);
    }
  },
  computed: {
    streamThumbDefault() {
      return require('@/assets/img/server-thumb-default.svg');
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    closable() {
      return this.previewImage !== null;
    }
  },
  created() {
    this.setAvatar();
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click();
    },
    removeUpload() {
      this.setAvatar();
    },
    async updateImage() {
      if (this.previewImage) {
        let upload = await this.handleUpload();
        console.log('uploaded', upload);
        if (upload.status && upload.status >= 200 && upload.status < 300 && upload.data && upload.data.uploadedImage) {
          if (upload.data.uploadedImage.filename) {
            let updatedUserData = {
              image: upload.data.uploadedImage.filename
            }
            let response = await axios.put('/users/' + this.userDataAfterLogIn._id , updatedUserData, { });
          }
        }
      }
    },
    pickFile () {
      let input = this.$refs.fileInput;
      let file = input.files
      if (file && file[0]) {
        this.previewImage = file[0];
        console.log('pickFile', file[0]);
        let reader = new FileReader
        reader.onload = e => {
          this.avatar = e.target.result;
        }
        reader.readAsDataURL(file[0]);
        this.$emit('input', file[0]);
        this.needSave = true;
      }
    },
    async handleSaveProfile() {
      //this.handleUpload();
    },
    imageSelected() {
      console.log('selected', this.serverImage);
      //this.handleUpload();
    },
    async handleUpload() {
      let formData = new FormData();
      //formData.append('file', this.previewImage);
      formData.append('image', this.previewImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      this.loading = true;
      try {
        let response = await axios.post('/images/profile', formData, {
          headers: axHeaders
        });
        this.loading = false;
        return response;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    setAvatar() {
      this.previewImage = null;
      //this.$emit('previewImageUpdate', null);
      if (this.stream && this.stream.image) {
        if ('http' === this.stream.image.slice(0, 4)) {
          this.avatar = this.stream.image;
        } else {
          if ('server' === this.stream.image.slice(0, 7)) {
            this.avatar = this.$imageurl + '/' + this.stream.image;
          } else {
            this.avatar = this.stream.image;
          }
        }
      } else {
        this.avatar = this.streamThumbDefault;
      }
    },
    handleUpdateProfile() {

    }
  }
}
</script>

<style >
  #stream-image-upload {
    width: 100%;
    text-align: center;
    margin: 40px 0;
    display: table;
  }


  #stream-image-upload-preview-wrapper {
    width: 120px;
    height: 120px;
    display: table-cell;
    vertical-align: middle;
  }

  #stream-image-upload-preview {
    width: 90px;
    height: 90px;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    /**padding: 28px 25px 29px;*/
    /**border-radius: 35px;*/
    border-radius: 35px;
    background-color: #632ff3;
    /**margin-bottom: 12px;*/
    display: inline-block;
  }

  #stream-image-upload-button-wrapper {
    display: table-cell;
    vertical-align: middle;
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
  }

  #stream-image-upload-button,
  #stream-image-uploaded {
    /**font-family: 'Circular Std Medium', sans-serif;*/
    color: #ffffff;
    border: 0 solid;
    padding: 5px 14px;
    border-radius: 6px;
    background-color: #272b2e;
  }

  #stream-image-upload-button:hover {
    color: #ffffff;
  }



</style>
