import axios from 'axios';

const isProd = process.env.NODE_ENV === 'production';

//instance.defaults.headers.common.accept = 'application/json';

let axiosInstance = axios.create({
    baseURL: (isProd) ?  process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        //Pragma: 'no-cache'
        //'t101Secret': 'IOLi0GrGwRSp2zMMpeMmRB1ui1TkdRJO',
    },
})

axiosInstance.defaults.validateStatus = status => {
    return status < 500;
};

export default axiosInstance;
