<template>
  <!--      <div>-->
  <!--ul id="private-chat-group-list" class="messages" v-chat-scroll="{always: true, smooth: true}" v-if="formattedMessages"-->
  <vueScroll
      @handle-scroll="handleScroll"
      @handle-scroll-complete="handleScrollCompleted"
      :ops="ops"
      id="private-chat-group-list"
      ref="privateMessageDisplay"
      class="messages"
  >
    <div style="width: 100%; text-align: center;margin-top: 1em;" v-if="showLoadMoreMessagesPrivate && loadingMore">
      <b-button id="load-more-private-chat-messages"
                ref="loadMoreButtonPrivate"
                rounded
                @click="fetchMessagesPrivate()"
      >Loading history ...
      </b-button>
    </div>
    <div
        v-for="(dayMessages, groupDate) in selectedPrivateRoomMessages"
        :key="groupDate"
        :dayMessages="dayMessages[0].date.split('T')[0]"
        class="message-container"
    >
      <div class="d-flex private-message-separator">
        <div class="date-side-line flex-grow-1"></div>
        <span
            class="date-text align-self-center"
        >{{ dayMessages[0].date | moment("dddd[,] D MMMM YYYY") }}</span>
        <div class="date-side-line flex-grow-1"></div>
      </div>
      <div v-for="(dayMessage, g) in dayMessages" :key="g">
        <PrivateChatMessage
            v-if="dayMessage"
            :key="dayMessage._id"
            :data-index="g"
            :id="'private-chat-message-' + dayMessage._id"
            :message="dayMessage"
        />
      </div>

    </div>
  </vueScroll>
  <!--        <div-->
  <!--            v-if="!privateRoom.privateRoomAccept"-->
  <!--            class="mx-3 text-center"-->
  <!--        >-->
  <!--          <span class="align-self-center text-wrap" v-html="spanMessage()"></span>-->
  <!--          <b-button-->
  <!--              v-if="userDataAfterLogIn._id !== privateRoom.owner"-->
  <!--              type="is-success"-->
  <!--              class="btn-block"-->
  <!--              @click="acceptInvitation"-->
  <!--          >Accept</b-button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--/ul-->
</template>

<script>
import PrivateChatMessage from "./PrivateChatMessage";
import vueScroll from 'vuescroll';
import moment from 'moment';
// import * as _ from 'lodash'
import axios from '../../axios';

const ErrorForm = {
  props: ['dataMessage'],
  template: `
    <div>
    <h4>PROFIT</h4>
    <h2 class="modal-title">Something went wrong</h2>
    <br/>
    <h3>Error message: "{{ dataMessage }}"</h3>
    <br/>
    <p>Please press "Accept" button again.</p>
    <p>If this persist, contact support@profit.com and tell them the error message</p>
    <footer class="error-modal-footer">
      <b-button
          label="Close"
          type="is-primary"
          @click="$emit('close')"
          expanded
      />
    </footer>
    </div>
  `
}

export default {
  name: "PrivateChatMessageGroup",
  components: {
    PrivateChatMessage,
    vueScroll
  },
  data() {
    return {
      loadingPrevious: false,
      loadingNext: false,
      activeRoom: null,
      bookmark: null,
      messagesList: {},
      bottom: null,
      enableScroll: true,
      scrollToLastMessage: false,
      autoSelect: null,
      // focus: null,
      scrollLock: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false
      },
      // messages:[],
      loadingMore: false,
      reloadingRoom: false,
      afterLoadMoreFocus: null,
      indicator: null,
      showIndicator: false,
      timer: null,
      loadMore: null,
      ops: {
        vueScroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          // initialScrollY: '100%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'right'
        },
        rail: {},
        bar: {},
        scrollButton: {
          enable: true,
          background: 'rgb(141,141,215)',
          opacity: 1,
          step: 280,
          mousedownStep: 120
        }
      },
    }
  },
  watch: {
    loadMore(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.getMorePrivateMessages(newVal);
        }, 150);
      }
    },
    focus(newVal) {
      // console.log('focus watch 1111111111: ', newVal);
      if (newVal && newVal === 'last') {
        this.scrollToLast();
      }
      if (newVal && newVal !== 'last') {
        this.focusMessage(newVal);
      }
    },
    selectedPrivateRoomMessages(newVal) {
      // asd
    },
  },
  computed: {
    focus() {
      return this.$store.getters.get_privateMessageFocus;
    },
    showLoadMoreMessagesPrivate() {
      return this.$store.getters.get_showLoadMoreMessagesPrivate;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    privateChatWithMember() {
      return this.$store.getters.get_privateChatWithMember
    },
    privateRoom() {
      //console.log('@@@@@@@@@@@ ', this.$store.getters.get_privateSelectedRoom)
      return this.$store.getters.get_privateSelectedRoom;
    },
    selectedPrivateRoomGroups() {
      return this.$store.getters.get_selectedPrivateRoomGroups;
    },
    selectedPrivateRoomMessages() {
      return this.$store.getters.get_selectedPrivateRoomMessages;
    },
    previousPrivateMessagesIndex() {
      return this.$store.getters.get_previousPrivateMessagesIndex;
    },
    nextPrivateMessagesIndex() {
      return this.$store.getters.get_nextPrivateMessagesIndex;
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'add_privateMessageToGroup') {
        // console.log('PRIVATE add_privateMessageToGroup', mutation.payload);
        // Giving time for new message to get attached in dom
        setTimeout(()=> {
          this.scrollToLast();
        }, 150);
      }
      if (mutation.type === 'append_selectedPrivateRoomMessages') {
        // console.log('PRIVATE add_privateMessageToGroup', mutation.payload);
        // Giving time for new message to get attached in dom
        setTimeout(()=> {
          this.scrollToLast();
        }, 150);
      }

    });
  },
  mounted() {
    if (document.getElementById('private-chat-bar')) {
      this.bottom = document.getElementById('private-chat-bar').offsetTop;
    }
  },
  methods: {
    handleScroll(vertical, horizontal, nativeEvent) {
      // console.log('handleScroll', this.$refs["messageDisplay"]);
      clearTimeout(this.timer);
      let current = this.$refs["privateMessageDisplay"].getCurrentviewDom()[0];
      if (current) {
        this.indicator = current.id;
        this.timer = setTimeout(() => {
          this.indicator = null;
        }, 1200);
      } else {
        this.indicator = null;
      }

      if (this.scrollLock) {
        console.log('this.scrollLock on : returns false');
        return false;
      }

      let toFocus;
      const scroll = this.$refs["privateMessageDisplay"].$el;
      const view = this.$refs["privateMessageDisplay"].$children[0].$el.firstChild;

      if (vertical.scrollTop === 0 && this.previousPrivateMessagesIndex) {
        // console.log('this.previousMessagesIndex', this.previousMessagesIndex , toFocus);
        // this.$parent.scrollLock = true;
        let elems = this.$el.getElementsByClassName("private-chat-message");
        if (elems.length) {
          toFocus = elems[0].id;
        } else {
          toFocus = null;
        }

        this.bookmark = toFocus;

        this.loadMore = {
          index: this.previousPrivateMessagesIndex,
          direction: 'past',
          indicator: toFocus
        };
        // setTimeout(()=> {
        //   this.getMoreMessages(this.previousMessagesIndex, 'past');
        // }, 150);
      } else if (scroll && view && (vertical.scrollTop + scroll.clientHeight) > view.clientHeight) {
        //if (this.nextPrivateMessagesIndex && this.indicator && this.nextPrivateMessagesIndex !== this.indicator && !this.loadingPrevious && !this.loadingNext ) {
        if (this.nextPrivateMessagesIndex && !this.loadingPrevious && !this.loadingNext) {
          // this.$parent.scrollLock = true;
          let elems = this.$el.getElementsByClassName("private-chat-message");
          if (elems.length) {
            toFocus = elems[elems.length - 1].id;
          } else {
            toFocus = null;
          }
          // console.log('this.nextPrivateMessagesIndex', toFocus);

          this.loadMore = {
            index: this.nextPrivateMessagesIndex,
            direction: 'future',
            indicator: toFocus
          };
        }
      }
    },
    indicatorDate() {
      if (moment(this.indicator).format("D MMMM YYYY") === moment().format("D MMMM YYYY")) {
        return 'Today'
      } else if (moment(this.indicator).format("D MMMM YYYY") === moment().add(-1, 'days').format("D MMMM YYYY")) {
        return 'Yesterday'
      } else {
        return moment(this.indicator).format("dddd[,] D MMMM YYYY");
      }
    },
    scrollToCursor() {
      this.$nextTick(() => {
        const elem = document.getElementById(this.afterLoadMoreFocus);
        // console.log('append_selectedRoomMessages @ list', this.afterLoadMoreFocus, elem);
        // this.$refs["vs"].scrollIntoView("#d3", 500);
        if (elem) {
          elem.classList.add("cursor");
          elem.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        }
      });
      //this.afterLoadMoreFocus = null; // Check
      return true;
    },
    async focusMessage(id) {
      console.log('focusMessage', id);
      const elem = document.getElementById(id);
      this.$nextTick(() => {
        if (elem) {
          if (this.autoSelect) {
            elem.classList.add('cursor');
          }

          // const window_offset = elem.offset().top - window.scrollTop();
          elem.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
          // this.$refs["messageDisplay"].scrollIntoView(elem, true);

          // this.$refs["messageDisplay"].scrollBy(
          //     {
          //       dy: -100
          //     },
          //     500,
          //     "easeInQuad"
          // );
          // this.$refs["messageDisplay"].scrollBy("50px", 300);

          // this.$parent.scrollLock = false;
          // this.$parent.autoSelect = null;
          // this.$parent.toFocus = null;
        }
        this.scrollLock = false;
        this.autoSelect = null;

        // console.log('focusMessage end', this.getScrollXY() );
        // EventBus.$emit('NEW_ROOM_LOADING', false);
      });
      await this.$sleep(1100);
      // EventBus.$emit('NEW_ROOM_LOADED', true);
    },
    getScrollXY() {
      let scrOfX = 0, scrOfY = 0;
      if (typeof (window.pageYOffset) == 'number') {
        //Netscape compliant
        scrOfY = window.pageYOffset;
        scrOfX = window.pageXOffset;
      } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
        //DOM compliant
        scrOfY = document.body.scrollTop;
        scrOfX = document.body.scrollLeft;
      } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
        //IE6 standards compliant mode
        scrOfY = document.documentElement.scrollTop;
        scrOfX = document.documentElement.scrollLeft;
      }
      return [scrOfX, scrOfY];
    },
    scrollToLast() {
      let elems = this.$el.getElementsByClassName("private-chat-message");
      if (elems.length && this.$refs["privateMessageDisplay"]) {
        const last = elems[elems.length - 1];
        this.$refs["privateMessageDisplay"].scrollIntoView(last, true);
        //last.scrollIntoView({behavior: "auto", block: "center", inline: "nearest"});
        //this.$parent.scrollLock = false;
        // console.log('scrol2last end' );
        // EventBus.$emit('NEW_ROOM_LOADING', false);
      }
      this.scrollLock = false;
      this.autoSelect = null;
      this.scrollToLastMessage = false;
      // EventBus.$emit('NEW_ROOM_LOADED', true);
    },
    handleScrollCompleted() {
      console.log('handleScrollCompleted:');
      // this.$parent.scrollLock = false;
      // this.focus = null;
      this.$store.commit(`set_privateMessageFocus`, null);
      this.bookmark = null;
      // EventBus.$emit('NEW_ROOM_LOADED', true);
    },

    async getMorePrivateMessages(options) {
      const index = options.index
      const direction = options.direction;
      const indicator = options.indicator;
      console.log('getMorePrivateMessages', index, direction, indicator);
      if (this.selectedPrivateRoomGroups.indexOf(index) > -1) {
        // console.log('alreadyLoaded List: return ');
        return false;
      }
      if (direction === 'past') {
        this.afterLoadMoreFocus = document.getElementsByClassName('private-message-separator')[0].id;
        this.loadingPrevious = true;
      } else {
        const els = document.getElementsByClassName('chat-message');
        this.afterLoadMoreFocus = els[els.length - 1].id;
        this.loadingNext = true;
      }
      this.loadingNewRoom = true;
      let messagesWithPagination = await this.$store.dispatch('messagesWithPaginationPrivate',
          {
            roomId: this.privateRoom._id,
            flag: 1,
            direction: direction,
            dates: [index]
          });

      if (indicator) {
        this.scrollLock = true;
        setTimeout(() => {
          // this.focus = indicator;
          this.$store.commit(`set_privateMessageFocus`, indicator);
        }, 300);
      }

      // console.log('his.afterLoadMoreFocus ', this.afterLoadMoreFocus);
      this.loadingPrevious = false;
      this.loadingNext = false;
      // this.loadingNewRoom = false;
    },
    async fetchMessagesPrivate() {
      this.afterLoadMoreFocus = null;
      let messages = document.getElementsByClassName("pcje");
      if (messages.length) {
        [].forEach.call(messages, function (el) {
          el.className = el.className.replace(/\bcursor\b/, "");
        });
        let elem = messages[0];
        // console.log('private next cursor is ' , elem, elem.innerText);
        this.afterLoadMoreFocus = elem.id;
      }
      try {
        this.$store.commit('set_flagPrivate', 2);
        const response = await this.$store.dispatch('messagesWithPaginationPrivate',
            {
              roomId: this.privateRoom._id,
              flag: this.$store.getters.get_flagPrivate
            });

        console.log('fetchMessagesPrivate Success', response);
      } catch (e) {
        console.log('fetchMessagesPrivate Error', e)
      }

      this.loadingMore = false;
    },

    async acceptInvitation() {
      const currentPrivateRoom = this.privateRoom;
      const {data, status} = await axios.put(`/rooms/${currentPrivateRoom._id.toString()}`, {
        'privateRoomAccept': true
      });

      if (status >= 200 && status < 300) {
        this.$store.commit('set_privateSelectedRoom', data);
      } else {
        this.errorModal(data.message);
      }
    },
    errorModal(dataMessage) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          'dataMessage': dataMessage
        },
        component: ErrorForm,
        width: 540,
        customClass: 'error-modal',
        trapFocus: true
      });
    },
    spanMessage() {
      if (this.userDataAfterLogIn._id === this.privateRoom.owner &&
          this.$store.getters.get_messagesWithPaginationPrivate.length === 1) {
        return `<b>${this.privateChatWithMember.user.username}</b> have not yet accepted your private chat invitation`
      } else if (this.userDataAfterLogIn._id === this.privateRoom.owner &&
          this.$store.getters.get_messagesWithPaginationPrivate.length < 1) {
        return `
                This is the 1st time that you try to speak with <b>${this.privateChatWithMember.user.username}</b>.
                <br/>
                You are allowed to send him ONLY one (1) message.
                <br/>
                Please be kind and specific.
              `
      } else {
        return `
                You have not accept the invitation of <b>${this.privateChatWithMember.user.username}</b>.
                <br />
                If you wish to continue this private chat please click the button below
              `
      }
    },
  },
}
</script>

<style>

#private-chat-group-list {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  width: 100%;
}

#private-chat-group-list ul {
  /**overflow: auto;
  height: 100%;
  max-height: 100%;*/
}

#private-chat-group-list .message {
  background-color: transparent !important;
}

#private-chat-group-list .msg-content {
  background-color: #4a4a4a;
}


#private-chat-group-list .private-message-separator .date-text {
  font-size: 13px;

  color: rgba(255, 255, 255, 0.35);
}

#private-chat-group-list .date-side-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.35);
  margin: 30px;
}

#private-chat-group-list .message-seperator .date-text {
  font-size: 13px;
  color: #d3d3d3;
}

#private-chat-group-list .date-side-line {
  height: 1px;
  background-color: #c8d0d8;
  margin: 30px;
}

#load-more-private-chat-messages {
  margin: 0;
  color: #dddddd;
  /**background-color: #1a1c1f;*/
  background-color: #594b6f;
  border: 0px solid #1a1c1f;

}

.pcje.cursor {
  /**background-color: #6f6d4b;*/
}

.error-modal h4 {
  margin: 0;
  font-family: Circular, sans-serif;
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3.07px;
  color: #8356ff;
}

.error-modal h3 {
  font-family: Circular, sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #8356ff;
}

.error-modal.modal .modal-content {
  background-color: #1e2124 !important;
  max-width: 420px !important;
  padding: 1.5em 1em 1em;
  border-radius: 17px !important;
  /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
}

.error-modal-footer {
  margin-top: 1em;
  background-color: transparent;
  padding: 0;
  width: 100%;
  text-align: center;
}

</style>
