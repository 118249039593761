
<template>
  <div class="streamer-single streamer-owner-single">
    <div class="video-thumb"
         :style="{ 'background-image': `url(${setThumb()})` }"
    ><a href="#" @click.prevent="joinStream">&nbsp;</a></div>
    <div class="video-info">
      <p class="video-info-title" ><a class="join-stream-link" href="#" @click.prevent="joinStream">{{streamer.name}}</a></p>
      <p class="video-info-user"><span class="streamer streamer-image" :style="{ 'background-image': `url(${setAvatar(userDataAfterLogIn.image)})` }" >&nbsp;</span>
        <span class="streamer streamer-username">{{userDataAfterLogIn.username}}</span></p>
      <!--p class="video-info-metrics"><fa-icon icon="eye" scale="1" /><span>2303</span></p-->
<!--      <popper-->
<!--          trigger="hover"-->
<!--          :options="{-->
<!--                      placement: 'bottom',-->
<!--                      modifiers: {-->
<!--                        offset: { offset: '0, 0', enabled: true }-->
<!--                      }-->
<!--                  }">-->
<!--        <div v-if="streamer.description" class="popper toolip-popper streamer-desc ">{{streamer.description}}</div>-->
<!--        <span v-if="streamer.description"  class="video-info-desc" slot="reference" >{{streamer.description}}</span>-->
<!--        <span v-if="!streamer.description"  class="video-info-desc" slot="reference" >This is my streaming session!</span>-->
<!--      </popper>-->
      <!--p v-if="streamer.description"  class="video-info-desc">{{streamer.description}}</p-->
      <!--p v-if="!streamer.description"  class="video-info-desc">This is my streaming session!</p-->
    </div>
    <div class="video-misc">&nbsp;
      <b-dropdown aria-role="list" class="chat-message-actions is-pulled-right is-bottom-left">
        <b-icon  style="color: #4a4a4a;" icon="dots-vertical" slot="trigger"></b-icon>
        <b-dropdown-item aria-role="listitem" @click="stopStream">Stop</b-dropdown-item>
        <b-dropdown-item aria-role="listitem" @click="streamSettings">Settings</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>




<script>

// import Popper from 'vue-popperjs';

export default {
  name: "StreamerOwnerSingle",
  props:['streamer'],
  components: {
    // 'popper': Popper
  },
  computed: {
    selectedStream() {
      return this.$store.getters.get_selectedStream;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    myBroadcasting() {
      return this.$store.getters.get_myBroadcasting;
    },
    videoThumbDefault () {
      return require('@/assets/img/profit-video-placeholder-1x.png');
    },
    defaultOwnerImage () {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    }
  },
  created() {
    console.log('streamSettings', this.streamer);
  },
  methods: {
    joinStream() {
      this.$store.commit('set_selectedStream', this.streamer);
    },
    stopStream() {
      console.log(' stopStreamButton')
      let connection = this.$store.getters.get_connectionInfo;
      this.$store.commit('CAMERA_WORKING', false); // Check
      this.$store.dispatch('reloadStreamers'); // Temp
      connection.attachStreams.forEach(function(stream) {
        stream.stop();
      });
    },
    streamSettings() {
      console.log('streamSettings', this.streamer);
    },
    setThumb() {
      if (this.streamer.image) {
        if ('http' === this.streamer.image.slice(0, 4)) {
          return this.streamer.image;
        } else {
          if ('broadcast' === this.streamer.image.slice(0, 9)) {
            return  this.$imageurl + '/' + this.streamer.image;
          } else {
            return this.videoThumbDefault;
          }
        }
      }
      return this.videoThumbDefault;
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return  this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },

  }


}
</script>

<style>

.streamer-owner-single {

}


/**
.streamer-single {
  width: 100%;
  display: flex;

}


.streamer-username {
  text-transform: capitalize;
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 9px;
  min-width: 100px;
}

.streamer-image {
  width: 32px;
  height: 32px;
  max-width: 32px;
  max-height: 32px;
  margin: 0 9px 0 0;
  padding: 8px 8px 8px 9px;
  border-radius: 50%;
  background-color: #5f5bfc;
  background-position: center;
  background-size: cover;
  display: table-cell;
}
.streamer-button {
  margin: 0 12px;
}



.video-thumb {
  height: 90px;
  max-height: 90px;
  width: 30%;
  max-width: 30%;
  display: inline-block;
  background-position: center;
  background-size: contain;
}

.video-thumb a {
  height: 100%;
  width: 100%;
  display: block !important;
  border: 1px solid rgb(63, 63, 64);

}


.video-info {
  display: inline-block;
  width: 60%;
  max-width: 60%;
  padding: 0 6px;
}

.video-info p {
  margin-bottom: 3px;
}

.video-info a {
  color: #7957d5;

}

.video-info a:hover {
  color: #7c7c7c;
  text-decoration: none;
}


.video-info-title {

}

.video-info-user {

}

.video-info-metrics {
  margin-top: 5px;
  padding-left: 3px;
  font-size: 10px;
  padding-top: 5px;
}


.video-misc {
  display: inline-block;
  width: 10%;
  max-width: 10%;
}
*/


</style>
