<template>
  <div id="sidebar-search" class="widget-wrapper">
    <div  class="sidebar-header" >
      <b-button class="sidebar-button close darker"
                style="position: absolute; left: 10px; background-color: transparent;"
                @click="closeSidebar"
      >
        <img :src="closeSidebarIcon" style="" />
      </b-button>
            <p style="text-transform: capitalize;">
              <span v-if="selectedRoom">{{selectedRoom.name}}</span>
              <span v-else>Search Room</span>
            </p>
    </div>
    <b-field id="sidebar-search-bar">
      <b-input
          :placeholder="'Search in ' + room.name + '...'"
          type="search"
          v-model="text"
          icon="search"
          icon-right="times"
          icon-right-clickable
          @icon-right-click="text = ''"
      />
    </b-field>
    <div id="sidebar-search-results" v-if="showSearch">
      <vueScroll :ops="ops" id="sidebar-search-results-scroll" v-if="messages.length">
            <a v-for="message in messages"
                class="room-search-result"
                :key="message._id"
                @click.prevent="goToMessage(message)">
              <p class="result-author">{{ message.user[0].username }}</p>
              <p class="result-message" >
              <span v-for="(word, i) in getPartials(message.message)"
                   :key="i"
              ><span :class="(word.trim().toLowerCase() === text.trim().toLowerCase()) ? 'highlight' : ''">{{word}}</span><span>&nbsp;</span></span>
              </p>
              <span class="result-date" v-if="today - new Date(message.date) > 2 * 24 * 60 * 60 * 1000 ">{{ new Date(message.date.split('T')[0]).toLocaleDateString(`${$defaultLocale}`) }}</span>
              <span class="result-date" v-else-if="today - new Date(message.date) <= 2 * 24 * 60 * 60 * 1000 && today - new Date(message.date) > 24 * 60 * 60 * 1000">Yesterday</span>
              <span class="result-date" v-else-if="today - new Date(message.date) <= 24 * 60 * 60 * 1000">Today</span>
            </a>
      </vueScroll>
      <div v-else style="width: 100%;text-align: center; color: #BCBCBC;">No Messages Found</div>
<!--        <p class="results-heading"></p>-->
    </div>

  </div>
</template>

<script>
import { debounce } from 'lodash';
import axios from '@/axios'
import vueScroll from "vuescroll";

export default {
  name: 'RoomSearch',
  components: {
    vueScroll
  },
  data() {
    return {
      messages: [],
      selected: null,
      isFetching: false,
      text: '',
      query: '',
      page: 1,
      totalPages: 1,
      showSearch: false,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {}
      },
      today: new Date(Date.now()),
    }
  },
  watch: {
    selected(val) {
      console.log('selected', val);
    },
    text(val) {
      this.getAsyncData(val);
    },
    messages(val) {
      console.log('messages', val);
    },
    room(newVal, oldVal) {
      console.log('room is : ssss', newVal, oldVal);
      if (newVal._id !== oldVal._id) {
        this.messages = [];
        this.query = '';
        this.text = '';
      }
    }
  },
  computed: {
    server() {
      return this.$store.getters.get_selectedServer;
    },
    room() {
      return this.$store.getters.get_selectedRoom
    },
    closeSidebarIcon () {
      return require('@/assets/icons/close-side.svg');
    },
    selectedRoom() {
      return this.$store.getters.get_selectedRoom
    },
  },
  created() {
    // console.log('room search', this.room);
  },
  methods: {
    getPartials(msg) {
      return msg.split(' ');
    },
    setSelected(option) {
      this.selected = option;
    },
    getAsyncData: debounce(function (query) {
      // String update
      if (this.query !== query) {
        this.query = query
        this.messages = []
        this.page = 1
        this.totalPages = 1
      }
      // String cleared
      if (!query.length) {
        this.messages = []
        this.page = 1
        this.totalPages = 1
        return
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return
      }
      this.isFetching = true

      axios.get(`/rooms/get-messages/${this.room._id}/${query}`)
          .then(({data}) => {
            this.showSearch = true;
            data.forEach(message => {
              this.messages.push(message)
            })

            this.page++;
            this.totalPages = data.total_pages; // Todo: do we need it here?
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => {
            this.isFetching = false
          })
    }, 500),
    getMoreAsyncData: debounce(function () {
      this.getAsyncData(this.query);
    }, 250),
    async goToMessage(message) {
      // console.log('message:', message);
      // console.log('server', this.server);

      const request = {
        serverId: this.room.server,
        roomId: this.room._id,
        messageId: 'chat-message-' + message._id,
        date: message.date,
        server: this.server,
        messageObjectId: message._id,
        searchRequest: true,
        action: 'search'
      }

      // const toGo = `/servers/${this.server.slug}?room=${message.room._id}&msg=${message._id}`;

      // if (this.$route.fullPath === toGo) {
      //   //setTimeout(()=> { EventBus.$emit('autoSelect', request ); }, 600);
      // } else {
      //   this.$router.replace(toGo);
      //   //setTimeout(()=> { EventBus.$emit('autoSelect', request ); }, 600);
      // }

      this.$store.commit('set_autoSelect', request);
      // setTimeout(() => {
      //   // this.$store.commit('set_autoSelect', null);
      //   // this.$store.commit('set_selectedRoom', null);
      //   // this.$store.commit('set_selectedRoomMessages', []);
      //   // this.$store.commit('set_selectedRoomGroups', []);
      //   // this.$store.commit('set_previousMessagesIndex', null);
      //   // this.$store.commit('set_nextMessagesIndex', null);
      //   this.$store.commit('set_autoSelect', request);
      // }, 500);

      // Todo maybe get a feedback before committing
      //this.$store.commit('set_selectedServer', server);
      // setTimeout(()=> { this.$store.commit('set_selectedServer', server); }, 360);
      //this.$router.push('/servers/' + server.slug + '?room=' + mention.room._id._id + '&mention=' + mention._id);
      //await this.$store.commit('set_selectedServer', server);
      //await this.$store.commit('set_selectedRoom', mention.room);
      //this.$router.push({ path: '/servers/' + server.slug + '?room=' + mention.room._id , params: { server_id: mention.room.server , room: mention.room._id,  mention: mention._id } });
    },
    closeSidebar() {
      this.$store.dispatch('set_openCloseSidebar', 'close');
    },
    getFullDate(date) {
      const messageDate = new Date(date);
      const month = messageDate.getMonth();
      const day = messageDate.getDate();
      const year = messageDate.getFullYear();

      return `${day}-${month}-${year}`
    }
  }
}
</script>

<style>

#sidebar-search {
  padding: 0;
}


#sidebar-search-section {
  position: relative;
}

#sidebar-search-section .field {
  margin-bottom: 0;
  display: inline-block;
}

#sidebar-search-section i {
  color: #424345;
}

#sidebar-search-bar {
  margin-bottom: 26px;
  padding:11px 22px;
}

#sidebar-search-bar .control {
  border-style: solid;
  border-color: #C5C5C5;
  border-width: 0 0 1px 0;
  display: flex;
  align-items: center;
}

#sidebar-search-bar .icon {
  color: #66686B;
  font-size: 17px;
}

#sidebar-search-bar input {
  width: 100%;
  color: rgba(255, 255, 255, 1);
  border-radius: 0;
  background-color: transparent;
  /*border-style: solid;*/
  /*border-color: #C5C5C5;*/
  border-width: 0;
  font-size: 15px;
  line-height: 19px;
  letter-spacing: -0.1px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 6px;
}

#sidebar-search-bar input::placeholder {
  color: rgba(255, 255, 255, 0.34);
}

#sidebar-search-bar input:focus,
#sidebar-search-bar input:focus-visible {
  border: 1px solid #313131;
  border-radius: 8px;
}

/*#sidebar-search-bar .dropdown-content {*/
/*  !*height: calc(var(--results));*!*/
/*  !*height: 245px;*!*/
/*  max-height: 296px;*/
/*  width: 100%;*/
/*  overflow: hidden;*/
/*  background-color: #23272a;*/
/*  padding: 0;*/
/*}*/

#sidebar-search-bar .dropdown-content button {
  border-width: 0;
  border-bottom-width: 0px;
  border-color: #7c7c7c;
  border-radius: 0;

}

#sidebar-search-results {
  height: calc(100% - 70px - 70px);
  max-height: calc(100% - 70px - 70px);
  width: 100%;
  overflow: hidden;
  background-color: #23272a;
  padding: 0 22px;
}


#sidebar-search-results button {
  border-width: 0;
  border-bottom-width: 0px;
  border-color: #7c7c7c;
  border-radius: 0;
}

.room-search-result {
  margin-bottom: 10px;
  padding: 0 0 10px;
  background-color: transparent;
  position: relative;
  display: block;
  width: 100%;
  /*border-width: 0 0 1px 0;*/
  border-color: #2D3034;
  /*border-radius: 0;*/
  border-style: solid;
  /*border: 0 solid #2D3034;*/
  border-width:0 0 1px 0;
}

.result-author {
  color: #BCBCBC;
  position: relative;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.5px;
  width: 80%;
}

.result-message {
  color: #BCBCBC;
  position: relative;
  font-size: 15px;
  line-height: 19px;
  /*margin-bottom: 15px;*/
  text-overflow: ellipsis;
  width: 80%;
}

.result-date {
  position: absolute;
  top: 0; right: 0;
  font-size: 14px;
  font-weight: normal;
  color: #BCBCBC
}

span.highlight {
  background-color: #22832B;
  padding: 1px 1px 5px;
}

</style>
