<template>
  <section id="navbar-search-section" class="navbar-search-section">

    <b-field id="navbar-search-bar"
             class="navbar-search-bar"
             :class="{
                'mobile' : window_width <= mobileBreakpoint,
                'desktop' : window_width > mobileBreakpoint,
              }"
    >
      <b-input
          placeholder="Search..."
          type="search"
          v-model="text"
          icon="search"
          icon-right="times"
          icon-right-clickable
          @icon-right-click="clearSearchBar"
          @keypress.native.enter="goToSearchResults"

      />
    </b-field>
    <div id="navbar-search-results"
         v-if="showSearchResult"
         :class="{
                'mobile' : window_width <= mobileBreakpoint,
                'desktop' : window_width > mobileBreakpoint,
         }"
    >
      <vueScroll :ops="ops" id="navbar-search-results-scroll">
        <a v-for="server in servers"
           class="navbar-search-result vcentered"
           :key="server._id"
           @click.prevent="goToServer(server)">
          <div class="result-title">{{server.name}}
<!--            <b-tag v-if="showTags" type="is-primary" class="search-tag" style="margin-left: 10px;" rounded>Server</b-tag>-->
          </div>
          <div class="result-type"><b-tag rounded>Server</b-tag></div>
        </a>

        <a v-for="room in rooms"
           class="navbar-search-result vcentered"
           :key="room._id"
           @click.prevent="goToRoom(room)">
          <div class="result-title">{{room.name}}
<!--            <b-tag v-if="showTags" type="is-primary" class="search-tag" style="margin-left: 10px;" rounded>Room</b-tag>-->
          </div>
          <div class="result-type"><b-tag rounded>Room</b-tag></div>
        </a>

        <a v-for="user in users"
           class="navbar-search-result vcentered"
           :key="user._id"
           @click.prevent="goToUser(user)">
          <div class="result-title">{{user.username}}
<!--            <b-tag v-if="showTags" type="is-primary" class="search-tag" style="margin-left: 10px;" rounded>User</b-tag>-->
          </div>
          <div class="result-type"><b-tag rounded>User</b-tag></div>
        </a>
      </vueScroll>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce';
import axios from '@/axios';
import vueScroll from "vuescroll";
import EventBus from "@/eventBus";

export default {
  name: "SearchBarNative",
  props:['showSearch'],
  components: {
    vueScroll
  },
  data() {
    return {
      imageUrl: this.$imageurl,
      mobileBreakpoint: 768,
      showTags: true,
      servers: [],
      rooms: [],
      users: [],
      data: [],
      selected: null,
      isFetching: false,
      name: '',
      page: 1,
      totalPages: 1,
      text: '',
      resultHeight: 210,
      totalItems: 0,
      // showSearch: true,
      showSearchResult: false,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {}
      },
    }
  },
  created() {
    this.mobileBreakpoint = this.$mobileBreakpoint;

    console.log('thsishjpww', this.showSearch)
  },
  watch: {
    selected(val) {
      console.log('selected', val);
    },
    text(val) {
      this.getAsyncData(val);
    },
    totalItems(val) {
      const h = (this.totalItems > 0) ? (this.totalItems * 40) + 93 : 0;
      document.documentElement.style.setProperty('--results', `${h}px`);
    },
    showSearch(val) {
      if (!val) {
        this.text = '';
      }

    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    userServers() {
      return this.$store.getters.get_userServers;
    },
    noResults() {
      return !this.servers.length && !this.rooms.length && !this.users.length;
    },
    window_width() {
      return this.$store.getters.get_window_width;
    },
  },
  methods: {
    goToUser(user) {
      this.resetSearch();
      if (!this.isAuthenticated) {
        this.$store.commit('set_openCloseLogIn');
        return;
      }
      const uri = `/user-profile/${user.username.toLowerCase()}`;
      if (this.$route.name !== 'UserProfile') {
        this.$router.push(uri);
      } else {
        if (this.$route.path !== uri) {
          this.$router.replace(uri);
          EventBus.$emit('showUserProfile', user.username);
        }
      }

    },
    async goToRoom(room) {
      this.resetSearch();
      if (!this.isAuthenticated) {
        this.$store.commit('set_openCloseLogIn');
        return;
      }
      let server;
      const uri = `/servers/${room.server.slug}/${room.slug}`;
      try {
        let {data} = await axios.get('/servers/slug/' + room.server.slug);
        server = data;
      } catch (e) {
        console.log('error:', e);
      }
      if (!server) {
        return false;
      }
      // console.log('goToRoom server', server);
      const request = {
        serverId: room.server._id,
        roomId: room._id,
        server: server,
        searchRequest: false,
        action: 'search'
      }

      if (this.$route.name !== 'Chat') {
        this.$router.push(uri);
      } else {
        if (this.$route.path !== uri) {
          this.$router.push(uri);
        }
      }

      setTimeout(()=> {
        this.$store.commit('set_autoSelect', null);
        this.$store.commit('set_selectedRoom', null);
        this.$store.commit('set_selectedRoomMessages', []);
        this.$store.commit('set_selectedRoomGroups', []);
        this.$store.commit('set_previousMessagesIndex', null);
        this.$store.commit('set_nextMessagesIndex', null);
        this.$store.commit('set_autoSelect', request);
      }, 200);

    },
    goToServer(server) {
      this.resetSearch();
      if (this.isAuthenticated) {
        const id = server._id;
        if (this.userServers[id]) {
          this.$router.push({name: 'Chat', params: {id: server.slug, action: 'visit', server_id: server._id}});
        } else {
          this.$router.push({name: 'Chat', params: {id: server.slug, action: 'join', server_id: server._id}});
        }
        //this.$router.replace('/servers/' + server.slug);
        //this.$store.commit('set_selectedServer', server);
      } else {
        this.$store.commit('set_openCloseLogIn');
      }
    },
    resetSearch() {
      this.servers = [];
      this.rooms = [];
      this.users = [];
      this.data = [];
      this.name = '';
      this.text = '';
    },
    async goToSearchResults() {

      // this.showSearch = false;

      const query = this.text;
      this.text = '';

      await this.$router.push({
        name: 'Search',
        params: {
          query
        }
      })

    },
    // You have to install and import debounce to use it,
    // it's not mandatory though.
    getAsyncData: debounce(async function (name) {
      //console.log('text', name);
      this.totalItems = 0;
      // String update
      if (this.name !== name) {
        this.name = name
        this.data = [];
        this.servers = [];
        this.rooms = [];
        this.users = [];
        this.page = 1
        this.totalPages = 1
      }
      // String cleared
      if (!name.length) {
        this.data = [];
        this.servers = [];
        this.rooms = [];
        this.users = [];
        this.page = 1
        this.totalPages = 1
        return
      }
      // Reached last page
      if (this.page > this.totalPages) {
        return;
      }
      this.isFetching = true
      const serverRequest = axios.get(`/servers/search/${name}`)
          .then(({data}) => {
            this.showSearchResult = true;
            console.log('servers', data.length);
            this.totalItems = this.totalItems + data.length;
            //data.forEach((item) => this.data.push(item))
            data.forEach(item => {
              this.servers.push(item);
            });
            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            throw error;
          })

      const roomRequest = axios.get(`/rooms/search/${name}`)
          .then(({data}) => {
            this.showSearchResult = true;
            console.log('rooms', data.length);
            this.totalItems = this.totalItems + data.length;
            data.forEach(item => {
              if (!item.is_private && !item.name.includes('Broadcast')) {
                this.rooms.push(item);
              }
            });

            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            throw error;
          })

      const userRequest = axios.get(`/users/search/${name}`)
          .then(({data}) => {
            this.showSearchResult = true;
            console.log('users', data.length);
            this.totalItems = this.totalItems + data.length;
            data.forEach(item => {
              this.users.push(item);
            });
            this.page++;
            this.totalPages = data.total_pages;
          })
          .catch((error) => {
            throw error;
          })
      // .finally(() => {
      //   this.isFetching = false
      //   console.log('finally');
      // });

      Promise.all([serverRequest, roomRequest, userRequest])
          .then(() => {
            console.log('finally');
            this.isFetching = false;
          })
    }, 500),
    getMoreAsyncData: debounce(function () {
      this.getAsyncData(this.name);
    }, 250),
    clearSearchBar() {
      this.text = '';
      this.showSearchResult = false;
    }

  }
}
</script>

<style>

#navbar-search {

}

#navbar-search-section {
  position: relative;
}

#navbar-search-section .field {
  margin-bottom: 0;
  display: inline-block;
}

#navbar-search-section i {
  color: #424345;
}

#navbar-search-bar .control {

}


#navbar-search-bar input {
  width: 470px;
  color: rgba(255, 255, 255, 0.99);
  border-radius: 8px;
  background-color: #2e3135;
  /*border: 1px solid #313131;*/
  border: 1px solid #3e3e3e;
}

@media (max-width: 1200px) {
  #navbar-search-bar input {
    width: 378px;
  }
}

@media (max-width: 992px) {
  #navbar-search-bar input {
    width: 340px;
  }
}

@media (max-width: 768px) {
  #navbar-search {
    z-index: 999;
  }
  #navbar-search-section {
    width: calc(100% - 60px);
  }
  #navbar-search-bar{
    width: 80%;
    display: inline-block;
  }
  #navbar-search-results {
    width: 80%;
  }
  #navbar-search-bar input {
    width: 100%;
  }
}

@media (max-width: 480px) {
  #navbar-search-bar {
    width: 100%;
    display: inline-block;
  }
  #navbar-search-bar input {
    width: 100%;
  }
}



#navbar-search-bar .icon {
  color: #6668;
  font-size: 17px;
}


#navbar-search-bar input::placeholder {
  color: rgba(255, 255, 255, 0.34);
}

#navbar-search-bar input:focus,
#navbar-search-bar input:focus-visible,
#navbar-search-bar input:focus-within {
  /*border: 1px solid #313131;*/
  /*background-color: #5D4AB8;*/
  background-color: #2E3135;
  /*background-color: transparent;*/
  border: 1px solid #8064F6;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 1) !important;
  box-shadow: 0 0 0 0.09em rgba(121, 87, 213, 1) !important;
}

#navbar-search-results {
  position: absolute;
  top: 50px;
  right: 0px;
  height: calc(var(--results) - 1px);
  /**height: 245px;*/
  max-height: 296px;
  width: 100%;
  overflow: hidden;
  /*background-color: #23272a;*/
  padding: 0;
  border-radius: 8px;
  background: #2E3135;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

#navbar-search-results.mobile {
  border-radius: 0;
}


#navbar-search-results button {
  border-width: 0;
  border-bottom-width: 0px;
  border-color: #7c7c7c;
  border-radius: 0;
}

.navbar-search-result {
  /*margin-bottom: 10px;*/
  padding: 7px 15px;
  background-color: transparent;
  position: relative;
  width: 100%;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.53px;
  color: #FFFFFF;
  /*border-width: 0 0 1px 0;*/
  /*border-color: #2D3034;*/
  /*border-radius: 0;*/
  /*border-style: solid;*/
  /*border: 0 solid #2D3034;*/
  /*border-width:0 0 1px 0;*/border-color: transparent;
}



.navbar-search-result:hover {
  background-color: #5D4AB8;
  color: #FFFFFF;
}

#navbar-search-results.mobile .navbar-search-result {
  padding: 13px 21px;
  border-bottom:  1px solid rgba(62, 64, 66, 1);
}

.result-title {
  float: left;
  display: inline-block;
  width: 70%;
  text-align: left;
}

.result-type {
  float: left;
  display: inline-block;
  width: 30%;
  text-align: right;
}

.result-type .tag {
  height: 1.8em;
  line-height: 1.5;
  padding-left: 0.55em;
  padding-right: 0.55em;
  min-width: 47px;
  background-color: #4a4a4a;
  color: #CCCBCB;
}

.results-section {
  display: inline-block;
  width: 100%;
  max-width: 100%;
}

.results-heading {
  text-align: left;
  font-size: 16px;
  padding: 6px 12px;
  color: #664bf3;
  font-weight: 600;
}

.results-section button {
  background-color: transparent;
  color: #7c7c7c;
}

.results-section button:hover {
  background-color: #303538;
  color: #7c7c7c;
}

.results-link {
  width: 100%;
  display: block;
  text-align: center;
  padding: 0.25em;
  text-decoration: none;
}

.results-link:hover {
  background-color: #303538;
  color: #7c7c7c;
  text-decoration: none;
}


.search-toggle span {
  margin-top: 2px;
}


</style>
