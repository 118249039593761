<template>
  <section>
    <b-modal id="join-server" v-model="showJoinServer" :width="540" scroll="keep" :on-cancel="handleCancel">
      <h4>PROFIT</h4>
      <h2 v-if="server" class="modal-title-thin">{{server.name}}</h2>
      <b-steps
          v-model="activeStep"
          :animated="isAnimated"
          :rounded="isRounded"
          :has-navigation="hasNavigation"
          :icon-prev="prevIcon"
          :icon-next="nextIcon"
          :label-position="labelPosition"
          :mobile-mode="mobileMode"
          >
        <b-step-item step="0" label="Join Server" :clickable="isStepsClickable">
<!--          <p class="signup-note">You are not member of this server yet.</p>-->
<!--          <p v-if="server.price > 0" class="signup-note">-->
<!--            Access to server requires a subscription.<br/>-->
<!--            Price: {{server.price}} € / per {{server.intervalBillingCycleCount === 1 ? server.billingCycle : server.intervalBillingCycleCount + ' ' + server.billingCycle + 's'}}-->
<!--          </p>-->
<!--          <p v-else class="signup-note">This Server is Free of Charge.</p>-->
          <div class="row vcentered" style="padding: 12px; background-color: #2D3034; border-radius: 10px; height: 60px;">
            <div id="join-server-modal-thumb"
                 :style="{ 'background-image': `url(${setThumb(server.image)})` }"
            >
            </div>
            <div id="join-server-modal-text">
              <p class="title">{{server.name}}</p>
              <p class="subtitle">{{server.subscriptions.length}} members</p>
            </div>
          </div>
          <footer id="join-server-footer" class="footer">
            <b-button
                v-if="server.price > 0"
                class="submit"
                type="submit"
                @click="activeStep = 1"
            >Subscribe</b-button>
            <b-button
                v-else
                class="submit"
                type="submit"
                @click="handleJoin()"
            >Join</b-button>
            <p class="bottom-link" style="margin-top: 21px;"><a style="color: white" href="#" @click.prevent="handleCancel()">Cancel</a></p>
          </footer>
        </b-step-item>
        <b-step-item step="1" label="Set a Payment" :clickable="isStepsClickable">
          <StripePayment
              :product="server"
              :isServer="true"
              v-on:subscriptionCreated="subscriptionCreated()"
              v-on:closeJoin="handleCancel()"
          />
        </b-step-item>
        <b-step-item step="2" label="Joined Server" :clickable="isStepsClickable">
          <h1 style="text-align: center;font-size: 120%;margin-top: 1em;">Welcome to {{server.name}}</h1>
          <sweetalert-icon icon="success"></sweetalert-icon>
          <footer id="join-server-success-footer" class="footer">
            <p class="signup-note"></p>
            <b-button
                class="submit"
                type="submit"
                @click="handleClose()"
            >Close</b-button>
          </footer>
        </b-step-item>
        <b-step-item step="3" label="Join Server Error" :clickable="isStepsClickable">
          <h1 v-if="errorMsg" style="text-align: center;font-size: 120%;">{{errorMsg}}</h1>
          <h1 v-if="!errorMsg"  style="text-align: center;font-size: 120%;">Error in Joining Room</h1>
          <sweetalert-icon icon="error"></sweetalert-icon>
          <footer id="joined-server-failed-footer" class="footer">
            <b-button
                class="submit"
                type="submit"
                @click="handleCancel()"
            >Close</b-button>
          </footer>
        </b-step-item>

      </b-steps>

    </b-modal>
  </section>
</template>

<script>
import StripePayment from '@/components/Payments/StripePayment';
import axios from "@/axios";
import SweetalertIcon from "vue-sweetalert-icons";
//import { loadStripe } from '@stripe/stripe-js';
//import socket from '../../socket';


export default {
  name: 'JoinServer',
  components: {
    StripePayment,
    SweetalertIcon
  },
  data(){
    return {
      showJoinServer: true,
      loading: false,
      today : new Date(),
      buttonDisabled: true,
      activeStep: 0,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: false,
      hasNavigation: false,
      customNavigation: false,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      errorMsg: null,
    }
  },
  props: [
      'server'
  ],
  async created() {
    // console.log(this.server);
  },
  computed: {
    userServers() {
      return this.$store.getters.get_userServers;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    serverThumbDefault () {
      return require('@/assets/img/server-thumb-default.png');
    },
  },
  methods: {
    async handleJoin() {
      this.loading = true;
      if (this.server.price === 0) {
        try {
          let data = {
              "user": this.userDataAfterLogIn._id,
              "amountPaid": 0,
              "server": this.server._id,
              "endDate": null,
              "isOwner" : false,
              "indefinite": true,
          };
          console.log('handleJoin:', data);

          let response = await axios.post('/subscriptions', data);
          this.loading = false;
          if (response.data) {
            this.$store.commit('set_selectedServer', this.server);
            let user = JSON.parse(this.$localStorage.get('user'));
            user.token = this.$localStorage.get('accessToken');
            let result = this.$store.dispatch('login', user); // Temp
            this.handleClose();
          }
        } catch (e) {
              this.loading = false
              this.handleCancel();
              console.log('error:', e);
        }
      } else {
        this.loading = false
        this.handleCancel();
      }

    },
    dateToLocalISO(date) {
      const off    = date.getTimezoneOffset()
      const absoff = Math.abs(off)
      return (new Date(date.getTime() - off*60*1000).toISOString().substr(0,23) +
          (off > 0 ? '-' : '+') +
          (absoff / 60).toFixed(0).padStart(2,'0') + ':' +
          (absoff % 60).toString().padStart(2,'0'))
    },
    async subscriptionCreated() {
      this.activeStep = 2;
      let user = JSON.parse(this.$localStorage.get('user'));
      user.token = this.$localStorage.get('accessToken');
      let result = await this.$store.dispatch('login', user); // Temp
      this.$store.commit('set_selectedServer', this.server);
    },
    handleCancel() {
      console.log('handleCancel:');
      this.$emit('closeJoinServer');
      this.$router.push({name: "Home"});
    },
    handleClose() {
      //this.$store.commit('set_openCloseJoinServer');
      this.$emit('closeJoinServer');
    },
    setThumb(image) {
        if (image && image.length) {
          if ('http' === image.slice(0, 4)) {
            return image;
          } else {
            if ('server' === image.slice(0, 6)) {
              return this.$imageurl + '/' + image;
            } else {
              return image;
            }
          }
        }
        return this.serverThumbDefault;
      },
  },
}
</script>

<style>

  #join-server {
    /**
    width: 414px;
    height: 726px;
    padding: 51px;
    border-radius: 17px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
    background-color: #1e2124;
    */
  }

  /*h2.modal-title {*/
  /*  margin: 0.25em 0 0.1em;*/
  /*  font-family: Circular, sans-serif;*/
  /*  font-size: 41px;*/
  /*  font-weight: 500;*/
  /*  font-stretch: normal;*/
  /*  font-style: normal;*/
  /*  line-height: normal;*/
  /*  letter-spacing: -1.16px;*/
  /*  color: rgba(255, 255, 255, 0.84);*/
  /*  overflow: hidden;*/
  /*  white-space: normal;*/
  /*}*/

  #join-server h4 {
    margin: 0;
    font-family: Circular, sans-serif;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 3.07px;
    color: #8356ff;
  }

  #join-server.modal .modal-content {
    background-color: #1e2124 !important;
    max-width: 420px !important;
    padding: 1.5em 1em 1em;
    border-radius: 17px!important;
    /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
  }

  #join-server-footer,
  #join-server-success-footer,
  #joined-server-failed-footer {
    margin-top: 1em;
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: center;
  }
  #join-server-modal-thumb {
    width: 29px;
    height: 29px;
    border-radius: 13px;
    background-color: #232728;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 3px;
  }

  #join-server-modal-text {
    font-family: 'Circular Std Book', sans-serif;
    margin-top: 2px;
    margin-left: 9px;
  }

  #join-server-modal-text .title {
    color: #CCCBCB;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Circular Std Book', sans-serif;
    margin: 0;
    width: 100%;
  }

  #join-server-modal-text .subtitle {
    font-family: 'Circular Std Book', sans-serif;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    width: 100%;
    letter-spacing: -0.34px;
  }


</style>
