import authActions from './actions';
import authMutations from './mutations';
import authGetters from './getters';

export default {
  state: {
      user:{},
      token: null,
      refreshToken: null,
      userDataAfterLogIn:{},
      connectedToSocket: false,
      userServers: null,
      userRooms: null,
  },
  getters: authGetters,
  mutations: authMutations,
  actions: authActions
}
