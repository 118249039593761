<template>
  <div class="private-chat-container" @click="openPrivateRoom(oppositeUser)">
    <span class="private-chat-user-image"
          :style="{ 'background-image': `url(${(oppositeUser.image) ? setAvatar(oppositeUser.image) : defaultUserImage})` }"
    />
    <div class="private-chat-info">
      <span class="private-chat-user-name">{{ oppositeUser.username }}</span>
      <span v-if="privateRoom.lastMessageDate" class="private-chat-last-message"> Last Message: {{
          privateRoom.lastMessageDate | moment("dddd[,] D MMMM YYYY")
        }}</span>
    </div>
    <span v-if="unreadMessages > 0" class="unread-messages">{{ unreadMessages }}</span>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import axios from '../../axios';

export default {
  name: 'PrivateChatListSingle',
  props: [
    'currentUser',
    'privateRoom',
  ],
  data() {
    return {
      oppositeUser: {},
      unreadMessages: 0,
    }
  },
  async created() {
    // console.log(`PrivateChatListSingle props: currentUser, privateRoom`, this.currentUser, this.privateRoom);
    // console.log(`PrivateChatListSingle privateRoom`, this.privateRoom);
    this.getOppositeUser();
    await this.getUnreadMessages();
    // await this.getUnreadMessages(this.privateRoom._id);
  },
  watch: {
    async privateRoom(newVal) {
      // console.log(`privateRoom`, newVal);
      await this.getUnreadMessages();
    }
  },
  computed: {
    defaultUserImage() {
      return this.$userDefaultImage;
    },
  },
  methods: {
    getOppositeUser() {
      if (this.privateRoom.users.length === 1) {
        this.oppositeUser = this.privateRoom.users[0];
      } else if (this.privateRoom.users.length > 1) {
        this.privateRoom.users.forEach(user => {
          if (user.username !== this.currentUser.username) {
            this.oppositeUser = user;
          }
        })
      }
    },
    async getUnreadMessages() {
      if (this.privateRoom.messages.length > 0) {
        this.unreadMessages = this.privateRoom.messages[0].messageCount;
      } else {
        this.unreadMessages = 0;
      }
    },
    setAvatar(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }

      return this.defaultOwnerImage;
    },
    async openPrivateRoom(member) {
      console.log(`openPrivateRoom`, member)
      this.profileModalUser = null;
      try {
        this.$store.commit('set_selectedPrivateRoomGroups', []);
        this.$store.commit('set_selectedPrivateRoomMessages', []);
        // console.log('clicked regular member is :  ', member);
        this.$store.commit('set_privateChatWithMember', member)
        const privateRoom = await this.$store.dispatch('createPrivateRoom', member);
        // console.log('ACTION RETURN SELECTED PRIVATE ROOM IF EXISTING OR NOT ', privateRoom);
        // Set an if here
        EventBus.$emit('sidebarClass', 'normal');
        this.$store.commit('set_privateSelectedRoom', privateRoom);
        this.$store.commit('set_openPrivateChat'); // Check
        EventBus.$emit('sidebarClass', 'normal');
        EventBus.$emit('selectedTab', 'chat');
        this.$store.commit('set_openPrivateChat');
        this.$store.commit('set_showLoadMoreMessagesPrivate', true);
        await this.$store.dispatch('messagesWithPaginationPrivate',
            {
              roomId: privateRoom._id,
              flag: 1,
            });

        this.unreadMessages = 0;
      } catch (e) {
        console.log('ERROR with openPrivateRoom  ', e);
      }
    },
  }

}
</script>

<style scoped>
.private-chat-container {
  /*border: solid 2px #7c7c7c;*/
  display: flex;
  padding: 0.2em 0.2em;
  margin: 0.3em 0.1em;
}

.private-chat-container:hover {
  border: solid 2px #7c7c7c;
  cursor: pointer;
}

.private-chat-user-image {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  margin: 0 9px 0 0;
  /*padding: 8px 8px 8px 9px;*/
  padding: 0;
  border-radius: 14px;
  background-color: #5f5bfc;
  background-position: center;
  background-size: cover;
  display: table-cell;
}

.private-chat-info {
  display: grid;
  margin-right: 0.3em;
}

.private-chat-user-name {
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: bold;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 9px;
}

.private-chat-last-message {
  display: table-cell;
  vertical-align: middle;
  color: #7c7c7c;
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  word-wrap: normal;
  max-width: 100%;
  overflow: hidden;
  padding-left: 9px;
}

.unread-messages {
  font-size: 1.7em;
  padding-left: 0.5em;
  color: #5f5bfc;
}
</style>
