<template>
  <div
      :class="{
            'private-chat-message': true,
            'private-last-message': true,
            'private-users-message': usersMessage,
            'private-non-users-message': !usersMessage,
        }"
  >
    <div v-if="message" class="msg-wrap">
      <div v-if="messageUser" :style="{ 'background-image': `url(${setAvatar(messageUser.image)})` }"
           class="message-avatar-container">
        <!--img :src="setAvatar(message.user.image)" /-->
      </div>
      <div v-if="!messageUser" :style="{ 'background-image': `url(${setAvatar(messageUser.image)})` }"
           class="message-avatar-container">
        <!--img :src="defaultOwnerImage" /-->
      </div>

      <div class="header-wrap" v-if="!usersMessage">
        <span class="subheading username">{{ messageUser.username }}</span>
        <span class="caption date-sent">{{ message.date | moment('LT') }}</span>
      </div>
      <div class="header-wrap" style="direction: ltr;" v-else>
        <span class="caption date-sent">{{ message.date | moment('LT') }}</span>
        <span class="subheading username">{{ messageUser.username }}</span>
      </div>

      <div class="message-wrapper">
        <PrivateChatSingleMessage
            :message="message"
        ></PrivateChatSingleMessage>
      </div>
    </div>
  </div>
</template>

<script>
import PrivateChatSingleMessage from "./PrivateChatSingleMessage";

export default {
  data() {
    return {}
  },
  name: "PrivateChatMessage",
  components: {
    PrivateChatSingleMessage
  },
  props: ['message'],
  created() {
    // console.log('message passed', this.message);
  },
  computed: {
    messageUser() {
      if (this.message.user.length) {
        return this.message.user[0];
      }
      return this.message.user;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    usersMessage() {
      return this.messageUser._id === this.userDataAfterLogIn._id;
    },
    isBase64() {
      return 'http' !== this.messageUser.user.image.slice(0, 4);
    },
    defaultOwnerImage() {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    }
  },
  methods: {
    setAvatar(image) {
      // console.log('reeeeee', image);
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            this.defaultOwnerImage;
          }
        }
      }
      return this.defaultOwnerImage;
    },
  },
}
</script>

<style lang="scss">

.private-chat-message {
  margin: 0px 40px;
  margin-bottom: 30px;

  .private-message-wrapper {
    max-width: 75%;
    margin-bottom: 20px;
  }

  &.private-users-message {
    border-radius: 14px;
    direction: rtl;

    .header-wrap {
      direction: rtl;
    }

    .private-message-wrapper {
      margin-left: auto;
    }
  }

  &.private-non-users-message {
    border-radius: 14px;
  }

  .private-message-divider {
    margin-top: 10px;
    max-width: calc(100% - 12px);
  }

  .container {
    padding-top: 0px;
  }

  .msg-wrap {

    .header-wrap {
      position: absolute;
      display: inline-block;
      vertical-align: top;

      .username {
        margin-left: 0px;
        /**font-size: 15px;*/
        font-weight: normal;
        color: #bcbcbc;
      }

      .date-sent {
        color: rgb(117, 117, 117);
        margin: 5px 6px 0 6px;
        padding: 5px 0 0 0px;
        font-size: 90%;
      }
    }

    .message-avatar-container {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      display: inline-block;
      margin: 0 8px 8px 8px;

      .img {
        -webkit-filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
        filter: drop-shadow(1px 1px 0 black) drop-shadow(-1px -1px 0 black);
      }

    }

    .msg-content-wrap {
      display: inline-block;
      position: relative;
      max-width: 95%;
      vertical-align: text-top;
      left: 2px;
      top: 15px;
      padding-right: 12px;

      .msg-content {
        margin-bottom: 0;
        overflow-wrap: break-word;
        font-size: 15px;
      }

      .msg-images {
        border: 1px black solid;
        border-radius: 3px;
      }

      &.pulled-aside {
        top: -7px;
        left: 48px + 7px;
      }
    }
  }
}


</style>
