<template>
  <div id="mentions-tab">
    <div  class="sidebar-header" >
      <b-button class="sidebar-button close darker"
                style="position: absolute; left: 10px; background-color: transparent;"
                @click="closeSidebar"
      >
        <img :src="closeSidebarIcon" style="" />
      </b-button>
      <p style="text-transform: capitalize;">
        <span>Mentions</span>
      </p>
    </div>
    <p v-if="loadingMentions" class="mention-loader"><span>Loading Mentions</span></p>
    <p class="mention-loader" v-if="!loadingMentions && (!mentions || !mentions.length)"><span>No Mentions Found</span></p>
    <vuescroll id="mentions-scroll" :ops="ops" class="main-scroll" v-if="mentions && mentions.length && !loadingMentions">
      <div
          class="user-mention"
          v-for="mention in mentions"
          :key="mention._id"
      >
        <div class="user-mention-wrapper" >
        <!--h3>{{mention.date | moment("D MMMM YYYY LT") }}</h3-->
        <p><span style="float: left;font-size: 15px;">{{mention.user.username}} said:</span><span style="float: right;font-size: 11px;color: #7c7c7c;">{{mention.room.name}}@{{getServerName(mention.room.server)}} {{mention.date | moment("D MMMM YYYY LT") }}</span></p>
        <p><a @click.prevent="goToMessage(mention)">{{mention.message}}</a></p>
        </div>
      </div>
    </vuescroll>
  </div>
</template>

<script>
import axios from "@/axios";
import vuescroll from 'vuescroll';
// import HomeCarousel from "@/components/HomeCore/HomeCarousel";
// import HomeFamousServersParent from "@/components/HomeFamousServersParent";
// import HomeLatestServersParent from "@/components/HomeLatestServersParent";
// import HomeCategoriesParent from "@/components/HomeCategoriesParent";
import EventBus from "@/eventBus";


export default {
  name: "UserMentions",
  props: ['user'],
  components: {
    vuescroll
  },
  computed: {
    userServers() {
      return this.$store.getters.get_userServers;
    },
    selectedRoom() {
      return this.$store.getters.get_selectedRoom;
    },
    closeSidebarIcon () {
      return require('@/assets/icons/close-side.svg');
    },
  },
  data() {
    return {
      mentions: null,
      roomToGo: null,
      loadingMentions: false,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {},
      },

    };
  },
  created() {
    this.getMentions();

  },
  methods:{
    async goToMessage(mention) {
      // this.$store.commit('set_autoSelect', null);
      const server = this.userServers[mention.room.server];
      if (server) {
        let request = {
          serverId: mention.room.server,
          roomId: mention.room._id,
          messageId: 'chat-message-' + mention._id,
          date: mention.date,
          server: server,
          action: 'mention'
        }
        // this.roomToGo = request;
        // this.$store.commit('set_showLoadMoreMessages', false);
        // let exist = '/servers/' + server.slug';' + '?room=' + mention.room._id;
        const toGo = '/servers/' + server.slug + '?action=mention&room=' + mention.room._id + "&msg=" + mention._id;
        // const toGo = '/servers/' + server.slug;
        // console.log('mentions go', this.$route);
        if (this.$route.fullPath === toGo) {
          //setTimeout(()=> { EventBus.$emit('autoSelect', request ); }, 600);
        } else {
          this.$router.replace(toGo);
          //setTimeout(()=> { EventBus.$emit('autoSelect', request ); }, 600);
        }

        setTimeout(()=> {
          this.$store.commit('set_autoSelect', null);
          this.$store.commit('set_selectedRoom', null);
          this.$store.commit('set_selectedRoomMessages', []);
          this.$store.commit('set_selectedRoomGroups', []);
          this.$store.commit('set_previousMessagesIndex', null);
          this.$store.commit('set_nextMessagesIndex', null);
          this.$store.commit('set_autoSelect', request);
        }, 500);

        // Todo maybe get a feedback before committing
        //this.$store.commit('set_selectedServer', server);
        // setTimeout(()=> { this.$store.commit('set_selectedServer', server); }, 360);
        //this.$router.push('/servers/' + server.slug + '?room=' + mention.room._id._id + '&mention=' + mention._id);
        //await this.$store.commit('set_selectedServer', server);
        //await this.$store.commit('set_selectedRoom', mention.room);
        //this.$router.push({ path: '/servers/' + server.slug + '?room=' + mention.room._id , params: { server_id: mention.room.server , room: mention.room._id,  mention: mention._id } });
      }

    },
    async getMentions() {
      this.loadingMentions = true;
      const {data} = await axios.get('/rooms/messages/get/mentions');
      if (data && data.length) {
        this.mentions = data;
        console.log('mentions go', this.mentions);
      }
      this.loadingMentions = false;
    },
    closeSidebar() {
      this.$store.dispatch('set_openCloseSidebar', 'close');
    },
    getServerName(server_id) {
      if (this.userServers && this.userServers[server_id]) {
        return this.userServers[server_id].name;
      }
      return '';
    }

  }
}
</script>

<style scoped>
  #mentions-tab {
   overflow: hidden;
    height: 100%;
    max-height: 100%;
    position: relative;
  }

  .user-mention {
    padding: 0.5em 0.75em;

    overflow: hidden;
    white-space: normal;

  }

  .user-mention-wrapper {
    margin: 0;
    border-bottom: 1px solid rgba(204, 204, 204, 0.1);
    padding-bottom: 5px;
  }

  .user-mention h3 {
    text-align: center;
    padding: 0.5em;
    background-color: #303538;
  }

  .user-mention p {
    /*text-align: left;*/
    padding: 0 0.25em;
    display: inline-block;
    width: 100%;
  }

  .user-mention p span {
    display: inline-block;
  }

  .user-mention a {
    margin-left: 3px;
    word-wrap: break-word;
    font-size: 15px;
    border-radius: 0 15px 15px 15px;
    padding: 6px 15px 6px 15px;
    width: auto;
    /*max-width: calc(100% - 90px);*/
    display: inline-block;
    background-color: #594b6f;
    color: #dddddd;
    overflow: hidden;
    white-space: normal;
  }

  .mention-loader {
    text-align: center;
    padding: 1em;

  }

  .mention-loader span {
    font-size: 15px;
    border-radius: 15px;
    padding: 6px 15px 6px 15px;
    display: inline-block;
    background-color: #594b6f;
    color: #dddddd;
  }




</style>
