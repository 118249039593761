<template>
  <div class="row  boot-row message-box-full">
    <div id="chat-file-upload-preview" class="message-bar-images" v-if="uploadedFiles.length !== 0 ">
      <div class="row">
        <UploadedFile
            v-for="(uploaded_file, index) in uploadedFiles"
            :key="index"
            :index="index"
            :src="uploaded_file"
            :tempImage="tempImage"
        ></UploadedFile>
      </div>
    </div>
    <div id="mentions-list">
      <vuescroll :ops="ops" >
        <ul id="mentions-list-scroll">
        </ul>
      </vuescroll>
    </div>
    <!--div id="chat-message-quote-preview" class="columns message-box-container quote">
        <QuoteMessageBar v-if="quote" :quote="quote"></QuoteMessageBar>
    </div-->
    <div class="row  boot-row users-typing-label">{{ isTypingText }}</div>
    <div id="message-box-container" class="columns message-box-container"
         :class="{'has-quote' : quote}"
    >
      <div id="quote-message-box-container" v-if="quote">
        <!--QuoteMessageBar v-if="quote" :quote="quote"></QuoteMessageBar-->
        <div class="quoted-message" v-if="quote.images.length === 0">
          <span><fa-icon icon="quote-left" class="messagebar-button-icon" :size="$faDefaultIconSize" /> {{ this.quote.message }} <fa-icon
              icon="quote-right" class="messagebar-button-icon" :size="$faDefaultIconSize" /></span>
          <b-button class="remove-quote" size="is-small" @click="removeQuote"> <fa-icon style="margin-left: 4px;" icon="times-circle" class="messagebar-button-icon" :size="$faDefaultIconSize" /></b-button>
        </div>
        <div v-else>
          <div class="quoted-message image-wrapper">
            <img :src="quote.images[0].content" class="uploaded-file-img" :alt="quote.images[0].name"/>
            <b-button class="remove-image" size="is-small" @click="removeQuote">
              <fa-icon icon="times-circle" style="margin-left: 4px;" class="messagebar-button-icon" :size="$faDefaultIconSize"/>
            </b-button>
          </div>
        </div>
      </div>
      <!--div style="position: absolute; bottom: 200px;"></div-->
      <b-field id="chat-message-textbox" :class="{'editing' : edit, 'has-quote' : chatHasQuote}"
               class="chat-message-textbox">
        <!--div id="chat-message-preview" v-html="partials"></div-->
        <!--Zzquill :users="usersForMention"></Zzquill-->
        <quillEditor
            v-if="ready"
            id="chat-message-textbox-input" class="chat-message-textbox-input"
            ref="focusInput"
            :placeholder="placeholder"
            v-model="message"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @input="emitIsTyping($event)"
            @ready="onEditorReady($event)"
            @keyup.esc.native="stopEdit"
            @keyup.enter.native="handleEnterUp"
            @keydown.enter.native="handleEnterDown"
        ></quillEditor>
      </b-field>

      <UploadFile @change="changedFile"
                  :class="{'has-quote' : chatHasQuote}"
                  :accept="true"
      ></UploadFile>
      <b-button id="chat-message-textbox-button-emoticon" class="chat-message-textbox-button emoticon"
                :class="{'has-quote' : chatHasQuote}"
      >
        <b-image
            :src="smilee"
        ></b-image>
      </b-button>

      <b-button @click="submitMessage" id="chat-message-textbox-button-send" class="chat-message-textbox-button send"
                :class="{'has-quote' : chatHasQuote}"
      >
        <b-image
            :src="send"
        ></b-image>
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import socket from '../socket';
import UploadFile from './UploadFile';
import UploadedFile from './UploadedFile';
import axios from '@/axios';
import vuescroll from "vuescroll";
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

//import atPeople from 'quill-mention-people';
import atPeople from '@/quiller';
import VueSanitize from 'vue-sanitize';
Quill.register('modules/atPeople', atPeople);

// https://github.com/quilljs/quill/issues/1432
// https://github.com/merryliao/vue-quill-at#readme
//https://github.com/apostrophecms/sanitize-html
const defaultOptions = {
  //allowedTags: ['a', 'b', 'span', 'p'],
  allowedTags: [],
  allowedAttributes: {
    'a': ['href'],
    //'span': ['class', 'contenteditable', 'data-index', 'data-value'],
    'p': ['class'],
    'span': ['class'],
  }
};
Vue.use(VueSanitize, defaultOptions);


export default {
  name: 'MessageBarComponent',
  components: {
    UploadFile,
    UploadedFile,
    vuescroll,
    quillEditor
    //Zzquill
    //QuoteMessageBar
  },
  props: ['chatHasQuote'],
  data() {
    return {
      message: '',
      partials: '',
      canSend: false,
      sending: false,
      uploadedFiles: [],
      isTypingText: '',
      isFetching: false,
      item_entered: '',
      selected: '',
      tempImage: null,
      // images:[],
      // filesToUpload:[]
      messageToEdit: null,
      quote: null,
      quoteImage: [],
      mentionedUser: null,
      usersForMention: null,
      hashtagsForMention: null,
      userToMention: null,
      showMentions: false,
      quoteOpened: false,
      editorOption: {
        placeholder: 'Write Message...',
        modules: {
          keyboard: {
            bindings: {
              enter: {
                key: 13,
                handler: () => {
                  let list = document.getElementById('mentions-list');
                  if (list && list.style.display === 'none') {
                    this.submitMessage();
                  }
                }
              }
            }
          },
          toolbar: false,
          syntax: false,
          atPeople: {
            // list: [],
            list: {
              mentions: [],
              hashtags: [],
            },
            //hashes: this.hashtagsForMention,
            fixMentionsToQuill: true,
            blotName: 'mention',
            defaultMenuOrientation: 'top',
            spaceAfterInsert: true, //check
            props: {label: name},
            //onSelect: this.onSelect,
            onOpen: this.onOpen,
            onClose: this.onClose,
            atOneMemberAction: this.atOneMemberAction,
            mentionDenotationChars: ['@', '#'],
            isolateCharacter: false,
            minChars: 0,
            maxChars: 31,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            positioningStrategy: 'fixed',
          },

        }
      },
      ready: false,
      ops: {
        vueScroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          //initialScrollY: '100%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'right'
        },
        rail: {},
        bar: {},
        scrollButton: {
          enable: true,
          background: 'rgb(141,141,215)',
          opacity: 1,
          step: 280,
          mousedownStep: 120
        }
      },
    }
  },
  computed: {
    selectedRoomHashtags() {
      return this.$store.getters.get_selectedRoomHashtags;
    },
    selectedServer() {
      return this.$store.getters.get_selectedServer;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    edit() {
      return !!this.messageToEdit;
    },
    send() {
      return require('@/assets/icons/send.svg');
    },
    smilee() {
      return require('@/assets/icons/smilee.svg');
    },
    upload() {
      return require('@/assets/icons/upload.svg');
    },
    quoteLeft() {
      return require('@/assets/icons/quote-left-solid.svg');
    },
    quoteLRight() {
      return require('@/assets/icons/quote-right-solid.svg');
    },
    placeholder() {
      if (this.chatHasQuote) {
        return 'Follow up quote...'
      }
      return 'Write Message...';
    },
    filteredDataArray() {
      return this.usersForMention.filter((option) => {
        return option
            .label
            .toString()
            .toLowerCase()
            .indexOf(this.item_entered.replace('@', '').toLowerCase()) >= 0
      });
    },
  },
  watch: {
    message(val) {
      //console.log('message' , val);
      this.item_entered = val;
      this.canSend = val.length > 0;
    },
    uploadedFiles(val) {
      this.canSend = val.length > 0;
    },
    quote(val) {
      if (val && val._id) {
        console.log('is quote', val);
        this.$parent.chatHasQuote = true;
      } else {
        console.log('is not quote', val);
        this.$parent.chatHasQuote = false;
      }
    }
  },
  methods: {
    async handleUpload() {
      let formData = new FormData();
      formData.append('image', this.tempImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      //this.loading = true;
      this.uploading = true;
      try {
        let response = await axios.post(`/images/chat/${this.$store.getters.get_selectedRoom._id}`, formData, {
          headers: axHeaders
        });
        console.log('response', response)
        this.uploading = false;
        return response;
      } catch (e) {
        this.uploading = false;
        console.log('error:', e);
        return e;
      }
    },
    async submitMessage() {
      console.log('inside submitMessage uploadedFiles  ', this.uploadedFiles)
      console.log('canSend ' + this.canSend);
      if (this.canSend) {
        this.sending = true;
        let filesToUpload = [];
        let images = [];

        if (this.tempImage) {
          let upload = await this.handleUpload();
          if (upload.status && upload.status >= 200 && upload.status < 300 && upload.data && upload.data.uploadedImage && upload.data.uploadedImage.filename) {
            images.push(upload.data.uploadedImage.filename);
            this.imageUploaded = true;
          }
        }

        const mentionedUsers = [];
        const hashtags = [];
        //const messageToSent = this.message.replace(/<[^>]+>/g, '');
        const messageToSent = this.sanitizer(this.message);
        const messageToSplit = messageToSent.replace(/<[^>]+>/g, '');
        //console.log('messageToSplit', messageToSplit);
        const messageArray = messageToSplit.split(' ');
        messageArray.forEach(str => {
          if (str.includes('@')) {
            const username = str.replace('@', '');
            this.usersForMention.forEach(user => {
              if (user.name.trim() === username.trim()) {
                mentionedUsers.push(user.value);
              }
            })
          }
          if (str.includes('#')) {
            const hashtag = str.replace('#', '');
          }
        })

        if (!this.messageToEdit) {
          let message = {
            message: messageToSent,
            images: images,
            files: filesToUpload,
            room: this.$store.getters.get_selectedRoom._id,
            user: this.userDataAfterLogIn._id
          }

          if (this.quote) {
            message['quote'] = this.quote._id;
            // message.images = this.quoteImage; // Todo: check if needed
          }

          if (mentionedUsers.length > 0)
            message['mentionedUsers'] = mentionedUsers;
          this.$iv.socket.emit(
              'publishMessage',
              message,
              (response) => {
                this.sending = false;
              }
          );
        } else {
          let message = {
            messageId: this.messageToEdit._id,
            message: messageToSent,
            room: this.$store.getters.get_selectedRoom._id,
            //mentionedUser: this.mentionedUser
            mentionedUser: null  // Hard
          };

          if (mentionedUsers.length > 0)
            message['mentionedUsers'] = mentionedUsers;

          this.messageToEdit = null;
          this.$iv.socket.emit(
              'editMessage',
              message,
              (response) => {
                this.sending = false;
              }
          );
        }

        this.quote = null
        this.message = '';
        this.uploadedFiles = [];
        this.quoteImage = [];
        this.mentionedUser = null;
        // this.images = [] ;
        // this.filesToUpload = [];
      }
    },
    sanitizer(html) {
      return this.$sanitize(html);
    },
    setMentionedUser(user) {
      //console.log('setMentionedUser ', user);
      this.userToMention = user.value;
      this.showMentions = false;
    },
    emitIsTyping() {
      //console.log('emitIsTyping', e);
      //this.checkHasMention(e);
      socket.emit(
          'isTyping',
          this.$store.getters.get_selectedRoom._id,
          (response) => {

          }
      );
    },
    changedFile(event) {
      console.log('ADD FILES event.files =  ', event.files);
      this.addFilesToUpload(event.files);


      // TODO fix and enable
      //this.$refs.focusInput.focus();

    },
    addFilesToUpload(files) {
      console.log('INSIDE addFilesToUpload ', files);
      for (const file of files) {
        console.log('file.type', file.type)
        if (file.type.indexOf('image') === -1) {
          console.log('FILE');
          if (file) {
            this.uploadedFiles.push(file)
          }
        } else {
          // convert image file to base64 string
          console.log('IMAGE');
          this.tempImage = file;
          let reader = new FileReader();
          reader.onload = event => {
            this.uploadedFiles.push(reader.result);
            // this.images.push(reader.result)
          };
          reader.readAsDataURL(file);
        }
      }
    },
    stopEdit() {
      if (this.messageToEdit) {
        this.messageToEdit = null;
        this.message = '';
      }

    },
    removeQuote() {
      this.quote = null;
    },
    async setUsersForMention() {
      if (this.selectedServer && this.selectedServer.subscriptions.length) {
        //this.$refs.mentiondropdown.toggle(true);
        let users = [];
        this.selectedServer.subscriptions.forEach((subscription) => {
          let temp = {};
          temp.value = subscription.user._id;
          temp.id = subscription.user._id;
          temp.label = subscription.user.username;
          temp.name = subscription.user.username;
          temp.image = subscription.user.image;
          users.push(temp);
        });
        this.usersForMention = users;
        this.editorOption.modules.atPeople.list.mentions = users;
        // this.editorOption.modules.atPeople.list.hashtags = this.hashtagsForMention;
        const hashtags = await this.getHashtagsForMention();
        //this.$store.commit('set_selectedRoomHashtags', hashtags);
        // console.log('selectedRoomHashtags' , hashtags);
        this.editorOption.modules.atPeople.list.hashtags = hashtags;
        this.ready = true;
        //console.log('setUsersForMention' , this.usersForMention);
      }
    },
    async getHashtagsForMention() {
      const hashtags = [];
      const {data} = await axios.get('/hashtag');
      data.forEach(hashtag => {
        const temp = {};
        temp['id'] = hashtag._id;
        temp['name'] = hashtag.name;
        hashtags.push(temp);
      })

      return hashtags;
    },

    handleEnterUp() {
      // let list = document.getElementById('mentions-list');
      //console.log('handleEnterUp', this.quoteOpened);
      // if (list && list.getAttribute("style") !== 'display: none;') {
      //   // this.submitMessage();
      // } else {
      //   this.submitMessage();
      // }
    },
    handleEnterDown() {
      // let list = document.getElementById('mentions-list');
      //console.log('handleEnterDwn', this.quoteOpened);
      // if (list && list.getAttribute("style") !== 'display: none;') {
      //   // this.submitMessage();
      // } else {
      //   this.submitMessage();
      // }
    },
    onOpen(quill) {
      this.quoteOpened = true;
      console.log('onOpen', quill );
    },
    onClose: function (quill) {
      this.quoteOpened = false;
      console.log('onClose', quill);
    },
    onSelect(item, insertItem) {
      console.log('onSelect', item, insertItem);
    },
    atOneMemberAction: function (item) {
      console.log('atOneMemberAction', item);
      // console.log(item);
    },
    onEditorBlur(quill) {
      //console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //console.log('onEditorFocus', quill);
      this.emitIsTyping();
    },
    onEditorReady(quill) {
      //this.editorOption.modules.atPeople.list = this.users;
      //console.log('editor focus!', this.editorOption.modules.atPeople.list);
    },
    onEditorChange({quill, html, text}) {
      //console.log('editor change!', quill, html, text)
      this.message = html;
      //console.log('this.content ', this.message );
    },
  },
  async created() {
    // this.editorOption.modules.atPeople.list.hashtags = this.selectedHashtags;
    await this.setUsersForMention();
  },
  mounted() {

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'USER_IS_TYPING') {
        let typingUsers = mutation.payload.target.usersTyping;
        switch (typingUsers.length) {
          case 0:
            this.isTypingText = '';
            break;
          case 1:
            this.isTypingText = `${typingUsers[0]} is typing...`;
            break;
          case 2:
            this.isTypingText = `${typingUsers[0]} and ${typingUsers[1]} are typing...`;
            break;
          case 3:
            this.isTypingText = `${typingUsers[0]}, ${typingUsers[1]} and 1 other are typing...`;
            break;
          default:
            this.isTypingText = `${typingUsers[0]}, ${
                typingUsers[1]
            } and ${typingUsers.length - 2} other are typing...`;
        }
      }

      if (mutation.type === 'INIT_EDIT_MESSAGE') {
        console.log('INIT_EDIT_MESSAGE mutation payload is ', mutation.payload);
        this.messageToEdit = mutation.payload;
        this.message = this.messageToEdit.message;
      }

      if (mutation.type === 'QUOTE_MESSAGE') {
        console.log('QUOTE_MESSAGE mutation payload is ', mutation.payload);
        this.quote = mutation.payload;
        if (this.quote.images.length !== 0) {
          this.quoteImage.push(this.quote.images[0].content)
        }
      }

      // if (mutation.type == 'rooms/SET_SELECTED_ROOM') {
      //     this.stopEdit();
      //     this.message = '';
      // }
    });

  }
}
</script>

<style>
#message-box-container {
  display: block;
  height: 48px;
  max-height: 48px;
  width: 100%;
  margin: 0 2.5em;
  padding: 0.25em 1em;
  opacity: 1;
  border-radius: 8px;
  border: solid 1px #313131;
  background-color: #202428;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6064;
}

#message-box-container.has-quote {
  min-height: 85px;
  height: 85px;
  max-height: 85px;
}

#quote-message-box-container {
  display: block;
  min-height: 35px;
  height: 35px;
  max-height: 35px;
  width: 100%;
  margin: 0;
  padding: 0.25em 1em;
  opacity: 1;
  border-radius: 0;
  background-color: #202428;
  white-space: nowrap;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6064;
  border-color: #313131;
  border-style: solid;
  border-width: 0 0 1px 0;
  text-align: left;
  position: relative;
}

#chat-message-textbox {
  display: inline-block;
  margin: 0 !important;
  height: 100%;
  max-height: 100%;
  width: calc(100% - 75px);
  max-width: calc(100% - 75px);
  position: relative;
}

#chat-message-textbox.has-quote {
  height: calc(100% - 36px);
  max-height: calc(100% - 36px);
}

#chat-message-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: block;
  text-align: left;
}

#chat-message-preview b {
  display: inline-block;
}


.chat-message-textbox-input,
.chat-message-textbox-input:visited {
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  width: 100%;
  height: 100%;
  color: #5c6064;
  position: absolute;
  top: 0;
  left: 0;
  /**display: table;*/
  display: inline-block;
}


.chat-message-textbox-input input,
.chat-message-textbox-input input:visited {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
}

/**
#chat-message-textbox-input::placeholder,
#chat-message-textbox-input:-moz-placeholder,
#chat-message-textbox-input::-moz-placeholder {
  color: #5c6064;
  opacity: 1;
  font-weight: 500;
}
*/


#chat-message-textbox-input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #5c6064;
}

#chat-message-textbox-input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #5c6064;
  opacity: 1;
}

#chat-message-textbox-input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #5c6064;
  opacity: 1;
}

#chat-message-textbox-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #5c6064;
}

#chat-message-textbox-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #5c6064;
}

#chat-message-textbox-input::placeholder { /* Most modern browsers support this now. */
  color: #5c6064;
}


button.chat-message-textbox-button {
  display: inline-block;
  height: 100%;
  max-height: 100%;
  background-color: transparent;
  border: 0px solid transparent;
  outline: none;
  padding: 0;
  text-align: center;
}

button.chat-message-textbox-button figure {
  text-align: center;
}

button.chat-message-textbox-button figure img {
  max-height: 20px;
  max-width: 20px;
  float: right;
}

button.remove-quote {
  margin-bottom: 2px;
}


#chat-bar .users-typing-label {
  height: 1.6em;
  max-height: 1.6em;
  min-height: 1.6em;
  font-size: 0.8em;
  padding: 0.25em 4.2em;
  display: inline-block;
  width: 100%;
  margin-bottom: 0.15em;
  text-align: left;
  color: #dddddd;

  height: 22px;
  max-height: 1.6em;
  min-height: 1.6em;
  font-size: 14px;
  padding: 2px 4.2em;
}

#chat-message-textbox-input {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  color: #5c6064;
  height: 100%;
  max-height: 100%;
}


#chat-message-textbox.editing input {
  color: #594b6f;
}

#chat-message-textbox-input .ql-container.ql-snow {
  border: 1px solid transparent !important;
}

#chat-message-textbox-button-upload,
#chat-message-textbox-button-emoticon,
#chat-message-textbox-button-send {
  background-color: transparent;
  border: 0 solid transparent !important;
  -webkit-box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0);
  /**
  width: auto;
  max-width: 5%;
  margin-left: 0.75em;
  margin-left: 0.5em;*/
  width: 20px;
  max-width: 20px;
  margin-left: 5px;

  height: 100%;
  max-height: 100%;
}

#chat-message-textbox-button-upload.has-quote,
#chat-message-textbox-button-emoticon.has-quote,
#chat-message-textbox-button-send.has-quote {
  height: calc(100% - 36px);
  max-height: calc(100% - 36px);
}


#chat-file-upload-preview {
  position: absolute;
  bottom: 60px;
  left: 30px;
}


#chat-message-quote-preview {
  position: absolute;
  bottom: 60px;
  left: 30px;
  display: inline-block;

  /**
  min-height: 30px;
  width: 100%;
  margin: 0 2.5em;
  padding: 0.25em 1em;
  opacity: 1;
  border-radius: 8px;
  border: solid 1px #313131;
  background-color: #202428;
  white-space: nowrap;
  height: auto;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6064;
  */
}

#chat-mentions-preview {
  position: absolute;
  bottom: 60px;
  right: 30px;
  height: 260px;
  max-height: 260px;
  width: 240px;
  overflow: hidden;
  background-color: #23272a;
  padding: 0 8px;
}

#chat-mentions-preview button {
  border-width: 0;
  border-bottom-width: 1px;
  border-color: #7c7c7c;
  border-radius: 0;
}

#mentions-list-scroll li {
  padding: 4px 16px;
  /*width: 100%;*/
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
}

#mentions-list-scroll li:hover,
#mentions-list-scroll li.selected {
  background-color: #5D4AB8;
}

#mentions-list-scroll li span {
 display: inline-block;
}

.mentions-list-item-avatar {
  width: 22px;
  height: 22px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 14px;
  margin-right: 7px;
}

.quill-at-member {
  position: absolute;
  left: 0;
  bottom: 50px !important;
  top: unset !important;
  width: 300px;
  height: 200px;
  max-height: 200px;
  text-align: left;
  z-index: 9999;
  overflow: hidden;
  background-color: #2E3135;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
  border-radius: 8px;
  padding: 16px 0;
}

span.mention {
  color: #674cf4;
}

.mention-item {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: rgb(192, 250, 153);
}


.quill-at-member > ul {
  position: relative;
  padding: 3px;
  margin: 0;
  border: 0 solid #000;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;

}

.quill-at-member > ul > li {
  position: relative;
  padding: 10px 0;
  margin: 0;
  list-style: none;

  cursor: pointer;

}

.quill-at-member > ul > li.selected {
  background-color: #303538;
  color: #fff;
}

.quill-at-member > ul > li:hover {
  background-color: #303538;
  color: #fff;
}

.ql-editor {
  padding: 3px 0;
  display: table;
  width: 100%;
}

.ql-editor p,
.ql-editor br {
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}


.ql-editor.ql-blank::before {
  color: #5c6064 !important;
  content: attr(data-placeholder);
  font-style: normal;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  /*display: table-cell;*/
  /*vertical-align: middle;*/
  /*height: 100%;*/
  top: 27%;
}


</style>
