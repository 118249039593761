export default {
  set_token: (state,token) =>{
    state.token = token ;
  },
  set_refreshToken: (state,refreshToken) =>{
    state.refreshToken = refreshToken;
  },
  set_userDataAfterLogIn: (state, userData) =>{
    state.userDataAfterLogIn = userData;
  },
  set_connectedToSocket: (state, result) =>{
    state.connectedToSocket = result;
  },
  clear_authData:(state) => {
    state.token = null;
    state.refreshToken = null;
    state.userDataAfterLogIn = {};
    state.userServers = null; // Added
    state.userRooms = null;
  },
  set_userServers: (state, servers) => {
    state.userServers = servers;
  },
  set_userRooms: (state, rooms) => {
    state.userRooms = rooms;
  },

}
