import axios from "../../../axios";
import socket from "@/socket";
// import * as _ from 'lodash';
export default {

    async allRooms({commit}) {
        try {
            let responseRooms = await axios.get('/rooms');
            console.log('inside allRooms responseRooms is :   ', responseRooms)
            responseRooms.data.rooms.map((room) => {
                return Object.assign(room, {
                        // disabled: false,
                        // newMessages: false,
                        currentlySelected: false,
                        // loading: false,
                        // canLeave: false,
                        // notifications: true,
                        usersTyping: [],
                    }
                )
            })
            console.log('RESPONSEROOMS', responseRooms)
            commit('set_rooms', responseRooms.data);
            console.log(responseRooms);
        } catch (e) {
            console.log('GET ROOMS ERROR  ', e);
        }
    },

    async newRoomMessage({dispatch, commit, state, getters, rootState, rootGetters}, newMessageInfo) {
        const messageDate = newMessageInfo.message.date;
        let messages;

        await dispatch('populatePrivateRooms');

        if (getters.get_selectedRoom && getters.get_selectedRoomMessages && getters.get_selectedRoom._id === newMessageInfo.towards) { // todo check if needed 2 ==

            if (getters.get_selectedRoomMessages.length) {
                const lastIndex = getters.get_selectedRoomMessages.length - 1;
                const lastGroup = getters.get_selectedRoomMessages[lastIndex];
                console.log('step 5 inside newRoomMessage rooms/actions the newMessageInfo is   ', newMessageInfo);

                // Mandatory check , index might be null
                if (lastGroup && lastGroup.length && lastGroup[0].date) {
                    // check for duplicate ids in messages array : START
                    const incomingMessageId = newMessageInfo.message._id;
                    const existsInMsgArray = lastGroup.some( function (duplicate) {
                        return duplicate._id === incomingMessageId;
                    });

                    if(existsInMsgArray) {
                        console.log('DUPLICATEd ID : ' + incomingMessageId);
                        return;
                    }
                    // check for duplicate ids in messages array : END

                    const latestDate = lastGroup[0].date;
                    if (new Date(latestDate).toDateString() === new Date(messageDate).toDateString()) {
                        //console.log ('same date   ', lastIndex, lastGroup);
                        let data = {
                            index: lastIndex,
                            message: newMessageInfo.message
                        }

                        commit('add_messageToGroup', data);
                    } else {
                        //console.log ('no same date   ', lastGroup);
                        const group = [newMessageInfo.message];
                        commit('append_selectedRoomMessages', group);
                    }
                }
            } else {
                // If room is empty of messages
                const group = [newMessageInfo.message];
                commit('append_selectedRoomMessages', group);
            }

            // let isCurrentlySelected = getters.get_selectedRoom._id === getters.get_selectedRoom._id;
            // //messages with pagination code start
            // messages = [...getters.get_messagesWithPagination,newMessageInfo.message];
            // commit('set_messagesWithPagination', messages);
            // //messages with pagination code finish

            // await commit('ADD_MESSAGE_TO_ROOM', {
            //   messageInfo: newMessageInfo,
            //   target: getters.get_selectedRoom,
            //   isCurrentlySelected: isCurrentlySelected,
            // });

        } else if (getters.get_privateSelectedRoom && getters.get_privateSelectedRoom._id === newMessageInfo.towards) {
            // console.log('inside else if PrivateRoomsCore', getters.get_privateSelectedRoom)
            //
            // //messages with pagination code start
            // messages = [...getters.get_messagesWithPaginationPrivate, newMessageInfo.message];
            // commit('set_messagesWithPaginationPrivate', messages);

            if (getters.get_selectedPrivateRoomMessages.length) {
                const lastIndex = getters.get_selectedPrivateRoomMessages.length - 1;
                const lastGroup = getters.get_selectedPrivateRoomMessages[lastIndex];
                console.log('step 5 inside newPrivateRoomMessage rooms/actions the newMessageInfo is   ', newMessageInfo, getters.get_selectedRoom);

                // Mandatory check , index might be null
                if (lastGroup && lastGroup.length && lastGroup[0].date) {
                    // check for duplicate ids in messages array : START
                    const incomingMessageId = newMessageInfo.message._id;
                    const existsInMsgArray = lastGroup.some( function (duplicate) {
                        return duplicate._id === incomingMessageId;
                    });

                    if(existsInMsgArray) {
                        console.log('private DUPLICATEd ID : ' + incomingMessageId);
                        return;
                    }
                    // check for duplicate ids in messages array : END

                    const latestDate = lastGroup[0].date;
                    if (new Date(latestDate).toDateString() === new Date(messageDate).toDateString()) {
                        //console.log ('same date   ', lastIndex, lastGroup);
                        let data = {
                            index: lastIndex,
                            message: newMessageInfo.message
                        }
                        commit('add_privateMessageToGroup', data);
                    } else {
                        //console.log ('no same date   ', lastGroup);
                        const group = [newMessageInfo.message];
                        commit('append_selectedPrivateRoomMessages', group);
                    }
                }
            } else {
                // If room is empty of messages
                const group = [newMessageInfo.message];
                commit('append_selectedPrivateRoomMessages', group);
            }


            //messages with pagination code finish

            // await commit('ADD_MESSAGE_TO_ROOM', {
            //   messageInfo: newMessageInfo,
            //   target:getters.get_privateSelectedRoom,
            //   isCurrentlySelected: true,
            // });
        }

    },

    async get_allRooms({state, rootState, rootGetters}) {
        let rooms = [];
        //console.log('get_allRooms ' + typeof(rootGetters.get_servers))
        for (const server of rootGetters.get_servers) {
            rooms = rooms.concat(server.rooms);
        }

        rooms = rooms.concat(state.rooms.selectedServerRooms);
        console.log('step 6 this return from getters.allrooms ', rooms)
        return rooms;
    },
    async setSelectedServerRooms({dispatch, commit, state}, server) {
        let toSelect = [];
        if (server.rooms.length) {
            server.rooms.forEach(value => {
                toSelect.push(value._id);
            });
            try {
                //let response = await axios.post('/rooms/get-rooms', server.rooms);
                // Getting the rooms without messages for quicker navigation.messages will be loaded on room selection
                let response = await axios.post('/rooms/get-rooms-data', toSelect);
                if (response.status === 201 && response.data) {
                    commit('set_selectedServerRooms', response.data);
                } else {
                    commit('set_selectedServerRooms', []);
                }
            } catch (error) {
                console.log(error);
                return error
            }
        }

    },
    async updateSelectedServerRooms({dispatch, commit, state}, array) {
        console.log('updateSelectedServerRooms @store actions ', array);
        for (const room of array) {
            try {
                let response = await axios.get('/rooms/' + room);
                console.log('updateSelectedServerRooms @store actions response', response);
                if (response.status === 201 && response.data) {
                    commit('update_selectedServerRooms', response.data);
                }
            } catch (error) {
                console.log(error);
                return error
            }
        }

    },
    // Merge
    async isTyping(
        {dispatch, commit, state, getters, rootState, rootGetters},
        payload
    ) {

        /**
         let room = _.find(
         getters.get_rooms,
         (room) => room._id === payload.towards
         );
         */

        //let room = getters.get_selectedRoom;

        if (getters.get_selectedRoom && getters.get_selectedRoom._id && getters.get_selectedRoom._id === payload.towards) {
            Object.assign(getters.get_selectedRoom, {usersTyping: []})
            console.log('isTyping11', getters.get_selectedRoom);
            await commit('USER_IS_TYPING', {
                target: getters.get_selectedRoom,
                ...payload,
            });


        } else if (getters.get_privateSelectedRoom && getters.get_privateSelectedRoom._id && getters.get_privateSelectedRoom._id === payload.towards) {
            Object.assign(getters.get_privateSelectedRoom, {usersTyping: []})
            console.log('isTyping22', getters.get_privateSelectedRoom)
            await commit('USER_IS_TYPING_PRIVATE', {
                target: getters.get_privateSelectedRoom,
                ...payload,
            });
        }
    },

    async deleteRoomMessage(
        {dispatch, commit, state, getters, rootState, rootGetters},
        deleteMessageInfo
    ) {
        let messages
        console.log('STEPPP INSIDE deleteRoomMessage action deleteMessageInfo is  ', deleteMessageInfo);

        if (getters.get_selectedRoom && getters.get_selectedRoom._id === deleteMessageInfo.towards) {
            //messages with pagination code start
            messages = getters.get_messagesWithPagination.filter(message => message._id !== deleteMessageInfo.message._id)
            commit('set_messagesWithPagination', messages);
            //messages with pagination code finish
            await commit('DELETE_ROOM_MESSAGE', {
                messageInfo: deleteMessageInfo,
                target: getters.get_selectedRoom,
            });
        } else {
            console.log('inside else ');
            //messages with pagination code start
            messages = getters.get_messagesWithPaginationPrivate.filter(message => message._id !== deleteMessageInfo.message._id)
            commit('set_messagesWithPaginationPrivate', messages);
            //messages with pagination code finish
            await commit('DELETE_ROOM_MESSAGE', {
                messageInfo: deleteMessageInfo,
                target: getters.get_privateSelectedRoom,
            });
        }

    },

    async editRoomMessage(
        {dispatch, commit, state, getters, rootState, rootGetters},
        editMessageInfo
    ) {
        //mia if to else den xreiazete kai sto target ternary operator
        if (getters.get_selectedRoom && getters.get_selectedRoom._id === editMessageInfo.towards) {
            await commit('EDIT_ROOM_MESSAGE', {
                messageInfo: editMessageInfo,
                target: getters.get_selectedRoom,
                isCurrentlySelected: true,
                messages: getters.get_messagesWithPagination
            });
        } else {
            await commit('EDIT_ROOM_MESSAGE', {
                messageInfo: editMessageInfo,
                target: getters.get_privateSelectedRoom,
                isCurrentlySelected: true,
                messages: getters.get_messagesWithPaginationPrivate
            });
        }

    },

    async createPrivateRoom(
        {dispatch, commit, state, getters, rootState, rootGetters},
        roomMember
    ) {
        const thisUser = rootGetters.get_userDataAfterLogIn;
        thisUser.token = rootGetters.get_token;
        if (roomMember.user && roomMember.user._id) {
            try {
                const {data, status} = await axios.get(`/rooms/private/${roomMember.user._id}`);
                if (status === 400) {
                    try {
                        const {data} = await axios.post(`/rooms/private/${roomMember.user._id}`);
                        // console.log('/rooms/private/ result status ' , response.status);
                        socket.disconnect();
                        const user = {
                            token: thisUser.token,
                        };
                        await dispatch('login', user);
                        // socket.connect();

                        //if(result.statusText && result.statusText === "Created") {
                        //  let login = await dispatch('login', thisUser); // Check
                        //}

                        return data;
                    } catch (e) {
                        console.log('ERROR in createPrivateRoom action  ', e)
                    }
                }
                return data;
            } catch (e) {
                console.log(`Error in get Private room`, e);
            }

        } else if (roomMember._id) {
            try {
                const {data, status} = await axios.get(`/rooms/private/${roomMember._id}`);
                if (status === 400) {
                    try {
                        const {data} = await axios.post(`/rooms/private/${roomMember._id}`);
                        // console.log('/rooms/private/ result status ' , response.status);
                        socket.disconnect();
                        const user = {
                            token: thisUser.token,
                        };
                        await dispatch('login', user);

                        return data;
                    } catch (e) {
                        console.log('ERROR in createPrivateRoom action  ', e)
                    }
                }
                return data;
            } catch (e) {
                console.log(`Error in get Private room`, e);
            }
        }
        return false;
    },

    async messagesWithPagination(
        {dispatch, commit, state, getters, rootState, rootGetters},
        room
    ) {
        if (room) {
            if (room.dates && room.dates.length) {
                let params = {
                    "roomId": room.roomId,
                    "dateToShow": room.dates[0],
                    "direction": room.direction,
                    "action": room.action || 'load'
                };
                await dispatch('fetchDayMessagesByDate', params);
                return true;
            } else {
                let params = {
                    "roomId": room.roomId,
                    "direction": room.direction
                };
                await dispatch('fetchFirstAvailableDateMessages', params);

                return true;
            }
            // else if (room.messageId) {
            //   response = await dispatch('fetchDayMessagesByMsgId' , room.messageId);
            //   // console.log('messagesWithPagination messageId', response);
            //   //commit('set_messagesWithPagination', response);
            //   commit('append_selectedRoomMessages', response);
            //   // todo add next cursor?
            //   //commit('set_nextCursor', response.data.nextCursor);
            //   return true;
            // } else {
            //   // do what
            // }
        } else {
            //sssssss

        }

        // Todo bring by message id or date
        return false;
    },

    async fetchFirstAvailableDateMessages({dispatch, commit, state, getters}, params) {
        try {
            const {data} = await axios.get(`/rooms/messages/${params.roomId}/get-last-day/messages`);
            if (data && data.length) {
                const l = data.length;
                const d = data;
                console.log('fetchFirstAvailableDateMessages data', d);
                // let messageCounter = data.length;
                const previousDate = (data[0] && data[0][0]) ? data[0][0].date.split('T')[0] : null;
                const nextDate =
                    (
                        data[l - 1]
                        && data[l - 1].length
                        && data[l - 1][data[l - 1].length - 1]
                    )
                        ? data[l - 1][data[l - 1].length - 1].date.split('T')[0]
                        : null;

                // console.log('fetchFirstAvailableDateMessages actions first', previousDate, nextDate);
                await commit('set_previousMessagesIndex', previousDate);
                await commit('set_nextMessagesIndex', nextDate);
                console.log('fetchFirstAvailableDateMessages previousDate, nextDate', previousDate, nextDate);
                data.forEach((messages) => {
                    const currentDate = messages[1].date.split('T')[0];
                    commit('update_selectedRoomGroups', currentDate);
                    const fi = messages.splice(0, 1);
                    const ls = messages.splice(-1);
                    commit('append_selectedRoomMessages', messages);
                });
                return true;
            }
        } catch (e) {
            console.log('fetchFirstAvailableDateMessages ERROR: ', e);
        }
        return false;
    },

    async fetchFirstAvailableDatePrivateMessages({dispatch, commit, state, getters}, params) {
        try {
            const {data} = await axios.get(`/rooms/messages/${params.roomId}/get-last-day/messages`);
            const dataLength = data.length;
            // console.log('fetchFirstAvailableDatePrivateMessages data', data);
            // let messageCounter = data.length;
            const previousDate = (data[0] && data[0][0]) ? data[0][0].date.split('T')[0] : null;
            const nextDate =
                (
                    data[dataLength - 1]
                    && data[dataLength - 1].length
                    && data[dataLength - 1][data[dataLength - 1].length - 1]
                )
                    ? data[dataLength - 1][data[dataLength - 1].length - 1].date.split('T')[0]
                    : null;

            // console.log('fetchFirstAvailableDateMessages actions first', previousDate, nextDate);
            await commit('set_previousPrivateMessagesIndex', previousDate);
            await commit('set_nextPrivateMessagesIndex', nextDate);
            // console.log('fetchFirstAvailableDatePrivateMessages previousDate, nextDate', previousDate, nextDate);
            data.forEach((messages) => {
                const currentDate = messages[1].date.split('T')[0];
                commit('update_selectedPrivateRoomGroups', currentDate);
                const fi = messages.splice(0, 1);
                const ls = messages.splice(-1);
                commit('append_selectedPrivateRoomMessages', messages);
            });
            return true;
        } catch (e) {
            console.log('fetchFirstAvailableDatePrivateMessages ERROR: ', e);
        }
        return false;
    },

    async fetchDayMessagesByDate({dispatch, commit, state, getters}, params) {
        const checkDate = params.dateToShow;
        const previous = getters.get_previousMessagesIndex;
        const next = getters.get_nextMessagesIndex;
        console.log('fetchDayMessagesByDate BEFORE ', params, previous, next);
        if (getters.get_selectedRoomGroups.indexOf(checkDate) > -1) {
            console.log('fetchDayMessagesByDate:actions return ');
            return false;
        }

        try {
            const {data} = await axios.post(`/rooms/messages/get/daily/messages`, params);
            if (data && data.length) {
                console.log('fetchDayMessagesByDate ', data);
                const l = data.length;
                // const previousDate = (data[0] && data[0].date) ? data[0].date.split('T')[0] : null;
                // const nextDate = (data[data.length - 1] && data[data.length - 1].date) ? data[data.length - 1].date.split('T')[0] : null;
                const previousDate = (data[0] && data[0][0]) ? data[0][0].date.split('T')[0] : null;
                const nextDate =
                    (
                        data[l - 1]
                        && data[l - 1].length
                        && data[l - 1][data[l - 1].length - 1]
                    )
                        ? data[l - 1][data[l - 1].length - 1].date.split('T')[0]
                        : null;

                console.log('fetchDayMessagesByDate actions', previousDate, nextDate, params.action);

                // commit('set_previousMessagesIndex', previousDate);
                // commit('set_nextMessagesIndex', nextDate);
                let temp = [];
                if (params.direction && params.direction === 'past') {
                    await commit('set_previousMessagesIndex', previousDate);
                    const reversed = data.reverse();
                    reversed.forEach((messages) => {
                        // console.log('reversed messages', messages);
                        const currentDate = messages[1].date.split('T')[0];
                        if (getters.get_selectedRoomGroups.indexOf(currentDate) === -1) {
                            console.log('update_selectedRoomGroups past');
                            commit('update_selectedRoomGroups', currentDate);
                            messages.splice(0, 1);
                            messages.splice(-1);
                            commit('prepend_selectedRoomMessages', messages);
                        }

                        // temp.push(...messages)

                    });
                    // commit('prepend_selectedRoomMessages', temp);
                    if (params.action && (params.action === 'mention' || params.action === 'search')) {
                        await commit('set_nextMessagesIndex', nextDate);
                    }
                } else {
                    if (params.action && (params.action === 'mention' || params.action === 'search')) {
                        await commit('set_previousMessagesIndex', previousDate);
                    }
                    await commit('set_nextMessagesIndex', nextDate);
                    data.forEach((messages) => {
                        // console.log('foreached messages', messages);
                        const currentDate = messages[1].date.split('T')[0];
                        if (getters.get_selectedRoomGroups.indexOf(currentDate) === -1) {
                            console.log('update_selectedRoomGroups future');
                            commit('update_selectedRoomGroups', currentDate);
                            messages.splice(0, 1);
                            messages.splice(-1);
                            // temp.push(...messages)
                            commit('append_selectedRoomMessages', messages);
                        }

                    });
                    // commit('append_selectedRoomMessages', temp);
                }

                // if (previousDate !== checkDate) {
                //     data.splice(0, 1);
                // }
                // if (nextDate !== checkDate) {
                //     data.splice(-1);
                // }
                //
                // commit('update_selectedRoomGroups', checkDate);
                // if (params.direction && params.direction === 'past') {
                //     commit('prepend_selectedRoomMessages', data);
                // } else {
                //     commit('append_selectedRoomMessages', data);
                // }
                // return {data, previousDate};
            }
        } catch (e) {
            console.log('fetchDayMessagesByDate ERROR: ', e);
        }

        // commit('set_previousMessagesIndex', null);
        // commit('set_nextMessagesIndex', null);
        return false;
    },

    async fetchDayPrivateMessagesByDate({dispatch, commit, state, getters}, params) {
        const checkDate = params.dateToShow;
        const previous = getters.get_previousPrivateMessagesIndex;
        const next = getters.get_nextPrivateMessagesIndex;
        console.log('fetchDayPrivateMessagesByDate BEFORE ', params, previous, next);
        if (getters.get_selectedPrivateRoomGroups.indexOf(checkDate) > -1) {
            console.log('fetchDayPrivateMessagesByDate:actions return ');
            return false;
        }

        try {
            const {data} = await axios.post(`/rooms/messages/get/daily/messages`, params);
            console.log('fetchDayPrivateMessagesByDate ', data);
            const dataLength = data.length;
            // const previousDate = (data[0] && data[0].date) ? data[0].date.split('T')[0] : null;
            // const nextDate = (data[data.length - 1] && data[data.length - 1].date) ? data[data.length - 1].date.split('T')[0] : null;
            const previousDate = (data[0] && data[0][0]) ? data[0][0].date.split('T')[0] : null;
            const nextDate =
                (
                    data[dataLength - 1]
                    && data[dataLength - 1].length
                    && data[dataLength - 1][data[dataLength - 1].length - 1]
                )
                    ? data[dataLength - 1][data[dataLength - 1].length - 1].date.split('T')[0]
                    : null;

            console.log('fetchDayPrivateMessagesByDate actions', previousDate, nextDate, params.action);

            // commit('set_previousMessagesIndex', previousDate);
            // commit('set_nextMessagesIndex', nextDate);
            let temp = [];
            if (params.direction && params.direction === 'past') {
                await commit('set_previousPrivateMessagesIndex', previousDate);
                const reversed = data.reverse();
                reversed.forEach((messages) => {
                    // console.log('reversed messages', messages);
                    const currentDate = messages[1].date.split('T')[0];
                    if (getters.get_selectedPrivateRoomGroups.indexOf(currentDate) === -1) {
                        console.log('update_selectedRoomGroups past');
                        commit('update_selectedPrivateRoomGroups', currentDate);
                        messages.splice(0, 1);
                        messages.splice(-1);
                        commit('prepend_selectedPrivateRoomMessages', messages);
                    }

                });
                // commit('prepend_selectedRoomMessages', temp);
                if (params.action && (params.action === 'mention' || params.action === 'search')) {
                    await commit('set_nextPrivateMessagesIndex', nextDate);
                }
            } else {
                if (params.action && (params.action === 'mention' || params.action === 'search')) {
                    await commit('set_previousPrivateMessagesIndex', previousDate);
                }
                await commit('set_nextPrivateMessagesIndex', nextDate);
                data.forEach((messages) => {
                    // console.log('foreached messages', messages);
                    const currentDate = messages[1].date.split('T')[0];
                    if (getters.get_selectedPrivateRoomGroups.indexOf(currentDate) === -1) {
                        console.log('update_selectedPrivateRoomGroups future');
                        commit('update_selectedPrivateRoomGroups', currentDate);
                        messages.splice(0, 1);
                        messages.splice(-1);
                        // temp.push(...messages)
                        commit('append_selectedPrivateRoomMessages', messages);
                    }

                });
                // commit('append_selectedRoomMessages', temp);
            }
        } catch (e) {
            console.log('fetchDayPrivateMessagesByDate ERROR: ', e);
        }

        return false;
    },

    async fetchDayMessagesByMsgId({dispatch, commit, state, getters, rootState, rootGetters}, messageId) {
        try {
            const {data} = await axios.get(`/rooms/messages/${messageId}/get-that-day/messages`);
            return data;
        } catch (e) {
            console.log('fetchDayMessagesByMsgId ERROR: ', e);
        }
        return null;
    },

    async messagesWithPaginationPrivate(
        {dispatch, commit, state, getters, rootState, rootGetters},
        roomPrivate
    ) {
        // console.log(`roomPrivate`, roomPrivate);
        commit('set_privateMessageFocus', null);
        if (roomPrivate.dates && roomPrivate.dates.length) {
            const params = {
                roomId: roomPrivate.roomId,
                dateToShow: roomPrivate.dates[0],
                direction: roomPrivate.direction,
                action: roomPrivate.action || 'load'
            };

            await dispatch('fetchDayPrivateMessagesByDate', params);
            commit('set_privateMessageFocus', 'last');
            return true;
        }

        const params = {
            roomId: roomPrivate.roomId,
        };
        await dispatch('fetchFirstAvailableDatePrivateMessages', params);
        commit('set_privateMessageFocus', 'last');
        return true;
    },

    async newRoomCreated({dispatch, commit, state, getters, rootState, rootGetters}, payload) {
        const thisUser = rootGetters.get_userDataAfterLogIn;
        const thisServer = rootGetters.get_selectedServer;
        const user = payload.user;

        if (!user || !thisUser || !thisServer) {
            return
        }
        // Check if it is a different user and not the one that made the room (we handle it elsewhere this event)
        // and also if the "other" user is in the same server
        if (user._id !== thisUser._id && thisServer._id === payload.server._id) {
            const rooms = getters.get_selectedServerRooms;
            // console.log(`Rooms: `, rooms);
            rooms.push(payload.room);
            // console.log(`New Rooms: `, rooms);
            commit('set_selectedServerRooms', rooms);
        }
    },
    async fetchFirstAvailableDateMessages1({dispatch, commit, state, getters}, params) {
        try {
            let {data} = await axios.get(`/rooms/messages/${params.roomId}/get-last-day/messages`);
            console.log('fetchFirstAvailableDateMessages actions', data);
            if (data && data.length) {
                let messageCounter = data.length;
                let previousDate = (data[0] && data[0].date) ? data[0].date.split('T')[0] : null;
                const currentDate = (data[1] && data[1].date) ? data[1].date.split('T')[0] : null;
                const nextDate = (data[data.length - 1] && data[data.length - 1].date) ? data[data.length - 1].date.split('T')[0] : null;

                if (currentDate && getters.get_selectedRoomGroups.indexOf(currentDate) > -1) {
                    console.log('fetchFirstAvailableDateMessages:actions return ');
                    return false;
                }

                commit('set_previousMessagesIndex', previousDate);
                commit('set_nextMessagesIndex', nextDate);

                if (previousDate !== currentDate) {
                    data.splice(0, 1);
                }

                if (nextDate !== currentDate) {
                    data.splice(-1);
                }
                // console.log('messagesWithPagination actions', room, response);
                // let commit = {
                //   date: room.dates[0],
                //   data: response
                // };
                commit('update_selectedRoomGroups', currentDate);

                console.log('get_selectedRoomGroups', getters.get_selectedRoomGroups);

                // if (params.direction && params.direction === 'past') {
                //   commit('prepend_selectedRoomMessages', data);
                // } else {
                commit('append_selectedRoomMessages', data);
                // }

                console.log('get_selectedRoomMessages', getters.get_selectedRoomMessages);

                // params.direction = (params.direction === 'past') ? 'future' : 'past';
                // while (messageCounter < 10 && previousDate !== null) {
                //   params.dateToShow = previousDate;
                //   const response = await dispatch('fetchDayMessagesByDate', params);
                //   data = response.data;
                //   previousDate = response.previousDate;
                //   messageCounter += data.length;
                // }
                return true;
            }
        } catch (e) {
            console.log('fetchFirstAvailableDateMessages ERROR: ', e);
        }

        return false;
    },

    async populatePrivateRooms({commit}) {
        try {
            const {data} = await axios.get(`/rooms/user-private-rooms`);
            // console.log(`Private message rooms of user: `, data);
            commit('set_privateRooms', data);
            return data;
        } catch (e) {
            console.log(`Error in private message fetching: `, e);
        }
    },

}
