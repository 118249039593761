<template>
  <div>
    Login You Out
  </div>
</template>

<script>
export default {
  name: "Logout",
  created() {

    this.$localStorage.remove('accessToken');
    this.$localStorage.remove('user');
    this.$localStorage.remove('userServers');
    this.$localStorage.remove('redirectAfterLogin');
    this.$store.dispatch('logOut');
    if (this.$route.path === '/') {
      //window.location.reload();
    } else {
      this.$router.replace('/');
      //window.location.reload();
    }

  }
}
</script>

<style scoped>

</style>
