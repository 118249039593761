<template>
  <div id="chat-private-list" class="sidebar-tab-inner">
    <div id="private-chat-list-header" class="sidebar-header">
      <b-button class="sidebar-button close darker"
                style="position: absolute; left: 10px; background-color: transparent;"
                @click="closeSidebar"
      >
        <img :src="closeSidebarIcon" style=""/>
      </b-button>
      <p>Choose User for Private Chat</p>
    </div>
    <div class="chat-scroll" v-if="privateRooms.length">
      <vueScroll
          v-if="privateRooms"
          :ops="ops"
          id="chat-private-group-list"
      >
        <PrivateChatListSingle
            v-for="privateRoom in privateRooms"
            :key="privateRoom._id"
            :currentUser="userDataAfterLogIn"
            :privateRoom="privateRoom"
        />
      </vueScroll>
    </div>
  </div>
</template>

<script>
import vueScroll from 'vuescroll';
import PrivateChatListSingle from './PrivateChatListSingle';

export default {
  name: 'PrivateChatList',
  components: {
    vueScroll,
    PrivateChatListSingle
  },
  data() {
    return {
      // privateRooms: [],
      ops: {
        vueScroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: true,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          // initialScrollY: '100%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'right'
        },
        rail: {},
        bar: {},
        scrollButton: {
          enable: true,
          background: 'rgb(141,141,215)',
          opacity: 1,
          step: 280,
          mousedownStep: 120
        }
      },
    }
  },
  async created() {
  },
  async mounted() {
  },
  watch: {
    async userDataAfterLogIn(newVal) {
      // console.log(`userDataAfterLogin in PrivateChatList `, newVal);
    },
    privateRooms(newVal) {
      // console.log(`privateRooms`, newVal);
    }
  },
  computed: {
    openPrivateChatList() {
      return this.$store.getters.get_openPrivateChatList;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    privateRooms() {
      return this.$store.getters.get_privateRooms;
    },
    closeSidebarIcon() {
      return require('@/assets/icons/close-side.svg');
    },
  },
  methods: {
    closeSidebar() {
      this.$store.dispatch('set_openCloseSidebar', 'close');
    }
  }
}
</script>

<style scoped>
#chat-private-group-list {
  height: 100%;
  max-height: 100%;
  overflow: auto;
  width: 100%;
}

#chat-private-list {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  width: 100%;
}

#private-chat-list-header {
  /**height: 6%;
    max-height: 6%;*/
  height: 50px;
  max-height: 50px;
  padding: 0.5em;
  overflow: hidden;
  text-align: center;
  background-color: #293035;
  display: table;
  width: 100%;
  max-width: 100%;
  color: #bcbcbc;
  position: relative;
}
</style>
