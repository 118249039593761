<template>
  <div>
    <b-carousel-list
        id="home-live-carousel"
        :data="items"
        :items-to-show="itemsToShow"
        :arrow="arrow"
        :items-to-list="increment"
        :repeat="repeat"
        :has-drag="drag"
        :icon-pack="iconPack"
        :icon-prev="iconPrev"
        :icon-next="iconNext"

    >
      <template slot="item" slot-scope="list">
        <div class="card carousel-card">
          <HomeCarouselItem :item="items[list.index]"></HomeCarouselItem>
        </div>
      </template>
    </b-carousel-list>
  </div>
</template>

<script>
import axios from '../../axios';
import HomeCarouselItem from './HomeCarouselItem';

export default {
  name: "HomeCarousel",
  components: {
    HomeCarouselItem,
  },
  data() {
    return {
      itemsToShow: 2,
      arrow: true,
      arrowHover: true,
      drag: true,
      gray: false,
      opacity: false,
      values: 1,
      increment: 2,
      repeat: true,
      iconPack: "fa",
      iconPrev: "chevron-left",
      iconNext: "chevron-right",
      // iconSize: "10px",
      items: []
    }
  },
  async created() {
    await this.getBroadcasts();
  },
  mounted() {
    this.setCarouselCols();
    this.$nextTick(() => {
      window.addEventListener('resize', this.setCarouselCols);
    });
  },
  methods: {
    setCarouselCols() {
      if (window.innerWidth > 1600) {
        this.itemsToShow = 3;
      } else if (window.innerWidth <= 992) {
        this.itemsToShow = 1;
      } else {
        this.itemsToShow = 2;
      }
    },
    async getBroadcasts() {
      const cursor = new Date('December 31, 2018 03:24:00');
      try {
        const {data} = await axios.get(`/broadcasting/10/${cursor}`);
        console.log(`Broadcasts are: `, data.broadcasts);
        if (data.broadcasts.length === 0) {
          this.$emit('noBroadcasts', false);
          return ;
        }
        this.items = data.broadcasts;
        this.$emit('noBroadcasts', true)
      } catch (e) {
        console.log(`Could not get broadcasts, with error: `, e);
      }
    }
  }
}
</script>

<style>
#home-live-carousel .carousel-card {
  background-color: transparent !important;

}

#home-live-carousel .carousel-arrow .icon {
  background-color: rgba(104, 67, 255, 0.77) !important;
  color: #ffffff !important;
  cursor: pointer;
  border: 1px solid transparent;
  width: 45px;
  height: 45px;
  padding: 9px 14px;
  /**outline: 0;**/
}


#home-live-carousel .carousel-arrow .icon.has-icons-right {
  right: 2.5rem;
}
</style>
