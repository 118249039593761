<template>
  <vueScroll id="browse-profile-servers" :ops="ops" class="main-scroll">
    <div class="cata-img">
      <img :src="userProfileDefaultBackgroundImg" alt="Profile-Background-Img">
    </div>

    <div class="single-server">
      <div class="single-server-owner vcentered">
        <div v-if="this.user && this.user.image" class="profile-thumb"
             :style="{ 'background-image': `url(${setThumb(this.user.image)})` }"
        ></div>
        <div v-else class="profile-thumb"
             :style="{ 'background-image': `url(${userDefaultImg})` }"
        ></div>
        <div class="single-server-owner-text">
          <p v-if="this.user && this.user.firstName" class="single-server-owner-text-title">
            {{ this.user.firstName }} {{ this.user.lastName }}
          </p>
          <p v-else-if="this.user && !this.user.firstName" class="single-server-owner-text-title">
            {{ this.user.username }}
          </p>
          <p v-if="user">Member Since: {{user.created_at | moment("MM YYYY")}}</p>
<!--          <div class="single-server-tags">-->
<!--            <b-tag-->
<!--                v-for="category in categories.slice(0,3)"-->
<!--                :key="`user-category-${category._id}`"-->
<!--                rounded-->
<!--                class="single-server-category"-->
<!--            >-->
<!--              <p-->
<!--                  @click="goToCategory"-->
<!--                  style="cursor: pointer;"-->
<!--              >-->
<!--                {{ category.name }}-->
<!--              </p>-->
<!--            </b-tag>-->
<!--          </div>-->
        </div>
      </div>
    </div>

    <div id="profile-filters" ref="mainLive">
      <div id="browse-profile-heading"><h3>User <small style="color: #5c5c5c;">Servers</small></h3></div>

<!--      <div id="browse-profile-actions">-->
<!--        <div id="browse-profile-sort">-->
<!--          &lt;!&ndash;div class="display-tables"></div&ndash;&gt;-->
<!--          <b-button id="browse-profile-sorting">-->
<!--            <fa-icon v-if="sortingAsc" @click="sortingAsc = false" icon="sort-amount-up" :size="$faDefaultIconSize"/>-->
<!--            <fa-icon v-if="!sortingAsc" @click="sortingAsc = true" icon="sort-amount-down" :size="$faDefaultIconSize"/>-->
<!--          </b-button>-->
<!--          <div class="dropdown-label field"><span>Sort by:</span></div>-->
<!--          <b-field>-->
<!--            <b-select v-model="selectedOption">-->
<!--              <option value="famous" selected>Popularity</option>-->
<!--              <option value="dateCreated">Date Created</option>-->

<!--            </b-select>-->
<!--          </b-field>-->
<!--        </div>-->
<!--        <div id="browse-profile-search">-->
<!--          <BrowseSearch></BrowseSearch>-->
<!--        </div>-->
<!--      </div>-->

    </div>

    <div id="browse-profile-list" class="columns" v-if="user && servers && servers.length">
<!--      <div class="list-center">-->
<!--        <SingleServer-->
<!--            class="browse-single column"-->
<!--            :style="{ 'width': itemCol+'%', 'max-width': itemCol+'%' }"-->
<!--            v-for="server in servers"-->
<!--            :key="server._id"-->
<!--            :server="server"-->
<!--        />-->


        <BrowseServersChild
            class="browse-single column"
            :style="{ 'width': itemCol+'%', 'max-width': itemCol+'%' }"
            v-for="(server) in servers"
            :key="server._id"
            :server="server"
            :user="user"
        ></BrowseServersChild>

<!--      </div>-->
    </div>
  </vueScroll>
</template>

<script>
import axios from '../axios';
import vueScroll from 'vuescroll';
// import BrowseSearch from '../components/BrowseCore/BrowseSearch';
// import SingleServer from "@/components/ServersCore/SingleServer";
import BrowseServersChild from "../components/BrowseCore/BrowseServersChild";
import moment from 'moment';
import EventBus from "@/eventBus";

export default {
  name: 'UserProfile',
  components: {
    // BrowseSearch,
    // SingleServer,
    BrowseServersChild,
    vueScroll
  },
  data() {
    return {
      username: null,
      user: null,
      loading: false,
      sortingAsc: false,
      enableScroll: false,
      skipFamous: null,
      showLoadMore: true,
      selectedOption: 'famous',
      servers: [],
      categories: [],
      showCarousel: true,
      itemCol: 25,
      browseWidth: null,
      ops: {
        vuescroll: {
          mode: 'native',
          sizeStrategy: 'percent',
          detectResize: false,
          /** Enable locking to the main axis if user moves only slightly on one of them at start */
          locking: false,
        },
        scrollPanel: {
          initialScrollY: '0%',
          scrollingX: false,
          scrollingY: true,
          speed: 200,
          //easing: undefined,
          //verticalNativeBarPos: 'left'
        },
        rail: {},
        bar: {},
      },
    }
  },
  async created() {
    this.username = this.$route.params.name;
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === 'set_userDataAfterLogIn' && mutation.payload) {
        this.loadUser(this.username);
      }
    });

    EventBus.$on('showUserProfile', (payload) => {
      this.username = payload;
      this.loadUser(this.username);
    });

    if (this.userDataAfterLogIn && this.userDataAfterLogIn._id) {
      this.loadUser(this.username);
    }
  },
  computed: {
    userProfileDefaultBackgroundImg() {
      return require('@/assets/img/profile-background-img.png');
    },
    userDefaultImg() {
      return require('@/assets/img/user-profile-default.png');
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    }
  },
  watch: {
    selectedOption(newValue) {
      if (newValue === 'dateCreated') {
        // console.log(`SelectedOption value: ${newValue}`)
        this.servers = [];
        this.fetchServersByDate();
      } else {
        this.servers = [];
        this.fetchServersBySubs();
      }
    },
    sortingAsc() {
      this.servers.reverse();
    },
    browseWidth(newval, oldval) {
      //console.log('browseWidth', newval);
      if (newval > 1600) {
        this.columns = 4;
      } else if (newval <= 992 && newval > 530) {
        this.columns = 2;
      } else if (newval <= 530) {
        this.columns = 1;
      } else {
        this.columns = 3;
      }
      this.itemCol = parseFloat((100 / this.columns) - 0.5);
    }
  },
  methods: {
    getUserProfileDefaultBackgroundImg() {
      return this.userProfileDefaultBackgroundImg;
    },
    memberSinceDate() {
      console.log(this.user.created_at);
      return this.user.created_at;
    },
    async loadUser(username) {
      this.user = null;
      await this.fetchUser(username);
      await this.fetchServersBySubs();
    },
    async fetchUser(username) {
      try {
        const {data} = await axios.get(`/users/username/${username}`);
        this.user = data;

      } catch (e) {
        console.log(e.response.message);
      }
    },
    async fetchServersBySubs() {
      this.loading = true;
      this.servers = [];
      this.categories = [];
      const categoriesNames = [];
      try {
        const {data} = await axios.get(`servers/owner-by-subs/${this.username}`);
        this.servers = data;
        data.forEach(server => {
          server.categories.forEach(category => {
            // Didn't work with this.categories.includes(category) for some reason
            if (!categoriesNames.includes(category.name)) {
              categoriesNames.push(category.name);
              this.categories.push(category);
            }
          });
        });
      } catch (e) {
        console.log(e.response.message);
      }
      this.loading = false;
    },
    async fetchServersByDate() {
      this.loading = true;
      this.servers = [];
      this.categories = [];
      const categoriesNames = [];
      try {
        const {data} = await axios.get(`servers/owner-by-date/${this.username}`);
        this.servers = data;
        data.forEach(server => {
          server.categories.forEach(category => {
            if (!categoriesNames.includes(category.name)) {
              categoriesNames.push(category.name);
              this.categories.push(category);
            }
          });
        });
      } catch (e) {
        console.log(e.response.message);
      }
      this.loading = false;
    },
    goToCategory(category) {
      console.log('category: ', category)
      this.$router.push({name: 'Browse', params: {category}});
    },
    setThumb(image) {
      if (image && image.length) {
        if ('http' === image.slice(0, 4)) {
          return image;
        } else {
          if ('profile' === image.slice(0, 7)) {
            return this.$imageurl + '/' + image;
          } else {
            return this.userDefaultImg;
          }
        }
      }
      return this.userDefaultImg;
    },
  }
}
</script>

<style>

/*.main-scroll {*/
/*  overflow: auto;*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/

.profile-thumb {
  width: 81px;
  height: 80px;
  border-radius: 25px;
  background-color: #232728;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: inline-block;
}

#browse-profile-servers {
  /*width: 100%;*/
  /*max-width: 100%;*/
  /*white-space: nowrap;*/
  /*position: relative;*/
  /*margin: 0;*/
  /*display: inline-flex;*/
}

#browse-profile-categories {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  background-color: #23272a;
  overflow: hidden;
  padding: 0.5em 0;
  width: 255px; /** zeplin */
  max-width: 255px;
}


#browse-profile-main {
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  /**padding: 0.75em 0.75em 0.75em;*/
  padding: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #1d2023;
  width: calc(100% - 1px);
  max-width: calc(100% - 1px);
}

#browse-profile-main.has-categories {
  width: calc(100% - 256px);
  max-width: calc(100% - 256px);
}

#browse-profile-slider {
  padding: 55px 0;
}

#browse-profile-filters {
  padding: 30px;
  display: flex;
  align-items: center;
}

#browse-profile-overlay {
  height: 100%;
  max-height: 100%;
  display: inline-block;
  position: relative;
  padding: 0.75em 0.75em 1.25em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: #1d2023;
  width: 100%;
}

#browse-profile-sorting {
  background-color: transparent;
  border-color: transparent;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

#browse-profile-categories-select {
  display: inline-block;
  margin-right: 1em;
}

#browse-profile-categories-select .select select,
#browse-profile-categories-select .select select:active,
#browse-profile-categories-select .select select:visited,
#browse-profile-categories-select .select select:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
  box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
}

#browse-profile-sort {
  display: inline-block;
}


#browse-profile-categories-select .field,
#browse-profile-sort .field {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

#browse-profile-categories-select select,
#browse-profile-sort select {
  background-color: transparent;
  border-color: transparent;
  border-radius: 4px;
  color: #664bf3;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.47px;
  padding-right: 1.5em;
  height: 100%;
  /*display: table;*/
}


#browse-profile-sort .select select,
#browse-profile-sort .select select:active,
#browse-profile-sort .select select:visited,
#browse-profile-sort .select select:hover {
  border-color: transparent;
  -webkit-box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
  box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0);
}


#browse-profile-categories-select .select select option,
#browse-profile-sort .select select option {
  padding: calc(0.75em - 1px) calc(0.75em - 1px);
  display: table-caption;
  font-weight: bold;
  background-color: #23272a;
  /*font-family: Circular, 'sans-serif ';*/
}

#browse-profile-categories-select .select select option:hover,
#browse-profile-sort .select select option:hover {
  background-color: #303538 !important;
  color: #fff;
}

#browse-profile-categories-select .select select option span,
#browse-profile-sort .select select option span {
  /*display: table-cell;*/
}


#browse-profile-categories-select .select:not(.is-multiple):not(.is-loading)::after,
#browse-profile-sort .select:not(.is-multiple):not(.is-loading)::after {
  border-color: #664bf3;
  margin-top: -0.37em;
}

#browse-profile-categories-select .select,
#browse-profile-sort .select {
  height: auto;
}

#browse-profile-search {
  width: 168px;
  max-width: 168px;
  display: inline-block;
  color: #424345;
  vertical-align: middle;
}

#browse-profile-search input {
  color: #424345;
  border-radius: 8px;
  background-color: #2e3135;
  /**height: 34px;*/
  border: 1px solid #2e3135;

}

#browse-profile-search i {
  color: #5c6064;
}

#browse-profile-search input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: #5c6064;
}

#browse-profile-search input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color: #5c6064;
  opacity: 1;
}

#browse-profile-search input::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #5c6064;
  opacity: 1;
}

#browse-profile-search input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #5c6064;
}

#browse-profile-search input::-ms-input-placeholder { /* Microsoft Edge */
  color: #5c6064;
}

#browse-profile-search input::placeholder { /* Most modern browsers support this now. */
  color: #5c6064;
}


#browse-profile-window {
  padding: 1em 0;
  overflow: hidden;
  /**height: calc(100% - 75px );
  max-height: calc(100% - 75px );*/
}

#browse-profile-list {
  height: 99%;
  max-height: 99%;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  white-space: normal;
  padding: 0 22px !important;
  margin: 0;
}

#browse-profile-list.has-carousel {
  height: calc(100% - 460px) !important;
  max-height: calc(100% - 460px) !important;
}

/*#browse-list .__vuescroll {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/
/*#browse-list .__panel {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/

/*#browse-list .__view {*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/


#browse-profile-load-more {
  width: 100%;
  text-align: center;
  margin: 1em 0 3em;
}

#browse-profile-load-more button {
  background-color: #303538;
  border-color: #303538;
  color: #797979;
}


#browse-profile-heading {
  display: inline-block;
  width: 200px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.67px;
  color: #ffffff;
  padding: 20px;
}

#browse-profile-actions {
  display: inline-block;
  text-align: right;
  width: calc(100% - 200px);
  max-width: calc(100% - 200px);
  /*border: 1px solid darkgoldenrod;*/
}

.dropdown-label {
  margin-right: 6px !important;
  color: #cccbcb;
  font-size: 14px;
  font-weight: 600;
  margin-top: 3px !important;
}

/*.dropdown-label span {*/

/*}*/


/*.display-table {*/
/*  display: table;*/
/*}*/

/*.browse-one {*/
/*  padding: 8px;*/
/*  display: inline-block !important;*/
/*}*/

.main-scroll,
.has-scroll {
  overflow: auto;
  height: 100%;
  max-height: 100%;
}

/*.no-scroll {*/
/*  overflow: hidden;*/
/*  height: 100%;*/
/*  max-height: 100%;*/
/*}*/

/*.chat-scroll {*/
/*  padding: 0;*/
/*  margin: 0;*/
/*}*/


/*.browse-slider {*/
/*  position: relative;*/
/*  background-color: #171C22;*/
/*  padding-bottom: 40px;*/
/*}*/


</style>
