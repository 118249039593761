import SocketClass from "./socketClass";
export default class RoomsClass {
  constructor() {}

  async onRoomCreation(payload) {
    SocketClass.store.dispatch('newRoomCreated', payload)
  }

  async onNewMessageSocketHandler(newMessageInfo) {
    console.log('step 4 onNewMessageSocketHandler inside RoomsClass works and newMessageInfo is ', newMessageInfo);
    SocketClass.store.dispatch('newRoomMessage', newMessageInfo);
  }
  async onIsTypingSocketHandler(isTypingDTO) {
    SocketClass.store.dispatch('isTyping', isTypingDTO);
  }

  async onMessageDeletedSocketHandler(deleteMessageInfo) {
    console.log('STEP INSIDE onMessageDeletedSocketHandler , deleteMessageInfo is  ',deleteMessageInfo);
    SocketClass.store.dispatch('deleteRoomMessage', deleteMessageInfo);
  }

  async onMessageEditedSocketHandler(editMessageInfo) {
    SocketClass.store.dispatch('editRoomMessage', editMessageInfo);
  }


  async onCameraStarted(broadcastStartedInfo) {
    //console.log('onCameraStarted broadcastStartedInfo ' ,broadcastStartedInfo)
    SocketClass.store.dispatch('cameraStarted');
  }

  async onCameraStopped(broadcastStartedInfo) {
    //console.log('onCameraStopped broadcastStartedInfo ' ,broadcastStartedInfo)
    SocketClass.store.dispatch('cameraEnded');
  }

}
