<template>
  <div id="profile-image-upload" class="image-upload">
    <b-tag
        v-if="previewImage"
        type="is-primary"
        id="profile-image-upload-preview"
        :style="{ 'background-image': `url(${avatar})` }"
        :closable="closable"
        aria-close-label="Close tag"
        @close="removeUpload"
    />
    <b-tag
        v-else
        type="is-primary"
        id="profile-image-upload-preview"
        class="no-avatar"
    >
      <img :src="avatar" />
    </b-tag>
    <!--span class="preview-file-name" v-if="previewImage">{{ previewImage.name }}</span-->
    <b-field class="file is-primary" :class="{'has-name': !avatar}">
    </b-field>
    <b-button
        v-if="!imageUploaded"
        id="profile-image-upload-button1"
        class="image-upload-button"
        :loading="uploading"
        @click="showCropper = true"
    >
      + Upload Profile Picture
    </b-button>
    <Cropper v-if="showCropper" :original="''" v-on:cropperClose="showCropper = false"
             v-on:cropperSave="cropperSave"/>
  </div>
</template>

<script>
import axios from "@/axios";
import Cropper from "@/components/Traits/Cropper";

export default {
  name: "ProfileImage",
  components: {
    Cropper
  },
  props: [
    'tempImage',
    'uploading',
    'imageUploaded'
  ],
  data() {
    return {
      showUserSettingsModal: true,
      userImage: null,
      userUploadedImages: null,
      previewImage: null,
      needSave: false,
      avatar: null,
      loading: false,
      updatedUserData: null,
      cropped: null,
      cropped_image: false,
      modalShow: false,
      origImage: null,
      origImageRead: null,
      croppieImage: '',
      letters: 'PR',
      showCropper: false,
    }
  },
  watch: {
    userDataAfterLogIn(newval) {
      // console.log('userDataAfterLogIn @ user profile', newval);
      this.setAvatar();
    },
    previewImage(newval) {
      // console.log('previewImage watch', newval);
      this.$emit('previewImageUpdate', newval);
      this.setAvatar();
    }
  },
  computed: {
    defaultOwnerImage() {
      return this.$userDefaultImage;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    closable() {
      return this.previewImage !== null;
    }
  },
  created() {
    console.log('UserProfile Loaded', this.tempImage);
    this.setAvatar();
  },
  methods: {
    selectImage() {
      // this.$refs.fileInput.click();
    },
    removeUpload() {
      this.previewImage = null;
    },
    cropperSave(croppedImage) {
      console.log('croppedImage', croppedImage);

      this.showCropper = false;
      this.updateImage(croppedImage);
    },
    async updateImage(image) {
      const upload = await this.handleUpload(image);
      console.log('uploaded', upload);
      if (upload.uploadedImage) {
        this.previewImage = upload.uploadedImage;
      }
    },
    async handleUpload(file) {
      let formData = new FormData();
      formData.append('image', file);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      this.loading = true;
      try {
        const {data}  = await axios.post('/images/profile', formData, {
          headers: axHeaders
        });
        this.loading = false;
        return data;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    async handleSaveProfile() {
      //this.handleUpload();
    },
    imageSelected() {
      console.log('selected', this.userImage);
      //this.handleUpload();
    },
    setAvatar() {
      if (this.previewImage) {
        this.avatar = this.$imageurl + '/' + this.previewImage.filename
      } else {
        this.avatar = this.defaultOwnerImage;
      }
    },
    handleUpdateProfile() {

    },
  }
}
</script>

<style>

#profile-image-upload {
  width: 100%;
  text-align: center;
  padding: 36px 0 54px;
  border-bottom: 1px solid #2D3034;
}

#profile-image-upload-preview {
  width: 99px;
  height: 99px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  /**padding: 28px 25px 29px;*/
  /**border-radius: 35px;*/
  border-radius: 35px;
  background-color: #632ff3;
  margin-bottom: 12px;
}

#profile-image-upload-preview.no-avatar {
  padding: 21px;
}

#profile-image-upload-button {
  /**font-family: 'Circular Std Medium', sans-serif;*/
  color: #ffffff;
  border: 0 solid;
  padding: 5px 14px;
  border-radius: 6px;
  background-color: #272b2e;
}

#profile-image-upload-button:hover {
  color: #ffffff;
}

.preview-file-name {

}

</style>
