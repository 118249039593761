<template>
  <div id="edit-server-image-upload">
    <div id="edit-server-image-upload-preview-wrapper" >
      <b-tag
          v-if="avatar"
          type="is-primary"
          id="edit-server-image-upload-preview"
          :style="{ 'background-image': `url(${avatar})` }"
          :closable="closable"
          aria-close-label="Close tag"
          @close="removeUpload"
      />
      <b-tag
          v-else
          type="is-primary"
          id="edit-server-image-upload-preview"
          class="no-avatar vcentered"
      >{{ letters }}
        <!--        <div class="letters">{{letters}}</div>-->
      </b-tag>
      <div style="display: inline-block; margin-left: 29px; text-align: center">
        <p style="font-size: 11px;">512x512 image</p>
        <p style="font-size: 11px;">recommended</p>
      </div>
    </div>
    <div style="width: 100%;display: inline-block;text-align: center">
      <b-button
          v-if="!imageUploaded"
          style="width: 100%;"
          id="edit-server-image-upload-button"
          class="server-image-upload-button"
          :loading="uploading"
          @click="showCropper = true">
        + Upload Server Picture
      </b-button>
    </div>
    <Cropper v-if="showCropper" :original="''" v-on:cropperClose="showCropper = false"
             v-on:cropperSave="cropperSave"/>
  </div>
</template>

<script>
import axios from "@/axios";
import Cropper from "@/components/Traits/Cropper";

export default {
  name: "EditServerImageUpload",
  components: {Cropper},
  props: ['server', 'tempImage', 'uploading', 'imageUploaded', 'serverUploadedImage', 'servername'],
  data() {
    return {
      showUserSettingsModal: true,
      serverImage: null,
      previewImage: null,
      needSave: false,
      avatar: null,
      loading: false,
      updatedServerData: null,
      showCropper: false,
      letters: 'PR'
    }
  },
  watch: {
    previewImage(newval) {
      this.$emit('previewImageUpdate', newval);
      this.setAvatar();
    },
    servername(newval) {
      if (newval.length) {
        const arr = newval.match(/\b(\w)/g);
        if (arr.length > 2) {
          arr.splice(2);
          this.letters = arr.join('').toUpperCase();
        } else {
          this.letters = arr.join('').toUpperCase();
        }
        // console.log('servername', arr, this.letters);
      } else {
        this.letters = 'PR';
      }
    }
  },
  computed: {
    serverThumbDefault() {
      return require('@/assets/img/server-thumb-default.svg');
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    closable() {
      return this.previewImage !== null;
    },
    // letters() {
    //   return this.servername.match(/\b(\w)/g);
    // }


  },
  created() {
    this.previewImage = this.imageUploaded;
    this.setAvatar();
  },
  methods: {
    selectImage() {
      // this.$refs.fileInput.click();
    },
    removeUpload() {
      this.previewImage = null;
    },
    cropperSave(croppedImage) {
      console.log('croppedImage', croppedImage);
      // this.origImage = croppedImage;
      this.showCropper = false;
      this.updateImage(croppedImage);
    },
    async updateImage(croppedImage) {
      let uploaded = await this.handleUpload(croppedImage);
      if (uploaded.uploadedImage) {
        console.log('uploaded', uploaded.uploadedImage);
        this.previewImage = uploaded.uploadedImage;
      }
      //this.setAvatar();
    },
    async handleUpload(file) {
      let formData = new FormData();
      //formData.append('file', this.previewImage);
      formData.append('image', file);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      this.loading = true;
      try {
        const {data} = await axios.post('/images/server', formData, {
          headers: axHeaders
        });
        this.loading = false;
        return data;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    async handleSaveProfile() {
      //this.handleUpload();
    },
    imageSelected() {
      console.log('selected', this.serverImage);
      //this.handleUpload();
    },
    async handleUploadOld() {
      let formData = new FormData();
      //formData.append('file', this.previewImage);
      formData.append('image', this.previewImage);
      let axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      this.loading = true;
      try {
        let response = await axios.post('/images/profile', formData, {
          headers: axHeaders
        });
        this.loading = false;
        return response;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    setAvatar() {
      if (this.previewImage) {
        this.avatar = this.$imageurl + '/' + this.previewImage.filename
      } else {
        // this.avatar = this.serverThumbDefault;
        this.avatar = this.tempImage ? this.$imageurl + '/' + this.tempImage : '';
        // this.avatar = '';
      }
      return this.avatar;
    },
  }
}
</script>

<style>

#edit-server-image-upload {
  width: 100%;
  overflow: hidden;
  white-space: normal;
  /*text-align: center;*/
  /*margin: 31px 0;*/
  /*display: table;*/
}


#edit-server-image-upload-preview-wrapper {
  display: block;
  padding-bottom: 14px;
}

#edit-server-image-upload-preview {
  width: 74px;
  height: 74px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  /**padding: 28px 25px 29px;*/
  /**border-radius: 35px;*/
  border-radius: 26px;
  background-color: #632ff3;
  /*background-color: #272b2e;*/
  /**margin-bottom: 12px;*/
  /*display: inline-block;*/

}

#edit-server-image-upload-preview.no-avatar {
  /*display: inline-block;*/
  /*vertical-align: middle;*/
  /*position: relative;*/
}

#edit-server-image-upload-preview.no-avatar span {
  /*width: 100%;*/
  /*height: 100%;*/
  text-align: center;
  font-size: 2.8em;
  /*position: absolute;*/
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-52%, -50%);*/
  /*text-transform: uppercase;*/
  /*line-height: 3em;*/
  /*display: inline-block;*/
  /*vertical-align: middle;*/
}

#edit-server-image-upload-button-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: calc(100% - 120px);
  max-width: calc(100% - 120px);
}

#edit-server-image-upload-button,
#edit-server-image-uploaded {
  /**font-family: 'Circular Std Medium', sans-serif;*/
  color: #ffffff;
  border: 0 solid;
  padding: 5px 14px;
  border-radius: 6px;
  background-color: #6458FF;
  width: 195px;
  height: 35px;
}

#edit-server-image-upload-button span {
  font-size: 15px;
  line-height: 18px;
}

#edit-server-image-upload-button:hover {
  color: #ffffff;
}




</style>
