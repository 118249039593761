<template>
  <section>
    <h2>My Profile Settings</h2>
    <form id="user-profile-settings-form" @submit.prevent="handleUpdateProfile">
      <b-field label="First Name"
               class="form-field"

      >
        <b-input type="text"
                 autocomplete="off"
                 class="signup-input"
                 v-model="firstName"
                 vid="firstName"
                 :readonly="loading"
        >
        </b-input>
      </b-field>

      <b-field label="Last Name"
               class="form-field"
      >
        <b-input type="text"
                 autocomplete="off"
                 class="signup-input"
                 v-model="lastName"
                 vid="lastName"
                 :readonly="loading"
        >
        </b-input>
      </b-field>
      <div class="image-upload">
        <span>&nbsp;</span>
        <b-tag
            type="is-primary"
            id="profile-update-upload-preview"
            :style="{ 'background-image': `url(${avatar})` }"
            :closable="closable"
            aria-close-label="Close tag"
            @close="removeUpload"></b-tag>
        <span class="preview-file-name" v-if="previewImage">{{ previewImage.name }}</span>
        <b-field class="file is-primary" :class="{'has-name': !avatar}">

        </b-field>
        <b-button v-if="!previewImage" @click="showCropper = true" class="profile-update-button">Choose Another</b-button>
        <!--b-button class="profile-update-button" v-if="userImage" @click="handleUpload">Upload</b-button-->
        <b-button class="profile-update-button" v-if="previewImage && needSave" @click="updateImage()">Update Image
        </b-button>
        <b-button class="profile-update-button" @click="updateUserData()">Update Profile</b-button>

        <Cropper v-if="showCropper" :original="avatar" v-on:cropperClose="showCropper = false"
                 v-on:cropperSave="cropperSave"/>
      </div>
    </form>
  </section>
</template>

<script>
import axios from "@/axios";
import Cropper from "@/components/Traits/Cropper";

export default {
  name: "UserProfile",
  components: {Cropper},
  data() {
    return {
      showUserSettingsModal: true,
      imageToCrop: null,
      userImage: null,
      userUploadedImage: null,
      previewImage: null,
      needSave: false,
      avatar: null,
      loading: false,
      updatedUserData: {},
      firstName: null,
      lastName: null,
      cropped: null,
      cropped_image: false,
      modalShow: false,
      origImage: null,
      origImageRead: null,
      croppieImage: '',
      showCropper: false,
    }
  },
  watch: {
    userDataAfterLogIn(newval) {
      console.log('userDataAfterLogIn @ user profile', newval);
      this.setAvatar();
    },
  },
  computed: {
    defaultOwnerImage() {
      // return require('@/assets/img/user-profile-default.png');
      return this.$userDefaultImage;
    },
    userDataAfterLogIn() {
      return this.$store.getters.get_userDataAfterLogIn;
    },
    closable() {
      return this.previewImage !== null;
    }
  },
  created() {
    // console.log('UserProfile Loaded', this.userDataAfterLogIn);
    this.firstName = this.userDataAfterLogIn.firstName;
    this.lastName = this.userDataAfterLogIn.lastName;
    this.setAvatar();
  },
  methods: {
    cropperSave(croppedImage) {
      console.log('croppedImage', croppedImage);
      // this.origImage = croppedImage;
      this.showCropper = false;
      this.updateImage(croppedImage);
    },
    selectImage() {
      this.showCropper = true;
    },
    removeUpload() {
      this.setAvatar();
    },
    handleUpdateProfile() {
    },
    async updateImage(croppedImage) {
      const uploaded = await this.handleUpload(croppedImage);
      if (uploaded.uploadedImage) {
        this.updatedUserData.image = uploaded.uploadedImage.filename;
        this.updateUserData();
      } else {
        this.updatedUserData.image = '';
      }
    },
    async updateUserData() {
      this.updatedUserData.firstName = this.firstName;
      this.updatedUserData.lastName = this.lastName;
      const axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'application/json';
      const response = await axios.put(`/users/${this.userDataAfterLogIn._id}`, this.updatedUserData, {
        headers: axHeaders
      });
      if (response.data && response.data._id) {
        this.$store.commit('set_userDataAfterLogIn', response.data);
      }
    },
    async handleSaveProfile() {
      //this.handleUpload();
    },
    imageSelected() {
      console.log('selected', this.userImage);
      //this.handleUpload();
    },
    async handleUpload(file) {
      const formData = new FormData();
      formData.append('image', file);
      const axHeaders = axios.defaults.headers;
      axHeaders['Content-Type'] = 'multipart/form-data';
      this.loading = true;
      try {
        const {data} = await axios.post('/images/profile', formData, {
          headers: axHeaders
        });
        this.loading = false;
        return data;
      } catch (e) {
        this.loading = false;
        console.log('error:', e);
        return e;
      }
    },
    setAvatar() {
      this.previewImage = null;
      if (this.userDataAfterLogIn.image) {
        if ('http' === this.userDataAfterLogIn.image.slice(0, 4)) {
          this.avatar = this.userDataAfterLogIn.image;
        } else {
          if ('profile' === this.userDataAfterLogIn.image.slice(0, 7)) {
            this.avatar = this.$imageurl + '/' + this.userDataAfterLogIn.image;
          } else {
            this.avatar = this.userDataAfterLogIn.image;
          }
        }
      } else {
        this.avatar = this.defaultOwnerImage;
      }
    },
  }
}
</script>

<style>
#profile-update-upload-preview {
  width: 80px;
  height: 80px;
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

button.profile-update-button {
  margin: 0.5em 0;
}

.preview-file-name {

}

</style>
