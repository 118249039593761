<template>
  <div>404</div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>
